import { ScoreRangeBlockProps } from './types/score-range-block-types'
const ScoreRangeBlock = (props: Readonly<ScoreRangeBlockProps>) => {
  const { min, max, color, label } = props.model
  return (
    <div className="flex flex-row items-center md:mt-2 mt-[0.65rem]">
      {color &&
        <div className='bg-auto w-5 h-5 mx-2 rounded-md' style={{ backgroundColor: color }}></div>}
      <p className="font-normal font-mulish md:text-md text-[0.9rem]">
        {label}
        {'(' + min + ' - ' + max + ')'}
      </p>
    </div>
  )
}

export default ScoreRangeBlock
