import { CartLineItemModel, OrderModel } from '@/api/types/content-delivery-types'
import { PartialNullable } from '@/lib/partial-nullable.type'
import { EventType } from '../enums/event-type.enum'
import { cartLinesToEventItemParameters } from '../functions/cart-line-to-event-item-parameters.function'
import { getCurrency } from '../functions/get-currency.function'
import { pushEvent } from '../functions/push-event.function'
import { EventItemParameters } from '../types/event-item-parameters.type'

export const purchaseGTM = async (trackingNumber: string, order: OrderModel | undefined, shippingMethodName: any) => {
  const cartLineItems: CartLineItemModel[] = []
  const shipments = order?.shipments ? [...(order?.shipments ?? [])] : []
  shipments.forEach((shipment) => shipment.lineItems.forEach((lineItem) => cartLineItems.push({ ...lineItem })))
  const items: PartialNullable<EventItemParameters>[] = await cartLinesToEventItemParameters(cartLineItems)

  pushEvent({
    event: EventType.Purchase,

    ecommerce: {
      currency: getCurrency({ order: order }),
      value: order?.totals.total,
      shipping: shippingMethodName,
      tax: order?.totals.taxTotal,
      transaction_id: trackingNumber,
      items: items,
    },
  })
}
