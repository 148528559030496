import CardSlider from '@/components/shared/card-slider/card-slider'
import { useGlobalSettings } from '@/stores/global-settings'
import { useMediaStore } from '@/stores/media-store'
import { useEffect, useState } from 'react'
import { uid } from 'uid'
import { HighlightsProps } from '../types/index-types'
import HighlightCard from './highlight-card'

function Highlights(props: Readonly<HighlightsProps>) {
  const { headline, list } = props

  const globalSettings = useGlobalSettings((state) => state.globalSettings)

  const [showCardAmount, setShowCardAmount] = useState(0)
  const { isTablet, isMobile, isSmallMobile } = useMediaStore()

  const handleShowCardAmount = () => {
    if (isSmallMobile) {
      setShowCardAmount(1)
    } else if (isMobile) {
      setShowCardAmount(2)
    } else if (isTablet) {
      setShowCardAmount(3)
    } else {
      setShowCardAmount(4)
    }
  }

  useEffect(() => {
    handleShowCardAmount()
  }, [isTablet, isMobile, isSmallMobile])

  return (
    <div className="flex flex-col space-y-6 md:space-y-12">
      {headline && (
        <h2 className="text-2xl md:text-3xl font-bold text-grey-dark">
          {headline}
        </h2>
      )}
      {list && list.length > 0 && showCardAmount > 0 && (
        <CardSlider
          cardsToShow={showCardAmount}
          cardTotal={list.length}
          nextScreenreaderText={globalSettings?.sliderNextAriaText}
          previousScreenreaderText={globalSettings?.sliderPreviousAriaText}
        >
          {list.map((card) => (
            <HighlightCard key={uid()} {...card} />
          ))}
        </CardSlider>
      )}
    </div>
  )
}

export default Highlights
