import { CartInfoModel } from '@/api/types/content-delivery-types'
import { DEFAULT_PRICE, DEFAULT_QUANTITY } from '../constants'
import { DEFAULT_CURRENCY_CODE } from '@/lib/constants'
import { EventType } from '../enums/event-type.enum'
import { createItem } from '../functions/create-item.function'
import { includeItemCategories } from '../functions/include-item-categories.function'
import { includeItemLanguage } from '../functions/include-item-language.function'
import { pushEvent } from '../functions/push-event.function'
import { ProductId } from '../types/product-id.type'

export const addToCartGTM = async (
  productId: ProductId,
  variant: any,
  price: number | undefined,
  quantity: number,
  cartInfo: CartInfoModel | null | undefined,
) => {
  const item = createItem(productId, variant)

  await includeItemCategories(item, productId)
  await includeItemLanguage(item, productId)

  item.price = price ?? DEFAULT_PRICE
  item.quantity = quantity || DEFAULT_QUANTITY
  item.item_variant_id = variant.variantCode || variant.code

  const ecommerce = {
    currency: cartInfo?.currency ?? DEFAULT_CURRENCY_CODE,
    value: price && quantity ? price * quantity : DEFAULT_PRICE,
    items: [item],
  }

  pushEvent({
    event: EventType.AddToCart,
    ecommerce: ecommerce,
  })
}
