import { IndexHeroProps } from '../types/index-types'

function HeroIndex(props: Readonly<IndexHeroProps>) {
  const { heading, subheading, children } = props

  return (
    <div className="flex justify-center">
      <div className="md:w-1/2 flex flex-col space-y-4 md:space-y-6">
        {heading && (
          <div
            className="text-4xl md:text-6xl md:text-center text-grey-dark font-bold"
            dangerouslySetInnerHTML={{ __html: heading }}
          ></div>
        )}
        {subheading && (
          <div className="flex md:justify-center">
            <div
              className="text-lg md:text-xl md:text-center text-grey-light prose md:prose-xl prose-headings:black prose-a:text-dark-blue-primary hover:prose-a:underline !max-w-none"
              dangerouslySetInnerHTML={{ __html: subheading }}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

export default HeroIndex
