import SharedLink from '@/components/shared/buttons/shared-link'
import { ScheduleConsultationBlockProps } from './types/schedule-a-consultation-block-types'

const ScheduleConsultationBlock: React.FC<ScheduleConsultationBlockProps> = ({
  model,
}) => {
  return (
    <section className='container wvprp-sac-block'>
      <div className="w-full flex flex-col md:flex-row md:justify-between bg-grey-secondary-dark px-8 py-8 md:py-12 md:px-10 md:mb-22 bg-light-blue-100 text-grey-dark md:flex md:justify-between rounded-lg">
        <div className='W-1/2'>
          {model.scheduleConsultationBodyText && (
            <p className="text-lg lg:max-w-[496px]">
              {model.scheduleConsultationBodyText}
            </p>
          )}
        </div>
        <div className='mt-4 md:flex md:flex-row md:justify-center md:items-center md:w-1/2'>
          {model.scheduleConsultationLink && (
            <SharedLink
              style="primary"
              {...model.scheduleConsultationLink}
              className="lg:text-lg"
            >
              {model.scheduleConsultationLink?.text}
            </SharedLink>
          )}
        </div>
      </div>
    </section>
  )
}

export default ScheduleConsultationBlock
