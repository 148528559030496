import { FeaturedTextToImageProps } from '@/components/blocks/featured-text-to-image/types/featured-text-to-image-types'
import SharedImage from '@/components/shared/image/shared-image'
import SectionHeader from '@/components/shared/section-header'
import { useEffect, useMemo, useRef, useState } from 'react'
import { uid } from 'uid'

const FeaturedTextToImageBlock = (
  props: Readonly<FeaturedTextToImageProps>,
) => {
  const { smallIntro, heading, subheading, items } = props.model
  const fadeElms = useRef<HTMLElement[]>([])
  const [activeIndex, setActiveIndex] = useState(0)

  const blockRef = useRef<HTMLElement>(null)

  const itemsAmended = useMemo(() => {
    if (!items) return
    return items.map((item) => {
      return { ...item, id: uid() }
    })
  }, [items])

  useEffect(() => {
    const windowCenter = Math.ceil((window.innerHeight + 150) / 2)
    const callback: IntersectionObserverCallback = (entries) =>
      entries.forEach((entry) => {
        // uncomment to add scrolling opacity from bottom to top
        // if (!entry.isIntersecting) {
        //   entry.target.classList.replace('md:opacity-100', 'md:opacity-0')
        // }
      })

    const offsets: number[] = []
    let itemsViewed: number[] = [0]

    const onScroll = () => {
      fadeElms.current?.forEach((el) => {
        const index = parseInt(el.dataset.index!)
        offsets[index] = el.getBoundingClientRect().top
        const activeSlide = offsets.indexOf(
          Math.max(...offsets.filter((offset) => offset - windowCenter < 0)),
        )
        if (activeSlide > -1) {
          setActiveIndex(activeSlide)

          if (activeSlide < fadeElms.current.length) {
            fadeElms.current[activeSlide].classList.replace(
              'md:opacity-0',
              'md:opacity-100',
            )

            if (!itemsViewed.includes(activeSlide)) {
              itemsViewed = [...itemsViewed, activeSlide]
            }
          }
        } else {
          setActiveIndex(0)
        }
      })
    }

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    }

    const observer = new IntersectionObserver(callback, options)
    fadeElms.current?.forEach((el) => observer.observe(el))
    window.addEventListener('scroll', onScroll, { passive: true })
  }, [])

  return (
    <section
      ref={blockRef}
      className={`
     ${!props.hideContainerClass ? 'container' : ''}`}
    >
      {(heading || smallIntro || subheading) && (
        <SectionHeader
          heading={heading}
          smallIntro={smallIntro}
          subheading={subheading}
        />
      )}

      <div className="flex">
        <div className="flex flex-col space-y-6 md:space-y-10 md:w-1/2 relative">
          {itemsAmended?.map((item, i) => (
            <div
              key={item.id}
              className={`flex flex-col space-y-4 md:space-y-1 md:w-2/3 shared-transition ${
                i === 0 ? 'md:!opacity-100' : 'md:opacity-0'
              }`}
              data-index={i}
              ref={(element) => {
                if (element) {
                  fadeElms.current[i] = element
                }
              }}
            >
              <div className="text-xl md:text-2xl text-grey-dark font-semibold">
                {item.model.title}
              </div>
              <div className="text-base md:text-xl text-grey-light">
                {item.model.description}
              </div>
            </div>
          ))}
        </div>

        <div className={`w-1/2 hidden md:block`}>
          <div className="sticky top-[207px] aspect-[5/4]">
            {itemsAmended?.map((item, i) => {
              return item.model.image ? (
                <SharedImage
                  key={item.id}
                  className={`absolute top-0 rounded-lg object-cover shared-transition aspect-[5/4] w-full
                  ${activeIndex === i ? 'opacity-100' : 'opacity-0'}
                  `}
                  {...item.model.image}
                />
              ) : null
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedTextToImageBlock
