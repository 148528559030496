﻿let handler: {
  replace: (changes: object | null, title?: string | null) => void
  getValue: (name: string) => string | null | undefined
}

// query-string-handler has this check, which is not compatible with SSR
// const hasHistoryApi = (typeof history.pushState !== 'undefined');
export default {
  async getHandler() {
    if (handler) return handler

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { default: queryStringHandler } = await import('query-string-handler')
    handler = queryStringHandler
    return handler
  },
}
