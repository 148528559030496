import SharedLink from '@/components/shared/buttons/shared-link'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { FeaturedResourceCardProps } from '../types/longform-resources-block-types'

const FeaturedRecourceCard: React.FC<FeaturedResourceCardProps> = ({
  title,
  description,
  linkText,
  url,
}) => {
  return (
    <SharedLink
      className="hover:no-underline p-4 md:px-6 rounded-lg bg-grey-secondary-light flex flex-col space-y-3 justify-between"
      href={url}
      title={linkText}
    >
      <div className="flex flex-col space-y-3">
        <div className="text-grey-dark font-bold text-xl md:text-2xl">
          {title}
        </div>
        <div className="text-grey-dark text-lg md:text-lg">{description}</div>
      </div>
      <div className="flex items-center text-dark-blue-primary font-semibold text-base">
        {linkText}
        <ArrowRightIcon className="lg:h-5 lg:w-5 w-4 h-4 lg:ml-3 ml-2 min-w-[20px]" />
      </div>
    </SharedLink>
  )
}

export { FeaturedRecourceCard }
