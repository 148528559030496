import { FooterEcommPropsModel } from './types/footer'
import SharedLink from '@/components/shared/buttons/shared-link'
import { uid } from 'uid'
import { useMemo } from 'react'

function FooterEcomm(props: Readonly<FooterEcommPropsModel>) {
  const {
    links,
    copyrightText,
  } = props.model

  const copyright = String.fromCodePoint(0x00A9);
  const copy = useMemo<string>(
    () => `${new Date().getFullYear()} ${copyrightText}`,
    [copyrightText],
  )

  return (

    <footer className="site-footer w-full h-[132px] bg-white flex flex-column justify-center">
      <div className="footer-wrapper">
        <div className="footer-links flex flex-row justify-center items-center mt-10">

          {links?.map((utilLink, index) => (
            <SharedLink
              key={uid()}
              {...utilLink}
              className="translate footer-link text-[#283B87] underline ml-2 mr-2"
            >
              <span>{utilLink.text}</span>
              {index !== links?.length - 1 && (
                <span className="mx-2.5">|</span>
              )}
            </SharedLink>
          ))}
        </div>
        <div className="footer-copyright flex flex-row justify-center items-center mt-2">
          <span data-translate="Copyright" className="translate text-grey-light">{copyright} {copy} </span>
        </div>
      </div>
    </footer>
  )
}

export default FooterEcomm
