import SharedImage from '@/components/shared/image/shared-image'
import { useMediaStore } from '@/stores/media-store'
import React, { useState } from 'react'
import Pages from './components/pages'
import TabBtn from './components/tab-btn'
import { IndustriesProps, IndustryTabProps } from './types/industries-types'

function IndustriesBlock(props: Readonly<IndustriesProps>) {
  const { model } = props

  const [activeIndex, setActiveIndex] = useState(0)

  const handleActiveIndex = (index: number) => {
    setActiveIndex(index)
  }

  const handleImageAspectRatio = (tab: IndustryTabProps) => {
    if (tab.model.imageAspectRatio === '3:4') {
      return 'aspect-video lg:aspect-[3/4]'
    } else if (tab.model.imageAspectRatio === '1:1') {
      return 'aspect-video lg:aspect-square'
    } else {
      return 'aspect-video'
    }
  }

  const { isMobile } = useMediaStore()

  return (
    <section className="group container flex flex-col space-y-8 lg:space-y-[60px]">
      <div className="flex flex-col text-center">
        {model.heading && (
          <h2 className="order-1 font-mulish text-grey-dark font-bold text-2xl md:text-4xl">
            {model.heading}
          </h2>
        )}
        {model.smallIntro && (
          <h4 className="order-0 font-bold text-xs font-mulish text-dark-blue-primary uppercase tracking-widest lg:mb-1">
            {model.smallIntro}
          </h4>
        )}
        {model.description && (
          <p className="order-1 font-normal font-mulish text-base md:text-lg mt-4 lg:mt-3">
            {model.description}
          </p>
        )}
      </div>

      {model.tabs && model.tabs.length > 0 && (
        <>
          {!isMobile ? (
            <div className="flex flex-col justify-center items-center space-y-1">
              <ul className="flex space-x-1 items-center">
                {model.tabs?.map((tab, index) => (
                  <>
                    {index < 4 && (
                      <li key={index} className="flex flex-col space-y-3">
                        <TabBtn
                          {...tab}
                          index={index}
                          activeIndex={activeIndex}
                          updateActiveIndex={handleActiveIndex}
                        />
                      </li>
                    )}
                  </>
                ))}
              </ul>
              {model.tabs.length > 4 && (
                <ul className="flex space-x-1 items-center">
                  {model.tabs?.map((tab, index) => (
                    <>
                      {index > 3 && index < 9 && (
                        <li key={index} className="flex flex-col space-y-3">
                          <TabBtn
                            {...tab}
                            index={index}
                            activeIndex={activeIndex}
                            updateActiveIndex={handleActiveIndex}
                          />
                        </li>
                      )}
                    </>
                  ))}
                </ul>
              )}
            </div>
          ) : (
            <div className="flex justify-center">
              <ul className="snap-x overflow-x-auto flex space-x-0.5 items-center mr-[-18px] pb-2 -mb-2">
                {model.tabs?.map((tab, index) => (
                  <li
                    key={index}
                    className="snap-start flex flex-col space-y-3"
                  >
                    <TabBtn
                      {...tab}
                      index={index}
                      activeIndex={activeIndex}
                      updateActiveIndex={handleActiveIndex}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}

      {model.tabs && model.tabs.length > 0 && (
        <>
          {model.tabs?.map((tab, index) => (
            <React.Fragment key={index}>
              {activeIndex === index && (
                <div className="flex flex-col space-y-reverse space-y-8 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-8 px-[18px] lg:px-8 py-5 lg:py-8 mx-[-18px] sm:mx-0 bg-grey-secondary-light sm:rounded-xl">
                  <div className="order-1 lg:order-none col-span-1 flex flex-col justify-center">
                    {tab.model.programPages && (
                      <ul
                        key={index}
                        className="flex flex-col space-y-8 w-full lg:px-6"
                      >
                        {tab.model.programPages.map((page, j) => (
                          <Pages key={j} {...page} />
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="order-0 lg:order-none col-span-1 flex flex-col justify-center">
                    {tab.model.industryTabImage && (
                      <div
                        className={`w-full min-h-full relative ${handleImageAspectRatio(
                          tab,
                        )}`}
                      >
                        <SharedImage
                          {...tab.model.industryTabImage}
                          className={`absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover rounded-lg ${
                            tab.model.industryTabMobileImage
                              ? 'hidden lg:block'
                              : ''
                          }`}
                        />
                        {tab.model.industryTabMobileImage && (
                          <SharedImage
                            {...tab.model.industryTabMobileImage}
                            className="absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover rounded-lg lg:hidden"
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </section>
  )
}

export default IndustriesBlock
