import { create } from 'zustand'

interface FindTrainingStoreProps {
  activeStep: number
  physicalAltercationsChecked: boolean
  setPhysicalAltercations: (value: boolean) => void
  goToNextStep: () => void
  goToPreviousStep: () => void
}

export const useFindTrainingStore = create<FindTrainingStoreProps>(
  (set, get) => ({
    activeStep: 1,
    physicalAltercationsChecked: false,
    setPhysicalAltercations: (value: boolean) =>
      set(() => ({
        physicalAltercationsChecked: value,
      })),
    goToNextStep: () => set(() => ({ activeStep: get().activeStep + 1 })),
    goToPreviousStep: () => set(() => ({ activeStep: get().activeStep - 1 })),
  }),
)
