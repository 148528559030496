import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import { CardItemProps } from '../types/index-types'

function HighlightCard(props: Readonly<CardItemProps>) {
  const { url, title, description, industry, linkText } = props

  return (
    <>
      {url && (
        <Link
          to={url}
          className={`group flex-1 flex flex-col justify-between space-y-4 py-6 px-4 rounded-lg border shadow-none border-grey-secondary-dark hover:shadow-lg hover:no-underline shared-transition`}
        >
          <div className="flex flex-col space-y-4">
            {industry && (
              <div className="text-xs font-bold uppercase tracking-[1.2px] text-dark-blue-primary">
                {industry}
              </div>
            )}
            {title && (
              <div className="text-base font-semibold text-grey-dark">
                {title}
              </div>
            )}
            {description && (
              <div className="text-sm text-grey-dark">{description}</div>
            )}
          </div>
          {linkText && (
            <div className="flex items-center space-x-3 text-dark-blue-primary font-semibold">
              <span>{linkText}</span>
              <span>
                <ArrowRightIcon className="text-dark-blue-primary h-5 w-5" />
              </span>
            </div>
          )}
        </Link>
      )}
    </>
  )
}

export default HighlightCard
