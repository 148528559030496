import { CartLineItemModel } from '@/api/types/content-delivery-types'
import { PartialNullable } from '@/lib/partial-nullable.type'
import { EventType } from '../enums/event-type.enum'
import { includeItemCategories } from '../functions/include-item-categories.function'
import { includeItemLanguage } from '../functions/include-item-language.function'
import { pushEvent } from '../functions/push-event.function'
import { EventItemParameters } from '../types/event-item-parameters.type'
import { ProductId } from '../types/product-id.type'

export const selectItemOnCartGTM = async (cartLineModel: CartLineItemModel, productId: ProductId) => {
  const ecommerce: { items: any[] } = {
    items: [],
  }

  const item: PartialNullable<EventItemParameters> = {
    item_id: productId,
    item_name: cartLineModel.displayName,
    item_variant_id: cartLineModel.code,
    item_list_id: null,
    item_list_name: null,
    price: cartLineModel.placedPrice,
    quantity: cartLineModel.quantity,
  }

  await includeItemCategories(item, productId)
  await includeItemLanguage(item, productId)

  ecommerce.items.push(item)

  pushEvent({
    event: EventType.SelectItem,
    ecommerce: ecommerce,
  })
}
