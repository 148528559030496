import { useBreadcrumbs } from '@/stores/breadcrumbs'
import { useGlobalSettings } from '@/stores/global-settings'
import { useMediaStore } from '@/stores/media-store'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Link } from 'react-router-dom'
import { uid } from 'uid'
import SharedLink from '../buttons/shared-link'

const Breadcrumbs = () => {
  const { breadcrumbItems, pageName, parentLinkUrl } = useBreadcrumbs()
  const { isMobile } = useMediaStore()
  const globalSettings = useGlobalSettings((state) => state.globalSettings)
  const breadcrumbItemsSorted = [
    breadcrumbItems.find((item) => item.isHome),
    ...breadcrumbItems.filter((item) => !item.isHome),
  ]

  return (
    <div className="container py-4 md:py-8">
      {breadcrumbItems && (
        <>
          {!isMobile ? (
            <ul className="flex items-center">
              {breadcrumbItemsSorted.map((item) => (
                <React.Fragment key={uid()}>
                  {item?.isHome ? (
                    <li className="flex relative pr-5 mr-2 after:content-['>'] after:w-[12px] after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2 after:text-grey-primary">
                      <SharedLink {...item}>
                        <span className="sr-only">{item.text}</span>
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="h-6 w-6 text-dark-blue-primary"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </SharedLink>
                    </li>
                  ) : (
                    <li className="flex relative pr-5 mr-2 after:content-['>'] after:w-[12px] after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2 after:text-grey-primary">
                      <SharedLink {...item}>{item?.text}</SharedLink>
                    </li>
                  )}
                </React.Fragment>
              ))}
              {pageName && (
                <li className="flex">
                  <span className="text-grey-dark">{pageName}</span>
                </li>
              )}
            </ul>
          ) : (
            <>
              {parentLinkUrl && (
                <Link
                  to={parentLinkUrl}
                  className="hover:no-underline inline-flex items-center space-x-3 py-1.5"
                >
                  <span>
                    <ChevronLeftIcon className="text-dark-blue-300 h-4 w-4" />
                  </span>
                  <span>{globalSettings?.breadcrumbsBackText}</span>
                </Link>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Breadcrumbs
