import { ScoreResultBlockProps } from './types/score-result-block-types'

const ScoreResultBlock = (props: Readonly<ScoreResultBlockProps>) => {
  const { scoreResultsBlockLargeText, scoreResultsBlockSubheadingText, scoreResultsBlockBodyText } = props.model
  return (
    <div className="wvprp-results-block md:w-4/12 flex flex-col space-y-6 md:items-start justify-between">
      {scoreResultsBlockLargeText &&
        <h1 className="font-mulish text-dark-blue-primary font-extrabold text-7xl lg:text-8xl">
          {scoreResultsBlockLargeText}
        </h1>
      }
      <div className="w-full bg-[#EFEFF4] rounded-full h-2.5">
        <div className="bg-dark-blue-primary h-2.5 rounded-full progress-bar-target"></div>
      </div>
      {scoreResultsBlockSubheadingText &&
        <h3 className='md:text-lg text-grey-dark font-bold text-base'>
          {scoreResultsBlockSubheadingText}
        </h3>
      }
      {scoreResultsBlockBodyText &&
        <p className='text-md font-mulish text-base'>{scoreResultsBlockBodyText}</p>
      }
    </div>
  )
}

export default ScoreResultBlock
