import { useGlobalSettings } from '@/stores/global-settings'
import { useCartStore } from '@/stores/useCartStore'
import { useUserStore } from '@/stores/useUserStore'
import { ShoppingCartIcon } from '@heroicons/react/24/solid'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Markets } from '../../market/types/market-types'
import { useHydrated } from 'react-hydration-provider'
import LoadingSpinner from '../../loading-spinner'

function CartButton() {
  const globalSettings = useGlobalSettings((state) => state.globalSettings)
  const hydrated = useHydrated()
  const { t } = useTranslation()

  const { cartData, loadUserCart } = useCartStore((state) => state)
  const { user } = useUserStore((state) => state)

  let total =
    (cartData?.cartLines
      ?.map((entity) => entity.quantity) || [])
      .reduce((a, b) => a + b, 0)

  useEffect(() => {
    loadUserCart();
  }, [])

  const spinner = (<div style={{display: "flex"}}>
                    <div style={{margin: "auto"}}>
                      <LoadingSpinner className={'h-4 w-4'}/>
                    </div>
                  </div>)
  const miniCartView = user?.authInfo?.market === Markets.CAN_MARKET || user?.authInfo?.market === Markets.USA_MARKET
    ?
    <a
      className="flex h-full w-16 items-center justify-center px-3 hover:bg-grey-secondary-light hover:no-underline"
      href={globalSettings?.cartPageUrl ?? '/'}
    >
      <span className="sr-only">{hydrated && t('ScreenReader.GoToCartPage')}</span>
      <span>
        <ShoppingCartIcon className="h-8 w-8 text-dark-blue-primary" />
      </span>

      {total > 0 && (
        <span className="absolute h-5 w-5 flex-1 -translate-y-1/2 translate-x-full rounded-3xl border border-solid border-white bg-orange-primary px-1 text-center text-xs font-extrabold">
        {hydrated ? (total > 9 ? '9+' : total) 
          :
          spinner
        }
        </span>
      )}
    </a> 
    :
    null

  return (
      miniCartView 
  )
}

export default CartButton
