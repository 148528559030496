import axios from 'axios'
import api from './intercept-axios-api'
import { CONTENT_API_BASE_URL, GET_CONTENT, GET_INVENTORY, GET_ME_CART, GET_ME_ORDER, GET_PAYMENTMETHODS, GET_PRICES, POST_ADD_PAYMENT, POST_CONVERTTOORDER, POST_PROCESS_PAYMENTS, POST_VALIDATECART } from './routes'
import { CartModel, CartValidationResponseModel, CategoryContentModel, ContentModel, InventoryModel, OrderModel, PaymentMethodModel, PaymentModel, PricesContentModel, ProductContentModel, VariantContentModel } from './types/content-delivery-types'

export interface ApiResponse<T> {
    code: RESULTCODES
    data?: T
}

export enum RESULTCODES {
    SUCCESS = 1,
    CARTVALIDATION_ERROR = 1101,
    CARTSTOCK_ERROR = 1102,
    ORDER_ERROR = 1201,
    PAYMENT_ERROR = 1501
}
const cacheParameters = {
    currentPageUrl: typeof window !== "undefined" ? window.location.pathname : "",
    preventCache: new Date().getTime()
}

const callContentDeliveryApi = async (relativeUrl: string, parameters: any, language?: string) => {
    const { data } = await axios.get(
        `${CONTENT_API_BASE_URL}/${relativeUrl}`,
        {
            headers: {
                Accept: 'application/json',
                'Accept-Language': language ?? '',
            },
            params: parameters,
        }
    )
    return data
}

async function epiContentApi<T = ContentModel, P = any>(relativeUrl?: string, parameters?: P) {
    const { data } = await api.get<T>(
        `${GET_CONTENT}${relativeUrl ? `/${relativeUrl}` : ''}`,
        {
            headers: {
                Accept: 'application/json',
                'Accept-Language': 'en',
            },
            params: parameters,
        }
    )
    return data
}

async function epiApiGet<T, P = any>(relativeUrl?: string, parameters?: P, additionalHeaders?: Record<string, string>) {
    try {
        const { data } = await api.get<T>(
            relativeUrl!,
            {
                headers: {
                    Accept: 'application/json',
                    'Accept-Language': 'en',
                    ...additionalHeaders
                },
                params: parameters,
            }
        )
        return data
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.error("Errors", JSON.parse(error.response?.request.response));
        } else {
            console.error(error);
        }
    }
}

async function epiApiPut<T, P = any>(relativeUrl?: string, parameters?: P) {
    try {
        const { data } = await api.put<T>(
            relativeUrl!,
            parameters,
            {
                headers: {
                    Accept: 'application/json',
                    'Accept-Language': 'en',
                },
            }
        )
        return data
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.error("Errors", JSON.parse(error.response?.request.response));
        } else {
            console.error(error);
        }
    }
}

async function epiApiDelete<T>(relativeUrl?: string) {
    try {
        const response = await api.delete<T>(
            relativeUrl!,
        )
        if (response.status === 204) return true
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.error("Errors", JSON.parse(error.response?.request.response));
        } else {
            console.error(error);
        }
    }
    return false
}

async function epiApiPost<T, P = any>(relativeUrl?: string, parameters?: P) {
    try {
        const { data } = await api.post<T>(
            relativeUrl!,
            parameters,
            {
                headers: {
                    Accept: 'application/json',
                    'Accept-Language': 'en',
                }
            }
        )
        return data
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.error("Errors", JSON.parse(error.response?.request.response));
        } else {
            console.error(error);
        }
    }
}

async function epiGetProduct(productId: string) {
    return await epiContentApi<ProductContentModel>(`${productId}__CatalogContent`, cacheParameters)
}

async function epiGetCategory(categoryId: string) {
    return await epiContentApi<CategoryContentModel>(`${categoryId}__CatalogContent`, cacheParameters)
}

async function epiGetCatalogChildren<T = ContentModel>(productId: string) {
    return await epiContentApi<T[]>(`${productId}__CatalogContent/children`, cacheParameters)
}

async function epiGetCatalogAncestors<T = ContentModel>(productId: string) {
  return await epiContentApi<T[]>(`${productId}__CatalogContent/ancestors`, cacheParameters)
}

async function epiGetVariantByContentGuid(variantGuid: string) {
    return await epiContentApi<VariantContentModel>(variantGuid, cacheParameters)
}

async function epiGetPrices(contentGuids: string[], marketId: string, currencyCode: string) {
    const contentIdsQuery = contentGuids.map(v => `contentIds=${v}`).join("&")
    return await epiApiGet<PricesContentModel[]>(`${GET_PRICES}?${contentIdsQuery}&marketId=${marketId}&currencyCode=${currencyCode}`)
}

async function epiGetInventory(contentGuid: string) {
    return await epiApiGet<InventoryModel[]>(`${GET_INVENTORY}?contentId=${contentGuid}`)
}


async function epiGetCart(marketId: string) {
    return await epiApiGet<CartModel>(GET_ME_CART(marketId))
}

async function epiGetPaymentMethods(language = 'en') {
    return await epiApiGet<PaymentMethodModel[]>(`${GET_PAYMENTMETHODS}/${language}`)
}

async function epiCreateOrUpdatePayment(cartId: number, payload: PaymentModel, language = 'en') {
    if(payload.paymentId) { // otherwise creates duplicate payments
        return await epiUpdatePayment(cartId, payload, language)
    }
    const url = POST_ADD_PAYMENT(cartId, language)
    return await epiApiPost<any>(url, payload)
}

async function epiUpdatePayment(cartId: number, payload: PaymentModel, language = 'en') {
    const url = POST_ADD_PAYMENT(cartId, language)
    // will throw error if it doesn't find a matching payment (via the paymentMethodId)
    return await epiApiPut<any>(url, payload)
}

async function epiProcessPayments(cartId: number) {
    const url = POST_PROCESS_PAYMENTS(cartId)
    const result = await epiApiPost<any>(url)
    return result === '';
}

async function epiConvertToOrder(cartId: number) {
    const url = POST_CONVERTTOORDER(cartId)
    return await epiApiPost<OrderModel>(url)
}

async function epiGetValidatedCart(cartId: number) {
    const url = POST_VALIDATECART(cartId)
    return await epiApiPost<CartValidationResponseModel>(url)
}

async function epiGetOrder(orderNumber: string) {
    return await epiApiGet<OrderModel>(`${GET_ME_ORDER(orderNumber)}`)
}

export default {
    getContent: async (contentId: string | number, language?: string) =>
        callContentDeliveryApi(`content/${contentId}`, {
            expand: '*',
            currentPageUrl: window.location.pathname,
            preventCache: new Date().getTime(),
        },
        language),
}

export {
    epiApiGet, epiApiPost, epiApiPut, epiContentApi, epiApiDelete,
    epiGetCatalogChildren, epiGetCatalogAncestors, epiGetCategory, epiGetInventory, epiGetPrices, epiGetProduct,
    epiGetCart, epiGetValidatedCart,
    epiGetPaymentMethods, epiCreateOrUpdatePayment, epiUpdatePayment, epiProcessPayments, epiConvertToOrder,
    epiGetOrder,
    epiGetVariantByContentGuid
}
