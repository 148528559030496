import Tooltip from '@/components/shared/address/components/tooltip'
import { useUserStore } from '@/stores/useUserStore'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'usehooks-ts'

interface TaxExemptionCheckboxProps {
  setIsTaxExempt: (value: boolean) => void,
  forceDisable?: boolean
}

export const TaxExemptionCheckbox: React.FC<TaxExemptionCheckboxProps> = ({ setIsTaxExempt, forceDisable }) => {
  const { t } = useTranslation()
  const { user } = useUserStore()
  const isCustomerTaxExempt = user?.customerInfo?.extendedProperties?.find((kv) => kv.name === 'IsTaxExempt')?.value === 'True'

  const isCartTaxExempt =
    user?.cart?.payments[0]?.extendedProperties?.find((x) => x.name === 'taxExemption')?.value === 'true'

  const isExempt = isCustomerTaxExempt || isCartTaxExempt

  const [checked, setChecked] = useLocalStorage<boolean>('tax-exemption-checkbox-state', isExempt)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => 
  {
    setChecked(e.target.checked)
    setIsTaxExempt(e.target.checked)
  }

  return (
    <div className="CommerceForms EPiServerForms mb-2 flex flex-col">
      <div className="flex flex-col gap-3">
        <div>
          <label className={'label flex flex-row items-start'} htmlFor="taxExemption">
            <input
              className="checkbox mr-2 mt-1 min-w-[18px]"
              name="taxExemption"
              type="checkbox"
              onChange={handleChange}
              checked={checked}
              disabled={forceDisable || isCustomerTaxExempt}
            />

            <span className='text-wrap'>
              {t("Checkout.TaxExemption.IsExempt")}
            </span>

            {isExempt && checked && <span className={'flex items-center flex-1 text-black'}>
              <Tooltip text={t("Checkout.TaxExemption.TaxExemptionChanged")} />
            </span>}
          </label>
        </div>
        <div>
          {!isCustomerTaxExempt && checked && <span dangerouslySetInnerHTML={{ __html: t("Checkout.TaxExemption.TaxExemptionVerification") }} className={'flex-1 text-black'}></span>}
        </div>
      </div>
    </div>
  )
}
