export type Currency = 'USD' | 'CAD' | 'GBP' | 'EUR';
export type Locale = 'en-US' | 'en-CA' | 'en-GB' | 'fr-FR';
export type CurrencyToLocale = Record<Currency, Locale>;
export type CurrencySign = "symbol" | "code" | "name" | "narrowSymbol";

export const currencyToLocale: CurrencyToLocale = {
    'USD': 'en-US',
    'CAD': 'en-CA',
    'GBP': 'en-GB',
    'EUR': 'fr-FR',
 };