import { useState } from "react";

export function useSorting(initialField = "date", initialOrder = 1) {
  const [sorting, setSorting] = useState([
    { id: initialField, desc: initialOrder === 1 },
  ]);
  let order;
  if (!sorting.length) {
   order = initialOrder;
  } else if (sorting[0].desc) {
   order = 1;
  } else {
   order = 0;
  }
  return {
    sorting,
    onSortingChange: setSorting,
    order: order,
    field: sorting.length ? sorting[0].id : initialField,
  };
}
