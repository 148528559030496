import { MaskFunction } from '../types/mask-type'

export const creditCardMask: MaskFunction = (
  value: string = '',
): string => {
  const creditCardPattern = /(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/
  const cardValue = value?.replace(/\D/g, '')?.match(creditCardPattern)

  if (cardValue) {
    if (!cardValue[2]) {
      value = cardValue[1]
    } else {
      const segments = [cardValue[1], cardValue[2], cardValue[3], cardValue[4]]
      value = segments.filter(Boolean).join('-')
    }
  }

  return value
}
