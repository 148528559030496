import SharedLink from '@/components/shared/buttons/shared-link'
import SharedImage from '@/components/shared/image/shared-image'
import { ImageGalleryProps } from './types/image-gallery'

function ImageGallery(props: Readonly<ImageGalleryProps>) {
  const { model } = props

  return (
    <section className="container flex flex-col space-y-4 lg:space-y-12 mb--2 md:mb-0">
      {model.heading && (
        <div className="flex justify-center">
          <h2 className="text-dark-blue-primary uppercase text-xs font-bold">
            {model.heading}
          </h2>
        </div>
      )}

      {model.images && model.images.length > 0 && (
        <div className="flex flex-wrap justify-center items-center mx-[-8px] md:mx-[-16px]">
          {model.images.map((image, index) => (
            <div
              key={index}
              className="px-2 md:px-4 py-2 md:py-0 w-1/2 md:w-1/4"
            >
              <div
                className={`w-full aspect-[16/9] ${
                  image.model.link?.href ? 'hover:shadow-lg' : ''
                } shared-transition`}
              >
                {image.model.link?.href && image.model.image && (
                  <SharedLink
                    {...image.model.link}
                    className="w-full focus:shadow-focus rounded-lg"
                  >
                    <SharedImage
                      {...image.model.image}
                      className="rounded-lg w-full h-full object-cover"
                    />
                  </SharedLink>
                )}
                {!image.model.link?.href && image.model.image && (
                  <SharedImage
                    {...image.model.image}
                    className="rounded-lg w-full h-full object-cover"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  )
}

export default ImageGallery
