import { Markets } from "@/components/shared/market/types/market-types"

export const POST_OFFICE_REGEX = /^(?!.*(?:po\.? box|post office|po\s+|p.o\s+|\bp\.?o\b|\bpob|\bpost\b|\bbox\s*#?-?\d+\b|\b(p[\.\-]?\s*o[\.\-]?\s*(box\b|\b\-?box\b)))).*$/i
export const EMAIL_REGEX = /(?:[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?)$/
export const EMAIL_REGEX_FOR_ADDRESS = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/

export enum ValidationIssue {
    None = "None",
    CannotProcessDueToMissingOrderStatus = "CannotProcessDueToMissingOrderStatus",
    RemovedDueToCodeMissing = "RemovedDueToCodeMissing",
    RemovedDueToNotAvailableInMarket = "RemovedDueToNotAvailableInMarket",
    RemovedDueToUnavailableCatalog = "RemovedDueToUnavailableCatalog",
    RemovedDueToUnavailableItem = "RemovedDueToUnavailableItem",
    RemovedDueToInsufficientQuantityInInventory = "RemovedDueToInsufficientQuantityInInventory",
    RemovedDueToInactiveWarehouse = "RemovedDueToInactiveWarehouse",
    RemovedDueToMissingInventoryInformation = "RemovedDueToMissingInventoryInformation",
    RemovedDueToInvalidPrice = "RemovedDueToInvalidPrice",
    RemovedDueToInvalidMaxQuantitySetting = "RemovedDueToInvalidMaxQuantitySetting",
    AdjustedQuantityByMinQuantity = "AdjustedQuantityByMinQuantity",
    AdjustedQuantityByMaxQuantity = "AdjustedQuantityByMaxQuantity",
    AdjustedQuantityByBackorderQuantity = "AdjustedQuantityByBackorderQuantity",
    AdjustedQuantityByPreorderQuantity = "AdjustedQuantityByPreorderQuantity",
    AdjustedQuantityByAvailableQuantity = "AdjustedQuantityByAvailableQuantity",
    PlacedPricedChanged = "PlacedPricedChanged",
    RemovedGiftDueToInsufficientQuantityInInventory = "RemovedGiftDueToInsufficientQuantityInInventory",
    RejectedInventoryRequestDueToInsufficientQuantity = "RejectedInventoryRequestDueToInsufficientQuantity"
}

export function getPostalCodeRegex(country: string) {
  if (country.toUpperCase() === Markets.CAN_COUNTRY_CODE_3ISO) {
    return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]\s\d[ABCEGHJ-NPRSTV-Z]\d$/
  }
  return /^\d{5}(?:-\d{2}(\d{2}|[A-Z]{2}))?$/
}

export const validateAddress = (address: string, postOfficeRegex: string) => {
  if (postOfficeRegex) {
    const cmsRegex = new RegExp(postOfficeRegex.replaceAll("\\\\", "\\"), 'i')
    return cmsRegex.test(address)
  }

  return POST_OFFICE_REGEX.test(address)
}

export const validateAddressSpecialCharacter = (address: string, specialCharacterRegex: string) => {
  if (specialCharacterRegex) {
    const cmsRegex = new RegExp(specialCharacterRegex.replaceAll("\\\\", "\\"), 'i')
    return cmsRegex.test(address)
  }

  return !EMAIL_REGEX_FOR_ADDRESS.test(address)
}

export const checkExpirationDate = (value: string, localizations: {invalidMonthText: string, invalidDateText: string}) => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    const [month, year] = value.split('/')
    const nMonth = parseInt(month, 10)
    const inputYear = parseInt(`20${year}`, 10)

    if (!(nMonth >= 1 && nMonth <= 12)) {
      return localizations.invalidMonthText
    }

    if (inputYear < currentYear || (inputYear === currentYear && nMonth <= currentMonth)) {
      return localizations.invalidDateText
    }

    return true
  }