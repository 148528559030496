import { Link } from 'react-router-dom'

type LinkStyles = 'primary' | 'secondary' | 'outline' | 'text'
type LinkSize = 'large' | 'regular' | 'small'

interface LinkProps {
  style?: LinkStyles
  size?: LinkSize
  href?: string
  title?: string
  isExternal?: boolean
  target?: string
  children?: React.ReactNode
  className?: string
  tabIndex?: number
  onClick?: (e: any) => void
  onMouseOver?: () => void
  onFocus?: () => void
  onKeyUp?: (e: any) => void
  onKeyDown?: (e: any) => void
}

const handleActiveStyle = (style?: LinkStyles, size?: LinkSize) => {
  const linkGlobal =
    'inline-flex items-center justify-center text-base font-semibold cursor-pointer shared-transition focus:shadow-focus px-3 border-solid border rounded-lg hover:no-underline'
  let linkSize
  let linkStyle

  if (style) {
    if (style === 'secondary') {
      linkStyle = `bg-orange-primary text-grey-950 border-orange-primary 
                            hover:bg-orange-400 hover:text-grey-950 hover:border-orange-400
                            active:bg-orange-400 active:text-grey-950 active:border-orange-400
                            disabled:bg-grey-300 disabled:text-grey-light disabled:border-grey-300 disabled:cursor-not-allowed`
    } else if (style === 'outline') {
      linkStyle = `bg-white text-dark-blue-primary border-dark-blue-primary 
                            hover:bg-dark-blue-100
                            active:bg-dark-blue-100
                            disabled:bg-white disabled:text-grey-400 disabled:border-grey-400 disabled:cursor-not-allowed`
    } else if (style === 'text') {
      linkStyle = `bg-transparent text-dark-blue-400 border-transparent
                            hover:text-dark-blue-primary
                            acitve:text-dark-blue-primary
                            disabled:text-grey-400 disabled:cursor-not-allowed`
    } else {
      linkStyle = `bg-dark-blue-primary text-dark-blue-50 border-dark-blue-primary 
                            hover:bg-dark-blue-400 hover:text-dark-blue-50 hover:border-dark-blue-400
                            active:bg-dark-blue-400 active:text-dark-blue-50 active:border-dark-blue-400
                            disabled:bg-grey-300 disabled:text-grey-light disabled:border-grey-300 disabled:cursor-not-allowed`
    }

    if (size && size === 'large') {
      linkSize = 'py-3.5'
    } else if (size && size === 'small') {
      linkSize = 'py-2'
    } else {
      linkSize = 'py-2.5'
    }

    return `${linkGlobal} ${linkStyle} ${linkSize}`
  } else {
    return ''
  }
}

const SharedLink: React.FC<LinkProps> = ({
  size,
  style,
  href,
  className,
  title,
  isExternal,
  target,
  children,
  onClick,
  onMouseOver,
  tabIndex,
  onFocus,
  onKeyUp,
  onKeyDown,
}) => {
  if (!href) {
    return (
      <div className={`${className} ${handleActiveStyle(style, size)}`}>
        {children}
      </div>
    )
  }
  if (isExternal) {
    return (
      <a
        href={href}
        target={target}
        aria-label={title}
        className={`${className} ${handleActiveStyle(style, size)}`}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onFocus={onFocus}
        tabIndex={tabIndex}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
      >
        {children}
      </a>
    )
  } else {
    // dummy base url for relative urls
    const url = href ? new URL(href, 'http://localhost') : null
    return (
      <Link
        to={{
          pathname: url?.pathname,
          hash: url?.hash,
          search: url?.search,
        }}
        aria-label={title}
        className={`${className} ${handleActiveStyle(style, size)} `}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onFocus={onFocus}
        tabIndex={tabIndex}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
      >
        {children}
      </Link>
    )
  }
}
export default SharedLink
