import SharedLink from '@/components/shared/buttons/shared-link'
import pingOptionsConfig from '@/lib/ping-identity/config'
import { useGlobalSettings } from '@/stores/global-settings'
import { useUserStore } from '@/stores/useUserStore'
import {
  Bars3Icon,
  UserIcon
} from '@heroicons/react/24/solid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { uid } from 'uid'
import { NavProps } from '../types/header-ecomm'
import { useHydrated } from 'react-hydration-provider'

const NavToggleButton: React.FC<Readonly<NavProps>> = (props: Readonly<NavProps>) => {
  const { t } = useTranslation()
  const hydrated = useHydrated()
  const [isOpen, setIsOpen] = useState(false)
  
  const toggleMenu = () => {
    setIsOpen(!isOpen)
    document.body.classList.toggle('overflow-hidden', !isOpen)
  }

  const { user, signOut } = useUserStore()
  const userFirstName = user?.customerInfo?.firstName ?? ''
  const userLastName = user?.customerInfo?.lastName ?? ''

  const globalSettings = useGlobalSettings((state) => state.globalSettings)

  const signOutProp = (user && globalSettings?.menuLinks?.find(x=> x?.href?.includes(pingOptionsConfig.logoutUrl))) ?? null
  
  const signOutLink =
    <button
      className="inline-flex w-full items-center space-x-3 p-5 hover:no-underline"
      title={signOutProp?.title}
      onClick={() => {toggleMenu(); signOut()}}
    >
      {signOutProp?.imageUrl && (
        <img alt="" src={signOutProp?.imageUrl} className="h-6 w-6" />
      )}
      <span className="font-semibold">{t("Header.SignOut")}</span>
    </button>

  return (
    <>
        <button
          className={`flex h-full w-16 items-center justify-center px-3 hover:bg-grey-secondary-light hover:no-underline ${
            isOpen ? 'bg-grey-secondary-light' : ''
          }`}
          id="NavButton"
          aria-haspopup="true"
          aria-controls="mobileMenu"
          onClick={toggleMenu}
        >
          <span className="sr-only">{hydrated && t('ScreenReader.OpenSiteNavigation')}</span>
          <span>
            <Bars3Icon className="h-8 w-8 text-dark-blue-primary" />
          </span>
        </button>

        <div
          className={`fixed bottom-0 right-0 top-[70px] z-50 w-full justify-end bg-grey-300/80 text-dark-blue-primary  ${
            isOpen ? 'flex' : 'hidden'
          }`}
        >
        <div className="w-full overflow-y-auto bg-grey-100 lg:w-[297px]">
          <div className="bg-grey-secondary-light">
            {user && (
            <div className="flex items-center border-b border-grey-100 px-5 py-3">
              <UserIcon className="h-6 w-6 text-dark-blue-primary" />
              <div className="pl-3">
                <div className="font-semibold italic">
                  {t("Header.YouLoggedInAs")}
                </div>
                <div className="font-bold">
                  {userFirstName} {userLastName}
                </div>
              </div>
            </div>
            )}
            
            {globalSettings?.menuLinks && (
              <>
                {globalSettings?.menuLinks?.filter(x=> !x?.href?.includes("authentication/logout")).map((link) => (
                    <div className="flex border-b border-grey-100" key={uid()}>
                      <SharedLink
                        {...link}
                        className="inline-flex w-full items-center space-x-3 p-5 hover:no-underline"
                        title={link.title}
                        onClick={toggleMenu}
                      >
                        {link.imageUrl && (
                          <img alt="" src={link.imageUrl} className="h-6 w-6" />
                        )}
                        <span className="font-semibold">{link.text}</span>
                      </SharedLink>
                    </div>
                ))}
                {signOutLink}
              </>
            )}
          </div>
        </div>
      </div> 
    </>
  )
}

export default NavToggleButton
