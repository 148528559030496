import {
  SearchPageItemProps,
  SearchPageSectionProps,
  FacetGroupProps
} from '@/components/pages/search/types/search-types'
import axios from 'axios'
import { create } from 'zustand'

interface SearchStoreProps {
  pageUrl: string
  page: number
  orderBy: number
  categoryId: string
  isLoading: boolean
  isLoadMore: boolean
  activeTabIndex: number
  activeCategory: string
  searchTerm: string
  hasSearchQuery: boolean
  programs: SearchPageItemProps[]
  products: SearchPageItemProps[]
  pages: SearchPageItemProps[]
  facetGroups: FacetGroupProps[]
  selectedFacets: string;
  hasMoreProducts: boolean
  hasMorePages: boolean
  noResultsMessage: string
  setPageUrl: (url: string) => void
  setPage: (newPage: number) => void
  setOrderBy: (value: number) => void
  setCategoryId: (categoryId: string) => void
  setFacetGroups: (data: []) => void
  setSelectedFacets: (value: string) => void;
  setIsLoading: (value: boolean) => void
  setIsLoadMore: (value: boolean) => void
  setActiveTabIndex: (index: number) => void
  setActiveCategory: (value: string) => void
  setSearchTerm: (value: string) => void
  setHasSearchQuery: (value: boolean) => void
  setPages: (data: []) => void
  setPrograms: (data: []) => void
  setProducts: (data: []) => void
  setMoreProductItems: (value: boolean) => void
  setMorePageItems: (value: boolean) => void
  setNoResultsMessage: (value: string) => void
  resetPrograms: () => void
  resetProducts: () => void
  resetPages: () => void
  resetPage: () => void
  fetchResults: () => Promise<void>
}

export const useSearchStore = create<SearchStoreProps>((set, get) => ({
  pageUrl: '',
  page: 1,
  orderBy: 0,
  categoryId: '',
  isLoading: true,
  isLoadMore: false,
  activeTabIndex: 0,
  activeCategory: 'all',
  searchTerm: '',
  hasSearchQuery: false,
  programs: [],
  products: [],
  pages: [],
  facetGroups: [],
  selectedFacets: '',
  hasMoreProducts: false,
  hasMorePages: false,
  noResultsMessage: '',
  setPageUrl: (url: string) => set(() => ({ pageUrl: url })),
  setPage: (newPage: number) => set(() => ({ page: newPage })),
  setOrderBy: (value: number) => set(() => ({ orderBy: value })),
  setCategoryId: (categoryId: string) => set(() => ({ categoryId: categoryId })),
  setFacetGroups: (data: []) => set(() => ({ facetGroups: data })),
  setSelectedFacets: (value: string) => set(() => ({ selectedFacets: value })),
  setIsLoading: (value: boolean) => set(() => ({ isLoading: value })),
  setIsLoadMore: (value: boolean) => set(() => ({ isLoadMore: value })),
  setActiveTabIndex: (index: number) => set(() => ({ activeTabIndex: index })),
  setActiveCategory: (value: string) => set(() => ({ activeCategory: value })),
  setSearchTerm: (value: string) => set(() => ({ searchTerm: value })),
  setHasSearchQuery: (value: boolean) => set(() => ({ hasSearchQuery: value })),
  setPages: (data: []) =>
    set((state) => ({ pages: [...state.pages, ...data] })),
  setPrograms: (data: []) =>
    set((state) => ({ programs: [...state.programs, ...data] })),
  setProducts: (data: []) =>
    set((state) => ({ products: [...state.products, ...data] })),
  setMoreProductItems: (value: boolean) =>
    set(() => ({ hasMoreProducts: value })),
  setMorePageItems: (value: boolean) => set(() => ({ hasMorePages: value })),
  setNoResultsMessage: (value: string) =>
    set(() => ({ noResultsMessage: value })),
  resetPrograms: () => set(() => ({ programs: [] })),
  resetProducts: () => set(() => ({ products: [] })),
  resetPages: () => set(() => ({ pages: [] })),
  resetPage: () => {
    get().setPage(1)
    get().resetPrograms()
    get().resetProducts()
    get().resetPages()
  },
  fetchResults: async () => {
    get().setIsLoading(true)

    if (get().searchTerm.length === 0) {
      get().setHasSearchQuery(false)
    } else {
      get().setHasSearchQuery(true)
    }
    const _pageUrl = get().pageUrl
    const _categoryId = get().categoryId
    const _loadMore = get().isLoadMore
    const _selectedFacets = get().selectedFacets
    const _orderBy = get().orderBy
    const _tab = get().activeCategory
    await axios
      .post(
        _pageUrl,
        {
          q: get().searchTerm,
          page: get().page,
          tab: _tab ?? 'all',
          categoryId: _categoryId,
          isLoadMore: _loadMore,
          selectedFacets: _selectedFacets,
          orderBy: _orderBy
        },
        {
          headers: { Accept: '*/*' },
        },
      )
      .then((response) => {
        const program = response.data.sections.find(
          (item: SearchPageSectionProps) => item.contentType === 'programs',
        )
        const product = response.data.sections.find(
          (item: SearchPageSectionProps) => item.contentType === 'products',
        )
        const page = response.data.sections.find(
          (item: SearchPageSectionProps) => item.contentType === 'pages',
        )

        if (program?.items && program.items.length > 0) {
          get().setPrograms(program.items)
        }
        if (product?.items && product.items.length > 0) {
          get().setProducts(product.items)
        }
        if (page?.items && page.items.length > 0) {
          get().setPages(page.items)
        }
        if (product?.facetGroups && product.facetGroups.length > 0) {
          get().setFacetGroups(product.facetGroups)
        }
        if (product?.noResultsMessage) {
          get().setNoResultsMessage(product.noResultsMessage)
        }
        if (page?.noResultsMessage) {
          get().setNoResultsMessage(page.noResultsMessage)
        }

        get().setOrderBy(product?.orderBy)
        get().setMoreProductItems(product?.hasMore)
        get().setMorePageItems(page?.hasMore)
        get().setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        get().setIsLoading(false)
      })
  },
}))
