import {
  epiApiPut,
  epiCreateOrUpdatePayment,
  epiGetCart,
  epiGetValidatedCart,
  epiUpdatePayment,
} from '@/api/content-delivery'
import { PUT_CART } from '@/api/routes'
import {
  CartInfoModel,
  CartLineItemModel,
  CartModel,
  CartValidationResponseModel,
  ShipmentModel,
  ShippingAndBillingAddressModel,
  ShippingMethodModel,
} from '@/api/types/content-delivery-types'
import { VariantModel } from '@/components/pages/product-detail/types/product-detail-types'
import { create } from 'zustand'
import { SYSTEMKEYWORD_DUMMY } from './checkout-store'
import { useUserStore } from './useUserStore'
import { DEFAULT_CURRENCY_CODE, DEFAULT_MARKET } from '@/lib/constants'
interface BillingShippingAddressPayload {
    billingAddress: ShippingAndBillingAddressModel | null
    shippingAddress: ShippingAndBillingAddressModel | null
    taxExemption?: boolean
    isShippingSameAsBilling: boolean
}

interface CartStoreProps {
  isLoading: boolean
  isLoadingCartTotals: boolean
  cartData: CartData | null
  validatedCartData: CartValidationResponseModel | null
  setIsLoading: (isLoading: boolean) => void
  setIsLoadingCartTotals: (isLoading: boolean) => void
  setCartData: (cartData: CartData) => void
  setValidatedCartData: (validatedCartData: CartValidationResponseModel | null) => void
  extractCartLines: (shipments: ShipmentModel[]) => CartLineItemModel[]
  getValidatedCartData: (marketId: string) => Promise<CartValidationResponseModel | null>
  updateCartLineQuantity: (cartLineId: number, quantity: number) => Promise<boolean>
  calculateCartLine: (cartLineId: number, quantity: number) => number | null
  validateCart: () => Promise<boolean>
  updateCart: (cart: CartModel, doValidateCart: boolean) => Promise<boolean>
  deleteShippingAddressFromCart: ({
    guidName,
  }: {
    guidName: string
  }) => Promise<boolean>
  deleteBillingAddressFromCart: ({
    guidName,
  }: {
    guidName: string
  }) => Promise<boolean>
  updateCartShippingMethod: ({
    shipmentId,
    shippingMethod,
  }: {
    shipmentId: number | null
    shippingMethod: ShippingMethodModel | null
  }) => Promise<boolean>
  updateCartShippingAddress: ({billingAddress, shippingAddress, isShippingSameAsBilling, taxExemption}: BillingShippingAddressPayload ) => Promise<any>
  loadUserCart: () => Promise<void>
  getFullCart: () => Promise<CartModel | null>
  removeCartItem: (cartLineId: number, code: string, linkedSku?: string) => Promise<boolean>
  addToCart: (cart: CartModel | null, variant: VariantModel, quantity: number) => Promise<boolean>
}

export interface CartData {
  fullCart: CartModel | null
  cartLines: CartLineItemModel[] | null
  cartInfo: CartInfoModel | null
}

export const useCartStore = create<CartStoreProps>((set, get) => ({
  isLoading: false,
  isLoadingCartTotals: false,
  validatedCartData: null,
  cartData: null,
  setCartData: (cartData: CartData) => set(() => ({ cartData: cartData })),
  setValidatedCartData: (validatedCartData) => set(() => ({ validatedCartData: validatedCartData })),
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  setIsLoadingCartTotals: (isLoadingCartTotals: boolean) => set(() => ({ isLoadingCartTotals })),
  loadUserCart: async () => {
    const userStore = useUserStore.getState()
    if (!userStore.user) return
    const cartState = get()
    get().setIsLoading(true)
    const cart = await epiGetCart(userStore.user?.authInfo?.market ?? DEFAULT_MARKET)
    if (!cart) return
    cartState.setCartData({
      fullCart: cart,
      cartLines: get().extractCartLines(cart.shipments),
      cartInfo: {
        market: cart.market,
        currency: cart.currency ?? DEFAULT_CURRENCY_CODE,
      },
    })
  
    userStore.setUser(({
      cart: cart,
    }))
    get().setIsLoading(false)
  },
  getValidatedCartData: async () => {
    const cart = await epiGetCart(useUserStore.getState().user?.authInfo?.market ?? DEFAULT_MARKET)
    const validatedCart = await epiGetValidatedCart(cart?.id ?? -1)
    if (!validatedCart) return null
    return validatedCart
  },
  validateCart: async () => {
    const userStore = useUserStore.getState()
    if (!userStore.user) {
      return false
    }
    get().setIsLoading(true)
    get().setIsLoadingCartTotals(true)
    const validatedCartData = await get().getValidatedCartData(userStore.user.authInfo?.market ?? DEFAULT_MARKET)

    if (validatedCartData) {
      get().setValidatedCartData(validatedCartData)
      get().setIsLoadingCartTotals(false)
      userStore.setUser(({
        cart: validatedCartData.cart,
        totals: validatedCartData?.totals,
        availableShippingMethods: validatedCartData?.availableShippingMethods,
        validationIssues: validatedCartData.validationIssues,
      }))
    }

    if (validatedCartData && !validatedCartData.cart.shipments.flatMap((x) => x.lineItems).some((x) => x.id)) {
      return false
    }

    if (validatedCartData && validatedCartData.validationIssues.length > 0) {
      return false
    }
    get().setIsLoading(false)
    return true
  },
  removeCartItem: async (cartLineId: number, code: string, linkedSku?: string) => {
    const userStore = useUserStore.getState()
    if (!userStore.user?.cart) return false

    const searchFn = (li: CartLineItemModel) => li.id === cartLineId && li.code === code
    const shipments = userStore.user.cart.shipments ?? []
    let theShipment = shipments.find((s) => !!s.lineItems.find(searchFn))

    if (theShipment) {
      theShipment.lineItems = theShipment.lineItems.filter((li) => !searchFn(li))
      if (linkedSku)
      {
        const shipmentWithLinkedSku = userStore.user?.cart.shipments.find(shipment => 
          shipment.lineItems.some(item => item.code === linkedSku)
        )
        const linkedItemToRemove = shipmentWithLinkedSku?.lineItems.find(item => item.code === linkedSku)
        theShipment.lineItems = theShipment.lineItems.filter(x => x.code !== linkedItemToRemove?.code)
      }
      if(theShipment.lineItems.length <= 0)
      {
        await get().updateCartShippingMethod({shipmentId: userStore.user.cart.shipments[0]?.id ?? null, shippingMethod: null})
      }
    } else {

      return false
    }
    return await get().updateCart(userStore.user.cart, true)
  },
  updateCart: async (cart: CartModel, doValidateCart = true) => {
    const updatedCart = await epiApiPut<CartModel | null>(PUT_CART, cart)
    get().setIsLoading(true)
    if (!updatedCart) {
      console.error('PUT cart failed')
      return false
    }
    if (doValidateCart) {
      await get().validateCart()
    }

    const cartState = get()

    cartState.setCartData({
      fullCart: updatedCart,
      cartLines: get().extractCartLines(updatedCart.shipments),
      cartInfo: {
        market: useUserStore.getState().user?.authInfo?.market ?? DEFAULT_MARKET,
        currency: useUserStore.getState().user?.authInfo?.currencyCode ?? DEFAULT_CURRENCY_CODE,
      },
    })

    useUserStore.getState().setUser(({
      cart: updatedCart,
    }))

    get().setIsLoading(false)
    return true
  },
  calculateCartLine: (cartLineId: number, quantity: number): number | null => {
    if (!useUserStore.getState().user) return null
    let lineItem = get().cartData?.cartLines?.find((x) => x.id === cartLineId)
    if (lineItem) return lineItem?.placedPrice * quantity
    return null
  },
  updateCartLineQuantity: async (cartLineId: number, quantity: number) => {
    const userStore = useUserStore.getState()
    if (!userStore.user?.cart) return false
    const lineItems = get().extractCartLines(userStore.user.cart?.shipments ?? [])
    const lineItemToUpdate = lineItems.find((x) => x.id === cartLineId)

    if (lineItemToUpdate) {
      lineItemToUpdate.quantity = quantity
    } else {
      return false
    }
    return await get().updateCart(userStore.user.cart, true)
  },
  addToCart: async (cart: CartModel | null, variant: VariantModel, quantity = 1) => {
    if (!cart) return false
    let shipments = cart?.shipments ?? []
    const allLineItems = get().extractCartLines(shipments)
    const existingLineItem = allLineItems?.find((l) => l.code === variant.variantCode)
    if (existingLineItem) {
      existingLineItem.quantity += quantity
    } else if (shipments.length) {
      const firstShipment = shipments[0]
      variant.prices.sort((a, b) => b.minQuantity - a.minQuantity)
      const price = variant.prices.find((p) => quantity >= p.minQuantity)?.price
      firstShipment.lineItems.push({
        code: variant.variantCode,
        displayName: variant.displayName,
        placedPrice: price ?? variant.prices[0].price,
        contentId: variant.contentId,
        isGift: false,
        quantity,
      })
    }

    return await get().updateCart(cart, false)
  },
  getFullCart: async () => {
    const userStore = useUserStore.getState()
    if (!userStore.user) return null
    const cart = await epiGetCart(userStore.user.authInfo?.market ?? DEFAULT_MARKET)
    if (!cart) return null
   
    userStore.setUser(({
        cart: cart,
    }))
    return cart
  },
  extractCartLines: (shipments: ShipmentModel[]) => {
      return shipments.flatMap((x) => x.lineItems) ?? []
  },
  deleteShippingAddressFromCart: async ({
    guidName,
  }) => {
    const userStore = useUserStore.getState()
    let shipments = userStore.user?.cart?.shipments ?? []
    shipments = shipments.map((item) =>
      item.shippingAddress?.name === guidName ? { ...item, shippingAddress: null } : item,
    )
    if (!shipments) return false
    const updatedCart = await epiApiPut<CartModel | null>(PUT_CART, {
      ...userStore.user?.cart,
      shipments: shipments,
    })

    if (updatedCart) {
      userStore.setUser(({
        cart: updatedCart,
      }))
      return true
    }
    return false
  },
  deleteBillingAddressFromCart: async ({
    guidName,
  }: {
    guidName: string
  }) => {
    const userStore = useUserStore.getState()
    if (!userStore.user) return false
    let payments = userStore.user?.cart?.payments ?? []
    payments = payments.map((item) =>
      item.billingAddress?.name === guidName ? { ...item, billingAddress: null } : item,
    )
    if (!payments || !userStore.user?.cart) return false
    const paymentModel = await epiUpdatePayment(userStore.user?.cart?.id, payments[0])
    if (paymentModel) {
     const prevUser = userStore.user
      userStore.setUser(({
        ...prevUser,
        cart: prevUser?.cart
          ? {
              ...prevUser.cart,
              payments: paymentModel,
            }
          : undefined,
      }))
      return true
    }
    return false
  },
  updateCartShippingMethod: async ({
    shipmentId,
    shippingMethod,
  }: {
    shipmentId: number | null
    shippingMethod: ShippingMethodModel | null
  }) => {
    const userStore = useUserStore.getState()
    if (!userStore.user) return false
    let shipments = userStore.user?.cart?.shipments ?? []
    const newShipment = shipments.find((x) => x.id === shipmentId)
    if (!newShipment) return false
    newShipment.shippingMethodId = shippingMethod?.id ?? '00000000-0000-0000-0000-000000000000'
    if (!shipments) return false

    const updatedCart = await epiApiPut<CartModel | null>(PUT_CART, {
      ...userStore.user.cart,
      shipments: [newShipment],
    })

    if (updatedCart) {
      get().setCartData({
        fullCart: updatedCart,
        cartLines: get().extractCartLines(updatedCart.shipments),
        cartInfo: {
          market: updatedCart.market ?? DEFAULT_MARKET,
          currency: updatedCart.currency ?? DEFAULT_CURRENCY_CODE,
        },
      })
      userStore.setUser(({
        cart: updatedCart,
      }))
      return true
    }
    return false
  },
  updateCartShippingAddress: async ({
    billingAddress,
    shippingAddress,
    isShippingSameAsBilling = false,
    taxExemption,
  }: {
    billingAddress: ShippingAndBillingAddressModel | null
    shippingAddress: ShippingAndBillingAddressModel | null
    isShippingSameAsBilling: boolean
    taxExemption?: boolean
  }) => {
    const userStore = useUserStore.getState()
    const validatedCart = await get().getValidatedCartData(userStore.user?.authInfo?.market ?? DEFAULT_MARKET)
    if (!validatedCart) return

    let shipments = validatedCart.cart.shipments ?? []
    if (shippingAddress) {
      shipments = shipments.map((shipment) => ({
        ...shipment,
        shippingAddress: shippingAddress,
      }))
    }

    if (isShippingSameAsBilling) 
    {
      shipments = shipments.map((shipment) => ({
        ...shipment,
        shippingAddress: billingAddress,
      }))
    }

      // we use a dummy payment to store the billing address
      // the real payment is created during the review step, based on the user's choices
     //https://local.crisisprevention.com:44300/api/episerver/v3.0/me/carts/30/en/payments
    if (billingAddress) 
    {
      let payments = validatedCart.cart.payments ?? []

      let thePayment = payments.find((p) => p.systemKeyword === SYSTEMKEYWORD_DUMMY)
      
      let extendedProperties = taxExemption ? [{ name: 'taxExemption', value: (taxExemption).toString()}] : []
      if (!thePayment) 
      {
        thePayment = {
          systemKeyword: SYSTEMKEYWORD_DUMMY,
          billingAddress: billingAddress,
          extendedProperties: extendedProperties,
        }
        await epiCreateOrUpdatePayment(validatedCart.cart.id, thePayment)
      } else {
        thePayment.billingAddress = billingAddress
        thePayment.extendedProperties = extendedProperties
        await epiUpdatePayment(validatedCart.cart.id, thePayment)
      }
    }

    const updatedCart = await epiApiPut<CartModel | null>(PUT_CART, {
      ...validatedCart.cart,
      shipments: shipments,
    })
    if (updatedCart) 
    {
      get().setCartData({
        fullCart: updatedCart,
        cartLines: get().extractCartLines(updatedCart.shipments),
        cartInfo: {
          market: updatedCart.market ?? DEFAULT_MARKET,
          currency: updatedCart.currency ?? DEFAULT_CURRENCY_CODE,
        },
      })
      userStore.setUser(({
        cart: updatedCart,
      }))
      return true
    } 
    return false
  },
}))

