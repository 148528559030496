interface VideoPlayIconProps {
  className?: string
}

const VideoPlayIcon = (props: VideoPlayIconProps) => {
  const { className } = props
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_6479_1374)">
        <g filter="url(#filter0_dd_6479_1374)">
          <path
            d="M12 2H44C49.5228 2 54 6.47715 54 12V44C54 49.5228 49.5228 54 44 54H12C6.47715 54 2 49.5228 2 44V12C2 6.47715 6.47715 2 12 2Z"
            stroke="white"
            strokeWidth="4"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.5 21.6526C20.5 20.2264 22.029 19.3224 23.2786 20.0097L34.8192 26.357C36.1144 27.0694 36.1144 28.9304 34.8192 29.6428L23.2786 35.9901C22.029 36.6774 20.5 35.7733 20.5 34.3472V21.6526Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_6479_1374"
          x="-12"
          y="-2"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_6479_1374"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6479_1374"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="3"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_6479_1374"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_6479_1374"
            result="effect2_dropShadow_6479_1374"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_6479_1374"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_6479_1374">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default VideoPlayIcon
