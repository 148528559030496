export const DEFAULT_MARKET = "GBPUKnt";
export const DEFAULT_CURRENCY_CODE = "GBP";
export const NO_SHIPPING = "NO_SHIPPING";
export const DEFAULT_ADDRESS_GUID = "00000000-0000-0000-0000-000000000000"
export const FIRST = "1st";
export const SECOND = "2nd";
export const THIRD = "3rd";
export const FOURTH = "4th";
export const FIFTH = "5th";
export const SIXTH = "6th";
export const SEVENTH = "7th";
export const EIGHTH = "8th";
export const NINTH = "9th";
export const TENTH = "10th";
export const EDITION = "EDITION";