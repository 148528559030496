import LoadingSpinner from "@/components/shared/loading-spinner";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/solid";
import { flexRender } from "@tanstack/react-table";
import PaginationComponent from "./pagination";
import { OrderHistoryTableProps, TOrderHistory } from "./types/order-history-types";

function DesktopTable(
  props: OrderHistoryTableProps
) {
    const {
        table,
        isLoadingRows,
    } = props

  return (
    <div className="hidden lg:block w-full">
      <table className='w-full'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {

                  return (
                    <th className='text-left pt-[14px] pb-[14px]' style={{borderBottom: "1px solid #E2E8F0"}} key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                          <button
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none flex gap-2 items-center'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                              onKeyDown: (event) => {
                                if (event.key === 'Enter') {
                                  header.column.getToggleSortingHandler();
                                }
                              },
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}

                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'asc' ? (
                              <ArrowUpIcon className='font-bold w-[16px] h-[16px]' />
                            ) : (
                              <ArrowDownIcon className='font-bold w-[16px] h-[16px]' />
                            )
                          ) : null}
                        </button>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td className='pt-[14px] pb-[14px] text-black' style={{ borderBottom: "1px solid #E2E8F0" }} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className='flex flex-col justify-center w-full'>
        {isLoadingRows && <div className="col-span-1 sm:col-span-2 flex h-full w-full items-center justify-center mt-3">
          <LoadingSpinner className="h-5 w-5 text-grey-light" />
        </div>}
      </div>
      {table.getPageCount() > 1 && (
        <div className='w-full flex justify-end'>
          {PaginationComponent<TOrderHistory>({
            table
          })}
        </div>
      )}
    </div>
  )
}
export default DesktopTable