import { create } from 'zustand'

interface MediaStoreProps {
  isDesktop: boolean
  isTablet: boolean
  isMobile: boolean
  isSmallMobile: boolean
  setIsDesktop: (value: boolean) => void
  setIsTablet: (value: boolean) => void
  setIsMobile: (value: boolean) => void
  setIsSmallMobile: (value: boolean) => void
  handleResize: () => void
}

export const useMediaStore = create<MediaStoreProps>((set, get) => ({
  isDesktop: false,
  isTablet: false,
  isMobile: false,
  isSmallMobile: false,
  setIsDesktop: (value: boolean) => set(() => ({ isDesktop: value })),
  setIsTablet: (value: boolean) => set(() => ({ isTablet: value })),
  setIsMobile: (value: boolean) => set(() => ({ isMobile: value })),
  setIsSmallMobile: (value: boolean) => set(() => ({ isSmallMobile: value })),
  handleResize: () => {
    if (window.matchMedia('(min-width: 1280px)').matches && !get().isDesktop) {
      get().setIsDesktop(true)
    } else if (
      window.matchMedia('(max-width: 1279px)').matches &&
      get().isDesktop
    ) {
      get().setIsDesktop(false)
    }

    if (window.matchMedia('(min-width: 1025px)').matches && get().isTablet) {
      get().setIsTablet(false)
    } else if (
      window.matchMedia('(max-width: 1024px)').matches &&
      !get().isTablet
    ) {
      get().setIsTablet(true)
    }

    if (window.matchMedia('(min-width: 768px)').matches && get().isMobile) {
      get().setIsMobile(false)
    } else if (
      window.matchMedia('(max-width: 767px)').matches &&
      !get().isMobile
    ) {
      get().setIsMobile(true)
    }

    if (
      window.matchMedia('(min-width: 501px)').matches &&
      get().isSmallMobile
    ) {
      get().setIsSmallMobile(false)
    } else if (
      window.matchMedia('(max-width: 500px)').matches &&
      !get().isSmallMobile
    ) {
      get().setIsSmallMobile(true)
    }
  },
}))
