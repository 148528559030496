import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import { ProgramPagesProps } from '../types/industries-types'

function Pages(props: Readonly<ProgramPagesProps>) {
  const { title, description, linkText, url } = props

  return (
    <li className="flex flex-col space-y-3 lg:px-6">
      {title && (
        <h3 className="text-grey-dark font-bold text-lg lg:text-xl">{title}</h3>
      )}

      {description && (
        <p className="text-grey-light lg:text-grey-dark">{description}</p>
      )}

      {linkText && url && (
        <div>
          <Link
            to={url}
            className="text-dark-blue-primary text-base font-semibold inline-flex items-center space-x-3"
          >
            <span>{linkText}</span>
            <span>
              <ArrowRightIcon className="text-dark-blue-primary h-4 w-4 lg:h-5 lg:w-5" />
            </span>
          </Link>
        </div>
      )}
    </li>
  )
}

export default Pages
