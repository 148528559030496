import SharedImage from '@/components/shared/image/shared-image'
import { useGlobalSettings } from '@/stores/global-settings'
import { useLocationsStore } from '@/stores/locations-store'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { CardItemProps } from '../types/index-types'

function LegislationCard(props: Readonly<CardItemProps>) {
  const { url, linkText, title, image, tag, publishDate } = props
  const { globalSettings } = useGlobalSettings()

  const { selectedCountry } = useLocationsStore()

  const formatDate = (date: string) => {
    const country = selectedCountry?.location?.cultureCode

    if (country) {
      return DateTime.fromISO(date)
        .setLocale(country)
        .toLocaleString(DateTime.DATE_MED)
    } else {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)
    }
  }

  return (
    <>
      {url && (
        <Link
          to={url}
          className="group flex flex-col space-y-3 md:space-y-4 px-4 pt-4 pb-3 border border-grey-secondary-dark rounded-lg hover:shadow-lg hover:no-underline shared-transition"
        >
          {image && (
            <div className="relative w-full">
              <SharedImage
                {...image}
                className="aspect-[16/9] object-cover w-full h-full rounded-md"
              />
            </div>
          )}

          {!image && globalSettings?.indexCardFallbackImage && (
            <div className="relative w-full">
              <SharedImage
                {...globalSettings.indexCardFallbackImage}
                className="aspect-[16/9] object-cover w-full h-full rounded-md"
              />
            </div>
          )}
          <div className="flex-1 flex flex-col justify-between space-y-3 md:space-y-4">
            <div className="flex flex-col space-y-3 md:space-y-4">
              {tag && (
                <div className="text-xs font-bold text-grey-light uppercase tracking-[1.2px]">
                  {tag}
                </div>
              )}
              {title && (
                <div className="text-base text-grey-dark font-semibold">
                  {title}
                </div>
              )}
              {publishDate && (
                <div className="text-xs font-medium text-grey-light">
                  {formatDate(publishDate)}
                </div>
              )}
            </div>
            {linkText && (
              <div className="flex items-center space-x-3 py-1.5 md:py-2.5 text-dark-blue-primary text-base">
                <span>{linkText}</span>
                <span>
                  <ArrowRightIcon className="text-blue-primary h-5 w-5" />
                </span>
              </div>
            )}
          </div>
        </Link>
      )}
    </>
  )
}

export default LegislationCard
