import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid'
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useSwipeable } from 'react-swipeable'
import SharedButton from '../buttons/shared-button'
import { CardSliderProps } from './types/card-slider'

const CardSlider = forwardRef(
  (props: Readonly<CardSliderProps>, ref: ForwardedRef<unknown>) => {
    const {
      children,
      cardsToShow,
      cardTotal,
      previousScreenreaderText,
      nextScreenreaderText,
      disableSwipe,
    } = props

    useImperativeHandle(ref, () => {
      return {
        next: handleNext,
        reset: handleReset,
      }
    })

    const [totalViews, setTotalViews] = useState(0)
    const [sliderWidth, setSliderWidth] = useState(0)

    /* eslint-disable prefer-const */
    let [currentView, setCurrentView] = useState(1)
    let [index, setIndex] = useState(cardsToShow)
    let [translateValue, setTranslateValue] = useState(0)
    /* eslint-enable prefer-const */

    const [showNavigation, setShowNavigation] = useState(false)
    const [disablePrevious, setDisablePrevious] = useState(false)
    const [disableNext, setDisableNext] = useState(false)
    const fullWidth = 100 // represents 100% width

    const handleIndex = (value: number) => {
      setIndex(value)
      setDisablePrevious(index === cardsToShow)
      setDisableNext(index >= cardTotal)
    }

    const handleNavigation = () => {
      setShowNavigation(totalViews > 1)
    }

    const calcSlideTranslateWidth = (direction: string) => {
      const totalViews: number = sliderWidth / fullWidth
      const slideSetWidth: number = fullWidth / totalViews
      const slideSetRemainingWidth: number = (totalViews % 1) * slideSetWidth
      let translateWidth = 0

      if (totalViews % 1 !== 0) {
        // returns different number if there is remaining space and last slide
        if (
          (direction === 'prev' && currentView + 1 > totalViews) ||
          (direction === 'next' && currentView > totalViews)
        ) {
          translateWidth = slideSetRemainingWidth
        } else {
          translateWidth = slideSetWidth
        }
      } else {
        translateWidth = slideSetWidth
      }

      return translateWidth
    }
    // sets the translate value in percentage
    const handleTranslateValue = (value: number) => {
      setTranslateValue(value)
    }
    // calculates how many total next and previous views in slider
    const handleTotalViews = () => {
      setTotalViews(Math.ceil(sliderWidth / fullWidth))
    }
    // calculates the slider width percentage
    const handleSliderWidth = () => {
      setSliderWidth((fullWidth / cardsToShow) * cardTotal)
    }

    const handleNext = () => {
      if (index >= cardTotal) {
        return
      }
      setCurrentView((currentView += 1))
      handleTranslateValue((translateValue -= calcSlideTranslateWidth('next')))
      handleIndex(index + cardsToShow)
    }

    const handlePrevious = () => {
      if (index <= cardsToShow) {
        return
      }
      setCurrentView((currentView -= 1))
      handleTranslateValue((translateValue += calcSlideTranslateWidth('prev')))
      handleIndex(index - cardsToShow)
    }

    const handleCurrentSlide = (btnIndex: number) => {
      let slidesToMove = 0
      let slideDir = ''

      if (currentView < btnIndex) {
        slideDir = 'next'
        slidesToMove = btnIndex - currentView
      } else if (currentView > btnIndex) {
        slideDir = 'prev'
        slidesToMove = currentView - btnIndex
      }

      if (slidesToMove > 0 && slideDir) {
        handleSelectedTranslateValue(slidesToMove, slideDir)
      }
    }

    const handleSelectedTranslateValue = (
      slidesToMove: number,
      slideDir: string,
    ) => {
      let translateAmount = 0

      for (let i = 0; i < slidesToMove; i++) {
        if (slideDir === 'prev') {
          setCurrentView((currentView -= 1))
          translateAmount += calcSlideTranslateWidth('prev')
          const val = (index -= cardsToShow)
          handleIndex(val)
        } else {
          setCurrentView((currentView += 1))
          translateAmount += calcSlideTranslateWidth('next')
          const val = (index += cardsToShow)
          handleIndex(val)
        }
      }

      if (slideDir === 'prev') {
        const val = (translateValue += translateAmount)
        setTranslateValue(val)
      } else {
        const val = (translateValue -= translateAmount)
        setTranslateValue(val)
      }
    }

    const handlers = useSwipeable({
      onSwipedLeft: () => handleNext(),
      onSwipedRight: () => handlePrevious(),
      preventScrollOnSwipe: true,
    })

    const handleReset = () => {
      setTranslateValue(0)
      setCurrentView(1)
      setIndex(cardsToShow)
      handleSliderWidth()
      handleTotalViews()
      handleNavigation()
    }

    const init = () => {
      handleSliderWidth()
      handleTotalViews()
      handleNavigation()
      handleIndex(index)
    }

    useEffect(() => {
      init()
    })

    useEffect(() => {
      handleReset()
    }, [cardsToShow])

    return (
      <div className="flex flex-col space-y-4 -translate-x-7 lg:translate-x-0 pl-7 lg:pl-0 lg:mr-[-9px]">
        <div
          {...(!disableSwipe && { ...handlers })}
          className="relative lg:overflow-x-hidden py-4 -my-4"
        >
          <div
            className="inline-flex space-x-6 lg:space-x-8 shared-transition"
            style={{
              width: `${sliderWidth}%`,
              transform: `translateX(${translateValue}%)`,
            }}
          >
            {children}
          </div>
        </div>
        {showNavigation && (
          <div className="relative flex justify-between pt-7 lg:pt-0 lg:pr-3">
            <SharedButton
              style="primary"
              className="h-10 w-10 rounded:lg hidden lg:flex items-center justify-center lg:p-0"
              disabled={disablePrevious}
              onClick={handlePrevious}
            >
              <span className="sr-only">{previousScreenreaderText}</span>
              <span>
                <ArrowLeftIcon className="h-5 w-5 text-white" />
              </span>
            </SharedButton>

            <div className="max-w-full absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2 flex align-center space-x-1">
              {[...Array(totalViews)].map((e, i) => (
                <button
                  key={i}
                  className={`${
                    i + 1 === currentView
                      ? 'after:bg-dark-blue-primary'
                      : 'after:bg-dark-blue-100'
                  } relative h-6 w-6 after:rounded after:block after:h-1 after:w-full after:absolute after:top-1/2 after:transform after:-translate-y-1/2`}
                  onClick={() => handleCurrentSlide(i + 1)}
                >
                  <span className="sr-only">{i}</span>
                </button>
              ))}
            </div>

            <SharedButton
              style="primary"
              className="h-10 w-10 rounded:lg hidden lg:flex items-center justify-center lg:p-0"
              disabled={disableNext}
              onClick={handleNext}
            >
              <span className="sr-only">{nextScreenreaderText}</span>
              <span>
                <ArrowRightIcon className="h-5 w-5 text-white" />
              </span>
            </SharedButton>
          </div>
        )}
      </div>
    )
  },
)

CardSlider.displayName = 'CardSlider'
export default CardSlider
