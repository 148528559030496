import SharedLink from '@/components/shared/buttons/shared-link'
import { uid } from 'uid'
import { ContactLabelLinkItemProp } from '../types/contact-types'
import ContactTitle from './contact-title'

const ContactLabelLinkItem = (props: Readonly<ContactLabelLinkItemProp>) => {
  const { header, list } = props

  return (
    <div className="col-span-1 flex flex-col space-y-4">
      {header && <ContactTitle header={header} />}
      {list && list.length > 0 && (
        <div className="flex flex-col pl-3">
          {list.map((item) => (
            <div key={uid()} className="flex space-x-3">
              {item.label && (
                <div className="text-grey-light">{item.label}:</div>
              )}
              {item.link && (
                <SharedLink {...item.link}>{item.link.text}</SharedLink>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ContactLabelLinkItem
