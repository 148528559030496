import {
  LocationInterstitialProps,
  LocationsItemProps,
} from '@/components/shared/header/types/header'
import { create } from 'zustand'

interface LocationsStoreProps {
  locations?: LocationsItemProps[]
  defaultLanguage?: string
  suggestionPopup?: LocationInterstitialProps
  selectedCountry?: LocationsItemProps
  showPopup: boolean
  setLocations: (locations: any[] | undefined) => void
  setDefaultLanguage: (value: string | undefined) => void
  setSuggestionPopup: (popup: object | undefined) => void
  setSelectedCountry: (value: object) => void
  getCookie: (cname: any) => void
  setCookie: (cname: any, cvalue: any, exdays: any) => void
  handleUpdateSelectedCountry: (item: LocationsItemProps) => void
  checkForCookie: () => void
  setDefaultSelectedLanguage: () => void
}

export const useLocationsStore = create<LocationsStoreProps>((set, get) => ({
  locations: [],
  defaultLanguage: '',
  suggestionPopup: {},
  selectedCountry: {},
  showPopup: false,
  setLocations: (locations) => set(() => ({ locations: locations ?? [] })),
  setDefaultLanguage: (value) => set(() => ({ defaultLanguage: value ?? '' })),
  setSuggestionPopup: (popup) => {
    set(() => ({ suggestionPopup: popup ?? {} }))
    set(() => ({ showPopup: popup != undefined }))
  },
  setDefaultSelectedLanguage: () => {
    const locations: any = get().locations

    if (get().defaultLanguage && locations) {
      const selectedIndex = locations.findIndex(
        (item: any) => item.location?.cultureCode === get().defaultLanguage,
      )

      get().setSelectedCountry(locations[selectedIndex])
    }
  },
  setSelectedCountry: (selectedCountry) =>
    set(() => ({ selectedCountry: selectedCountry })),
  getCookie: (cname) => {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')

    for (const item of ca) {
      const c = item.trim()

      if (c.startsWith(name)) {
        return c.substring(name.length, c.length)
      }
    }

    return ''
  },
  setCookie: (cname, cvalue, exdays) => {
    const date = new Date()
    date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000)
    const expires = `expires=${date.toUTCString()}`
    document.cookie = `${cname}=${cvalue};${expires};path=/`
  },
  handleUpdateSelectedCountry: (item) => {
    get().setSelectedCountry(item)

    get().setCookie('selectedCountry', item.location?.cultureCode, {
      expires: new Date('360'),
    })
  },
  checkForCookie: () => {
    const cookie: any = get().getCookie('selectedCountry')
    const locations: any = get().locations

    if (locations) {
      const selectedIndex = cookie
        ? locations.findIndex(
            (item: any) => item.location?.cultureCode === cookie,
          )
        : locations.findIndex(
            (item: any) => item.location?.cultureCode === get().defaultLanguage,
          )

      get().setSelectedCountry(locations[selectedIndex])
    }
  },
}))
