import MediaContent from '@/components/blocks/featured-media-block/components/media-content'
import { FeaturedMediaBlockProp } from '@/components/blocks/featured-media-block/types/featured-media-block-types'
import SharedLink from '@/components/shared/buttons/shared-link'
import Modal from '@/components/shared/modal/shared-modal'
import SharedRtf from '@/components/shared/rtf/shared-rtf'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useState } from 'react'

const FeaturedMediaBlock = (props: Readonly<FeaturedMediaBlockProp>) => {
  const [showModal, setShowModal] = useState(false)

  const {
    type,
    heading,
    description,
    buttonLink,
    image,
    video,
    backgroundColor,
    imageSide,
  } = props.model

  const bgClasses = classNames({
    'bg-light-blue-100': backgroundColor === 'LightBlue',
    'bg-grey-secondary-light': backgroundColor === 'Grey',
  })

  return (
    <section
      className={`text-grey-dark ${
        !props.hideContainerClass ? 'container' : ''
      }`}
    >
      <div className={`flex flex-col lg:flex-row ${bgClasses} rounded-lg`}>
        <div
          className={`lg:w-1/2 lg:flex lg:items-center mb-4 lg:mb-0 ${
            imageSide === 'Right' ? 'lg:order-2' : ''
          }`}
        >
          <MediaContent
            type={type}
            image={image}
            video={video}
            setShowModal={setShowModal}
            setFullWidthImage={true}
          />
        </div>
        <div
          className={`lg:w-1/2 lg:px-14 lg:py-16 ${
            backgroundColor ? 'px-4 pb-4 lg:px-0' : ''
          }`}
        >
          {heading && (
            <h2 className="font-bold text-2xl lg:text-4xl mb-4">{heading}</h2>
          )}

          {description && (
            <div className="mb-2 overflow-hidden lg:mb-4">
              <SharedRtf
                className="prose-p:leading-7 prose-a:leading-7 prose-ol:leading-7 prose-ul:leading-7"
                html={description}
              ></SharedRtf>
            </div>
          )}

          {buttonLink && (
            <SharedLink {...buttonLink} style={buttonLink.buttonStyle}>
              {buttonLink.text}
              <ArrowRightIcon className="h-4 w-4 ml-2" />
            </SharedLink>
          )}
        </div>
      </div>

      {video && (
        <Modal
          transparentBg={true}
          closeButtonLabel="close"
          showModal={showModal}
          onClose={() => setShowModal(false)}
        >
          <iframe
            src={`https://www.youtube.com/embed/${video}`}
            title="YouTube video player"
            className="w-full lg:w-[832px] aspect-video lg:m-32"
            allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </Modal>
      )}
    </section>
  )
}

export default FeaturedMediaBlock
