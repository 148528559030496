import { MaskFunction } from "../types/mask-type"

export const cvvMask: MaskFunction = (value: string = ''): string => {
  const cvvPattern = /\d{0,4}/
  const cvvValue = value?.replace(/\D/g, '')?.match(cvvPattern)

  if (cvvValue) {
    value = cvvValue[0]
  }

  return value
}