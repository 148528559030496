import { useIndexPageStore } from '@/stores/index-store'
import { IndexCategoryStateItemProp } from '../types/index-types'

function CategoryStateBtn(props: Readonly<IndexCategoryStateItemProp>) {
  const { id, name, toggleMenu } = props

  const {
    resetPage,
    resetSelectedCategories,
    updateSelectedCategories,
    fetchResults,
    setSelectedState,
  } = useIndexPageStore()

  const handleCategoryChange = (id: number, name: string) => {
    toggleMenu()
    resetPage()
    resetSelectedCategories()
    if (id === 0) {
      setSelectedState('')
    } else {
      updateSelectedCategories(id)
      setSelectedState(name)
    }
    fetchResults()
  }

  return (
    <button
      className="rounded inline-flex items-center space-x-2.5 hover:cursor-pointer"
      onClick={() => handleCategoryChange(id, name)}
    >
      <span className="text-sm font-medium text-grey-dark">{name}</span>
    </button>
  )
}

export default CategoryStateBtn
