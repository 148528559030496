import SectionHeader from '@/components/shared/section-header'
import { uid } from 'uid'
import IconItem from './components/icon-item'
import { IconBlockProps } from './types/icon-types'

const IconBlock: React.FC<IconBlockProps> = ({
  className,
  hideContainerClass,
  model,
}) => {
  const classNames = [!hideContainerClass ? 'container' : '', className]
    .filter((i) => i !== undefined && i !== '')
    .join(' ')

  return (
    <div className={classNames}>
      <SectionHeader
        smallIntro={model.smallIntro}
        heading={model.heading}
        description={model.subheading}
      /> 
      <div className={`${model.columns.length > 3 ? 'grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-8' : model.columns.length > 2 ? 'grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8' : model.columns.length > 1 ? 'grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8' : 'grid grid-cols-1 md:grid-cols-1 gap-6 md:gap-8'}`}>
        {model.columns.map((item) => (
          <IconItem key={uid()} model={item.model} />
        ))}
      </div>
    </div>
  )
}

export default IconBlock
