import SharedImage from '@/components/shared/image/shared-image'
import { CardItemProps } from '@/components/shared/index/types/index-types'
import { useGlobalSettings } from '@/stores/global-settings'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'

function CaseStudyCard(props: Readonly<CardItemProps>) {
  const {
    url,
    title,
    image,
    industry,
    tag,
    linkText,
    isFeatured,
    setFeaturedAspectRatio,
  } = props
  const { globalSettings } = useGlobalSettings()

  return (
    <>
      {url && (
        <Link
          to={url}
          className={`group hover:no-underline flex flex-col justify-between relative p-5 rounded-lg overflow-hidden ${
            isFeatured
              ? 'aspect-[4/5] md:w-full md:row-span-2'
              : 'row-span-1 aspect-square md:aspect-video'
          } ${
            isFeatured && !setFeaturedAspectRatio
              ? 'md:aspect-[auto] md:h-full'
              : 'md:aspect-[8/9]'
          }`}
        >
          {image && (
            <div className="absolute top-0 left-1/2 bottom-0 -translate-x-1/2 min-w-full h-auto aspect-video">
              <SharedImage {...image} className="object-cover w-full h-full" />
            </div>
          )}
          {!image && globalSettings?.indexCardFallbackImage && (
            <div className="absolute top-0 left-1/2 bottom-0 -translate-x-1/2 min-w-full h-auto aspect-video">
              <SharedImage
                {...globalSettings.indexCardFallbackImage}
                className="object-cover w-full h-full"
              />
            </div>
          )}
          <div className="relative">
            {isFeatured && (
              <div className="inline-flex text-xs font-bold text-grey-dark uppercase tracking-[1.2px] bg-white rounded-lg shadow px-2 py-1">
                {tag}
              </div>
            )}
          </div>
          <div
            className={`flex flex-col rounded-lg bg-white/70 backdrop-blur-[22px] transition-all duration-200 group-hover:bg-white/90 group-focus:bg-white/90 ${
              isFeatured
                ? 'space-y-7 px-4 md:px-10 pb-6 pt-4 md:pt-6'
                : 'px-6 md:px-5 py-4'
            } 
            ${
              !isFeatured ? 'group-hover:space-y-7 group-focus:space-y-7' : ''
            }           
            `}
          >
            <div
              className={`w-full flex flex-col ${
                isFeatured ? 'space-y-4' : 'space-y-3 md:space-y-4'
              }`}
            >
              {industry && (
                <div className="text-xs font-bold text-grey-dark uppercase tracking-[1.2px]">
                  {industry}
                </div>
              )}
              {title && (
                <div
                  className={`text-grey-dark ${
                    isFeatured ? 'text-xl md:text-3xl font-medium' : 'text-base'
                  }`}
                >
                  {title}
                </div>
              )}
            </div>
            {linkText && (
              <div
                className={`inline-flex items-center space-x-3 overflow-hidden transition-height duration-200 ease-in-out ${
                  isFeatured ? 'h-10' : 'h-0'
                }
                  ${!isFeatured ? 'group-hover:h-10 group-focus:h-10' : ''}
                  `}
              >
                <span className="text-dark-blue-primary text-lg font-semibold">
                  {linkText}
                </span>
                <span>
                  <ArrowRightIcon className="h-5 w-5 text-dark-blue-primary" />
                </span>
              </div>
            )}
          </div>
        </Link>
      )}
    </>
  )
}

export default CaseStudyCard
