import SharedLink from '@/components/shared/buttons/shared-link'
import SharedImage from '@/components/shared/image/shared-image'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { Image3To1HeroBlockProps } from './types/image-3to1-hero-block-types'

const Image3To1HeroBlock: React.FC<Image3To1HeroBlockProps> = ({
  className,
  hideContainerClass,
  model,
}) => {
  const classNames = [
    !hideContainerClass ? 'container' : '',
    className,
    'text-grey-dark',
  ]
    .filter((i) => i !== undefined && i !== '')
    .join(' ')

  return (
    <div className={classNames}>
      {model.desktopImage && (
        <SharedImage
          {...model.desktopImage}
          mobileUrl={model.mobileImage?.url}
          className="w-full rounded-xl aspect-[16/9] md:aspect-[3/1] object-cover"
          disableLazy={true}
        />
      )}

      <div className={model.heading? "md:w-1/2 mt-10" : "mt-0"}>
        {model.heading && (
          <div
            className="text-3xl lg:text-6xl font-bold mb-4"
            dangerouslySetInnerHTML={{ __html: model.heading }}
          />
        )}
        {model.subheading && (
          <div
            className="lg:text-xl text-grey-light"
            dangerouslySetInnerHTML={{ __html: model.subheading }}
          />
        )}
        <div className={model.primaryCtaButton? 'mt-4' : 'mt-0'}>
          {model.primaryCtaButton && (
            <SharedLink
              style={model.primaryCtaButton.buttonStyle}
              {...model.primaryCtaButton}
              className="mr-2 w-full md:w-auto mb-3 md:mb-0"
            >
              <span>{model.primaryCtaButton.text}</span>
              <span>
                <ArrowRightIcon className="text-blue-primary ml-2 h-5 w-5" />
              </span>
            </SharedLink>
          )}

          {model.secondaryCtaButton && (
            <SharedLink
              style="outline"
              {...model.secondaryCtaButton}
              className="w-full md:w-auto"
            >
              <span>{model.secondaryCtaButton.text}</span>
              <span>
                <ArrowRightIcon className="text-blue-primary ml-2 h-5 w-5" />
              </span>
            </SharedLink>
          )}
        </div>
      </div>
    </div>
  )
}

export default Image3To1HeroBlock
