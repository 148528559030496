import BlockComponentSelector from '@/epi/block-component-selector'
import { useFindTrainingStore } from '@/stores/find-training-store'
import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import QuestionFour from './components/question-four'
import QuestionOne from './components/question-one'
import QuestionThree from './components/question-three'
import QuestionTwo from './components/question-two'
import { FindTrainingProps } from './types/find-training-types'

const FindTrainingPage = (props: FindTrainingProps) => {
  const { model } = props
  const { activeStep, goToPreviousStep } = useFindTrainingStore()
  return (
    <>
      {model.pageTitle && <h1 className="sr-only">{model.pageTitle}</h1>}
      <div className="container flex flex-col lg:pt-20">
        <div className="relative flex flex-col space-y-6 lg:space-y-0">
          <div className="min-h-9 lg:min-h-0 py-4 lg:py-0 lg:absolute lg:left-0 lg:top-0 ">
            {model.previousBtnText && activeStep !== 1 && (
              <button
                className="flex items-center font-semibold space-x-3 hover:no-underline py-1.5 md:py-2.5"
                onClick={goToPreviousStep}
              >
                <span>
                  <ArrowLeftIcon className="h-5 w-5 text-dark-blue-primary" />
                </span>
                <span className="text-dark-blue-primary">
                  {model.previousBtnText}
                </span>
              </button>
            )}
          </div>
          <div className="flex flex-col min-h-[500px]">
            {model.questionOneItems && (
              <QuestionOne {...model.questionOneItems} />
            )}
            {model.questionTwoItems && (
              <QuestionTwo {...model.questionTwoItems} />
            )}
            {model.questionThreeItems && (
              <QuestionThree {...model.questionThreeItems} />
            )}
            {model.questionFourItems && (
              <QuestionFour {...model.questionFourItems} />
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-14 lg:space-y-20">
          {model.pageContent?.map((item, i) => (
            <BlockComponentSelector {...item} key={`pageContent-${i}`} />
          ))}
        </div>
      </div>
    </>
  )
}

export default FindTrainingPage
