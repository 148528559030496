import SharedRTF from '../../shared/rtf/shared-rtf'
import { CommerceRichTextBlockProps } from './types/commerce-rich-text-types'

const CommerceRichTextBlock = (props: Readonly<CommerceRichTextBlockProps>) => {
  const { model, className, hideContainerClass } = props

  return (
    <section
      id={model.anchorId}
      className={`
        ${!hideContainerClass ? 'container' : ''}
        ${className}
      `}
    >
      <SharedRTF html={model.richTextField}></SharedRTF>
    </section>
  )
}
export default CommerceRichTextBlock
