import { epiGetVariantByContentGuid } from '@/api/content-delivery'
import { CartLineItemModel } from '@/api/types/content-delivery-types'
import { PartialNullable } from '@/lib/partial-nullable.type'
import { EventItemParameters } from '../types/event-item-parameters.type'
import { includeItemCategories } from './include-item-categories.function'
import { includeItemLanguage } from './include-item-language.function'

const ITEM_BRAND = 'CPI'

export const cartLinesToEventItemParameters = async (cartLines: CartLineItemModel[] | null | undefined) => {
  const items: PartialNullable<EventItemParameters>[] = []

  if (cartLines) {
    await Promise.all(
      cartLines.map(async (product) => {
        const variantData = await epiGetVariantByContentGuid(product.contentId)
        const item = {
          item_id: variantData.contentLink.id,
          item_name: product.displayName,
          item_brand: ITEM_BRAND,
          item_list_id: null,
          item_list_name: null,
          item_variant_id: product.code,
          price: product.placedPrice ?? '0',
          quantity: product.quantity,
        }
        await includeItemCategories(item, variantData.contentLink.id)
        await includeItemLanguage(item, variantData.contentLink.id)

        items.push(item)
      }),
    )
  }
  return items
}
