 import { useHydrated } from 'react-hydration-provider';
import LoadingSpinner from '@/components/shared/loading-spinner';

const withHydration = (WrappedComponent: React.ComponentType<any>) => {
    return (props: any) => {
      const isHydrated = useHydrated()
      
      if (!isHydrated) 
      {
        return (
          <div style={{height: "100vh", display: "flex"}}>
            <div style={{margin: "auto"}}>
              <LoadingSpinner className={'h-[50px] w-[50px]'}/>
            </div>
          </div>
        )
      }

       return <WrappedComponent {...props} />
    };
};

export default withHydration;
