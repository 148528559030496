import { formatCurrency } from '@/lib/currency/formatting';
import { Currency, Locale } from './types/price-types';
import { DEFAULT_CURRENCY_CODE } from '@/lib/constants';

interface PriceProps {
  price: string;
  culture?: Locale | string;
  currency?: Currency | string;
  className?: string;
  textClassName?: string;
}

const Price: React.FC<PriceProps> = (props) => {
  const {
    culture,
    price,
    currency,
    className,
    textClassName
  } = props;

  return (
    <span className={`${className}`}>
      <span className={`${textClassName}`}>{formatCurrency(price, culture ?? "", currency ?? "")}</span>
    </span>
  )
}

Price.defaultProps = {
  culture: "en-GB",
  currency: DEFAULT_CURRENCY_CODE,
  className: "price",
  textClassName: ""
}

export default Price;
