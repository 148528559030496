import { useGlobalSettings } from '@/stores/global-settings'
import { ArrowUpIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { useMediaStore } from '@/stores/media-store'
import { usePageContext } from '@/stores/page-context-store'

const BackToTopButtonBlock = () => {
  const globalSettings = useGlobalSettings((state) => state.globalSettings)
  const [buttonIsVisible, setButtonIsVisible] = useState(false)
  const [footerIsVisible, setFooterIsVisible] = useState(false)
  const { isMobile, isTablet } = useMediaStore()
  const { contentTypes } = usePageContext()

  const getPageType = () => {
    if (!contentTypes) return null
    return contentTypes[contentTypes.length - 1]
  }
  const isSurveyPage = getPageType() === 'WorkplaceTrainingLandingPage'

  const handleButtonVisibility = () => {
    const offset = 300
    if (window.scrollY > offset) {
      setButtonIsVisible(true)
    } else {
      setButtonIsVisible(false)
    }
  }

  const handleFooterVisibility = () => {
    const footerOffset = 800
    const footer = document.getElementById('cpiSiteFooter')
    if (footer) {
      if (footerOffset > footer.getBoundingClientRect().top && !isMobile) {
        setFooterIsVisible(true)
      } else {
        setFooterIsVisible(false)
      }
      if (footerOffset > footer.getBoundingClientRect().top && !isTablet) {
        setFooterIsVisible(true)
      } else {
        setFooterIsVisible(false)
      }
    }
  }

  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const setupDocumentStyleObserver = () => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          const isBodyOverflowHidden = document.body.style.overflow === 'hidden';
          if (isBodyOverflowHidden) {
            setButtonIsVisible(false);
          }
        }
      }
    });

    observer.observe(document.body, { attributes: true, attributeFilter: ['style'] });

    return observer;
  }

  useEffect(() => {
    handleButtonVisibility()
    handleFooterVisibility()
    window.addEventListener('scroll', handleButtonVisibility)
    window.addEventListener('scroll', handleFooterVisibility)

    const observer = setupDocumentStyleObserver()

    return () => {
      observer.disconnect();
    };
  },[])

  return (
    !isSurveyPage &&
    <div
      className={`container flex justify-end fixed bottom-0 right-0 left-0 h-0 shared-transition z-[1000] ${buttonIsVisible ? 'opacity-1' : 'opacity-0'
        }`}
    >
      <div className={buttonIsVisible ? 'relative' : 'sr-only'}>
        <button
          className={`absolute right-0 flex items-center justify-center p-4 bg-grey-secondary-light shadow-xl rounded-xl transition-all duration-700 ${footerIsVisible ? 'bottom-[280px]' : 'bottom-10'}`}
          onClick={handleScroll}
          tabIndex={buttonIsVisible ? 0 : -1}
        >
          <span className="sr-only">{globalSettings?.backToTopLabel}</span>
          <span>
            <ArrowUpIcon className={`text-grey-dark ${footerIsVisible ? 'h-8 w-8' : 'h-10 w-10'}`} />
          </span>
        </button>
      </div>
    </div>
  )
}

export default BackToTopButtonBlock
