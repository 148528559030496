import { ContactHeaderProp } from '../types/contact-types'

const ContactTitle = (props: Readonly<ContactHeaderProp>) => {
  const { header } = props

  return (
    <div className="text-grey-dark text-lg font-bold flex items-center pl-3 relative after:absolute after:left-0 after:w-0.5 after:h-full after:block after:bg-orange-primary">
      {header}
    </div>
  )
}

export default ContactTitle
