import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import SharedRTF from '@/components/shared/rtf/shared-rtf'
import BlockComponentSelector from '@/epi/block-component-selector'
import { uid } from 'uid'
import { LibraryGeneralDetailProps } from './types/library-general-detail-page-types'

const LibraryGeneralDetailPage = (props: Readonly<LibraryGeneralDetailProps>) => {
  const { model } = props
  return (
    <>
      <Breadcrumbs />
      <div className="flex flex-col space-y-14 lg:space-y-20">
        {model.hero && <BlockComponentSelector {...model.hero} key={uid()} />}
        <div className="container">
          <div className="w-full flex flex-col space-y-14 lg:space-y-0 lg:grid lg:grid-cols-12 lg:gap-8">
            <div
              className={`flex flex-col space-y-14 lg:space-y-20 ${model.showRightColumnContentArea ? 'col-span-8' : 'col-span-12'
                }`}
            >
              {model.mainBody && <SharedRTF html={model.mainBody} />}
            </div>
            {model.showRightColumnContentArea && (
              <div className="col-span-4 flex flex-col space-y-14 lg:space-y-20">
                {model.rightColumnContentArea?.map((item, i) => (
                  <BlockComponentSelector
                    key={`rightColumnContentArea-${i}`}
                    {...item}
                    hideContainerClass={true}
                    isSidebar={true}
                  />
                ))}
              </div>
            )}
          </div>
          <div className='w-full flex flex-col mt-14 lg:mt-20 mb-14 lg:mb-20 space-y-14 lg:space-y-20'>
            {model.pageContent?.map((item, i) => (
              <BlockComponentSelector
                {...item}
                hideContainerClass={true}
                key={`pageContent-${i}`}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default LibraryGeneralDetailPage