import { SectionTitleProps } from '../types/search-types'

const SearchSectionTitle = (props: SectionTitleProps) => {
  const { title, children } = props

  return (
    <div className="w-full flex space-x-4 justify-between">
      {title && (
        <h2 className="text-base md:text-lg font-bold text-grey-dark">
          {title}
        </h2>
      )}
      {children}
    </div>
  )
}

export default SearchSectionTitle
