import { IndustryTabProps } from '../types/industries-types'

function TabBtn(props: Readonly<IndustryTabProps>) {
  const { model, index, activeIndex, updateActiveIndex } = props

  return (
    <button
      className={`flex-inline items-center justify-center text-grey-dark font-semibold text-base py-2 px-3 2xl:min-w-[184px] rounded-lg lg:rounded shared-transition ${
        activeIndex === index ? 'bg-grey-secondary-light' : 'bg-white'
      }`}
      onClick={() => updateActiveIndex(index)}
    >
      <div className="inline-flex items-center space-x-2">
        {activeIndex === index && (
          <span className="bg-dark-blue-primary h-2 lg:h-3 w-2 lg:w-3 rounded lg:rounded-md flex"></span>
        )}
        {model.industryTabName && (
          <span className="whitespace-nowrap">{model.industryTabName}</span>
        )}
      </div>
    </button>
  )
}

export default TabBtn
