import { useEffect, useState } from 'react'
import { useSearchParams } from "react-router-dom";
import { useLocalStorage, useScript } from 'usehooks-ts';
import { useEventScript } from './use-script';
import axios from 'axios';
interface EventRegistrationPageProps {
    model: {
    }
}

declare const window: any;

const eventRegJS = '/dist/event-registration/event-registration.min.js';

const hashTagToStep = () => {
    const hashTag = window.location.hash;
    const steps = [
        {step: 1, stepHash: "#select-specialty"},
        {step: 2, stepHash: "#select-event"},
        {step: 2, stephash: "#select-event-stp"},
        {step: 3, stepHash: "#summary"},
        {step: 4, stepHash: "#add-attendees"}
    ]
    const matchedStep = steps.find(step => step.stepHash === hashTag);
    return matchedStep ? {step: matchedStep.step, stepHash: matchedStep.stepHash}: {step: 1, stepHash: "#select-specialty"};
}

const EventRegistrationPage = (props: Readonly<EventRegistrationPageProps>) => {
    const [isBodyOverflowHidden, setIsBodyOverflowHidden] = useState(false);

    useEffect(() => {
      const handleMutation = () => {
        setIsBodyOverflowHidden(document.body.style.overflow === 'hidden');
      };
      const observer = new MutationObserver(handleMutation);
      observer.observe(document.body, { attributes: true });
      handleMutation();

      return () => {
        observer.disconnect();
      };
    }, []);

    let cleanupEventScript: any = null

    const loadScript = useEventScript(eventRegJS, {
        id: 'event-reg-unique',
        removeOnUnmount: false
    })
    const [currentEventStep, setCurrentEventStep] = useLocalStorage<number>('currentEventStep', 1)
    const [queryParameters] = useSearchParams()
    const specialtyParam = queryParameters.get("specialty")
    const programTypeParam = queryParameters.get("programtype") ?? "ICP"
    const shareParam = queryParameters.get("shareid") ?? ""
    const langParam = queryParameters.get("lang") ?? ""
    const specialty = specialtyParam ? `&specialty=${specialtyParam}` : ""
    const share = shareParam ? `&shareid=${shareParam}` : ""
    const lang = langParam ? `&lang=${langParam}` : ""

    const jqueryStatus = useScript(`https://code.jquery.com/jquery-3.4.1.min.js`, {
        removeOnUnmount: false,
    })

    const boostrapStatus = useScript(jqueryStatus === 'ready' ? `https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js` : null, {
        removeOnUnmount: false,
    })

    useScript(boostrapStatus === 'ready' ? `https://kit.fontawesome.com/45bf0db48c.js` : null, {
        removeOnUnmount: false,
    })

    useEffect(()=>{
        const step = hashTagToStep()?.step ?? 1
        setCurrentEventStep(step)
    },[])

    useEffect(() => {

        const getDataForEvents = async() => {
            const hasScriptAlready = document.querySelector("#event-reg-unique") !== null
            if (!programTypeParam)
            {
                return
            }

            const model = (await axios.get<any>(`/internalapi/EventRegistrationAPI/EventRegistration?programtype=${programTypeParam}${specialty}${share}${lang}`, {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Accept-Language': '',
                },
            })).data
            
            if (model && model.Specialties.length > 0)
            {
                window.pageLayoutElementData = (function () {
                    'use strict';

                    let spinnerData = [{ 'blueprint': 'spinnerBlueprint', 'id': 'spinner-container' }]

                    let modalData = [
                        {
                            'blueprint': 'modalBlueprint',
                            'states': ['hidden'],
                            'container-id': 'modal-container',
                            'confirm-id': 'modal-confirm-button',
                            'confirm-text': 'Ok',
                            'error-text': 'An error has occurred with the form. Please contact us if you continue to receive this message.',
                            'eventListeners': [
                                {
                                    'on': 'click',
                                    'element-id': 'modal-confirm-button',
                                    'method': 'toggleErrorModal'
                                }
                            ]
                        }
                    ]
            
                    let tileColumnsData = [
                        {
                            'blueprint': 'tileColumnBlueprint',
                            'states': ['triple'],
                            'ifPageStates': ['select-specialty'],
                            'id': 'header-fill'
                        },
                        {
                            'blueprint': 'tileColumnBlueprint',
                            'states': ['triple'],
                            'id': 'tiles-fill'
                        },
                        {
                            'blueprint': 'tileColumnBlueprint',
                            'states': ['half'],
                            'ifPageStates': ['summary'],
                            'id': 'tiles-fill-summary-program-details'
                        },
                        {
                            'blueprint': 'tileColumnBlueprint',
                            'states': ['half'],
                            'ifPageStates': ['summary'],
                            'id': 'tiles-fill-summary-included-with-registration'
                        }
                    ]
            
                    return {
                        'pageStates': model.PageStates,
                        'spinnerData': spinnerData,
                        'modalData': modalData,
                        'tileColumnsData': tileColumnsData,
                        'localizations': model.EventRegistrationStrings ?? {},
                        'isAuthenticated': model.IsAuthenticated ?? false,
                        'culture': model.Culture ?? "",
                        'summarySession': model.SummarySession ?? "",
                        'orgId': '1',//'<%= CMS.Membership.MembershipContext.AuthenticatedUser["OrgID"].ToString() %>',
                        'specialties': model.Specialties ?? "",
                        'isRow':  model.IsRow ?? false,
                        'programType': model.ProgramType ?? "ICP",
                        'showConference': model.ShowConference ?? false,
                      'enableEventFuturePrice': model.EnableEventFuturePrice && !model.IsRow
                    }
                })()
            
                window.selectSpecialtyElementData = (function () {
                    'use strict';
            
                    let eventsTileData = [
                        {
                            'blueprint': 'containerBlueprint',
                            'ifPageStates': ['select-event'],
                            'content': model?.EventRegistrationStrings?.ChangeProgram ?? "",
                            'id': 'start-over-button',
                            'styles': 'margin-top:32px;',
                            'eventListeners': [
                                {
                                    'method': 'reset',
                                    'on': 'click',
                                    'element-id': 'start-over-button',
                                }
                            ]
                        },
                        {
                            'blueprint': 'tileBlueprint',
                            'ifPageStates': ['select-event'],
                            'states': ['triple'],
                            'id': 'events-tile',
                            'title': 'Select Event',
                        }
                    ]
            
                    let eventsData: any[] = [];
                    return {
                        'eventsTileData': eventsTileData,
                        'eventsData': eventsData
                    }
                })()

                if (!hasScriptAlready)
                {
                    if (cleanupEventScript !== null && typeof cleanupEventScript === 'function') 
                    {
                        cleanupEventScript()
                    }
                    cleanupEventScript = loadScript()

                }
            }
        }

        if (window.pageLayoutElementData === undefined && currentEventStep === 1 || shareParam)
        {
            getDataForEvents()
        }

        getDataForEvents()


        return () => {
            if (cleanupEventScript !== null && typeof cleanupEventScript === 'function') {
                cleanupEventScript()
                cleanupEventScript = loadScript()
            }
        };
    }, [currentEventStep])


    useEffect(() => {
        let bootstrapCSSLinkElement = document.querySelector("#bootstrap-css") as HTMLLinkElement

        if(bootstrapCSSLinkElement === null)
        {
            bootstrapCSSLinkElement = document.createElement('link')
            bootstrapCSSLinkElement.id = "bootstrap-css"
            bootstrapCSSLinkElement.rel = 'stylesheet'
            bootstrapCSSLinkElement.href = 'https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css'
    
            document.head.appendChild(bootstrapCSSLinkElement)
        }


        return () => {
           bootstrapCSSLinkElement !== null && document.head.removeChild(bootstrapCSSLinkElement)
        }
    }, [])

    useEffect(()=>{
        let eventRegCssElement = document.querySelector("#event-reg-css") as HTMLLinkElement
        if(eventRegCssElement === null)
        {
            eventRegCssElement = document.createElement('link')
            eventRegCssElement.id = "event-reg-css"
            eventRegCssElement.rel = 'stylesheet'
            eventRegCssElement.href = '/dist/event-registration/event-registration.min.css'
    
            document.head.appendChild(eventRegCssElement)
        }
        
        return () => {
            eventRegCssElement !== null && document.head.removeChild(eventRegCssElement)
        }
    }, [])

    return (
        <div className="container py-0 flex px-0 flex-col font-mulish" style={{zIndex: isBodyOverflowHidden ? "999" : "998"}}>
            <div className='flex flex-col'>
                {/* Event registration specific markup */}
                <div id="site-content" className="site-content d-flex">
                    <div className="page-content brand-page">
                        <div id="main-container">
                            <section className="section-content section-profile-header bg-brand-white">
                                <div className="container-fluid">
                                    <div id="profile-header-fill"></div>
                                </div>
                            </section>
                            <section className="section-content section-dashboard">
                                <div className="container-fluid">
                                    <div id="tile-fill">
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                {/* Event registration specific markup */}
            </div>
        </div>
    )
}
export default EventRegistrationPage
