import ScrimCTAForm from '@/components/blocks/scrim-cta-form/scrim-cta-form'
import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import DownloadBtn from '@/components/shared/download-btn/download-btn'
import SharedRTF from '@/components/shared/rtf/shared-rtf'
import BlockComponentSelector from '@/epi/block-component-selector'
import { useEffect, useState } from 'react'
import { uid } from 'uid'
import LibraryHero from './components/library-hero'
import { LibraryDetailPageProps } from './types/library-detail-page-types'

const LibraryDetailPage: React.FC<LibraryDetailPageProps> = ({ model }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isGated, setIsGated] = useState(true)

  const getCookie = (cname: any) => {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')

    for (const item of ca) {
      const c = item.trim()

      if (c.startsWith(name)) {
        return c.substring(name.length, c.length)
      }
    }

    return ''
  }

  const setCookie = (cname: any, cvalue: any, exdays: any) => {
    const date = new Date()
    date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000)
    const expires = `expires=${date.toUTCString()}`
    document.cookie = `${cname}=${cvalue};${expires};path=/`
  }

  const handleGatedForm = () => {
    const cookieId = getCookie('scrimFormSubmitted')
    const submittedForm = document.getElementById(cookieId)
    if (submittedForm) {
      setIsGated(false)
    } else {
      handleGatedFormsSubmit()
    }
  }

  const handleGatedFormsSubmit = () => {
    const forms = document.querySelectorAll('.scrim-form')
    forms.forEach((form) => {
      const formElement = form.querySelector('form')
      const formId = formElement?.id
      const formSubmitBtn = formElement?.querySelector('button[type="submit"]')
      if (formSubmitBtn) {
        formSubmitBtn.addEventListener('click', () => {
          setTimeout(() => {
            if (formElement?.classList.contains('ValidationSuccess')) {
              setIsGated(false)
              setCookie('scrimFormSubmitted', formId, {
                expires: new Date('360'),
              })
            }
          }, 0)
        })
      }
    })
  }

  useEffect(() => {
    if (model.isGatedContent) {
      // allow optimizely form to load into the dom
      setTimeout(() => {
        handleGatedForm()
        setIsLoading(false)
      }, 500)
    } else {
      setIsLoading(false)
    }
  }, [])

  return (
    <>
      <Breadcrumbs />
      {model.hero && <LibraryHero {...model.hero} />}
      <div
        className={`flex flex-col space-y-14 md:space-y-20 shared-transition ${
          isLoading ? 'opacity-0' : 'opacity-1'
        }`}
      >
        <div
          className={`container w-full flex ${
            model.isGatedContent || !model.gatedContentForm?.form
              ? 'flex-col md:w-2/3 space-y-8 md:space-y-14'
              : 'flex-col md:flex-row md:justify-between md:space-x-8 space-y-8 md:space-y-0'
          }`}
        >
          {model.mainBody && (
            <div
              className={`flex w-full ${
                !model.isGatedContent && model.gatedContentForm?.form
                  ? 'md:w-5/12'
                  : ''
              } ${
                isGated && model.isGatedContent
                  ? 'relative max-h-80 overflow-hidden after:absolute after:bottom-0 after:left-0 after:right-0  after:h-24 after:bg-gradient-to-b after:from-white/0 after:to-white/100'
                  : ''
              }`}
            >
              <SharedRTF html={model.mainBody} />
            </div>
          )}
          {!model.isGatedContent && model.gatedContentForm?.form ? (
            <div className="w-full md:w-1/2">
              {model.gatedContentForm && (
                <ScrimCTAForm {...model.gatedContentForm} />
              )}
            </div>
          ) : (
            <>
              {!isGated ? (
                <>
                  {model.fileDownload && (
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 lg:gap-8">
                      {model.fileDownload?.map((item) => (
                        <div key={uid()} className="col-span-1">
                          <DownloadBtn
                            href={item.href}
                            text={item.text}
                            type={item.type}
                            extension={item.extension}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {model.pageContent?.map((item, i) => (
                    <BlockComponentSelector
                      {...item}
                      key={`pageContent-${i}`}
                      hideContainerClass={true}
                    />
                  ))}
                </>
              ) : (
                <>
                  {model.gatedContentForm && (
                    <ScrimCTAForm {...model.gatedContentForm} />
                  )}
                </>
              )}
            </>
          )}
        </div>
        {!isGated && model.isGatedContent && model.fullWidthPageContent && (
          <div className="flex flex-col space-y-[72px] md:space-y-32">
            {model.fullWidthPageContent.map((item, i) => (
              <BlockComponentSelector
                {...item}
                key={`fullWidthPageContent-${i}`}
              />
            ))}
          </div>
        )}
        {model.trackingScriptContent && (
            <BlockComponentSelector
                {...model.trackingScriptContent}
                key={uid()}
                hideContainerClass={true}
            />
        )}
      </div>
    </>
  )
}
export default LibraryDetailPage
