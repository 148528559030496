import { useEffect, useState } from 'react'
import SharedImage from '@/components/shared/image/shared-image'
import { useGlobalSettings } from '@/stores/global-settings'
import { useLocationsStore } from '@/stores/locations-store'
// @ts-ignore
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { CardItemProps } from '../types/index-types'
import { usePageContext } from '@/stores/page-context-store'


function IndexCard(props: Readonly<CardItemProps>) {
  const { url, author, title, image, industry, publishDate } = props
  const { globalSettings } = useGlobalSettings()
  const { selectedCountry } = useLocationsStore()
  const { contentTypes } = usePageContext()
  const [isLibraryIndexPage, setIsLibraryIndexPage] = useState(false)

  const formatDate = (date: string) => {
    const country = selectedCountry?.location?.cultureCode

    if (country) {
      return DateTime.fromISO(date)
        .setLocale(country)
        .toLocaleString(DateTime.DATE_MED)
    } else {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED)
    }
  }

  useEffect(() => {
    const getPageType = () => {
      if (!contentTypes) return null
      return contentTypes[contentTypes.length - 1]
    }
    setIsLibraryIndexPage(getPageType() === 'LibraryIndexPage')
  }, [contentTypes])

  return (
    <>
      {url && (
        <Link
          to={url}
          className="group hover:no-underline flex flex-col space-y-6 border border-grey-secondary-dark rounded-lg hover:shadow-lg shared-transition"
        >
          {image && (
            <div className="relative w-full">
              <SharedImage
                {...image}
                className="aspect-[16/9] object-cover w-full h-full rounded-lg shadow-xl"
              />
            </div>
          )}
          {!image && globalSettings?.indexCardFallbackImage && (
            <div className="relative w-full">
              <SharedImage
                {...globalSettings.indexCardFallbackImage}
                className="aspect-[16/9] object-cover w-full h-full rounded-lg shadow-xl"
              />
            </div>
          )}
          <div className="flex-1 flex flex-col justify-between space-y-2 md:space-y-4 px-4 pb-4">
            <div className="flex flex-col space-y-2 md:space-y-4">
              {author && (
                <div className="flex items-center space-x-3">
                  {author.image && (
                    <SharedImage
                      {...author.image}
                      className="rounded-xl h-5 w-5"
                    />
                  )}
                  {author.name && (
                    <span className="text-base text-grey-dark">
                      {author.name}
                    </span>
                  )}
                </div>
              )}
              {title && (
                <div className="text-base text-grey-dark font-bold">
                  {title}
                </div>
              )}
            </div>
            {(industry || publishDate) && (
              <div className="flex items-center justify-between space-x-4">
                {industry && (
                  <span className="text-xs font-bold text-dark-blue-primary uppercase tracking-[1.2px]">
                    {industry}
                  </span>
                )}
                {!isLibraryIndexPage && publishDate && (
                  <span className="text-sm font-medium text-grey-light">
                    {formatDate(publishDate)}
                  </span>
                )}
              </div>
            )}
          </div>
        </Link>
      )}
    </>
  )
}

export default IndexCard
