import { useEffect, useState } from "react";
import { TOrderHistory } from "../types/order-history-types";
import { epiApiPost } from "@/api/content-delivery";
import { OrderHistoryListingModel, OrderSearchParamsModel } from "@/api/types/content-delivery-types";

export function useOrderHistoryTableAPI(
    {
      pagination: { limit = 10, skip = 0 } = {},
      sort: { field = "date", order = 1} = {},
      orderHistoryListingPageUrl = ""
    } = {},
  ) {
    const [data, setData] = useState<TOrderHistory[] | null>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
      setLoading(true);
      // //0- ASC, 1-DESC
      const fetchPagedOrders = async () => {
        const data = await epiApiPost<OrderHistoryListingModel | null, OrderSearchParamsModel>(
          `${orderHistoryListingPageUrl}getorders/`,
          {
            // ...filters || {},
            startingIndex: skip,
            recordsToRetrieve: limit,
            orderByField: field,
            orderByDirection: order
          }
        )
        if (data) 
        {
          const vm: TOrderHistory[] = []
          data.orders.forEach((order)=>{
            vm.push({
              organization: order.organization ?? "",
              orderId: order.orderNumber,
              date: order.orderDate,
              status: order.status,
              currency: order.currency,
              total: order.totals.total,
              trackingNumber: "",
              viewOrder: "View Order",
            })
          })
          setData(vm);
          setTotalCount(data.totalCount);
          setLoading(false);
        }
      }
      fetchPagedOrders()
  
    }, [limit, skip, field, order, setData, setLoading]);
  
    return {data, totalCount, loading};
  }