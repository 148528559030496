import SharedLink from '@/components/shared/buttons/shared-link'
import { SearchPageItemProps } from '../types/search-types'

const SearchPageItem = (props: Readonly<SearchPageItemProps>) => {
  const { url, isExternal, target, title, description, industry } = props

  return (
    <article className="flex flex-col space-y-3 p-3 border border-grey-secondary-dark rounded-lg">
      {(title || description) && (
        <div className="flex flex-col space-y-1">
          {title && (
            <SharedLink href={url} isExternal={isExternal} target={target}>
              <h3 className="text-base md:text-lg font-semibold">{title}</h3>
            </SharedLink>
          )}
          {description && (
            <p className="text-grey-light line-clamp-2">{description}</p>
          )}
        </div>
      )}
      {industry && (
        <div className="text-grey-600 text-xs font-medium">{industry}</div>
      )}
    </article>
  )
}

export default SearchPageItem
