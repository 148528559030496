import { useCustomerHardLockStore } from '@/stores/customer-hard-lock-store'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLockedBody } from 'usehooks-ts'
import SharedButton from '../buttons/shared-button'

const CustomerHardLock = () => {
  const {
    isModalOpen,
    hasAcknowledged,
    closeModal,
    openModal,
    setHasAcknowledged,
  } = useCustomerHardLockStore()

  useLockedBody(isModalOpen, 'root')
  
  const { t } = useTranslation()

  const firstFocusable = useRef<HTMLElement>(null)
  const lastFocusable = useRef<HTMLElement>(null)
  const returnFocus = () => firstFocusable.current?.focus()

  const customerAcknowledgedHardLock = () => {
    setHasAcknowledged(true)
    closeModal()
  }

  useEffect(() => {
    const escKeyPressed = (e: KeyboardEvent) => {
      if (e.key === 'Escape') closeModal()
    }
    window.addEventListener('keydown', escKeyPressed)
    return () => window.removeEventListener('keydown', escKeyPressed)
  }, [isModalOpen])

  useEffect(() => {
    if (isModalOpen) returnFocus()
  }, [isModalOpen])

  useEffect(() => {
    !hasAcknowledged && openModal()
  }, [hasAcknowledged])

  return (
    isModalOpen && (
      <div className="relative z-[1000]">
        <div className="fixed inset-0 animate-modal-fade opacity-0 backdrop-blur-md"></div>
        <div className="fixed inset-0 z-[10] w-screen animate-modal-fade overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl sm:my-8 sm:w-full sm:max-w-3xl">
              <div className="rounded-xl border border-grey-secondary-light bg-white p-4 shadow-xl">
                <div className="mb-6 flex items-center justify-between">
                  <div className="text-lg font-bold text-grey-dark lg:text-xl">
                    {t('CustomerHardLock.MessageTitle')}
                  </div>

                  <SharedButton
                    style="primary"
                    size="regular"
                    className="flex h-10 w-10 items-center justify-center"
                    ref={firstFocusable as React.RefObject<HTMLButtonElement>}
                    onClick={customerAcknowledgedHardLock}
                  >
                    <span className="sr-only">
                      {t('CustomerHardLock.ScreenReaderCloseDescription')}
                    </span>
                    <span>
                      <XMarkIcon className="h-5 w-5 text-white" />
                    </span>
                  </SharedButton>
                </div>
                <div>{t('CustomerHardLock.MessageDescription')}</div>
                {/* Focus trap */}
                <input
                  className="sr-only h-0 w-0 opacity-0"
                  type="text"
                  ref={lastFocusable as React.RefObject<HTMLInputElement>}
                  onFocus={returnFocus}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default CustomerHardLock
