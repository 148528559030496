import { AccordionItemProps } from '@/components/blocks/accordion/types/accordion-types'
import SharedRtf from '@/components/shared/rtf/shared-rtf'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useRef } from 'react'

const AccordionItem = (props: Readonly<AccordionItemProps>) => {
  const ref = useRef<HTMLElement>(null)

  const handleIsOpen = () => {
    if (props.isOpen) {
      props.toggleItem(props.index)
    }
  }

  useEffect(() => {
    handleIsOpen()
  }, [])

  return (
    <div className="border-solid border-0 border-b border-grey-secondary-dark">
      <button
        className="w-full flex justify-between items-center text-left font-bold md:text-xl text-grey-dark py-4"
        onClick={() => props.toggleItem(props.index)}
        aria-expanded={props.activeIndex.includes(props.index)}
        aria-controls={`accordion-text-${props.id}`}
      >
        {props.title}
        <span
          className={`ease-in-out duration-300 ${
            props.activeIndex.includes(props.index) ? '-rotate-180' : 'rotate-0'
          }`}
        >
          <ChevronDownIcon className={`h-6 w-6 text-dark-blue-primary`} />
        </span>
      </button>
      <div
        id={props.id}
        className={`overflow-hidden max-h-0 transition-max-height duration-300`}
        style={
          props.activeIndex.includes(props.index)
            ? {
                maxHeight: ref.current?.clientHeight + 'px',
              }
            : {}
        }
      >
        <div ref={ref as React.RefObject<HTMLDivElement>} className="py-4">
          <SharedRtf html={props.content} />
        </div>
      </div>
    </div>
  )
}

export default AccordionItem
