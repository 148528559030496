import { CTADisruptorProps } from '@/components/blocks/cta-disruptor/types/cta-disruptor-types'
import SharedLink from '@/components/shared/buttons/shared-link'
import SharedImage from '@/components/shared/image/shared-image'
import { ArrowRightIcon } from '@heroicons/react/24/solid'

const CtaDisruptorBlock = (props: Readonly<CTADisruptorProps>) => {
  const { calloutHeading, calloutSubheading, image, calloutButtonLink } =
    props.model
  return (
    <section
      className={`
     ${!props.hideContainerClass ? 'container' : ''}
        ${props.className} flex flex-col md:flex-row`}
    >
      <div className="w-full md:w-1/2 md:rounded-l-lg overflow-hidden">
        {image && <SharedImage className="w-full" {...image}></SharedImage>}
      </div>
      <div className="w-full md:w-1/2 md:rounded-r-lg bg-grey-secondary-light text-grey-dark py-6 px-5 md:py-10 md:px-8 ">
        {calloutHeading && (
          <div className="text-2xl md:text-4xl font-bold">
            <p>{calloutHeading}</p>
          </div>
        )}
        {calloutSubheading && (
          <div className="md:text-base py-3 md:py-4">
            <p>{calloutSubheading}</p>
          </div>
        )}
        {calloutButtonLink && (
          <SharedLink {...calloutButtonLink} style="primary">
            {calloutButtonLink.text}
            <span>
              <ArrowRightIcon className="h-5 w-5 ml-2" />
            </span>
          </SharedLink>
        )}
      </div>
    </section>
  )
}

export default CtaDisruptorBlock
