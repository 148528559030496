import { Device } from '@/types/shared'
import { create } from 'zustand'

export interface SsrInitProps {
  device: Device
  setSsrInitDevice: (device: Device) => void
  wasInitFor: (device: Device) => boolean
  wasInitForDesktop: () => boolean
  wasInitForTablet: () => boolean
  wasInitForMobile: () => boolean
}

export const useSsrInitSettings = create<SsrInitProps>((set, get) => {
  const wasInitFor = (device: Device) => {
    return get().device === device
  }

  return {
    device: 'Unknown',
    setSsrInitDevice: (device: Device) => set(() => ({ device })),
    wasInitFor,
    wasInitForDesktop: () => wasInitFor('Desktop'),
    wasInitForTablet: () => wasInitFor('Tablet'),
    wasInitForMobile: () => wasInitFor('Mobile'),
  }
})
