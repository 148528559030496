import { QuoteTypes } from '@/components/blocks/quote/types/qutoe-types'
import SharedLink from '@/components/shared/buttons/shared-link'
import SharedImage from '@/components/shared/image/shared-image'
import { usePageContext } from '@/stores/page-context-store'
import { ArrowRightIcon } from '@heroicons/react/24/solid'

const QuoteBlock = (props: Readonly<QuoteTypes>) => {
  const { contentTypes } = usePageContext()

  const getPageType = () => {
    if (!contentTypes) return null
    return contentTypes[contentTypes.length - 1]
  }

  const isLandingPage = getPageType() === 'LandingPage'
  const isProgramPage = getPageType() === 'ProgramPage'

  const { model, hideContainerClass } = props

  return (
    <div
      className={`text-grey-dark space-y-6 lg:space-y-12 ${
        !hideContainerClass ? 'container' : ''
      }`}
    >
      <div
        className={`flex flex-col gap-x-6 md:gap-x-36  ${
          isLandingPage || isProgramPage ? 'md:flex-row' : 'text-center'
        }`}
      >
        <div
          className={`font-bold rounded-lg text-xl md:text-2xl md:py-12 ${
            isLandingPage || isProgramPage
              ? 'md:w-1/2'
              : 'w-full text-dark-blue-primary p-6 md:p-14'
          } ${
            model.showGreyBackground && !(isLandingPage || isProgramPage)
              ? 'bg-grey-secondary-light'
              : ''
          }
          `}
        >
          {model.quoteText}
        </div>
        <div
          className={`flex flex-col items-center justify-center ${
            isLandingPage || isProgramPage ? 'md:w-1/2' : 'w-full'
          }`}
        >
          <div
            className={`flex flex-col md:w-1/2 ${
              isLandingPage || isProgramPage
                ? 'items-start'
                : 'items-center -mt-[45px]'
            }`}
          >
            <div className="h-10 w-10 mb-4 mt-6">
              {model.image?.url && (
                <SharedImage {...model.image} className="rounded-[50%]" />
              )}
            </div>
            <span className="font-bold">{model.name}</span>
            <span className="text-grey-light my-2 ">
              {model.organization}
              <br />
              {model.organizationLocation}
            </span>
            {(isLandingPage || isProgramPage) && model.link && (
              <SharedLink
                {...model.link}
                style="text"
                className="!bg-transparent !p-0"
              >
                <span className="font-bold">{model.link.text}</span>
                <span className="ml-2">
                  <ArrowRightIcon className="w-5 h-5 text-dark-blue-primary" />
                </span>
              </SharedLink>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default QuoteBlock
