import SharedLink from '@/components/shared/buttons/shared-link'
import SharedImage from '@/components/shared/image/shared-image'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { CardProps } from '../types/image-card-block-types'

const ImageCardItem: React.FC<CardProps> = ({
  title,
  industry,
  description,
  image,
  linkText,
  url,
  tag,
  showCardTagName,
  showCardTitle,
  showCardIndustry,
  customTag,
  isExternal
}) => {
  return (
    <SharedLink
      href={url}
      isExternal={isExternal }
      className="shadow-none hover:no-underline hover:shadow-lg flex flex-col flex-1 border border-grey-secondary-dark bg-white rounded-lg shared-transition"
    >
      {image?.url && (
        <div className="w-full aspect-[16/9] relative">
          {!customTag && showCardTagName && tag && (
            <div className="z-10 absolute top-5 left-5 inline-flex text-xs font-bold text-grey-dark uppercase tracking-[1.2px] bg-white rounded-lg shadow px-2 py-1">
              {tag}
            </div>
          )}
          {customTag && (
            <div className="z-20 absolute top-5 left-5 inline-flex text-xs font-bold text-grey-dark uppercase tracking-[1.2px] bg-white rounded-lg shadow px-2 py-1">
              {customTag}
            </div>
          )}
          <SharedImage
            {...image}
            className="absolute w-full h-full rounded-t-lg rounded-b-xl shadow-xl object-cover"
          />
        </div>
      )}
      <div className="flex flex-col px-6 pt-8 pb-6 space-y-6 flex-1 justify-between">
        <div className="flex flex-col space-y-4">
          {showCardIndustry && industry && (
            <div className="text-xs font-bold uppercase tracking-[1.2px] text-dark-blue-primary">
              {industry}
            </div>
          )}
          {showCardTitle && title && (
            <h3 className="text-base font-semibold text-grey-dark">{title}</h3>
          )}
          {description && <div className="text-grey-light">{description}</div>}
        </div>
        {linkText && (
          <div className="flex items-center space-x-3 text-dark-blue-primary font-semibold">
            <span>{linkText}</span>
            <span>
              <ArrowRightIcon className="text-dark-blue-primary h-5 w-5" />
            </span>
          </div>
        )}
      </div>
    </SharedLink>
  )
}

export { ImageCardItem }
