import { flexRender } from "@tanstack/react-table";
import { OrderHistoryTableProps, TOrderHistory } from "./types/order-history-types";
import LoadingSpinner from "@/components/shared/loading-spinner";
import PaginationComponent from "./pagination";


function MobileTable(props: Readonly<OrderHistoryTableProps>) {
  const {
    table,
    isLoadingRows,
  } = props

  return (
    <div className="block lg:hidden">
      <div className="mobile-table">
        {/* <SharedButton className='mb-[13px] pl-[0px] pr-[0px]' style="text">
                    <div className='flex gap-2 items-center'>
                        <span>Filters</span>
                        <AdjustmentsVerticalIcon className='h-[16px] w-[16px]'/>
                    </div>
                </SharedButton> */}
        <div className='flex flex-col justify-start gap-2'>
          {table.getRowModel().rows.map(row => (
            <div className='flex flex-1 flex-col pt-[12px] pb-[12px]' style={{ borderBottom: "1px solid #E2E8F0" }} key={row.id}>
              <div className="flex flex-row justify-between gap-3">
                {/* Status, Date,*/}
                <div className="self-start">
                  {row.getVisibleCells().map(cell => {
                    if (cell.id.includes("date") || cell.id.includes("total") || cell.id.includes("status")) {
                      return (
                        <div className="flex gap-1" key={cell.id}>
                          <span className={
                            `${cell.id.includes("date") ? "font-bold" : ""} ${cell.id.includes("status") || cell.id.includes("date") ? "text-black" : ""} `
                          } key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </span>
                        </div>
                      )
                    }
                  })}
                </div>

                {/* Order Number  */}
                <div className="self-start">
                  {row.getVisibleCells().map(cell => {
                    if (cell.id.includes("orderId")) {
                      return (
                        <div className="flex gap-1" key={cell.id}>
                          <span className={``} key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </span>
                        </div>
                      )
                    }
                  })}
                </div>

                {/* View Order Arrow  */}
                <div className="self-center">
                  {row.getVisibleCells().map(cell => {
                    if (cell.id.includes("viewOrder")) {
                      return (
                        <div className="flex gap-1" key={cell.id}>
                          <span className={``} key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </span>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          ))}
          <div className='flex flex-col justify-center w-full'>
            {isLoadingRows && <div className="col-span-1 sm:col-span-2 flex h-full w-full items-center justify-center">
              <LoadingSpinner className="h-5 w-5 text-grey-light" />
            </div>}
          </div>
          {table.getPageCount() > 1 && (
            <div className='w-full flex justify-end'>
              {PaginationComponent<TOrderHistory>({
                table
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default MobileTable