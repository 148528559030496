import { ImageCardItem } from '@/components/blocks/image-card/components/image-card-item'
import SharedLink from '@/components/shared/buttons/shared-link'
import CardSlider from '@/components/shared/card-slider/card-slider'
import SectionHeader from '@/components/shared/section-header'
import { useGlobalSettings } from '@/stores/global-settings'
import { useMediaStore } from '@/stores/media-store'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useState } from 'react'
import { uid } from 'uid'
import { ImageCardBlockProps } from './types/image-card-block-types'

const ImageCardBlock: React.FC<ImageCardBlockProps> = ({ model }) => {
  type LinkSize = 'large' | 'regular' | 'small'

  const globalSettings = useGlobalSettings((state) => state.globalSettings)
  const { isTablet, isMobile } = useMediaStore()

  const [buttonSize, setButtonSize] = useState<LinkSize>('regular')
  const [cardsToShow, setCardsToShow] = useState(3)

  const handleResize = () => {
    if (isMobile) {
      setCardsToShow(1)
      setButtonSize('small')
    } else if (isTablet) {
      setCardsToShow(2)
      setButtonSize('regular')
    } else {
      setCardsToShow(3)
      setButtonSize('regular')
    }
  }

  useEffect(() => {
    handleResize()
  }, [isMobile, isTablet])

  return (
    <section className="container flex flex-col">
      <SectionHeader
        smallIntro={model.smallIntro}
        heading={model.sectionHeading}
      >
        {model.ctaButton?.text && (
          <div className="w-full md:w-1/2 flex justify-end items-center">
            <SharedLink
              {...model.ctaButton}
              style={model.ctaButton.buttonStyle}
              size={buttonSize}
              className="w-full md:w-auto space-x-3"
            >
              <span>{model.ctaButton.text}</span>
              <span>
                <ArrowRightIcon className="w-5 h-5" />
              </span>
            </SharedLink>
          </div>
        )}
      </SectionHeader>

      {model.cards && model.cards.length > 0 && (
        <CardSlider
          cardsToShow={cardsToShow}
          cardTotal={model.cards.length}
          nextScreenreaderText={globalSettings?.sliderNextAriaText}
          previousScreenreaderText={globalSettings?.sliderPreviousAriaText}
        >
          {model.cards.map((card) => (
            <React.Fragment key={uid()}>
              <ImageCardItem
                {...card}
                showCardTagName={model.showCardTagName}
                showCardIndustry={model.showCardIndustry}
                showCardTitle={model.showCardTitle}
              />
            </React.Fragment>
          ))}
        </CardSlider>
      )}
    </section>
  )
}

export default ImageCardBlock
