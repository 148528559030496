/**
 * (GA4) Google Tag Manager Event Types
 */
export enum EventType {
  /**
   * Ecommerce Add To Cart (event is sent when successful only)
   */
  AddToCart = 'add_to_cart',

  /**
   * Ecommerce Add Payment Info
   *
   * Action:
   * - Clicked on Place Order button in checkout. (note: the event is sent whenever the user clicks on the button, even if it fails because of a validation)
   */
  AddPaymentInfo = 'add_payment_info',

  /**
   * Ecommerce Add Shipping Info
   *
   * Action:
   * - Continue to Payment button click (note: the event is sent whenever the user clicks on the button, even if it fails because of a validation)
   */
  AddShippingInfo = 'add_shipping_info',

  /**
   * Ecommerce Begin Checkout (event is sent when successful only)
   */
  BeginCheckout = 'begin_checkout',

  /**
   * Ecommerce Purchase
   *
   * Action:
   * - Place Order button click
   * - Order Confirmation page load initialized (order was successful)
   */
  Purchase = 'purchase',

  /**
   * Ecommerce Remove From Cart
   */
  RemoveFromCart = 'remove_from_cart',

  /**
   * Ecommerce Product Click
   */
  SelectItem = 'select_item',

  /**
   * Ecommerce View Cart
   */
  ViewCart = 'view_cart',

  /**
   * Ecommerce Product Detail View
   */
  ViewItem = 'view_item',

  /**
   * Ecommerce Product Impression
   */
  ViewItemList = 'view_item_list',

  /**
   * FullStory
   */
  FullStory = 'dataLayerUpdated',
}
