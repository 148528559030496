import SharedButton from '@/components/shared/buttons/shared-button'
import SharedLink from '@/components/shared/buttons/shared-link'
import { useFindTrainingStore } from '@/stores/find-training-store'
import { QuestionThreeItemProps } from '../types/find-training-types'
import AnswersList from './answers-list'
import QuestionElement from './question-element'
import QuizBtnCheckbox from './quiz-btn-checkbox'

const QuestionThree = (props: QuestionThreeItemProps) => {
  const {
    question,
    altercationsCheckbox,
    checkboxNoAltercationsAnswers,
    trainingLink,
  } = props

  const { activeStep, physicalAltercationsChecked, goToNextStep } =
    useFindTrainingStore()

  return (
    <div
      className={`flex flex-col space-y-8 md:space-y-12 items-center shared-transition ${
        activeStep === 3 ? 'opacity-1' : 'invisible sr-only opacity-0'
      }`}
    >
      {question && <QuestionElement question={question} />}
      <AnswersList>
        {checkboxNoAltercationsAnswers && (
          <>
            {checkboxNoAltercationsAnswers.map((item, index) => (
              <div key={index} className="w-full">
                {item.checkboxText && (
                  <QuizBtnCheckbox text={item.checkboxText} />
                )}
              </div>
            ))}
          </>
        )}
        {altercationsCheckbox && (
          <QuizBtnCheckbox
            text={altercationsCheckbox}
            isPhysicalAltercations={true}
          />
        )}
      </AnswersList>
      {trainingLink && (
        <>
          {physicalAltercationsChecked ? (
            <SharedButton style="primary" size="small" onClick={goToNextStep}>
              {trainingLink.text}
            </SharedButton>
          ) : (
            <SharedLink {...trainingLink} style="primary" size="small">
              {trainingLink.text}
            </SharedLink>
          )}
        </>
      )}
    </div>
  )
}

export default QuestionThree
