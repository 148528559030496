import { useEffect, useState } from 'react'
import SharedImage from '@/components/shared/image/shared-image'
import { useLocationsStore } from '@/stores/locations-store'
// @ts-ignore
import { DateTime } from 'luxon'
import { Link } from 'react-router-dom'
import { CardItemProps } from '../types/index-types'
import { usePageContext } from '@/stores/page-context-store'

function FeaturedCard(props: Readonly<CardItemProps>) {
  const { url, title, image, description, industry, publishDate, tag } = props
  const { selectedCountry } = useLocationsStore()
  const { contentTypes } = usePageContext()
  const [isLibraryIndexPage, setIsLibraryIndexPage] = useState(false)

  const formatDate = (date: string) => {
    const country = selectedCountry?.location?.cultureCode

    if (country) {
      return DateTime.fromISO(date).setLocale(country).toLocaleString()
    } else {
      return DateTime.fromISO(date).toLocaleString()
    }
  }

  useEffect(() => {
    const getPageType = () => {
      if (!contentTypes) return null
      return contentTypes[contentTypes.length - 1]
    }
    setIsLibraryIndexPage(getPageType() === 'LibraryIndexPage')
  }, [contentTypes])

  return (
    <section className="flex flex-col">
      <div
        className={`
        group relative flex flex-col rounded-lg overflow-hidden
        md:flex-row md:items-center md:justify-between 
        shadow-none hover:shadow-xl hover:no-underline shared-transition
        before:md:content-[''] before:md:block before:md:absolute before:md:right-0 before:md:h-full before:md:w-2/3 before:md:bg-grey-secondary-light before:md:rounded-lg `}
      >
        {image && (
          <div className="relative w-full md:w-1/2">
            {image && (
              <SharedImage
                {...image}
                className="aspect-[16/9] object-cover w-full h-full md:rounded-lg"
              />
            )}
          </div>
        )}
        <div className="relative flex items-center md:flex-1 bg-grey-secondary-light md:bg-none py-6 md:py-5 px-[18px] md:px-8">
          <div className="flex flex-col space-y-4">
            {tag && (
              <div>
                <div className="inline-flex text-xs font-bold text-grey-dark uppercase tracking-[1.2px] bg-white rounded-lg shadow px-2 py-1">
                  {tag}
                </div>
              </div>
            )}
            {title && url && (
              <Link to={url} className="hover:no-underline">
                <h2 className="text-xl md:text-2xl font-bold text-grey-dark group-hover:text-dark-blue-primary">
                  {title}
                </h2>
              </Link>
            )}
            {description && (
              <div className="text-base text-grey-dark">{description}</div>
            )}
            {(industry || publishDate) && (
              <div className="flex space-x-8 text-base text-grey-dark font-medium">
                {industry && <span>{industry}</span>}
                {!isLibraryIndexPage && publishDate && <span>{formatDate(publishDate)}</span>}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedCard
