import { BellAlertIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { SiteAlertProps } from './types/site-alert-types'
import './styles/site-alert.scss'
import { useEffect, useState } from 'react'

const SiteAlertBlock = (props: Readonly<SiteAlertProps>) => {
  const { model } = props
  const [isOpen, setIsOpen] = useState(false)

  const handleCloseMenu = () => {
    setIsOpen(!isOpen)

    setCookie(`alert-${model.contentLink}`, 'accpeted', {
      expires: new Date('360'),
    })
  }

  const getCookie = (cname: any) => {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')

    for (const item of ca) {
      const c = item.trim()

      if (c.startsWith(name)) {
        return c.substring(name.length, c.length)
      }
    }

    return ''
  }

  const setCookie = (cname: any, cvalue: any, exdays: any) => {
    const date = new Date()
    date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000)
    const expires = `expires=${date.toUTCString()}`
    document.cookie = `${cname}=${cvalue};${expires};path=/`
  }

  const handleAlertMessage = () => {
    const cookieId = getCookie(`alert-${model.contentLink}`)
    if (!cookieId) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    handleAlertMessage()
  }, [])

  return (
    <>
      {isOpen && (
        <div className="site-alert bg-grey-secondary-light text-grey-dark py-4">
          <div className="container flex justify-between items-start">
            <div className="flex-1 flex flex-col">
              <div className="flex items-center space-x-3">
                <span
                  className="flex"
                  role="img"
                  aria-label={model.alertIconAriaText}
                >
                  <BellAlertIcon className="h-6 w-6 text-grey-dark" />
                </span>
                {model.headingText && (
                  <div
                    className="heading-rtf text-base md:text-lg font-bold"
                    dangerouslySetInnerHTML={{ __html: model.headingText }}
                  />
                )}
              </div>
              {model.subheadingText && (
                <div
                  className="subheading-rtf text-sm md:text-base a:font-bold ml-9"
                  dangerouslySetInnerHTML={{ __html: model.subheadingText }}
                />
              )}
            </div>
            <button
              className="flex items-center justify-center h-10 w-10 rounded-lg"
              onClick={handleCloseMenu}
            >
              {model.closeButtonAriaText && (
                <span className="sr-only">{model.closeButtonAriaText}</span>
              )}
              <span className="flex">
                <XMarkIcon className="text-grey-dark h-6 w-6" />
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default SiteAlertBlock
