import { epiGetCategory } from '@/api/content-delivery'
import { CategoryContentModel } from '@/api/types/content-delivery-types'
import SharedButton from '@/components/shared/buttons/shared-button'
import { RenderContentArea } from '@/epi/pageUtils/render-content-area'
import { useHeaderSearchStore } from '@/stores/header-search-store'
import { useSearchStore } from '@/stores/search-store'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ProductListingFilters from './components/product-listing-filters'
import ProductListingSort from './components/product-listing-sort'
import ProductTileGrid from './components/product-tile-grid'
import { CategoryModel, ProductListingPageProps } from './types/plp-types'
import { viewItemListGTM } from '@/components/shared/analytics/gtm-manager'
import withHydration from '@/lib/withHydration'

const ProductListingPage = (props: Readonly<ProductListingPageProps>) => {
  const { model } = props

  const {
    isLoading,
    page,
    setPage,
    setPageUrl,
    setCategoryId,
    setSearchTerm,
    setIsLoadMore,
    setActiveCategory,
    fetchResults,
    products,
    hasMoreProducts,
    resetProducts,
    facetGroups,
    setSelectedFacets,
    orderBy,
    setOrderBy,
  } = useSearchStore()

  const { setHeaderSearchTerm } = useHeaderSearchStore()

  const [searchParams, _] = useSearchParams()
  const categoryId = props.model.categoryId ?? searchParams.get('id');
  const [category, setCategory] = useState<CategoryModel>()
  const [hasAppliedParamFilter, setHasAppliedParamFilter] = useState(false);

  const updateUrlAndFetchResults = (
    page: number | undefined,
    removeProductPage = false,
  ) => {
    const currentSearchParams = new URLSearchParams(window.location.search)
    page && currentSearchParams.set('productPage', `${page}`)
    removeProductPage && currentSearchParams.delete('productPage')

    const newUrl = `${window.location.pathname}?${currentSearchParams.toString()}`
    window.history.pushState(null, '', newUrl)

    fetchResults()
  }

  const loadMore = async () => {
    const newPage = page + 1
    setPage(newPage)
    setIsLoadMore(true)
    updateUrlAndFetchResults(newPage)
  }

  const handleSearchParams = () => {
    const term = searchParams.get('q')
    term && setSearchTerm(term) // set search input value to query value on page load
    setHeaderSearchTerm('') // clear header search term on page load
  }

  const applyOrClearFilters = (selectedFacets: string) => {
    setSelectedFacets(selectedFacets)
    setPage(1)
    resetProducts()
    updateUrlAndFetchResults(undefined, true)
  }

  const applyOrderBy = (orderBy: number) => {
    setOrderBy(orderBy)
    setPage(1)
    resetProducts()
    updateUrlAndFetchResults(undefined, true)
  }

  const clearSelections = () => {
    applyOrClearFilters('')
  }

  useEffect(() => {
    const handlePopstate = (event: PopStateEvent) => {
      resetProducts()
      setIsLoadMore(false)
      const pageNumberFromQuery = searchParams.get('productPage')
      setPage(pageNumberFromQuery ? parseInt(pageNumberFromQuery) : 1)
      fetchResults()
    }
    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  useEffect(() => {
    if (!categoryId) return

    const getCategory = async () => {
      const categoryResponse = await epiGetCategory(categoryId)
      if (!categoryResponse) {
        console.warn('Unable to find product for categoryId', categoryId)
        return
      }
      setCategory(categoryContentModelToCategoryModel(categoryResponse))
    }

    resetProducts()
    setIsLoadMore(false)
    getCategory()
    setCategoryId(categoryId)
    handleSearchParams()
    setPageUrl(model.pageUrl)
    const pageNumberFromQuery = searchParams.get('productPage')
    setPage(pageNumberFromQuery ? parseInt(pageNumberFromQuery) : 1)
    setActiveCategory('products')
    fetchResults()
  }, [])

  const itemListId = 'product_catalogue'
  useEffect(() => {
    if (!products.length) return

    // Push the 'view_item_list' GTM event
    viewItemListGTM(products, itemListId)
  }, [products])

  useEffect(() => {
    if (products.length > 0 && hasAppliedParamFilter === false) {
      const currentSearchParams = new URLSearchParams(window.location.search)
      const fkey = currentSearchParams.getAll('fkey');
      const selectedKeys: string[] = [];
      facetGroups.find((fg)=> fg.facets?.find(f => {
        if(fkey.includes(f.key)) {
          selectedKeys.push(f.key)
        }
      }));
      applyOrClearFilters(selectedKeys.join(','))
      setHasAppliedParamFilter(true);
    }
  }, [products, hasAppliedParamFilter])


  return (
    <div className="mt-8 container flex flex-col py-0 font-mulish ">
      <div className="flex flex-col">
        <div className="flex w-full flex-col justify-between gap-[12px] pb-7">
          <h1 className="text-2xl font-bold text-grey-dark lg:text-4xl">
            {category?.displayName}
          </h1>
        </div>
        {RenderContentArea(model.topContentArea)}
        {category && (
          <div className="flex grid-cols-8 flex-col gap-7 md:grid">
            <div className="flex-auto md:col-span-2">
              <div className="h-auto text-grey-dark">
                <ProductListingFilters
                  facetGroups={facetGroups}
                  applyFilters={applyOrClearFilters}
                  clearSelections={clearSelections}
                  localizations={model.localizations}
                />
              </div>
              <div className="reduced-font mt-5">{RenderContentArea(props.model.underFiltersContentArea)}</div>
            </div>
            <div className="flex-1 md:col-span-6">
              <ProductListingSort
                orderBy={orderBy}
                onOrderByChange={applyOrderBy}
                setOrderBy={setOrderBy}
                localizations={model.localizations}
              />
              <div className="flex flex-col gap-4">
                <ProductTileGrid products={products} itemListId={itemListId} />
              </div>
              {(!products || products.length === 0) && !isLoading && (
                <div>{model.localizations?.emptyMessage}</div>
              )}
              <div className="flex flex-col gap-4">
                <div className="flex items-center justify-center mt-5">
                  {
                  hasMoreProducts &&  (
                  (products && products.length > 0) && 
                  <SharedButton
                      onClick={async () => await loadMore()}
                      style="primary"
                    >
                      <span>{model.localizations?.loadMore}</span>
                    </SharedButton>
                  )
                  }
                </div>
              </div>
            </div>
          </div>
        )}
        {RenderContentArea(model.bottomContentArea)}
      </div>
    </div>
  )
}

export default withHydration(ProductListingPage)

function categoryContentModelToCategoryModel(
  categoryContentModel: CategoryContentModel,
) {
  return {
    contentId: `${categoryContentModel.contentLink.id}`,
    displayName: categoryContentModel.displayName,
    name: categoryContentModel.name,
    code: categoryContentModel.code,
  } as CategoryModel
}
