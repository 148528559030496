import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import BlockComponentSelector from '@/epi/block-component-selector'
import { useEffect, useState } from 'react'
import { WorkplaceTrainingResultsPageProps } from './types/workplace-training-results-page-types'

const handleScript = (customScript: string) => {
  const script = document.createElement('script')
  script.type = 'application/javascript'
  script.textContent = customScript
  document.body.appendChild(script)
}

const WorkplaceTrainingResultsPage = (
  props: Readonly<WorkplaceTrainingResultsPageProps>,
) => {
  const { pageContent, logicScripts } = props.model
  const [pageIsLoaded, setPageIsLoaded] = useState(false)

  useEffect(() => {
    setPageIsLoaded(true)
  }, [])

  useEffect(() => {
    const body = document.querySelector('.wvp-calc-results-body')
    if (pageIsLoaded) {
      if (logicScripts) {
        handleScript(logicScripts)
        body?.classList.replace('opacity-0', 'opacity-1')
      }
    }
  }, [pageIsLoaded])

  return (
    <>
      <Breadcrumbs />
      <div className="wvp-calc-results-body flex flex-col space-y-[6rem] md:space-y-8 opacity-0 transition-opacity duration-700 ease-in">
        {pageContent && (
          <>
            {pageContent?.map((blockModel, i) => (
              <BlockComponentSelector
                {...blockModel}
                key={`pageContent-${i}`}
              />
            ))}
          </>
        )}
      </div>
    </>
  )
}
export default WorkplaceTrainingResultsPage
