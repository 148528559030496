import { useTranslation } from 'react-i18next'

const ErrorBlock = () => {
  const { t } = useTranslation()

  return (
    <section className="container justify-center">
      {t('Blocks.ErrorBlock.Message')}
    </section>
  )
}
export default ErrorBlock
