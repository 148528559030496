import Price from '../price/price'
import { PriceContentModel } from '@/api/types/content-delivery-types'
import { uid } from 'uid';
import { ProductDetailPageLocalization } from '@/components/pages/product-detail/types/product-detail-types';
import { currencyToLocale } from '../price/types/price-types';
import { useUserStore } from '@/stores/useUserStore';
import { DEFAULT_CURRENCY_CODE } from '@/lib/constants';

export interface TierPricingTableProps {
  className?: string
  emptyDisplay?: string
  rows: PriceContentModel[]
  localization?: ProductDetailPageLocalization
}

const TierPricing: React.FC<TierPricingTableProps> = (
  props: TierPricingTableProps,
) => {
  const { user } = useUserStore()
  return (
    <table
      className={`overflow-hidden rounded-lg text-sm shadow-sm ${props.className}`}
    >
      <thead className="border-b bg-grey-secondary-light text-grey-light">
        <tr>
          <th className="h-12 w-[150px] px-4 py-2 pr-0 text-left align-middle font-medium">
            {props.localization?.from}
          </th>
          <th className="h-12 w-[150px] px-4 py-2 pr-0 text-left align-middle font-medium">
            {props.localization?.to}
          </th>
          <th className="h-12 px-4 pl-0 text-right align-middle font-medium">
            {props.localization?.pricePerUnit}
          </th>
        </tr>
      </thead>
      <tbody>
        {props?.rows?.map((row, index) => (
          <tr key={uid()} className="transition-colors hover:bg-grey-light/10">
            <td className="px-4 py-2 align-middle">
              {row.minQuantity}
            </td>
            <td className="px-4 py-2 align-middle">
              {index + 1 == props.rows?.length ? props.emptyDisplay : props.rows[index + 1]?.minQuantity - 1}
            </td>
            <td className="px-4 py-2 text-right align-middle">
              {!!row.price && <Price currency={user?.authInfo?.currencyCode ?? DEFAULT_CURRENCY_CODE} culture={currencyToLocale[user?.authInfo?.currencyCode ?? DEFAULT_CURRENCY_CODE]} price={row.price.toString()} />}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

TierPricing.defaultProps = {
  emptyDisplay: '-',
}

export default TierPricing
