import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import SharedLink from '../buttons/shared-link'

interface DownloadBtnProp {
  href?: string
  type: string
  text?: string
  extension?: string
}

const DownloadBtn = (props: Readonly<DownloadBtnProp>) => {
  const { href, type, text, extension } = props
  return (
    <>
      {type === 'Image' ? (
        <a
          href={href}
          target="_blank"
          className="w-full flex flex-col space-y-[22px] py-3 px-4 bg-grey-secondary-light border border-grey-secondary-dark rounded-lg hover:no-underline"
          rel="noreferrer"
        >
          <div className="text-grey-dark text-sm uppercase font-medium">
            {extension}
          </div>
          <div className="w-full flex items-center justify-between space-x-4">
            <span className="text-left text-sm text-grey-dark font-semibold">
              {text}
            </span>
            <span>
              <ArrowDownTrayIcon className="h-6 w-6 text-grey-dark" />
            </span>
          </div>
        </a>
      ) : (
        <SharedLink
          href={href}
          isExternal={true}
          target="_blank"
          style="primary"
          className="w-full justify-between"
        >
          <span>{text}</span>
          <span>
            <ArrowDownTrayIcon className="h-5 w-5" />
          </span>
        </SharedLink>
      )}
    </>
  )
}

export default DownloadBtn
