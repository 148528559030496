import { SearchPageItemProps } from '@/components/pages/search/types/search-types'
import { PartialNullable } from '@/lib/partial-nullable.type'
import { DEFAULT_PRICE, DEFAULT_QUANTITY, EMPTY_VALUE, ITEM_BRAND } from '../constants'
import { EventType } from '../enums/event-type.enum'
import { includeItemCategories } from '../functions/include-item-categories.function'
import { includeItemLanguage } from '../functions/include-item-language.function'
import { pushEvent } from '../functions/push-event.function'
import { EventItemParameters } from '../types/event-item-parameters.type'

export const selectItemGTM = async (product: SearchPageItemProps, itemListId: string) => {
  const ecommerce: { items: PartialNullable<EventItemParameters>[] } = {
    items: [],
  }

  const item: PartialNullable<EventItemParameters> = {
    item_id: product.contentlinkId,
    item_name: product.title,
    item_brand: ITEM_BRAND,
    item_list_id: itemListId,
    item_list_name: itemListId,
    item_variant_id: EMPTY_VALUE,
    price: DEFAULT_PRICE,
    quantity: DEFAULT_QUANTITY,
  }

  await includeItemCategories(item, product.contentlinkId ?? EMPTY_VALUE)
  await includeItemLanguage(item, product.contentlinkId)

  ecommerce.items.push(item)

  pushEvent({
    event: EventType.SelectItem,
    ecommerce: ecommerce,
  })
}
