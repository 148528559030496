import SharedLink from '@/components/shared/buttons/shared-link'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { FullWidthCalloutProps } from './types/full-width-callout-types'

function FullWidthCalloutBlock(props: Readonly<FullWidthCalloutProps>) {
  const { model, hideContainerClass } = props

  return (
    <section className={!hideContainerClass ? 'container' : ''}>
      <div className="py-14 lg:py-16 px-8 mb-12 bg-light-blue-100 text-grey-dark text-center relative mx-[-18px] md:mx-0 flex flex-col space-y-4 rounded-lg">
        {model.headline && (
          <h2 className="mx-auto text-3xl lg:text-4xl font-bold lg:max-w-[617px]">
            {model.headline}
          </h2>
        )}

        {model.subheadline && (
          <p className="lg:text-lg lg:max-w-[496px] mx-auto">
            {model.subheadline}
          </p>
        )}

        {model.button && (
          <SharedLink
            style="primary"
            {...model.button}
            className="lg:text-lg absolute left-1/2 -translate-x-1/2 translate-y-1/2 bottom-0"
          >
            {model.button?.text}
            <ArrowRightIcon className="h-4 w-4 ml-2" />
          </SharedLink>
        )}
      </div>
    </section>
  )
}

export default FullWidthCalloutBlock
