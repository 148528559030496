import { useIndexPageStore } from '@/stores/index-store'
import { TabItemProps } from '../types/index-types'

function IndexTab(props: Readonly<TabItemProps>) {
  const { link, index, hideMinWidth } = props

  const {
    activeTabIndex,
    setActiveTabIndex,
    resetPage,
    setPageUrl,
    fetchResults,
  } = useIndexPageStore()

  const handleTabChange = (pageUrl: string) => {
    setActiveTabIndex(index)
    setPageUrl(pageUrl)
    resetPage()
    fetchResults()
  }
  return (
    <>
      {link?.href && (
        <button
          className={`inline-flex whitespace-nowrap justify-center ${
            hideMinWidth ? 'min-w-[128px] px-6' : 'px-3'
          } py-1 rounded-lg font-medium text-base hover:no-underline hover:text-white hover:bg-dark-blue-400 shared-transition ${
            activeTabIndex === index
              ? 'text-white bg-dark-blue-primary'
              : 'text-grey-dark bg-white'
          }`}
          onClick={() => handleTabChange(link.href)}
        >
          {link.text}
        </button>
      )}
    </>
  )
}

export default IndexTab
