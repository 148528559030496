import axios from 'axios';
import clientOptionsConfig from '@/lib/ping-identity/config';

const api = axios.create({
    baseURL: typeof window !== 'undefined' ? window.location.origin : ""
});

api.interceptors.response.use((response) => {
    if (response.status === 200 && response.request.requestType !== 'application/json'
        && response.request.responseURL?.includes("auth.pingone.com")) {
        localStorage.setItem("user", "null");
        window.location.href = window.location.origin + clientOptionsConfig.logoutUrl
    }
    return response;
}, (error) => {
    return Promise.reject(error);
});

export default api;