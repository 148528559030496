import queryStringHandler from '@/lib/lazy-query-string-handler'
import { useHeaderSearchStore } from '@/stores/header-search-store'
import { usePrimaryMenuMobileStore } from '@/stores/primary-menu-mobile'
import { HTMLInputEvent } from '@/types/shared'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SearchProps } from '../types/header'
import { useHydrated } from 'react-hydration-provider'


function Search(props: Readonly<SearchProps>) {
  const { t } = useTranslation()
  const hydrated = useHydrated()
  const { isDesktop, placeholder } = props
  const navigate = useNavigate()

  const { headerSearchTerm, setHeaderSearchTerm } = useHeaderSearchStore()
  const {
    toggleMobileSearchMenu,
    mobileSearchMenuIsOpen,
    handleMobileSearchMenuToggle,
  } = usePrimaryMenuMobileStore()

  const goToSearchPage = async () => {
    let searchCategory = 'all'
    // on search page if someone uses header search then check for category value
    const handler = await queryStringHandler.getHandler()
    if (handler.getValue('category') === 'products') {
      searchCategory = 'products'
    }

    navigate(
      `${props.pageUrl}?q=${headerSearchTerm}&category=${searchCategory}`,
    )

    if (mobileSearchMenuIsOpen) {
      toggleMobileSearchMenu()
    }
  }

  const handleResetSearch = () => {
    setHeaderSearchTerm('')
  }

  const handleUpdateSearch = (event: HTMLInputEvent) => {
    setHeaderSearchTerm(event.target?.value)
  }

  return (
    <div
      className={[
        !isDesktop
          ? 'h-14 bg-white flex items-center px-[18px] shared-transition'
          : '',
        !mobileSearchMenuIsOpen && !isDesktop ? 'opacity-0 sr-only' : '',
        mobileSearchMenuIsOpen && !isDesktop
          ? 'opacity-1 absolute top-0 right-0 z-10 left-0'
          : '',
      ].join(' ')}
    >
      <div className="relative flex w-full lg:max-w-[256px]">
        <div className="absolute left-3 z-10 top-1/2 -translate-y-1/2 w-5 h-5">
          <MagnifyingGlassIcon className="w-5 h-5 text-grey-light" />
        </div>
        <label className="sr-only" htmlFor="siteSearch">
          {placeholder}
        </label>
        <input
          type="text"
          name="q"
          id="siteSearch"
          value={headerSearchTerm}
          onChange={(e: any) => handleUpdateSearch(e)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              await goToSearchPage()
            }
          }}
          placeholder={placeholder}
          className="w-full text-base py-2 focus:shadow-focus rounded-lg pl-11 pr-10 bg-grey-secondary-light text-grey-light"
        />
        {isDesktop && headerSearchTerm.length > 0 && (
          <button
            className="absolute right-0 top-1/2 -translate-y-1/2 z-10 flex items-center justify-center h-10 w-10"
            tabIndex={headerSearchTerm.length > 0 ? 0 : -1}
            onClick={handleResetSearch}
          >
            <span className="sr-only">{hydrated && t('ScreenReader.ResetSearch')}</span>
            <XMarkIcon className="w-6 h-6 lg:w-4 lg:h-4" />
          </button>
        )}

        {!isDesktop && (
          <button
            className="flex items-center justify-center h-10 w-10 ml-2"
            onClick={handleMobileSearchMenuToggle}
          >
            <span className="sr-only">{hydrated && t('ScreenReader.CloseSearchMenu')}</span>
            <XMarkIcon className="w-6 h-6 lg:w-4 lg:h-4" />
          </button>
        )}
      </div>
    </div>
  )
}

export default Search
