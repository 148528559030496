import StatisticsLinkCard from '@/components/blocks/statistics-link-card-block/components/statistics-link-card'
import { StatisticsLinkCardBlockProps } from '@/components/blocks/statistics-link-card-block/types/statistics-link-card-types'
import SharedLink from '@/components/shared/buttons/shared-link'
import SectionHeader from '@/components/shared/section-header'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { uid } from 'uid'

const StatisticsLinkCardBlock = (
  props: Readonly<StatisticsLinkCardBlockProps>,
) => {
  const { model, hideContainerClass } = props

  return (
    <section
      className={`flex flex-col space-y-8 md:space-y-[78px] ${
        !hideContainerClass ? 'container' : ''
      }`}
    >
      <div className="flex flex-col md:space-y-[78px]">
        {(model.heading || model.smallIntro || model.subheading) && (
          <SectionHeader
            smallIntro={model.smallIntro}
            heading={model.heading}
            description={model.subheading}
          />
        )}

        {model.cards && model.cards.length > 0 && (
          <div className="flex flex-col gap-y-11 md:gap-y-0 md:gap-x-12 md:flex-row md:justify-center lg:px-12">
            {model.cards.map((card) => (
              <StatisticsLinkCard
                key={uid()}
                statistic={card.statistic}
                cardUrl={card.url}
                cardLinkText={card.linkText}
              />
            ))}
          </div>
        )}
      </div>

      {model.ctaLink && (
        <div className="flex justify-center">
          <SharedLink
            style={model.buttonStyle}
            {...model.ctaLink}
            className="space-x-2"
          >
            <span>{model.ctaLink.text}</span>
            <span>
              <ArrowRightIcon className="h-4 w-4" />
            </span>
          </SharedLink>
        </div>
      )}
    </section>
  )
}

export default StatisticsLinkCardBlock
