import React from 'react'
import { CheckoutStepThreeFormInputs } from '../../pages/checkout/types/checkout-types'
import { InputError } from './input-error'
import { FormInputProps } from './types/form-input-props'

export const FormInput: React.FC<FormInputProps> = ({ labelHasAsterisk, label, placeholder, register, required, errors, mask, ...inputProps }: FormInputProps) => {
  const key = (register.name as keyof CheckoutStepThreeFormInputs) || null
  const hasErrors = errors && key && errors?.[key]
  const errorMessage = hasErrors && errors?.[key]?.message
  const errorClass = hasErrors ? 'ValidationFail' : ''

  console.log("input", labelHasAsterisk)
  return (
    <div className="flex flex-col">
      <div className={`flex flex-col gap-3  ${errorClass}`}>
        <label className="flex-1 text-black font-bold" htmlFor={register.name}>
          {label} {labelHasAsterisk ? "*" : ""}
        </label>
        <input
          id={register.name}
          {...register}
          {...inputProps}
          className="input flex-[50%]"
          placeholder={placeholder}
        />
        <InputError message={errorMessage} />
      </div>
    </div>
  )
}

FormInput.defaultProps = {
  labelHasAsterisk: false
}