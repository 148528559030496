import SharedLink from '@/components/shared/buttons/shared-link'
import CountrySelector from '@/components/shared/header/components/country-selector'
import { useLocationsStore } from '@/stores/locations-store'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { usePageContext } from '@/stores/page-context-store'
// @ts-ignore
import Flag from 'react-world-flags'

const LocationInterstitial = () => {
  const { handleUpdateSelectedCountry, locations, suggestionPopup, showPopup } =
    useLocationsStore()

  const [isOpen, setIsOpen] = useState(false)

  const handleSelectedCountryItem = (code: any) => {
    const selectedIndex = locations?.findIndex(
      (item: any) => item.location?.cultureCode === code,
    )

    if (locations && typeof selectedIndex === 'number' && selectedIndex > -1) {
      handleUpdateSelectedCountry(locations[selectedIndex])
    }
  }

  const { contentTypes } = usePageContext()
  const getPageType = () => {
    if (!contentTypes) return null
    return contentTypes[contentTypes.length - 1]
  }
  const isSurveyPage = getPageType() === 'WorkplaceTrainingLandingPage'


  useEffect(() => {
    if (showPopup) {
      const element = document.getElementById('location-interstitial')
      if (element && element.classList.contains('hidden')) {
        element.classList.remove('hidden')
      }
      setTimeout(() => {
        setIsOpen(true)
      }, 2000)
    }
  }, [])

  const isClosed = () => {
    setIsOpen(false)
    const element = document.getElementById('location-interstitial')
    if (element && !element.classList.contains('hidden')) {
      setTimeout(() => {
        element.classList.add('hidden')
      }, 2500)
    }
  }

  return (
    !isSurveyPage &&
    <>
      {showPopup && suggestionPopup && (
        <div id='location-interstitial'
          className={`fixed bottom-0 z-[1050] right-[18px] max-w-[calc(100%-36px)] flex flex-col space-y-5 justify-end py-4 px-6 bg-white rounded-lg border border-grey-secondary-dark shadow-md shared-transition ${
            isOpen
            ? 'opacity-1 -translate-y-[18px]'
            : 'opacity-0 translate-y-0 h-0 overflow-hidden'
          }`}
        >
          <div className="flex justify-between space-x-5">
            <div className="text-grey-dark font-bold">
              {suggestionPopup.intro}
            </div>
            <button className="w-5 h-5 flex" onClick={() => isClosed()}>
              <span className="sr-only">{suggestionPopup.closeModalAria}</span>
              <span>
                <XCircleIcon className="w-5 h-5 text-grey-primary" />
              </span>
            </button>
          </div>
          <div className="flex flex-wrap items-center space-y-3 md:space-y-0 md:space-x-3">
            {suggestionPopup.stayButton && (
              <button
                className="w-full md:w-auto flex items-center space-x-3 whitespace-nowrap py-1.5 px-3 rounded-lg hover:no-underline text-dark-blue-primary border border-dark-blue-primary"
                onClick={() => isClosed()}
              >
                <span className="w-5">
                  {suggestionPopup.stayButton.countryCode && (
                    <Flag
                      code={suggestionPopup.stayButton.countryCode}
                      alt={suggestionPopup.stayButton.countryCode}
                      className="w-5 rounded-sm border border-black/10"
                    />
                  )}
                </span>
                <span>{suggestionPopup.stayButton.text}</span>
              </button>
            )}
            {suggestionPopup.applyButton && (
              <SharedLink
                href={
                  suggestionPopup.applyButton.url === 'en'
                    ? '/'
                    : suggestionPopup.applyButton.url
                }
                className="w-full md:w-auto flex items-center space-x-3 whitespace-nowrap hover:no-underline py-1.5 px-3 rounded-lg text-white hover:text-white bg-dark-blue-primary border border-dark-blue-primary"
                onClick={() =>
                  handleSelectedCountryItem(
                    suggestionPopup.applyButton?.cultureCode,
                  )
                }
              >
                <span className="w-5">
                  {suggestionPopup.applyButton.countryCode && (
                    <Flag
                      code={suggestionPopup.applyButton.countryCode}
                      alt={suggestionPopup.applyButton.countryCode}
                      className="w-5 rounded-sm border border-black/10"
                    />
                  )}
                </span>
                <span>{suggestionPopup.applyButton.text}</span>
              </SharedLink>
            )}

            <CountrySelector
              isLocationInterstitial={true}
              menuBtnText={suggestionPopup.allOptionsLabel}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default LocationInterstitial
