import { CategoryContentModel } from "@/api/types/content-delivery-types"
import { PartialNullable } from "@/lib/partial-nullable.type"
import { EventItemParameters } from "../types/event-item-parameters.type"

export const setCategories = (item: PartialNullable<EventItemParameters>, categories: CategoryContentModel[]) => {
  if (!categories?.length) return

  categories.forEach((category, index) => {
    let key = 'item_category'

    if (index > 0) key += index + 1

    item[key] = category.name
  })
}