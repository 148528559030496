import i18n from 'i18next'
import Backend, { HttpBackendOptions } from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    debug: false,
    load: 'currentOnly',
    lng: 'en',
    fallbackLng: 'en',
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: '/localization/get?lang={{lng}}',
      reloadInterval: false, // can be used to reload resources in a specific interval (milliseconds) (useful in server environments)
    },
    defaultNS: "",
    ns: '',
    keySeparator: '.',
    initImmediate: false,
    fallbackNS: '',
    interpolation: {
      escapeValue: false,
    },
  // resources: {},
  })

  export default i18n