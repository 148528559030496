import { create } from 'zustand'

interface HeaderSearchStoreProps {
  headerSearchTerm: string
  setHeaderSearchTerm: (value: string) => void
}

export const useHeaderSearchStore = create<HeaderSearchStoreProps>((set) => ({
  headerSearchTerm: '',
  setHeaderSearchTerm: (value: string) =>
    set(() => ({ headerSearchTerm: value })),
}))
