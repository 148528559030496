import { epiCreateOrUpdatePayment, epiConvertToOrder, epiGetCart, epiGetPaymentMethods, epiGetValidatedCart, epiProcessPayments, RESULTCODES, epiUpdatePayment } from '@/api/content-delivery'
import { ExtendedPropertyModel, OrderModel, PaymentMethodModel, PaymentModel } from '@/api/types/content-delivery-types'
import { ValidationIssue } from '@/api/validation'
import { create } from 'zustand'

export const SYSTEMKEYWORD_DUMMY = 'Dummy'
export const SYSTEMKEYWORD_PAYFABRIC = 'PayFabric'
export const SYSTEMKEYWORD_PAYONACC = 'PayOnAccount'

interface PlaceOrderResult {
    code: RESULTCODES
    order?: OrderModel
}

interface CheckoutStoreProps {
    isLoading: boolean
    pageUrl: string
    shippingAddress?: any
    billingAddress?: any
    setIsLoading: (isLoading: boolean) => void
    setPageUrl: (url: string) => void
    placeOrder: (marketId: string, payload: PlaceOrderPayload) => Promise<PlaceOrderResult>
}

export interface PlaceOrderPayload {
    cartId?: number
    paymentType: string
    purchaseOrderNumber?: string
    cardInfo?: {
        name: string
        ccn: string
        month: number
        year: number
        cvv: string
    }
}

export const useCheckoutStore = create<CheckoutStoreProps>((set, get) => ({
    isLoading: false,
    pageUrl: '',
    shippingAddress: undefined,
    billingAddress: undefined,
    setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
    setPageUrl: (url: string) => set(() => ({ pageUrl: url })),
    setShippingAddress: (address: any) => set(() => ({ shippingAddress: address })),
    setBillingAddress: (address: any) => set(() => ({ billingAddress: address })),
    placeOrder: async (marketId: string, payload: PlaceOrderPayload) => {

        const currentState = get()
        if(currentState.isLoading) return { code: RESULTCODES.ORDER_ERROR }
        currentState.setIsLoading(true)
        try {
            const cart = await epiGetCart(marketId)
            if(!cart) throw new Error(`placeOrder: Unable to get cart`)
            const validatedCart = await epiGetValidatedCart(cart.id)
            if(!validatedCart) throw new Error('placeOrder: Unable to get validated cart')
            if(validatedCart.validationIssues.length) {
                const issues = validatedCart.validationIssues.flatMap(i => i.validationIssues)
                const unavailable = 
                    issues?.includes(ValidationIssue.RemovedDueToInvalidMaxQuantitySetting) ||
                    issues?.includes(ValidationIssue.RemovedDueToInsufficientQuantityInInventory) ||
                    issues?.includes(ValidationIssue.RemovedDueToUnavailableItem)
                return {
                    code: unavailable ? RESULTCODES.CARTSTOCK_ERROR : RESULTCODES.CARTVALIDATION_ERROR
                }
            }
            const methods = await epiGetPaymentMethods()
            if(!methods) throw new Error('placeOrder: Unable to get payment methods')
            const dummyPayment = cart.payments.find(p => p.systemKeyword === SYSTEMKEYWORD_DUMMY)
            if(!dummyPayment) throw new Error('placeOrder: Expected dummy payment with billing address')
            const paymentMethod = getPaymentMethod(methods, payload.paymentType)
            const props = buildExtendedPropertiesForPayload(payload, dummyPayment)
            let payment = cart.payments.find((p) => p.systemKeyword === paymentMethod.systemKeyword)
            if(payment) {
                payment.amount = validatedCart.totals.total
                payment.billingAddress = dummyPayment.billingAddress
                payment.extendedProperties = props
                await epiUpdatePayment(validatedCart.cart.id, payment)
            }
            else {
                payment = await epiCreateOrUpdatePayment(validatedCart.cart.id, {
                    systemKeyword: paymentMethod.systemKeyword,
                    amount: validatedCart.totals.total,
                    billingAddress: dummyPayment.billingAddress,
                    extendedProperties: props
                })
            }
            
            if(payment) {
                const processPaymentsResult = await epiProcessPayments(cart.id)
                if(processPaymentsResult) {
                    const order = await epiConvertToOrder(cart.id)
                    if(order) return {
                        code: RESULTCODES.SUCCESS,
                        order
                    }
                    else return {
                        code: RESULTCODES.ORDER_ERROR
                    }
                }
                else return {
                    code: RESULTCODES.PAYMENT_ERROR
                }
            }
            else return {
                code: RESULTCODES.PAYMENT_ERROR
            }
        }
        finally {
            currentState.setIsLoading(false)
        }
    },
}))

function toExtendedProp(name: string, value: string) { 
    return { name, value } as ExtendedPropertyModel
 }

 function getPaymentMethod(methods: PaymentMethodModel[], keyword: string) {
    const method = methods.find(m => m.systemKeyword === keyword)
    if(!method) throw new Error(`getPaymentMethod: Unable to find ${keyword} payment method`)
    return method
 }

 // these need to be synced with CPI.Web.Infrastructure.Extensions.OtherPaymentExtensions consts
 const FieldName = "pf_name";
 const FieldCcn = "pf_ccn";
 const FieldExpMonth = "pf_month";
 const FieldExpYear = "pf_year";
 const FieldCvv = "pf_cvv";
 const FieldPurchaseOrderNumber = 'po_number';
 const FieldTaxExempt = "px_taxExemption";

 function buildExtendedPropertiesForPayload(payload: PlaceOrderPayload, dummyPayment: PaymentModel) {
    if(!payload) throw new Error('buildPropsForPayload: Payload is undefined/null')
    const isTaxExempt = dummyPayment?.extendedProperties?.find(kv => kv.name === 'taxExemption')?.value ?? false
    if(payload.paymentType === SYSTEMKEYWORD_PAYFABRIC) {
        if(!payload.cardInfo) throw new Error('buildPropsForPayload: Received PayFabric payload with no card info')
        return [
            toExtendedProp(FieldName, payload.cardInfo.name),
            toExtendedProp(FieldCcn, payload.cardInfo.ccn),
            toExtendedProp(FieldExpMonth, payload.cardInfo.month.toString()),
            toExtendedProp(FieldExpYear, payload.cardInfo.year.toString()),
            toExtendedProp(FieldCvv, payload.cardInfo.cvv),
            toExtendedProp(FieldTaxExempt, isTaxExempt.toString())
        ];
    }
    else if(payload.paymentType === SYSTEMKEYWORD_PAYONACC) {
        if(!payload.purchaseOrderNumber) throw new Error('buildPropsForPayload: Received PayOnAccount payload with no purchase order number')
        return [
            toExtendedProp(FieldPurchaseOrderNumber, payload.purchaseOrderNumber),
            toExtendedProp(FieldTaxExempt, isTaxExempt.toString())
        ]
    }
    else {
        throw new Error(`buildPropsForPayload: Unexpected payment type '${payload.paymentType}'`)
    }
 }