import { useIndexPageStore } from '@/stores/index-store'
import { ChevronUpIcon } from '@heroicons/react/24/solid'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IndexCategoryProps } from '../types/index-types'
import CategoryStateBtn from './category-state-btn'


function StateCategories(props: Readonly<IndexCategoryProps>) {
  const { t } = useTranslation()
  const { list, placeholder } = props

  const { selectedCategories, selectedState } = useIndexPageStore()

  const categoryMenu = useRef<HTMLDivElement>(null)
  const [categoryMenuIsOpen, setCategoryMenuIsOpen] = useState(false)

  const toggleCategoryMenu = () => {
    setCategoryMenuIsOpen(!categoryMenuIsOpen)
  }

  const closeIndustryMenu = (e: MouseEvent) => {
    //These conditions are to check if the click is outside the category menu or any of its children
    if (categoryMenu.current && !categoryMenu.current.contains(e.target as Node) && !(e.target as HTMLElement).closest('svg') && !(e.target as HTMLElement).closest('button') && !(e.target as HTMLElement).closest('span')) {
      setCategoryMenuIsOpen(false)
    }
    else if (categoryMenu.current?.contains(e.target as Node)) {
      setCategoryMenuIsOpen(true)
    }
  }

  useEffect(() => {
    document.addEventListener('click', closeIndustryMenu)
    return () => {
      document.removeEventListener('click', closeIndustryMenu)
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        setCategoryMenuIsOpen(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <div className="relative min-w-[292px]">
      <button
        id="categoryButton"
        className="w-full flex space-x-3 items-center justify-between py-2 px-3 text-grey-dark rounded-lg border border-grey-100 shadow-sm"
        aria-haspopup="true"
        aria-controls="categoryMenu"
        onClick={toggleCategoryMenu}
        aria-label={t('ScreenReader.ToggleCategoryMenu')}
      >
        <span>
          {selectedCategories.length === 0 ? (
            <>{placeholder}</>
          ) : (
            <>{selectedState}</>
          )}
        </span>
        <span
          className={`shared-transition ${categoryMenuIsOpen ? 'rotate-0' : '-rotate-180'
            }`}
        >
          <ChevronUpIcon className="h-5 w-5 text-dark-blue-primary" />
        </span>
      </button>

      <div
        className={`${categoryMenuIsOpen
            ? 'max-h-[4000px] lg:max-h-none'
            : 'max-h-0 overflow-hidden lg:max-h-none'
          }`}
      >
        <div
          className={`w-full bg-white border border-grey-100 shadow-md rounded-lg flex flex-col mt-4 lg:mt-0 lg:absolute top-full right-0 left-0 z-10 shared-transition ${categoryMenuIsOpen
              ? 'visible opacity-1 lg:translate-y-1'
              : 'invisible opacity-0 lg:translate-y-0'
            }`}
          role="menu"
          aria-labelledby="categoryButton"
          id="categoryMenu"
          ref={categoryMenu}
        >
          {list && (
            <div className="py-2">
              {list.map((category, index) => (
                <div
                  key={category.id}
                  role="none"
                  className={`flex items-center w-full pt-2.5 pb-2 px-3 ${selectedCategories[0] === category.id ||
                      (index === 0 && selectedCategories.length < 1)
                      ? 'hidden'
                      : ''
                    }`}
                >
                  {category && (
                    <CategoryStateBtn
                      {...category}
                      toggleMenu={toggleCategoryMenu}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default StateCategories
