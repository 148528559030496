import { PaymentMethodModel } from '@/api/types/content-delivery-types'
import { PartialNullable } from '@/lib/partial-nullable.type'
import { CartData } from '@/stores/useCartStore'
import { EventType } from '../enums/event-type.enum'
import { cartLinesToEventItemParameters } from '../functions/cart-line-to-event-item-parameters.function'
import { getCurrency } from '../functions/get-currency.function'
import { pushEvent } from '../functions/push-event.function'
import { EventItemParameters } from '../types/event-item-parameters.type'

export const addPaymentInfoGTM = async (cartData: CartData | null, paymentMethod: PaymentMethodModel | null) => {
  const items: PartialNullable<EventItemParameters>[] = await cartLinesToEventItemParameters(cartData?.cartLines)

  pushEvent({
    event: EventType.AddPaymentInfo,
    ecommerce: {
      payment_type: paymentMethod?.name,
      currency: getCurrency({ cart: cartData }),
      value: undefined,
      items: items,
    },
  })
}
