import { VariantModel } from '@/components/pages/product-detail/types/product-detail-types'
import { DEFAULT_PRICE, DEFAULT_QUANTITY } from '../constants'
import { EventType } from '../enums/event-type.enum'
import { createItem } from '../functions/create-item.function'
import { includeItemCategories } from '../functions/include-item-categories.function'
import { includeItemLanguage } from '../functions/include-item-language.function'
import { pushEvent } from '../functions/push-event.function'
import { ProductId } from '../types/product-id.type'

export const viewItemGTM = async (productId: ProductId, variant: VariantModel, price: number | undefined) => {
  const item = createItem(productId, variant)

  await includeItemCategories(item, productId)
  await includeItemLanguage(item, productId)

  item.price = price ?? DEFAULT_PRICE
  item.quantity = DEFAULT_QUANTITY
  item.item_variant_id = variant.variantCode
  item.item_list_id = null
  item.item_list_name = null

  const ecommerce = {
    items: [item],
  }

  pushEvent({
    event: EventType.ViewItem,
    ecommerce: ecommerce,
  })
}
