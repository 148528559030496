import { ImageProp } from '@/types/shared'
import './styles/s-image.scss'
import { useMediaStore } from '@/stores/media-store'
import { Link } from 'react-head'

const SharedImage = (props: Readonly<ImageProp>) => {
  const {
    url,
    mobileUrl,
    ranchor,
    svgFullWidth,
    xml,
    className,
    pictureClassName,
    imageType,
    imageAlt,
    mobileBreakpoint,
    disableLazy,
  } = props

  const breakpoint = 640
  const { isMobile } = useMediaStore()

  const handleSrcSet = () => {
    if (isMobile) {
      return `(min-width:${mobileBreakpoint ?? breakpoint + 1}px)`
    } else {
      return `(max-width:${mobileBreakpoint ?? breakpoint}px)`
    }
  }

  const handleImageUrl = () => {
    const backgroundPosition = ranchor ? `&ranchor=${ranchor}` : ''

    if (url) {
      return `${url}?format=webp${backgroundPosition}`
    }
  }

  const handleMobileSrc = () => {
    const backgroundPosition = ranchor ? `&ranchor=${ranchor}` : ''

    if (mobileUrl) {
      return `${mobileUrl}?format=webp${backgroundPosition}`
    } else {
      return `${url}?format=webp${backgroundPosition}`
    }
  }

  if (imageType === 'SVG' && xml) {
    const svgClassNames = [
      'inline-block',
      className,
      svgFullWidth ? 'svg-full-width' : '',
    ]
      .filter((i) => i !== undefined)
      .filter((i) => i !== '')
      .join(' ')

    return (
      <span
        dangerouslySetInnerHTML={{ __html: xml }}
        className={svgClassNames}
      />
    )
  } else {
    return (
      <>
        {disableLazy && (
          <>
            {isMobile && mobileUrl ? (
              <Link rel="preload" as="image" href={handleMobileSrc()} />
            ) : (
              <Link rel="preload" as="image" href={handleImageUrl()} />
            )}
          </>
        )}
        <picture className={pictureClassName}>
          <source
            media={handleSrcSet()}
            srcSet={isMobile ? handleImageUrl() : handleMobileSrc()}
          />

          <img
            src={isMobile ? handleMobileSrc() : handleImageUrl()}
            alt={imageAlt}
            className={className}
            loading={disableLazy ? 'eager' : 'lazy'}
          />
        </picture>
      </>
    )
  }
}
export default SharedImage
