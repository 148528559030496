import CardSlider from '@/components/shared/card-slider/card-slider'
import SectionHeader from '@/components/shared/section-header'
import { useGlobalSettings } from '@/stores/global-settings'
import { useMediaStore } from '@/stores/media-store'
import { useEffect, useState } from 'react'
import { uid } from 'uid'
import { SimpleLinkSliderItem } from './components/simple-link-slider-item'
import { SimpleLinkSlider } from './types/simple-link-slider-block-types'

const SimpleLinkSliderBlock: React.FC<SimpleLinkSlider> = ({
  model,
  className,
  hideContainerClass,
}) => {
  const classNames = [!hideContainerClass ? 'container' : '', className]
    .filter((i) => i !== undefined && i !== '')
    .join(' ')

  const globalSettings = useGlobalSettings((state) => state.globalSettings)
  const [cardsToShow, setCardsToShow] = useState(4)
  const { isTablet } = useMediaStore()

  const handleResize = () => {
    if (isTablet) {
      setCardsToShow(1)
    } else {
      setCardsToShow(4)
    }
  }

  useEffect(() => {
    handleResize()
  }, [isTablet])

  return (
    <section className={classNames}>
      <SectionHeader
        smallIntro={model.smallIntro}
        heading={model.sectionHeading}
        subheading={model.sectionSubheading}
      />

      {model.cards && (
        <CardSlider
          cardsToShow={cardsToShow}
          cardTotal={model.cards.length}
          nextScreenreaderText={globalSettings?.sliderNextAriaText}
          previousScreenreaderText={globalSettings?.sliderPreviousAriaText}
        >
          {model.cards.map((item) => (
            <SimpleLinkSliderItem key={uid()} {...item} />
          ))}
        </CardSlider>
      )}
    </section>
  )
}

export { SimpleLinkSliderBlock }
