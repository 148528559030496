import { NO_SHIPPING } from "@/lib/constants";

const checkForNoShipping = ({name, localizedValue}: {name: string, localizedValue: string}) => {
    if (name === NO_SHIPPING)
    {
        return localizedValue
    }
    return name
}

export {
    checkForNoShipping
}