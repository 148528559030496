import AddressBookForm from '@/components/shared/address/address-form'
import { AddressPageProps } from './types/address-page-types'
import { useEffect, useState } from 'react'
import SharedButton from '@/components/shared/buttons/shared-button'
import ExtendedModal from '@/components/shared/modal/extended-modal'
import { PlusIcon } from '@heroicons/react/24/solid'
import AddressList from './address-list'
import { useAddressStore } from '@/stores/useAddressStore'
import { AddressBookFormInputs } from '@/components/shared/address/types/address-types'
import { AddressModel } from '@/api/types/content-delivery-types'
import { CustomerAddressType } from '@/api/types/content-delivery-enums'
import LoadingSpinner from '@/components/shared/loading-spinner'
import { useTranslation } from 'react-i18next'
import { useUserStore } from '@/stores/useUserStore'
import withHydration from '@/lib/withHydration'

const AddressPage = (props: Readonly<AddressPageProps>) => {
  const {user} = useUserStore()
  const [ addressSaving, setAddressSaving ] = useState<boolean>(false)
  const {
    addresses,
    isLoading,
    showAddressBookModal,
    addressFormEditMode,
    hasWarning, 
    setAddressFieldsHidden,
    setHasWarning,
    getAddressBookData,
    setShowAddressBookModal,
    saveAddress,
    setEditableAddress,
    setAddressFormEditMode,
    createAddress
  } = useAddressStore()
  
  const { t } = useTranslation()
  const { pageTitle, pageDescription, localizations } = props.model
  
  useEffect(() => {
    getAddressBookData()
  }, [])

  const onSaveAddress = async(data: AddressBookFormInputs) => {

      setAddressSaving(true)
      let address: AddressModel = {
        customerId: user?.customerInfo?.customerId,
        extendedProperties: [],
        countryCode: data.countryCode,
        city: data.city,
        countryName: data.country,
        firstName: data.firstName,
        lastName: data.lastName,
        daytimePhoneNumber: data.phoneNumber,
        eveningPhoneNumber: data.phoneNumber,
        email: data.email,
        line1: data.address1,
        line2: data.address2,
        name: data.addressName,
        postalCode: data.postalCode,
        region: data.provinceOrState,
        regionCode: data.provinceOrState,
      }

      if (data.isShipping && data.isBilling)
      {
        const billingAndShipping =  {
          ...address,
          addressType: [1, 2, 4]
        } as AddressModel
        await saveAddress(billingAndShipping, addressFormEditMode)
      } 
      else if (data.isShipping) 
      {
        await saveAddress(createAddress(address, CustomerAddressType.Shipping), addressFormEditMode)
      } 
      else if (data.isBilling)
      {
        await saveAddress(createAddress(address, CustomerAddressType.Billing), addressFormEditMode)
      }

      setAddressSaving(false)
      setShowAddressBookModal(false)
  }

  const openNewAddressModalForm = () => {
    setEditableAddress(null)
    setAddressFormEditMode(false)
    setShowAddressBookModal(true)
  }

  return (
    <>
      <div className='container flex flex-col py-4 gap-3'>
        <div className="flex w-full flex-col gap-[12px] pb-[10px]">
          <h1 className="text-2xl font-bold text-grey-dark lg:text-4xl">
            {pageTitle}
          </h1>
          <span>{pageDescription}</span>
        </div>
        {addresses && addresses.length <= 0 && 
          <div className='flex w-full flex-col'>
            <span className='text-black'>{t('AddressBook.EmptyAddress')}</span>
          </div>
        }
        
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-5 gap-[34px]">
          {!isLoading ? 
              <AddressList />
            : <LoadingSpinner className='h-[14px] w-[14px]' />
          }
          <div className="bg-white rounded-lg shadow p-4 flex-1">
              <div className='flex flex-col h-full justify-center items-center gap-1'>
                <button onClick={() => openNewAddressModalForm()} className={'flex flex-col gap-3 items-center justify-center text-dark-blue-400 border-transparent hover:text-dark-blue-primary active:text-dark-blue-primary disabled:text-grey-400 disabled:cursor-not-allowed'}>
                  <PlusIcon className=' fill-black w-[20px] h-[20px]'/>
                  <span>{t('AddressBook.AddNewAddress')}</span>
                </button>
              </div>
          </div>
        </div>
      </div>

      <ExtendedModal
        modalTitle={addressFormEditMode ? t('AddressBook.EditTitle') : t('AddressBook.AddNewAddress')}
        open={showAddressBookModal} 
        onClose={() => {
          setShowAddressBookModal(false)
          setAddressFieldsHidden(true)
          setHasWarning(false)
        }}
      >
        <AddressBookForm
          localizations={{countries: localizations?.countries ?? [],
          statesAndProvinces: localizations?.statesAndProvinces ?? []}}
          isEditable={addressFormEditMode}
          onSubmitForm={async(data, e) => await onSaveAddress(data)}
          id={`address-book-form`}
        />
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-2 items-center mt-8 mb-8'>
          <div className="flex flex-col gap-3 CommerceForms EPiServerForms">
            <SharedButton
              size="large"
              htmlProps={{
                  type: "button"
              }} style="outline" 
              onClick={() => {
                  setShowAddressBookModal(false)
                  setAddressFieldsHidden(true)
                  setHasWarning(false)
              }}>
                {t("Common.CancelButton")}
              </SharedButton>
 
          </div>
          <div className="flex flex-col gap-3 CommerceForms EPiServerForms">
              <button className={`Button ${addressSaving ? "disabled" : ""}`} disabled={addressSaving} style={{padding:"14px 12px 14px 12px"}} type="submit" form={'address-book-form'} title="Submit" value="Submission">
                {addressSaving ?
                    <div className="flex w-full items-center justify-center">
                      <LoadingSpinner className="h-5 w-5 text-grey-light" />
                    </div>
                    :
                    <>{!hasWarning ? t('Common.SaveAndContinue') : "Accept Changes"}</>
                }
              </button>
          </div>
        </div>
      </ExtendedModal>
    </>
  )
}

export default withHydration(AddressPage)
