import { MaskFunction } from '../types/mask-type'

export const phoneNumberMask: MaskFunction = (
  value: string = '',
): string => {
  const phoneNumberPattern = /(\d{0,3})(\d{0,3})(\d{0,4})/
  const phoneValue = value?.replace(/\D/g, '')?.match(phoneNumberPattern)

  if (phoneValue) {
    if (!phoneValue[2]) {
      value = phoneValue[1]
    } else {
      const segments = [phoneValue[1], phoneValue[2], phoneValue[3]]
      value = segments.filter(Boolean).join('-')
    }
  }

  return value
}
