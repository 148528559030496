import SharedImage from '@/components/shared/image/shared-image'
import { useLocationsStore } from '@/stores/locations-store'
// @ts-ignore
import { DateTime } from 'luxon'
import { BlogDetailHeroProps } from '../types/blog-detail-types'

const BlogDetailHero = (props: BlogDetailHeroProps) => {
  const {
    authorName,
    authorTitle,
    authorImage,
    heading,
    subheading,
    mobileImage,
    desktopImage,
    publishDate,
  } = props

  const { selectedCountry } = useLocationsStore()

  const formatDate = (date: string) => {
    const country = selectedCountry?.location?.cultureCode

    if (country) {
      return DateTime.fromISO(date)
        .setLocale(country)
        .toLocaleString(DateTime.DATE_FULL)
    } else {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)
    }
  }

  return (
    <div className="flex flex-col space-y-8 lg:space-y-14">
      <div className="flex flex-col space-y-4 md:space-y-8">
        {heading && (
          <div
            className="text-grey-dark text-3xl lg:text-5xl font-bold"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
        {(subheading || publishDate) && (
          <div className="flex flex-col items-start">
            {subheading && (
              <div
                className="text-base lg:text-lg text-grey-light"
                dangerouslySetInnerHTML={{ __html: subheading }}
              />
            )}
            {publishDate && (
              <div
                className="flex items-center text-base lg:text-lg text-grey-light mt-6"
              >
                {formatDate(publishDate)}
              </div>
            )}
          </div>
        )}
        {(authorImage || authorName || authorTitle) && (
          <div className="flex items-center space-x-3 border-t border-b border-grey-secondary-dark py-3 lg:py-4">
            {authorImage && (
              <SharedImage
                {...authorImage}
                className="h-12 w-12 rounded-3xl"
                disableLazy={true}
              />
            )}
            <div className="flex flex-col">
              {authorName && (
                <span
                  className="text-grey-dark font-bold"
                  dangerouslySetInnerHTML={{ __html: authorName }}
                ></span>
              )}
              {authorTitle && (
                <span
                  className="text-grey-light"
                  dangerouslySetInnerHTML={{ __html: authorTitle }}
                ></span>
              )}
            </div>
          </div>
        )}
      </div>
      {desktopImage && (
        <SharedImage
          {...desktopImage}
          mobileUrl={mobileImage?.url}
          className="w-full rounded-xl aspect-video"
          disableLazy={true}
        />
      )}
    </div>
  )
}

export default BlogDetailHero
