import { PartialNullable } from '@/lib/partial-nullable.type'
import { CartData } from '@/stores/useCartStore'
import { DEFAULT_PRICE, DEFAULT_QUANTITY } from '../constants'
import { EventType } from '../enums/event-type.enum'
import { cartLinesToEventItemParameters } from '../functions/cart-line-to-event-item-parameters.function'
import { getCurrency } from '../functions/get-currency.function'
import { pushEvent } from '../functions/push-event.function'
import { EventItemParameters } from '../types/event-item-parameters.type'

export const viewCartGTM = async (cartData: CartData | null) => {
  const items: PartialNullable<EventItemParameters>[] = await cartLinesToEventItemParameters(cartData?.cartLines)

  pushEvent({
    event: EventType.ViewCart,
    ecommerce: {
      currency: getCurrency({ cart: cartData }),
      value: cartData?.cartLines?.reduce((a, v) => a + (v.quantity ?? DEFAULT_QUANTITY) * v.placedPrice, DEFAULT_PRICE),
      items: items,
    },
  })
}
