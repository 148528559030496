import { useFindTrainingStore } from '@/stores/find-training-store'

interface QuizBtnTextProps {
  text: string
}

const QuizBtnText = (props: QuizBtnTextProps) => {
  const { text } = props

  const { goToNextStep } = useFindTrainingStore()

  return (
    <button
      className="text-center w-full p-3 flex justify-center text-base md:text-lg font-medium text-grey-dark shared-transition shadow-sm hover:shadow-md border border-grey-secondary-dark bg-white hover:bg-grey-secondary-light rounded"
      onClick={goToNextStep}
    >
      {text}
    </button>
  )
}

export default QuizBtnText
