import React, { useEffect, useRef } from 'react';

interface PopoverProps {
 children: React.ReactNode;
 isOpen: boolean;
 onClose: () => void;
}

const Popover: React.FC<PopoverProps> = ({ children, isOpen, onClose }) => {
 const popoverRef = useRef<HTMLDivElement>(null);

 useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
 }, [onClose]);

 return (
    <div
      ref={popoverRef}
      className={`absolute mt-2 w-200 z-50 rounded-lg shadow-lg bg-white overflow-hidden ${isOpen ? '' : 'hidden'}`}
      style={{ transform: 'translate3d(0, 0, 0)' }}
    >
      <div>{children}</div>
    </div>
 );
};

export default Popover;