import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import SharedRTF from '@/components/shared/rtf/shared-rtf'
import BlockComponentSelector from '@/epi/block-component-selector'
import { ArrowDownIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import VideoDetailHero from './components/video-detail-hero'
import { VideoDetailProps } from './types/video-detail-types'

const VideoDetailPage = (props: Readonly<VideoDetailProps>) => {
  const { model } = props
  const [isOpen, setIsOpen] = useState(false)

  const handleTranscriptToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Breadcrumbs />
      <div className="container flex justify-center">
        <div className="w-full lg:w-2/3 flex flex-col space-y-8 lg:space-y-14">
          {(model.heroHeading || model.heroVideo) && (
            <VideoDetailHero
              heading={model.heroHeading}
              video={model.heroVideo}
              videoPlaceholder={model.heroVideoPlaceholderImage}
              publishDate={model.publishDate}
              industry={model.industry}
            />
          )}
          {model.mainBody && (
            <div className="flex flex-col space-y-2.5 p-5 md:p-6 border border-grey-secondary-dark rounded-lg">
              <SharedRTF
                html={model.mainBody}
                className={!isOpen ? 'line-clamp-15' : ''}
              />
              {model.collapseButtonText && model.expandButtonText && (
                <button
                  className="flex space-x-3 py-2.5 text-dark-blue-primary font-semibold"
                  onClick={handleTranscriptToggle}
                >
                  {isOpen ? (
                    <span>{model.collapseButtonText}</span>
                  ) : (
                    <span>{model.expandButtonText}</span>
                  )}
                  <span
                    className={`shared-transition ${
                      isOpen ? 'rotate-180' : 'rotate-0'
                    }`}
                  >
                    <ArrowDownIcon className="h-5 w-5 text-dark-blue-primary" />
                  </span>
                </button>
              )}
            </div>
          )}
          {model.pageContent?.map((blockModel, i) => (
            <BlockComponentSelector
              key={`pageContent-${i}`}
              {...blockModel}
              hideContainerClass={true}
            />
          ))}
        </div>
      </div>
    </>
  )
}
export default VideoDetailPage
