import AddressCard from './address-card';
import { useAddressStore } from '@/stores/useAddressStore';


const AddressList = () => {
    const {
        addresses,
        customer,
    } = useAddressStore()

    const sortedAddresses = [...addresses!].sort((a, b) => {
        const aIsDefault = customer?.preferredBillingAddressName === a.name || customer?.preferredShippingAddressName === a.name;
        const bIsDefault = customer?.preferredBillingAddressName === b.name || customer?.preferredShippingAddressName === b.name;

        if (aIsDefault && !bIsDefault) {
            return -1;
        } else if (!aIsDefault && bIsDefault) {
            return 1;
        }

        return 0;
    })
    const filteredAddresses = sortedAddresses.filter(x => x.extendedProperties?.find(x=> x?.name === "IsPrimaryD365Address")?.value?.toLowerCase() !== "true")
    
    return (
        <>
            {filteredAddresses.map((address, i) => {
                const isDefault = customer?.preferredBillingAddressName === address.name || customer?.preferredShippingAddressName == address.name;
                return (
                    <AddressCard
                        index={i}
                        key={address.name}
                        isDefault={isDefault}
                        address={address}
                        customerInfo={customer}
                    />
                );
            })}
        </>
    )
}

export default AddressList
