import { PartialNullable } from '@/lib/partial-nullable.type'
import { EventItemParameters } from '../types/event-item-parameters.type'
import { ProductId } from '../types/product-id.type'
import { getAncestors } from './get-ancestors.function'
import { setCategories } from './set-categories.function'

export const includeItemCategories = async (item: PartialNullable<EventItemParameters>, productId: ProductId) => {
  const categories = await getAncestors(productId)

  categories?.pop()

  if (!categories?.length) return

  setCategories(item, categories)
}
