import { create, StateCreator } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type ShippingSameAsBillingStoreProps = {
    disabled: boolean
    shippingSameAsBilling: boolean;
    setShippingSameAsBilling: (shippingSameAsBilling: boolean) => void;
    setDisabled: (disabled: boolean) => void;
};

const shippingSameAsBillingStore: StateCreator<ShippingSameAsBillingStoreProps, [["zustand/persist", unknown]]> = (set) => ({
    disabled: false,
    shippingSameAsBilling: false,
    setShippingSameAsBilling: (shippingSameAsBilling: boolean) => set(() => ({ shippingSameAsBilling })),
    setDisabled: (disabled: boolean) => set(() => ({ disabled })),
});



export const useShippingSameAsBilling = create(
    persist(shippingSameAsBillingStore, {
        name: 'useShippingSameAsBillingStore',
        storage: createJSONStorage(() => localStorage),
    })
);