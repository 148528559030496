import { Parameter, AddressCompletionViewModel, AddressVerificationViewModel} from '@/api/melissa-address/types/melissa-address-types';
import axios from 'axios';

/**
 * Melissa Autocomplete API Documentation
 * https://devconsole.melissadata.com/globalexpressentry/
 * Gets address completion based on address string data.
 * @param {string} payload Partial address string to be completed
 * @returns Promise<AddressCompletionResponse | null>
*/
const getAddressAutoComplete = async (parameters: Parameter[]): Promise<AddressCompletionViewModel | null> =>  {
  try {
    const apiUrl = `${window.location.origin}/internalapi/AddressApi/GetAutoCompleteAddresses`

    if (!parameters) return null;

    const addressesResponse = await (await axios.post(
        apiUrl, 
        {parameters: [...parameters]},
        {
           headers: {
               "Content-Type": "application/json"
           }
        }
    )).data;

    if (!addressesResponse) return null;
    
    return addressesResponse;
  } catch(e) {
    console.error(`API Error in function ${getAddressAutoComplete.name}`, e);
  }
  return null;
}

/**
 * Melissa Verification API Documentation
 * https://www.melissa.com/developer/global-address
 * Gets address verification results
 * @param {Parameter[]} parameters - List of fetch params
 * @returns Promise<AddressVerificationResponse | null>
*/
const getAddressVerification = async (parameters: Parameter[]): Promise<AddressVerificationViewModel | null> =>  {
  try {
    const apiUrl = `${window.location.origin}/internalapi/AddressApi/GetAddressVerification`;

    if (!parameters) return null;

    const addressVerificationResult = await (await axios.post(
        apiUrl, 
        {parameters: [...parameters]},
        {
          headers: {
              "Content-Type": "application/json"
          }
        }
    )).data;

    if (!addressVerificationResult) return null;
    
    return addressVerificationResult;
  } catch(e) {
    console.error(`API Error in function ${getAddressVerification.name}`, e);
  }
  return null;
}

export {
  getAddressVerification,
  getAddressAutoComplete
}
