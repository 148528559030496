import queryStringHandler from '@/lib/lazy-query-string-handler'
import { useSearchStore } from '@/stores/search-store'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { SearchPageInputProps } from '../types/search-types'

const SearchPageInput = (props: SearchPageInputProps) => {
  const { placeholder, clearSearchAria } = props

  const { searchTerm, setSearchTerm, fetchResults, resetPage } =
    useSearchStore()

  const handleSearchTerm = (value: any) => {
    setSearchTerm(value)
  }

  const handleResetSearch = () => {
    setSearchTerm('')
  }

  const handleKeyUpEvent = async (e: any) => {
    if (e.code === 'Enter') {
      const handler = await queryStringHandler.getHandler()
      handler.replace({ q: searchTerm })
      resetPage()
      fetchResults()
    }
  }

  return (
    <div className="relative w-full lg:w-2/3 flex">
      <label htmlFor="searchPageInput" className="sr-only">
        {placeholder}
      </label>
      <input
        type="text"
        id="searchPageInput"
        className="appearance-none w-full py-2 md:py-3 px-11 text-grey-dark text-base font-medium border border-grey-secondary-light shadow-sm rounded-lg focus:shadow-focus"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => handleSearchTerm(e.target.value)}
        onKeyUp={async (e) => await handleKeyUpEvent(e)}
      />
      <div className="absolute top-1/2 -translate-y-1/2 left-3">
        <MagnifyingGlassIcon className="h-5 w-5 text-grey-primary" />
      </div>
      <button
        className="absolute top-1/2 -translate-y-1/2 right-3 flex rounded"
        onClick={handleResetSearch}
      >
        <span className="sr-only">{clearSearchAria}</span>
        <span>
          <XMarkIcon className="h-5 w-5 text-dark-blue-primary" />
        </span>
      </button>
    </div>
  )
}

export default SearchPageInput
