import { useEffect, useState } from 'react'
import { TrackingScriptProps } from './types/tracking-script-block-types'
import { useLocation } from 'react-router-dom'

declare global {
    interface Window {
        $$epiforms: any
        MsCrmMkt: any
        eventAdded?: boolean
        eventAddingTimeout?: NodeJS.Timeout
        lastLocation?: string
    }
}

const TrackingScriptBlock = (props: Readonly<TrackingScriptProps>) => {
    const { model } = props
    const { d365Hostname, d365WebsiteId } = model
    
    const location = useLocation()

    useEffect(() => {
        if(location.pathname === window.lastLocation) return
        window.lastLocation = location.pathname
        window.eventAdded = false
        if(window.eventAddingTimeout) clearTimeout(window.eventAddingTimeout)
        addFormEvent()
    }, [location])

    function addFormEvent(attempt = 0) {
        if(attempt === 0) { 
            window.eventAddingTimeout = setTimeout(() => addFormEvent(1), 1000) 
            return
        }
        if(window.eventAdded) return
        if(attempt > 10) {
            return
        }
        if(!window.$$epiforms || !window.$$epiforms('.EPiServerForms').length) {
            window.eventAddingTimeout = setTimeout(() => addFormEvent(attempt + 1), 1000)
            return
        }

        if (window.MsCrmMkt) {
            const logger = new window.MsCrmMkt.Logger
            new window.MsCrmMkt.ConfigProvider(logger).getConfig().then(function (config: any) {
                if (config) {
                    window.MsCrmMkt.initTracking(config)
                }
            })
        }

        window.eventAdded = true
        window.$$epiforms('.EPiServerForms').on('formsSubmitted', onFormsSubmitted)
    }

    const onFormsSubmitted = (event: any) => {
        if (!window.MsCrmMkt) throw new Error('CE Tracking block error: MsCrmMkt (form-loader) not available')
        if (event.isFinalizedSubmission) {
            repopulateForm(event.currentTarget, event.formData)
            window.MsCrmMkt.MsCrmFormLoader.sendFormCaptureToCrm(event.currentTarget)
        }
    }

    return d365WebsiteId && d365Hostname ? (
        <div
            className="d365-mkt-config hidden"
            data-website-id={d365WebsiteId}
            data-hostname={d365Hostname}
            data-no-submit="true"
            data-ignore-prevent-default="true"
        ></div>
    ) : (
        <></>
    )
}
export default TrackingScriptBlock

const repopulateForm = (form: any, data: any) => {
    const entries = new URLSearchParams(data).entries()
    for (const [key, val] of entries) {
        const input = form.elements[key]
        if (!input) continue
        if (input.type == 'checkbox') {
            input.checked = !!val
        }
        else {
            input.value = val
        }
    }
}