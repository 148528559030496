import { useFindTrainingStore } from '@/stores/find-training-store'
import { uid } from 'uid'
import { QuestionOneItemProps } from '../types/find-training-types'
import AnswersList from './answers-list'
import QuestionElement from './question-element'
import QuizBtnLink from './quiz-btn-link'
import QuizBtnText from './quiz-btn-text'

const QuestionOne = (props: QuestionOneItemProps) => {
  const { question, answers } = props
  const { activeStep } = useFindTrainingStore()

  return (
    <div
      className={`flex flex-col space-y-8 md:space-y-12 items-center shared-transition ${
        activeStep === 1 ? 'opacity-1' : 'invisible sr-only opacity-0'
      }`}
    >
      {question && <QuestionElement question={question} />}
      {answers && (
        <AnswersList>
          {answers.map((answer) => (
            <div key={uid()} className="w-full">
              {answer.answerLink ? (
                <QuizBtnLink link={answer.answerLink} />
              ) : (
                <>
                  {answer.answerText && (
                    <QuizBtnText text={answer.answerText} />
                  )}
                </>
              )}
            </div>
          ))}
        </AnswersList>
      )}
    </div>
  )
}

export default QuestionOne
