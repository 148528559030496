import { epiGetVariantByContentGuid } from '@/api/content-delivery'
import { CartLineItemModel, VariantContentModel } from '@/api/types/content-delivery-types'
import Price from '@/components/shared/price/price'
import { Currency, currencyToLocale } from '@/components/shared/price/types/price-types'
import { DEFAULT_CURRENCY_CODE } from '@/lib/constants'
import { useEffect, useRef, useState } from 'react'
import LazyLoad from 'react-lazyload'
import { useDebounceValue, useIntersectionObserver } from 'usehooks-ts'

export interface CartLineItemReadOnlyProps {
  cartLineModel: CartLineItemModel
  currency?: Currency
  calculateCartLine: (cartLineId: number, quantity: number) => number | null
}

export const CartLineItemReadOnly = (props: CartLineItemReadOnlyProps) => {
  const { cartLineModel, currency, calculateCartLine } = props

  const { displayName, placedPrice, contentId, quantity, code, id } = cartLineModel

  if (id == undefined) {
    throw new Error('CartLineItem Component: cart line id shouldnt be undefined')
  }
  const [inputQuantity, setInputQuantity] = useState<number>(quantity)
  const [debouncedInputValue]  = useDebounceValue(inputQuantity, 300);

  const [_, setLineTotal] = useState<number>(0)
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, { threshold: 0.1 })
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)
  const isVisible = !!entry?.isIntersecting
  const [contentData, setContentData] = useState<VariantContentModel | null>(null)

  useEffect(() => {
    setInputQuantity(quantity)
  }, [quantity])

  useEffect(() => {
    const getVariantData = async () => {
      const content = await epiGetVariantByContentGuid(contentId)
      if (content) {
        setContentData(content)
      }
    }
    if (isVisible && !imageLoaded) getVariantData()
  }, [isVisible])

  useEffect(() => {
    const setTotal = async () => {
      const lineTotal = await calculateCartLine(id, debouncedInputValue)
      if (lineTotal) {
        setLineTotal(lineTotal)
      }
    }
    setTotal()
  }, [debouncedInputValue, placedPrice])

  return (
    <div
      ref={ref}
      style={{
        width: '100%',
        minHeight: 'min-content',
        fontSize: '2rem',
      }}
    >
      <div className="flex flex-col items-start gap-[12px] lg:flex-row text-black">
        <LazyLoad once height={72} style={{ filter: imageLoaded ? 'none' : 'blur(10px)', minHeight: "72px", minWidth: "72px" }}>
            <img
              alt={displayName}
              src={contentData?.assets[0]}
            className="h-[72px] w-[72px] rounded-lg object-cover"
              onLoad={() => setImageLoaded(true)}
            />
        </LazyLoad>
        <div className="flex flex-col gap-1">
          <div className="flex">
            <span className="text-base">{displayName}</span>
          </div>
          <div className="flex">
            <span className="text-base">QTY {inputQuantity}</span>
          </div>
          <div className="flex text-1xl text-right font-bold text-grey-dark lg:text-2xl">
              <Price
                currency={currency}
                price={placedPrice.toString()}
                culture={currencyToLocale[currency ?? DEFAULT_CURRENCY_CODE]}
                className="text-base whitespace-nowrap"
              />
          </div>
        </div>
      </div>
    </div>
  )
}
