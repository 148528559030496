import SharedLink from '@/components/shared/buttons/shared-link'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { NoImageHeroBlockProps } from './types/no-image-hero-block-types'

const NoImageHeroBlock: React.FC<NoImageHeroBlockProps> = ({
  className,
  hideContainerClass,
  model,
}) => {
  const classNames = [
    !hideContainerClass ? 'container' : '',
    className,
    'space-y-4 md:space-y-8 text-center',
  ]
    .filter((i) => i !== undefined && i !== '')
    .join(' ')

  return (
    <div className={classNames}>
      {model.heading && (
        <div
          className="text-3xl lg:text-6xl font-bold text-grey-dark"
          dangerouslySetInnerHTML={{ __html: model.heading }}
        ></div>
      )}
      {model.subheading && (
        <div>
          <div
            className="lg:text-xl text-grey-light"
            dangerouslySetInnerHTML={{ __html: model.subheading }}
          />
        </div>
      )}

      <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2 md:justify-center">
        {model.primaryCtaButton && (
          <SharedLink
            {...model.primaryCtaButton}
            style={model.primaryCtaButton.buttonStyle}
            className="w-full md:w-auto"
          >
            <span>{model.primaryCtaButton.text}</span>
            <span>
              <ArrowRightIcon className="text-blue-primary ml-2 h-5 w-5" />
            </span>
          </SharedLink>
        )}

        {model.secondaryCtaButton && (
          <SharedLink
            style="outline"
            {...model.secondaryCtaButton}
            className="w-full md:w-auto"
          >
            <span>{model.secondaryCtaButton.text}</span>
            <span>
              <ArrowRightIcon className="text-blue-primary ml-2 h-5 w-5" />
            </span>
          </SharedLink>
        )}
      </div>
    </div>
  )
}

export default NoImageHeroBlock
