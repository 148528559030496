import FormContainerBlock from '../form-container/form-container'
import { ScrimCTAFormProps } from './types/scrim-cta-form-types'

const ScrimCTAForm = (props: Readonly<ScrimCTAFormProps>) => {
  const { headline, subheadline, form, hideContainerClass } = props

  return (
    <section className={!hideContainerClass ? 'container' : ''}>
      <div className="w-full max-w-[655px] mx-auto">
        <div className="flex flex-col space-y-6 md:space-y-8">
          <div className="flex flex-col space-y-8">
            {headline && (
              <h2 className="text-grey-dark text-xl md:text-2xl font-bold text-center">
                {headline}
              </h2>
            )}
            {subheadline && (
              <div className="text-grey-dark text-base md:text-lg text-center">
                {subheadline}
              </div>
            )}
          </div>
          {form && (
            <div className="scrim-form w-full md:max-w-[465px] mx-auto">
              <FormContainerBlock model={form.model} isChildBlock={true} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
export default ScrimCTAForm
