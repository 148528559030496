import { create } from 'zustand'

interface ScrollToStoreProps {
  initRichTextScrollTo: () => void
  handleScrollTo: (href: string) => void
}

export const useScrollToStore = create<ScrollToStoreProps>((set, get) => ({
  handleScrollTo: (href: string) => {
    const target = document.querySelector(href)
    const stickyHeader = document.querySelector('#sticky-header')
    const offsetPadding = 16
    let targetOffset = window.scrollY - offsetPadding
    let stickyOffset = 0

    if (stickyHeader) {
      stickyOffset = stickyHeader.offsetHeight
    }

    if (target) {
      targetOffset += target.getBoundingClientRect().top
      window.scrollTo({
        top: targetOffset - stickyOffset,
        behavior: 'smooth',
      })
    }
  },
  initRichTextScrollTo: () => {
    const links = document.querySelectorAll('a[href^="#"]')

    if (links) {
      links.forEach((link) => {
        link.addEventListener('click', (e) => {
          const href = link.getAttribute('href')

          if (href) {
            e.preventDefault()
            get().handleScrollTo(href)
          }
        })
      })
    }
  },
}))
