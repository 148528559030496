import SharedLink from '@/components/shared/buttons/shared-link'
import CountrySelector from '@/components/shared/header/components/country-selector'
import { usePrimaryMenuMobileStore } from '@/stores/primary-menu-mobile'
import { ArrowUpRightIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { HeaderProps } from '../types/header'
import MobileNavItem from './mobile-nav-item'
import { useHydrated } from 'react-hydration-provider'
import { BookOpenIcon, UserCircleIcon } from '@heroicons/react/24/solid'

const MobileNav = (props: Readonly<HeaderProps>) => {
  const hydrated = useHydrated()
  const { t } = useTranslation()
  const { model } = props
  const { mobileMenuIsOpen } = usePrimaryMenuMobileStore()
  return (
    <div
      style={{
        display: mobileMenuIsOpen ? 'block' : 'none',
      }}
      id="mobileMenu"
      role="menu"
      aria-labelledby="mobileNavButton"
      className="relative h-[calc(100vh-73px)] pt-8 pb-16"
    >
      <div className="container flex flex-col justify-between h-full">
        <div>
          {model.mainNavigationBar && (
            <nav aria-label={hydrated ? t('ScreenReader.MainNavigation') : undefined}>
              <ul className="flex flex-col space-y-2 pb-4 border-b border-grey-200">
                {model.mainNavigationBar.primaryMenu?.map((item, i) => (
                  <MobileNavItem
                    key={i}
                    {...item}
                    backToMenuText={model.mainNavigationBar?.backToMenuText}
                    parentIndex={i}
                  />
                ))}
              </ul>
            </nav>
          )}

          <div className="mt-8 flex flex-col space-y-3">
            <CountrySelector />
            {model.utilityNavigationBar?.cpiLearningLink && (
              <SharedLink
                {...model.utilityNavigationBar.cpiLearningLink}
                className="text-sm flex items-center space-x-2 py-1 pl-0 2xl:pl-3 pr-3 text-dark-blue-primary"
                target={model.utilityNavigationBar.cpiLearningLink.target}
              >
                <BookOpenIcon className="w-5 h-5 text-dark-blue-primary" />
                <span className='uppercase'>{model.utilityNavigationBar.cpiLearningLink.text}</span>
                <span>
                </span>
              </SharedLink>
            )}
            {model.utilityNavigationBar?.myAccountLink && (
              <SharedLink
                {...model.utilityNavigationBar.myAccountLink}
                className="text-sm flex items-center space-x-2 py-1 pl-0 2xl:pl-3 pr-3 text-dark-blue-primary"
                target={model.utilityNavigationBar.myAccountLink.target}
              >
                <UserCircleIcon className="w-5 h-5 text-dark-blue-primary" />
                <span className='uppercase'>{model.utilityNavigationBar.myAccountLink.text}</span>
                <span>
                </span>
              </SharedLink>
            )}
          </div>
        </div>

        <div className="flex flex-col space-y-1.5 mb-12">
          {model.mainNavigationBar?.contactUsButton && (
            <SharedLink
              {...model.mainNavigationBar.contactUsButton}
              style="primary"
              size="small"
              className="!py-1.5 space-x-3"
              target={model.mainNavigationBar.contactUsButton.target}
            >
              <span>{model.mainNavigationBar.contactUsButton.text}</span>
              <span>
                <ArrowUpRightIcon className="flex h-4 w-4" />
              </span>
            </SharedLink>
          )}

          {model.utilityNavigationBar?.phoneLink && (
            <SharedLink
              {...model.utilityNavigationBar.phoneLink}
              style="outline"
              size="small"
              className="!py-1.5 space-x-3"
              target={model.utilityNavigationBar.phoneLink.target}
            >
              <span>{model.utilityNavigationBar.phoneLink.text}</span>
              <span>
                <PhoneIcon className="fill-dark-blue-primary w-4 h-4" />
              </span>
            </SharedLink>
          )}

          {model.utilityNavigationBar?.whatsLink && (
            <SharedLink
              {...model.utilityNavigationBar.whatsLink}
              style="outline"
              size="small"
              className="!py-1.5 space-x-3"
              target={model.utilityNavigationBar.whatsLink.target}
            >
              <span>{model.utilityNavigationBar.whatsLink.text}</span>
              <span>
                <img src="https://www.crisisprevention.com/globalassets/global-assets/icons/whatsapp-logo-primary.svg" alt="whats-app-logo-uae" className='w-5 h-5' />
              </span>
            </SharedLink>
          )}
        </div>
      </div>
    </div>
  )
}

export default MobileNav
