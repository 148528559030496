import { useLocationsStore } from '@/stores/locations-store'
import {
  CheckCircleIcon,
  ChevronDownIcon,
  GlobeAmericasIcon,
} from '@heroicons/react/24/solid'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Flag from 'react-world-flags'
import SharedLink from '../../buttons/shared-link'
import { useHydrated } from 'react-hydration-provider'

interface CountrySelectorProps {
  isLocationInterstitial?: boolean
  menuBtnText?: string
}

function CountrySelector(props: Readonly<CountrySelectorProps>) {
  const hydrated = useHydrated()
  const { t } = useTranslation()
  const { menuBtnText, isLocationInterstitial } = props
  const { locations, handleUpdateSelectedCountry, selectedCountry } =
    useLocationsStore()
  const countryLinks = useRef(null)
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false)

  const toggleUserMenu = () => {
    setUserMenuIsOpen(!userMenuIsOpen)
  }

  const closeUserMenu = () => {
    if (userMenuIsOpen) {
      toggleUserMenu()
    }
  }

  const focusElement = () => {
    if (countryLinks.current) {
      const focusEl = (countryLinks.current! as HTMLElement)
        .childNodes[0] as HTMLElement
      focusEl.focus()
    }
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        closeUserMenu()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <>
      {locations && (
        <div
          className={`relative ${isLocationInterstitial ? 'w-full md:w-auto' : ''
            }`}
        >
          <button
            id="countryButton"
            className={`flex items-center text-dark-blue-primary ${isLocationInterstitial
                ? 'py-1.5 space-x-3'
                : 'text-sm space-x-2 py-1 pl-0 2xl:pl-3 pr-3'
              }`}
            aria-haspopup="true"
            aria-controls="countryMenu"
            onClick={toggleUserMenu}
            onKeyUp={focusElement}
            aria-label={hydrated ? t('ScreenReader.ToggleUserMenu') : undefined}
          >
            {isLocationInterstitial ? (
              <>
                <span>{menuBtnText}</span>
                <span
                  className={`ease-in-out duration-300 ${userMenuIsOpen ? '-rotate-180' : 'rotate-0'
                    }`}
                >
                  <ChevronDownIcon className="h-4 w-4 text-dark-blue-primary" />
                </span>
              </>
            ) : (
              <>
                <span>
                  <GlobeAmericasIcon className="h-5 w-5 text-dark-blue-primary" />
                </span>
                <span className="uppercase">
                  {selectedCountry?.location?.siteName}
                </span>
                <span
                  className={`ease-in-out duration-300 ${userMenuIsOpen ? '-rotate-180' : 'rotate-0'
                    }`}
                >
                  <ChevronDownIcon className="h-5 w-5 text-dark-blue-primary" />
                </span>
              </>
            )}
          </button>

          <div
            className={`${userMenuIsOpen
                ? 'max-h-[4000px] lg:max-h-none'
                : 'max-h-0 overflow-hidden lg:max-h-none'
              }`}
          >
            <div
              className={`min-w-[200px] bg-white border border-grey-secondary-light shadow-lg rounded-lg py-4 px-5 flex flex-col space-y-3 mt-4 lg:mt-0 lg:absolute right-0 z-50 shared-transition ${userMenuIsOpen
                  ? 'visible opacity-1'
                  : 'invisible opacity-0 lg:translate-y-0'
                } ${isLocationInterstitial
                  ? 'bottom-full -translate-y-1'
                  : 'top-full lg:translate-y-3'
                }`}
              role="menu"
              aria-labelledby="countryButton"
              id="countryMenu"
            >
              {selectedCountry && (
                <div role="none" className="w-full">
                  <div
                    role="menuitem"
                    className="flex items-center space-x-3 whitespace-nowrap"
                  >
                    <span>
                      <CheckCircleIcon className="text-[#0F172A] h-5 w-5" />
                    </span>
                    <span className="text-grey-700">
                      {selectedCountry.location?.countryName}
                    </span>
                  </div>
                </div>
              )}

              {locations.map((item, index) => (
                <div
                  key={index}
                  role="none"
                  className={`w-full ${item.location?.cultureCode ===
                      selectedCountry?.location?.cultureCode
                      ? 'absolute invisible h-0 overflow-hidden'
                      : ''
                    }`}
                  ref={countryLinks}
                >
                  <SharedLink
                    href={item.url === 'en' ? '/' : item.url}
                    aria-label={item.location?.countryName}
                    className="flex items-center space-x-3 whitespace-nowrap"
                    onClick={() => handleUpdateSelectedCountry(item)}
                  >
                    <span className="w-5">
                      {item.location?.countryCode && (
                        <Flag
                          code={item.location.countryCode}
                          alt={item.location.countryName}
                          className="w-5 rounded-sm border border-black/10"
                        />
                      )}
                    </span>
                    <span className="text-grey-700">
                      {item.location?.countryName}
                    </span>
                  </SharedLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CountrySelector
