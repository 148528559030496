import { MediaContentProps } from '@/components/blocks/featured-media-block/types/featured-media-block-types'
import VideoPlayIcon from '@/components/icons/video-play-icon'
import SharedImage from '@/components/shared/image/shared-image'
import { useGlobalSettings } from '@/stores/global-settings'
import { useTranslation } from 'react-i18next'

const MediaContent = (props: Readonly<MediaContentProps>) => {
  const globalSettings = useGlobalSettings((state) => state.globalSettings)
  const { t } = useTranslation()

  if (props.type === 'Image' && props.image) {
    return (
      <SharedImage
        pictureClassName={props.setFullWidthImage ? 'w-full' : ''}
        className={`aspect-[16/9] object-cover ${props.removeMobileImageRadius ? 'md:rounded-lg' : 'rounded-lg'
          } ${props.setFullWidthImage ? 'w-full' : ''}`}
        disableLazy={props.preloadImage ? true : false}
        {...props.image}
      />
    )
  }

  if (props.type === 'Video' && props.video) {
    const videoThumbnailUrl = props.image
      ? props.image.url
      : `https://img.youtube.com/vi/${props.video}/hqdefault.jpg`

    return (
      <button
        className="w-full relative block cursor-pointer"
        onClick={() => props.setShowModal(true)}
      >
        <span className="sr-only">{globalSettings?.openModalAria}</span>        
        <SharedImage
          className={`w-full aspect-[16/9] object-cover ${props.removeMobileImageRadius ? 'md:rounded-lg' : 'rounded-lg'
            } ${props.setFullWidthImage ? 'w-full' : ''}`}
          url={videoThumbnailUrl}
          imageAlt={t('Blocks.FeaturedMediaBlock.AltImgText')}
          imageType="Image"
          disableLazy={props.preloadImage ? true : false}
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-14 w-14 flex items-center justify-center shadow-lg rounded-xl">
          <VideoPlayIcon className="h-14 w-14" />
        </div>
      </button>
    )
  }

  return null
}

export default MediaContent
