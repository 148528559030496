import FormContainer from '@/components/blocks/form-container/form-container'
import SharedButton from '@/components/shared/buttons/shared-button'
import Modal from '@/components/shared/modal/shared-modal'
import React, { useState } from 'react'
import { CtaWithInputProps } from './types/cta-with-input-types'

const CtaWithInput: React.FC<CtaWithInputProps> = (props) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <section className={`relative ${props.model ? '' : 'hidden'}`}>
      <div className="bg-light-blue-100 md:rounded-lg py-4 md:py-14 md:mx-6">
        <div className="container flex items-center">
          <div className="w-full flex flex-col space-y-4 md:grid md:grid-cols-12 md:gap-8">
            {(props.model.headline || props.model.description) && (
              <div className="col-span-9 flex flex-col space-y-4 md:space-y-8">
                {props.model.headline && (
                  <h2
                    className={`text-grey-dark
                  font-bold md:font-extrabold
                  text-2xl md:text-4xl
                `}
                  >
                    {props.model.headline}
                  </h2>
                )}
                {props.model.description && (
                  <div className="text-grey-dark text-base md:text-2xl">
                    {props.model.description}
                  </div>
                )}
              </div>
            )}

            {props.model.buttonLabel && (
              <div className="col-span-3 flex items-center justify-end">
                <SharedButton
                  style="primary"
                  size="regular"
                  className="w-full md:w-auto"
                  onClick={() => setShowModal(true)}
                >
                  {props.model.buttonLabel}
                </SharedButton>
              </div>
            )}
          </div>

          {props.model.form && (
            <Modal
              showModal={showModal}
              onClose={() => setShowModal(false)}
              closeButtonLabel="Close"
            >
              <FormContainer model={props.model.form.model} isModal={true} />
            </Modal>
          )}
        </div>
      </div>
    </section>
  )
}

export default CtaWithInput
