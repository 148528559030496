import SharedLink from '@/components/shared/buttons/shared-link'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
import { PromoBannerProps } from './types/promo-banner-types'

const PromoBannerBlock = (props: Readonly<PromoBannerProps>) => {
  const { bannerLinkText, bannerTitle, bannerLink } = props.model
  const navigate = useNavigate()

  return (
    <div className="container text-grey-dark">
      <div className="flex flex-col lg:flex-row">
        {bannerTitle && (
          <div className="flex-1 text-center md:text-left rounded-t-lg lg:rounded-tr-none lg:rounded-l-lg bg-grey-secondary-light p-4 lg:text-lg font-bold">
            {bannerTitle}
          </div>
        )}

        {bannerLinkText && bannerLink && (
          <SharedLink
            {...bannerLink}
            style="primary"
            className="rounded-t-none rounded-b-lg lg:rounded-tr-lg lg:rounded-l-none lg:text-lg font-bold"
          >
            {bannerLinkText ? <>{bannerLinkText}</> : <>{bannerLink.text}</>}
            <ArrowRightIcon className="h-4 w-4 ml-2" />
          </SharedLink>
        )}
      </div>
    </div>
  )
}

export default PromoBannerBlock
