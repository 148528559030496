import SharedLink from '@/components/shared/buttons/shared-link'
import SharedImage from '@/components/shared/image/shared-image'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { IconItemProps } from '../types/icon-types'

const IconItem: React.FC<IconItemProps> = ({ model }) => {
  return (
    <div className="flex flex-col justify-between">
      <div className="space-y-3 md:space-y-4">
        <div className="flex items-center justify-center h-10 md:h-11 w-10 md:w-11 rounded-lg bg-light-blue-primary text-white">
          <SharedImage {...model.icon} className="max-w-[50%]" />
        </div>
        {model.heading && (
          <h2 className="text-lg md:text-xl text-grey-dark font-bold">
            {model.heading}
          </h2>
        )}
        {model.description && (
          <div className="text-sm md:text-base">{model.description}</div>
        )}
      </div>
      {model.link && (
        <SharedLink
          {...model.link}
          className="inline-block font-semibold text-dark-blue-primary mt-5 md:mt-7"
        >
          <span>
            {model.link.text}{' '}
            <ArrowRightIcon className="inline-block ml-1 h-5 w-5" />
          </span>
        </SharedLink>
      )}
    </div>
  )
}

export default IconItem
