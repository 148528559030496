interface AnswersListProps {
  children?: React.ReactNode
}

const AnswersList = (props: AnswersListProps) => {
  const { children } = props
  return (
    <div className="w-full lg:w-1/3 flex flex-col space-y-4">{children}</div>
  )
}

export default AnswersList
