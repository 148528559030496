import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import SharedLink from '@/components/shared/buttons/shared-link'
import SharedRTF from '@/components/shared/rtf/shared-rtf'
import BlockComponentSelector from '@/epi/block-component-selector'
import { useEffect, useState } from 'react'
import { uid } from 'uid'
import { CaseStudyDetailProps } from './types/case-study-detail-types'

const CaseStudyDetailPage = (props: Readonly<CaseStudyDetailProps>) => {
  const { model } = props

  const [showLeftColumn, setShowLeftColumn] = useState(false)

  useEffect(() => {
    if (model.leftColumn?.showLeftColumnStatisticArea) {
      setShowLeftColumn(true)
    }
  }, [])

  return (
    <>
      <Breadcrumbs />
      {model.hero && (
        <div className="flex flex-col mb-14 lg:mb-[72px]">
          <BlockComponentSelector {...model.hero} key={uid()} />
        </div>
      )}
      <div className="flex flex-col space-y-[72px] lg:space-y-32">
        <div
          className={`container w-full flex flex-col lg:flex-row ${
            showLeftColumn
              ? 'space-y-14 space-y-reverse lg:space-y-0 lg:space-x-8 lg:space-x-reverse'
              : 'lg:justify-center'
          }`}
        >
          <div className="order-1 w-full lg:w-2/3 flex flex-col space-y-14 lg:space-y-[72px]">
            {model.mainBody && <SharedRTF html={model.mainBody} />}
            {model.pageContent?.map((blockModel, i) => (
              <BlockComponentSelector
                {...blockModel}
                hideContainerClass={true}
                key={`pageContent-${i}`}
              />
            ))}
          </div>
          {showLeftColumn && (
            <div className="order-0 w-full lg:w-1/3 flex flex-col space-y-14 lg:space-y-[72px]">
              {model.leftColumn?.showLeftColumnStatisticArea &&
                ((model.leftColumn.leftColumnStatistic &&
                  model.leftColumn.leftColumnStatistic.length > 0) ||
                  model.leftColumn.leftColumnStatisticsButton) && (
                  <div className="w-full lg:w-3/4 relative flex flex-col space-y-6 bg-grey-secondary-light rounded-lg px-10 lg:px-8 pt-8 pb-12">
                    {model.leftColumn.leftColumnStatistic?.map((item) => (
                      <div key={uid()} className="flex flex-col space-y-3">
                        <div className="text-4xl font-bold text-center lg:text-left text-dark-blue-primary">
                          {item.statNumber}
                        </div>
                        <div className="text-grey-dark text-base text-center lg:text-left">
                          {item.statDescription}
                        </div>
                      </div>
                    ))}
                    {model.leftColumn.leftColumnStatisticsButton && (
                      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2">
                        <SharedLink
                          style="secondary"
                          className="whitespace-nowrap"
                          {...model.leftColumn.leftColumnStatisticsButton}
                        >
                          {model.leftColumn.leftColumnStatisticsButton.text}
                        </SharedLink>
                      </div>
                    )}
                  </div>
                )}
            </div>
          )}
        </div>
        {model.fullWidthContentArea && (
          <div className="flex flex-col space-y-[72px] lg:space-y-32">
            {model.fullWidthContentArea.map((blockModel, i) => (
              <BlockComponentSelector
                {...blockModel}
                key={`fullWidthContentArea-${i}`}
              />
            ))}
          </div>
        )}
      </div>
    </>
  )
}
export default CaseStudyDetailPage
