import classNames from 'classnames';
import React from 'react';
import Select, { GroupBase, SelectInstance } from 'react-select';
import { AddressSelectProps, SelectOption } from './types/address-types';
import DropdownErrorIndicator from './components/dropdown-error-indicator';
import withHydration from '@/lib/withHydration';

const AddressSelectForwardRef: React.ForwardRefRenderFunction<SelectInstance<SelectOption, false, GroupBase<SelectOption>>, AddressSelectProps> = 
    ({
        noResultsText,
        noOptionsText,
        addresses,
        selectValue,
        className,
        placeholder,
        hasError,
        setSelectValue,
        handleChange,
        onSelectedAddress
    }, ref) => {
    const defaultInputStyles = classNames("select", className);

    const onChange = (selectedOption: SelectOption | null) => {
        if (!selectedOption) {
          setSelectValue(null);
        }
        const address = addresses?.find((address) => address.name === selectedOption?.value);
        if (address) {
            onSelectedAddress(address)
            setSelectValue({value: address.name, label: `${address.name}`})
        }
        handleChange(selectedOption);
    }
    
    const addressOptions = 
        addresses ? 
        addresses.map((address)=> ({
          value: address.name,
          label: `${address.name}`
        }))
    : undefined;

    const boxShadowFocusedErrorHandler = hasError ? "0px 0px 0px 3px rgba(244, 63, 94, 0.25)" : "0px 0px 0px 3px rgba(44, 172, 226, 0.3)" 
    const boxShadowUnfocusedErrorHandler = hasError ? "0px 0px 0px 3px rgba(244, 63, 94, 0.25)" : ""
    const borderFocusedErrorHandler = hasError ? "1px solid #DC2626" : "1px solid #2cabe2"
    const borderUnfocusedErrorHandler = hasError ? "1px solid #DC2626" : "1px solid #EFEFF4"
    
    const customStyles = {
        menu: (styles: any, state: any) => ({
            ...styles,
            color: state.isSelected ? "#FFF" : styles.color,
            backgroundColor: "#FFF",
            border: "1px solid black",
            boxShadow: 'none',
        }),
        input: (styles: any, state: any)=>({
          ...styles,
          border: "none",
          outline: "none",
          boxShadow: "none",
        }),
        option: (styles: any, state: any) => ({
            ...styles,
            paddingLeft: "8px",
            paddingRight: "8px",
            borderBottom: "unset",
            color: state.isSelected ? '#FFF' : 'inherit',
            backgroundColor: state.isFocused || state.isSelected ? '#c8c8c8' : 'transparent',
            "&:hover": {
              color: "#FFF",
              backgroundColor: "#1967d2"
            }
        }),
        control: (styles: any, state: any) => ({
            ...styles,
            boxShadow: state.isFocused ? boxShadowFocusedErrorHandler : boxShadowUnfocusedErrorHandler,
            borderRadius: "8px",
            border: !state.isFocused ? borderUnfocusedErrorHandler : borderFocusedErrorHandler,
            padding: "12px",
        }),
      }

    return (
        <Select
            className={defaultInputStyles}
            components={hasError ? {DropdownIndicator: DropdownErrorIndicator} : undefined}
            styles={customStyles}
            unstyled={true}
            ref={ref}
            options={addressOptions}
            value={selectValue}
            onChange={onChange}
            isClearable={false}
            placeholder={placeholder}
            noOptionsMessage={({inputValue}) => !inputValue ? noOptionsText : noResultsText} 
        />
    )
}

const AddressSelect = React.forwardRef(AddressSelectForwardRef);

AddressSelect.defaultProps = {
    placeholder: "Please select a value..",
    noOptionsText: "No options available",
    noResultsText: "No results found"
}

export default withHydration(AddressSelect);