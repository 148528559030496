export const CONTENT_API_BASE_URL = '/api/episerver/v3.0'
export const GET_ME = CONTENT_API_BASE_URL + "/me";
export const PUT_CART = CONTENT_API_BASE_URL + "/me/carts";
export const GET_ME_ADDRESSES = CONTENT_API_BASE_URL + "/me/addresses";
export const PUT_CUSTOMER = CONTENT_API_BASE_URL + "/me/customers";
export const GET_ME_CART = (marketId: string) => CONTENT_API_BASE_URL + `/me/carts/Default/${marketId}/true`;
export const SEARCH_CONTENT = CONTENT_API_BASE_URL + "/search/content";
export const SEARCH_ME_ORDERS = CONTENT_API_BASE_URL + "/me/orders/search";
export const POST_VALIDATECART = (cartId: number) => `${CONTENT_API_BASE_URL}/me/carts/${cartId}/preparecheckout`;
export const GET_CONTENT = CONTENT_API_BASE_URL + "/content";
export const GET_PRICES = CONTENT_API_BASE_URL + "/pricing";
export const SEARCH_ORDERS = CONTENT_API_BASE_URL + "/me/orders/search";
export const GET_INVENTORY = CONTENT_API_BASE_URL + "/inventory";
export const GET_PAYMENTMETHODS = CONTENT_API_BASE_URL + '/paymentmethods' // /{language}/{market}
export const POST_ADD_PAYMENT = (cartId: number, language = 'en') => CONTENT_API_BASE_URL + `/me/carts/${cartId}/${language}/payments`;
export const POST_PROCESS_PAYMENTS = (cartId: number) => CONTENT_API_BASE_URL + `/me/carts/${cartId}/processpayments`
export const POST_CONVERTTOORDER = (cartId: number) => CONTENT_API_BASE_URL + `/me/carts/${cartId}/converttoorder`
export const GET_ME_ORDER = (orderNumber: string) => CONTENT_API_BASE_URL + '/me/orders/' + orderNumber