import { usePrimaryMenuMobileStore } from '@/stores/primary-menu-mobile'
import { ArrowRightIcon, ArrowUturnLeftIcon } from '@heroicons/react/24/solid'
import SharedLink from '../../buttons/shared-link'
import { PrimaryMenuNavItemColumns } from '../types/header'

interface MobileNavSubItemProps extends PrimaryMenuNavItemColumns {
  columnIndex: number
  parentMenuIsOpen: boolean
}

const MobileNavSubItem = (props: Readonly<MobileNavSubItemProps>) => {
  const { model, columnIndex, parentMenuIsOpen } = props
  const {
    openSubMenuItemIndex,
    setOpenSubMenuIndexItem,
    resetOpenSubMenuIndexItem,
  } = usePrimaryMenuMobileStore()

  return (
    <>
      <button
        className="w-full hover:no-underline flex items-center space-x-3 pt-2.5 pb-2 px-1"
        onClick={() => setOpenSubMenuIndexItem(columnIndex)}
      >
        <span className="text-left flex-1 flex flex-col">
          {model.link?.text ? (
            <span className="text-grey-dark font-bold text-sm">
              {model.link.text}
            </span>
          ) : (
            <>
              {model.name && (
                <span className="text-grey-dark font-bold text-sm">
                  {model.name}
                </span>
              )}
            </>
          )}
          {model.description && (
            <span className="text-grey-light text-sm">{model.description}</span>
          )}
        </span>
        <span className="w-5 h-5">
          <ArrowRightIcon className="h-5 w-5 text-black" />
        </span>
      </button>

      <div
        className={`fixed z-20 top-0 left-0 right-0 bottom-0 bg-white shared-transition py-[14px] overflow-auto ${
          parentMenuIsOpen && openSubMenuItemIndex === columnIndex
            ? 'translate-x-0 visible'
            : 'translate-x-full invisible'
        }`}
      >
        <div className="container flex flex-col space-y-3">
          <button
            className="flex items-center space-x-2 p-2 text-black"
            onClick={resetOpenSubMenuIndexItem}
          >
            <span>
              <ArrowUturnLeftIcon className="h-5 w-5 text-grey-dark" />
            </span>
            <span>{model.mobileMenuBackToText}</span>
          </button>

          <ul className="flex flex-col space-y-5">
            {model.link && (
              <li>
                <SharedLink
                  {...model.link}
                  className="hover:no-underline flex items-center justify-between space-x-2 py-2 w-full text-black hover:text-black"
                >
                  <span className="text-left">{model.link.text}</span>
                  <span>
                    <ArrowRightIcon className="h-5 w-5 text-black" />
                  </span>
                </SharedLink>
              </li>
            )}
            {model.submenu?.map((item, k) => (
              <li key={k}>
                {item.model.link && (
                  <SharedLink
                    {...item.model.link}
                    className="hover:no-underline flex items-center space-x-3"
                  >
                    <span className="flex-1 flex flex-col">
                      {item.model.link?.text && (
                        <span className="text-grey-dark font-bold text-sm">
                          {item.model.link.text}
                        </span>
                      )}
                      {item.model.description && (
                        <span className="text-grey-light text-sm">
                          {item.model.description}
                        </span>
                      )}
                    </span>
                  </SharedLink>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default MobileNavSubItem
