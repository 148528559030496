import { LOCAL_STORAGE_KEY_LOG_EVENTS } from '../constants'
import { GtmEvent } from '../interfaces/gtm-event.interface'

export const logEvent = (eventData: GtmEvent, symbol: string = '⚡') => {
  if (localStorage.getItem(LOCAL_STORAGE_KEY_LOG_EVENTS)) {
    console.group(symbol + eventData.event)
    console.log({ ...eventData })
    console.groupEnd()
  }
}

