import React, { useEffect, useRef, useState } from 'react'
import './styles/form-container.scss'
import contentDeliveryApi from '@/api/content-delivery'
import formRenderingService from '@/lib/forms/form-rendering-service'
import { FormContainerBlockProps } from './types/form-container-types'

const FormContainerBlock: React.FC<FormContainerBlockProps> = ({
  model,
  isChildBlock,
  isSidebar,
  isModal,
  isContactPageForm,
}) => {
  const guidGenerator = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0
        const v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      },
    )
  }
  const [formContainerId, setFormContainerId] = useState<string>(
    model.contentLink || guidGenerator(),
  )

  const formElementRef = useRef<HTMLDivElement>(null)

  const renderForm = async () => {
    if (!model?.contentLink) {
      return
    }

    const { formModel } = await contentDeliveryApi.getContent(
      model.contentLink,
      model.language,
    )

    model.formModel = formModel

    if (formElementRef.current) {
      formRenderingService.render(model.formModel, formElementRef.current)
      handleEventListeners()
    }
  }

  const debounce = (mainFunction: any, delay: number) => {
    let timer: any

    return function (...args: any[]) {
      clearTimeout(timer)

      timer = setTimeout(() => {
        mainFunction(...args)
      }, delay)
    }
  }

  const handleScrollToFormTop = () => {
    let formOffset: number = formElementRef.current
      ? formElementRef.current.getBoundingClientRect().top + window.scrollY
      : 0
    const stickyHeader: HTMLDivElement | null =
      document.querySelector('#sticky-header')
    const stickyHeaderHeight = stickyHeader ? stickyHeader.offsetHeight : 0

    if (stickyHeaderHeight > 0) {
      formOffset -= stickyHeaderHeight
    }

    if (formOffset) {
      setTimeout(() => {
        window.scrollTo({ top: formOffset })
      }, 100)
    }
  }

  const handleEventListeners = () => {
    const btnNext = formElementRef.current?.querySelector('.btnNext')
    const btnPrev = formElementRef.current?.querySelector('.btnPrev')

    if (btnNext && btnPrev) {
      btnNext.addEventListener('click', handleScrollToFormTop)
      btnPrev.addEventListener('click', handleScrollToFormTop)
    }
  }

  useEffect(() => {
    debounce(renderForm(), 300)
  }, [model])

  const needsContainer = !isModal && !isSidebar && !isChildBlock

  const formElement = (
    <div
      id={`block-${model.contentLink}`}
      className={`max-w-[855px] 
      ${needsContainer ? 'bg-grey-secondary-light p-4 md:p-12 rounded-lg' : ''}
      ${isSidebar ? 'bg-grey-secondary-light p-4 md:p-6 rounded-lg' : ''}`}
    >
      <div id={`formContainer_${formContainerId}`} ref={formElementRef} />
    </div>
  )

  return needsContainer ? (
    <div className={!isContactPageForm ? 'container' : ''}>{formElement}</div>
  ) : (
    formElement
  )
}

export default FormContainerBlock
