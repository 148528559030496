import { Suspense, createContext } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { HeadProvider } from 'react-head'
import { BrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import '@/components/shared/react-toastify/react-toastify-overrides.scss'
import './assets/index.css'
import CustomerHardLock from './components/shared/customer-hard-lock/customer-hard-lock'
import Root from './layouts/root'
import { ServerDevice } from './types/shared'
import { useUserStore } from './stores/useUserStore'
import { HydrationProvider } from 'react-hydration-provider'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

const appRoot = document.getElementById('app')
const UserStoreContext = createContext(useUserStore);
  if (appRoot) {
  const loader = (<div className="flex h-screen"><div className="m-auto"></div></div>)

  const render = (
    <Suspense fallback={loader}>
      <HydrationProvider>
      <I18nextProvider i18n={i18n}>
        <UserStoreContext.Provider value={useUserStore}>
          <HeadProvider>
            <BrowserRouter>
              <Root
                initialState={window._model}
                device={window._device as ServerDevice}
              />
            </BrowserRouter>
          </HeadProvider>
          <CustomerHardLock />
        </UserStoreContext.Provider>
      </I18nextProvider>
      </HydrationProvider>
    </Suspense>
  )

  hydrateRoot(appRoot, render,
  {
    onRecoverableError: (error: any, errorInfo) => {
      console.error(
        'Caught error',
        error,
        error.cause,
        errorInfo.componentStack
      );
    }
  })
}
// Allow the passed state to be garbage-collected
delete window._model
