import SectionHeader from '@/components/shared/section-header'
import React from 'react'
import { uid } from 'uid'
import { StatisticsBlockProps } from './types/statistics-types'

function StatisticsBlock(props: Readonly<StatisticsBlockProps>) {
  const { model } = props

  const stats = [
    { statNumber: model.statNumber1, statDescription: model.statDescription1 },
    { statNumber: model.statNumber2, statDescription: model.statDescription2 },
    { statNumber: model.statNumber3, statDescription: model.statDescription3 },
  ]

  return (
    <section className="container">
      <div className="space-y-8 md:space-y-14">
        <SectionHeader
          smallIntro={model.smallIntro}
          heading={model.heading}
          description={model.subheading}
        />

        <div className="flex flex-col gap-y-5 md:gap-y-0 md:gap-x-8 md:flex-row md:justify-center">
          {stats.map((stat) => (
            <React.Fragment key={uid()}>
              {(stat.statNumber || stat.statDescription) && (
                <div className="flex flex-col w-full space-y-6 md:items-center justify-between md:w-3/12">
                  <div className="flex flex-col md:items-center md:text-center w-full space-y-6">
                    <p className="font-mulish text-dark-blue-primary font-extrabold text-5xl lg:text-8xl">
                      {stat.statNumber}
                    </p>
                    <p className="font-normal font-mulish text-lg md:text-xl">
                      {stat.statDescription}
                    </p>
                  </div>
                  <div className="h-1 w-6 bg-orange-primary"></div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}

export default StatisticsBlock
