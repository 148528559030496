import { IconProps } from './types/icon-types'

const TwitterIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#859097"
      className={className}
    >
      <path d="m14.3 10.6l9.7 14.4h-7.1l-6.6-9.7-8.2 9.7h-2.1l9.4-11.1-9.4-13.9h7.1l6.2 9.2 7.8-9.2h2.1l-8.9 10.6zm-2.9 3.4l6.5 9.4h3.2l-7.9-11.5-1-1.4-6.1-8.9h-3.2l7.5 11 1 1.4z" />
    </svg>
  )
}

export default TwitterIcon
