import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import BlockComponentSelector from '@/epi/block-component-selector'
import ProgramHero from './components/program-hero'
import { ProgramPageProps } from './types/program-page-types'

const ProgramPage = (props: Readonly<ProgramPageProps>) => {
  const { model } = props
  return (
    <>
      <Breadcrumbs />
      {props.model.hero && <ProgramHero {...props.model.hero} />}
      <div className="flex flex-col space-y-14 md:space-y-20">
        {model.pageContent?.map((item, i) => (
          <BlockComponentSelector {...item} key={`pageContent-${i}`} />
        ))}
      </div>
    </>
  )
}
export default ProgramPage
