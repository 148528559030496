import SectionHeader from '@/components/shared/section-header'
import { uid } from 'uid'
import { FeaturedRecourceCard } from './components/featured-resource-card'
import { LongformResourcesBlockProps } from './types/longform-resources-block-types'

const LongformResourcesBlock: React.FC<LongformResourcesBlockProps> = ({
  model,
  hideContainerClass,
  className,
}) => {
  const classNames = [
    !hideContainerClass ? 'container' : '',
    className,
    'space-y-4 md:space-y-6',
  ]
    .filter((i) => i !== undefined && i !== '')
    .join(' ')

  return (
    <section className={classNames}>
      <SectionHeader
        smallIntro={model.smallIntro}
        heading={model.sectionHeading}
        description={model.sectionDescription}
      />

      {model.featuredResources && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
          {model.featuredResources.map((item) => (
            <FeaturedRecourceCard key={uid()} {...item} />
          ))}
        </div>
      )}
    </section>
  )
}

export { LongformResourcesBlock }
