import { ShippingAndBillingAddressModel } from '@/api/types/content-delivery-types'

interface AddressCardProps {
    title?: string;
    defaultAddress: ShippingAndBillingAddressModel | null
}

const AddressCard = (props: AddressCardProps) => {
  const {
    title,
    defaultAddress,
  } = props

  return (
      defaultAddress ? 
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-1 py-[24px]">
              <span className='font-bold text-black text-2xl'>{title}</span>
              <span className='font-bold text-black'>{defaultAddress?.firstName} {defaultAddress?.lastName}</span>
              <span className='text-black'>{defaultAddress?.line1}</span>
              <span className='text-black'>{defaultAddress?.line2}</span>
              <span className='text-black'>{defaultAddress?.city} {defaultAddress?.regionName} {defaultAddress?.postalCode}</span>
              <span className='text-black'>{defaultAddress?.countryName}</span>
              <span className='text-black'>{defaultAddress?.phoneNumber}</span>
          </div>
        </div>
      : null
  )
}
export default AddressCard
