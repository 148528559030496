import { BreadcrumbProp } from '@/types/shared'
import { create } from 'zustand'

interface BreadcrumbsStoreProps {
  breadcrumbItems: BreadcrumbProp[]
  pageName: string
  parentLinkUrl: string
  setBreadcrumbItems: (breadcrumbItems: any[]) => void
  setBreadcrumbPageName: (pageName: string) => void
  setParentLink: (url: string) => void
}

export const useBreadcrumbs = create<BreadcrumbsStoreProps>((set) => ({
  breadcrumbItems: [],
  pageName: '',
  parentLinkUrl: '',
  setBreadcrumbItems: (breadcrumbItems: any[]) =>
    set(() => ({ breadcrumbItems: breadcrumbItems })),
  setBreadcrumbPageName: (pageName: string) =>
    set(() => ({ pageName: pageName })),
  setParentLink: (url: string) => set(() => ({ parentLinkUrl: url })),
}))
