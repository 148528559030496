import { useFindTrainingStore } from '@/stores/find-training-store'
import { CheckIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'

interface QuizBtnCheckboxProps {
  text: string
  isPhysicalAltercations?: boolean
}

const QuizBtnCheckbox = (props: QuizBtnCheckboxProps) => {
  const { text, isPhysicalAltercations } = props
  const { setPhysicalAltercations } = useFindTrainingStore()
  const [isChecked, setIsChecked] = useState(false)

  const handleClick = () => {
    setIsChecked(!isChecked)

    if (isPhysicalAltercations) {
      setPhysicalAltercations(!isChecked)
    }
  }

  return (
    <button
      className="w-full p-3 flex items-center space-x-3 text-base md:text-lg font-medium text-grey-dark shared-transition shadow-sm hover:shadow-md border border-grey-secondary-dark bg-white hover:bg-grey-secondary-light rounded"
      onClick={handleClick}
    >
      <span
        className={`h-[18px] w-[18px] border rounded relative ${
          isChecked ? 'border-white' : 'border-grey-300'
        }`}
      >
        {isChecked && (
          <span className="bg-light-blue-primary rounded h-4 w-4 flex items-center justify-center">
            <CheckIcon className="h-2.5 w-2.5 text-white" />
          </span>
        )}
      </span>
      <span className="text-left">{text}</span>
    </button>
  )
}

export default QuizBtnCheckbox
