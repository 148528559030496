import SharedButton from '@/components/shared/buttons/shared-button'
import SharedLink from '@/components/shared/buttons/shared-link'
import CardSlider from '@/components/shared/card-slider/card-slider'
import SectionHeader from '@/components/shared/section-header'
import { useGlobalSettings } from '@/stores/global-settings'
import { useMediaStore } from '@/stores/media-store'
import { PlayCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLockedBody } from 'usehooks-ts'
import TraingingCard from './components/training-card'
import { TrainingModalProps, TraningApproachProps } from './types/training-approach-types'

function TrainingApproachBlock(props: Readonly<TraningApproachProps>) {
  const { model } = props
  const { t } = useTranslation()

  const globalSettings = useGlobalSettings((state) => state.globalSettings)

  const { isMobile } = useMediaStore()
  const [activeIndex, setActiveIndex] = useState(1)
  const [pauseTimer, setPauseTimer] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const mobileCardSlider = useRef()

  useLockedBody(showModal, 'root')

  const Modal: React.FC<TrainingModalProps> = ({
    videoId,
    showModal,
    onClose,
  }) => {
    return (
      <div
        className={`fixed inset-0 flex items-center justify-center z-50 ${showModal ? '' : 'hidden'
          }`}
      >
        {/* Overlay with blur effect */}
        <div
          className="absolute inset-0 backdrop-blur-md"
          onClick={onClose}
        ></div>

        {/* Modal Content */}
        <div className="relative z-10 bg-white p-6 rounded-xl shadow-xl w-[767px] mx-[18px] border border-grey-secondary-light">
          <div className="mb-8 flex justify-end">
            <SharedButton
              style="primary"
              size="regular"
              className="h-[40px] w-[40px] flex items-center justify-center"
              onClick={onClose}
            >
              <span className="sr-only">{t('ScreenReader.CloseModal')}</span>
              <span>
                <XMarkIcon className="text-white h-5 w-5" />
              </span>
            </SharedButton>
          </div>
          <div className="w-full">
            <iframe
              src={`https://www.youtube.com/embed/${videoId}`}
              title={t('Blocks.TrainingApproachBlock.YouTubeFrameTitle')}
              className="w-full aspect-video"
              allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </div>
      </div>
    )
  }

  const handleActiveIndex = (index: number) => {
    if (!isMobile) {
      setActiveIndex(index)
    }
  }

  const handlePauseTimer = (value: boolean) => {
    if (!isMobile) {
      setPauseTimer(value)
    }
  }

  const handleShowVideo = () => {
    setPauseTimer(true)
    setShowModal(true)
  }

  const handleHideVideo = () => {
    setPauseTimer(false)
    setShowModal(false)
  }

  const handleMobileNextSlide = () => {
    // @ts-ignore
    mobileCardSlider.current?.next()
  }

  const handleMobileResetSlide = () => {
    // @ts-ignore
    mobileCardSlider.current?.reset()
  }

  useEffect(() => {
    if (pauseTimer) {
      return
    }

    const timer = setTimeout(() => {
      if (activeIndex < 3) {
        setActiveIndex(activeIndex + 1)
        handleMobileNextSlide()
      } else {
        setActiveIndex(1)
        handleMobileResetSlide()
      }
    }, 4000)
    return () => {
      clearTimeout(timer)
    }
  }, [activeIndex, pauseTimer])

  return (
    <section className="container flex flex-col">
      <SectionHeader
        smallIntro={model.smallIntro}
        heading={model.heading}
        subheading={model.subheading}
      >
        <div className="w-full md:w-1/2 flex flex-col lg:flex-row lg:justify-end lg:items-center space-y-3 lg:space-y-0 lg:space-x-5">
          {model.videoId && (
            <SharedButton
              style={'primary'}
              size={'regular'}
              className="w-full md:w-auto space-x-3"
              onClick={handleShowVideo}
            >
              <span>
                <PlayCircleIcon className="text-white h-4 md:h-5 w-4 md:w-5" />
              </span>
              {model.videoButtonText && <span>{model.videoButtonText}</span>}
            </SharedButton>
          )}
          {model.ctaButton && model.ctaButton.text && (
            <SharedLink
              {...model.ctaButton}
              style={model.ctaButton.buttonStyle}
              size={model.ctaButton.buttonSize}
              className="w-full md:w-auto space-x-3"
            >
              <span>{model.ctaButton.text}</span>
            </SharedLink>
          )}
        </div>
      </SectionHeader>
      <div className="2xl:w-[calc(100%-64px)] md:flex md:space-x-8">
        {!isMobile && (
          <>
            <TraingingCard
              heading={model.step1Heading}
              subheading={model.step1Subheading}
              image={model.step1Image}
              index={1}
              activeIndex={activeIndex}
              updateActiveIndex={handleActiveIndex}
              pauseTimer={handlePauseTimer}
              isDesktop={!isMobile}
            />
            <TraingingCard
              heading={model.step2Heading}
              subheading={model.step2Subheading}
              image={model.step2Image}
              index={2}
              activeIndex={activeIndex}
              updateActiveIndex={handleActiveIndex}
              pauseTimer={handlePauseTimer}
              isDesktop={!isMobile}
            />
            <TraingingCard
              heading={model.step3Heading}
              subheading={model.step3Subheading}
              image={model.step3Image}
              index={3}
              activeIndex={activeIndex}
              updateActiveIndex={handleActiveIndex}
              pauseTimer={handlePauseTimer}
              isDesktop={!isMobile}
            />
          </>
        )}

        {isMobile && (
          <CardSlider
            ref={mobileCardSlider}
            cardsToShow={1}
            cardTotal={3}
            nextScreenreaderText={globalSettings?.sliderNextAriaText}
            previousScreenreaderText={globalSettings?.sliderPreviousAriaText}
            disableSwipe={true}
          >
            <TraingingCard
              heading={model.step1Heading}
              subheading={model.step1Subheading}
              image={model.step1Image}
              index={1}
              activeIndex={activeIndex}
              updateActiveIndex={handleActiveIndex}
              pauseTimer={handlePauseTimer}
              isDesktop={!isMobile}
            />
            <TraingingCard
              heading={model.step2Heading}
              subheading={model.step2Subheading}
              image={model.step2Image}
              index={2}
              activeIndex={activeIndex}
              updateActiveIndex={handleActiveIndex}
              pauseTimer={handlePauseTimer}
              isDesktop={!isMobile}
            />
            <TraingingCard
              heading={model.step3Heading}
              subheading={model.step3Subheading}
              image={model.step3Image}
              index={3}
              activeIndex={activeIndex}
              updateActiveIndex={handleActiveIndex}
              pauseTimer={handlePauseTimer}
              isDesktop={!isMobile}
            />
          </CardSlider>
        )}
      </div>
      {model.videoId && (
        <Modal
          videoId={model.videoId}
          showModal={showModal}
          onClose={handleHideVideo}
        />
      )}
    </section>
  )
}

export default TrainingApproachBlock
