import AccordionItem from '@/components/blocks/accordion/components/accordion-item'
import { AccordionProps } from '@/components/blocks/accordion/types/accordion-types'
import SectionHeader from '@/components/shared/section-header'
import { useMediaStore } from '@/stores/media-store'
import { useEffect, useState } from 'react'

const AccordionBlock = (props: Readonly<AccordionProps>) => {
  const [activeIndex, setActiveIndex] = useState<number[]>([])
  const { isMobile } = useMediaStore()

  const toggleItem = (index: number) => {
    if (isMobile) {
      // On mobile, close all others and toggle only the clicked item
      if (activeIndex.includes(index)) {
        setActiveIndex([])
      } else {
        setActiveIndex([index])
      }
    }
    // On desktop, toggle the clicked item while keeping others open
    else if (activeIndex.includes(index)) {
      setActiveIndex(activeIndex.filter((i) => i !== index))
    } else {
      setActiveIndex([...activeIndex, index])
    }
  }

  useEffect(() => {
    // Close all items on mobile when switching to desktop view
    if (isMobile) {
      setActiveIndex([])
    }
  }, [isMobile])

  return (
    <section className={`${!props.hideContainerClass ? 'container' : ''}`}>
      {props.model.smallIntro || props.model.heading ? (
        <SectionHeader
          smallIntro={props.model.smallIntro}
          heading={props.model.heading}
        />
      ) : (
        <div></div>
      )}
      {props.model.description && (
        <div
          className={
            props.model.description
              ? 'text-grey-light text-base md:text-lg -mt-4 md:-mt-6 mb-8 md:mb-6'
              : ''
          }
        >
          {props.model.description}
        </div>
      )}
      {props.model.accordionItems.map((item, index) => (
        <AccordionItem
          key={index}
          id={item.model.contentLink}
          activeIndex={activeIndex}
          index={index}
          isOpen={item.model.isOpen}
          title={item.model.title}
          content={item.model.description}
          toggleItem={toggleItem}
        />
      ))}
    </section>
  )
}

export default AccordionBlock
