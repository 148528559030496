import { useFindTrainingStore } from '@/stores/find-training-store'
import { uid } from 'uid'
import {
  QuestionOneAnswerItemProps,
  QuestionTwoItemProps,
} from '../types/find-training-types'
import AnswersList from './answers-list'
import QuestionElement from './question-element'
import QuizBtnLink from './quiz-btn-link'
import QuizBtnText from './quiz-btn-text'

const QuestionTwo = (props: QuestionTwoItemProps) => {
  const { question, answerYes, answerMaybe, answerNoLink } = props
  const { activeStep } = useFindTrainingStore()

  const answers: QuestionOneAnswerItemProps[] = [
    { answerText: answerYes },
    { answerText: answerMaybe },
    { answerLink: answerNoLink },
  ]
  return (
    <div
      className={`flex flex-col space-y-8 md:space-y-12 items-center shared-transition ${
        activeStep === 2 ? 'opacity-1' : 'invisible sr-only opacity-0'
      }`}
    >
      {question && <QuestionElement question={question} />}
      {answers && (
        <AnswersList>
          {answers.map((answer) => (
            <div key={uid()} className="w-full">
              {answer.answerLink ? (
                <QuizBtnLink link={answer.answerLink} />
              ) : (
                <>
                  {answer.answerText && (
                    <QuizBtnText text={answer.answerText} />
                  )}
                </>
              )}
            </div>
          ))}
        </AnswersList>
      )}
    </div>
  )
}

export default QuestionTwo
