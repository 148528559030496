import SharedButton from '@/components/shared/buttons/shared-button'
import { XMarkIcon } from '@heroicons/react/24/solid'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useScrollLock } from 'usehooks-ts'


interface ModalExtendedProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  modalTitle?: string;
}

const ExtendedModal: React.FC<ModalExtendedProps> = (props: ModalExtendedProps) => {
  const { t } = useTranslation()

  useScrollLock({
    autoLock: props.open,
    lockTarget: 'root',
  })

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.key === 'Escape') {
        props.onClose();
      }
    }

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  if (!props.open) return null;

  return (
    <div role="dialog" className="fixed z-[1000] inset-0 overflow-y-auto" aria-labelledby="modal-title" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0 lg:pt-4 lg:px-4 lg:pb-20 ">
        <div className="fixed inset-0 backdrop-blur-md bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
          <div className='flex flex-row items-center px-6 py-4'>
            <div className="flex-1">
              <h2 id="modal-title" className='text-black font-bold text-xl'>{props?.modalTitle}</h2>
            </div>
            <div className="flex-1 text-right">
              <SharedButton
                style="primary"
                size="regular"
                className="h-[40px] w-[40px] flex items-center justify-center"
                onClick={props.onClose}
              >
                <span className="sr-only">{t('ScreenReader.CloseModal')}</span>
                <span>
                  <XMarkIcon className="text-white h-5 w-5" />
                </span>
              </SharedButton>
            </div>
          </div>
          <div className="bg-white px-4 pb-4 sm:p-6 sm:pb-4">
            <div className="flex flex-col items-start">
              <div className="mt-3 sm:mt-0 w-full">
                <div className="mt-2">
                  {props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ExtendedModal.defaultProps = {
}
export default ExtendedModal
