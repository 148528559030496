import React from 'react'
import { CheckoutStepThreeFormInputs } from '../../pages/checkout/types/checkout-types'
import { InputError } from './input-error'
import { FormCheckboxProps } from './types/form-checkbox-props'

export const FormCheckbox: React.FC<FormCheckboxProps> = ({ labelHasAsterisk, label, placeholder, register, errors, ...inputProps }: FormCheckboxProps) => {
  const key = (register.name as keyof CheckoutStepThreeFormInputs) || null
  const hasErrors = errors && key && errors?.[key]
  const errorMessage = hasErrors && errors?.[key]?.message

  return (
    <>
      <div className="mb-4 flex items-center">
        <input
          {...register}
          {...inputProps}
          id={register.name}
          type="checkbox"
          className={`min-h-4 min-w-4 h-4 w-4 rounded ${
            hasErrors ? 'bg-red-100 border-red-600' : 'bg-gray-100 border-gray-300'
          }`}
        />
        <label htmlFor={register.name} className={`ms-2 ${hasErrors ? 'text-red-600' : 'text-black'}`}>
          {label} {labelHasAsterisk ? "*" : ""}
        </label>
      </div>
      <InputError message={errorMessage} />
    </>
  )
}
FormCheckbox.defaultProps =
{
  labelHasAsterisk: false
}