import { epiApiPost, epiGetOrder } from '@/api/content-delivery'
import { CartLineItemModel, OrderModel } from '@/api/types/content-delivery-types'
import { purchaseGTM } from '@/components/shared/analytics/gtm-manager'
import OrderSummary from '@/components/shared/order-summary/order-summary'
import Price from '@/components/shared/price/price'
import { RenderContentArea } from '@/epi/pageUtils/render-content-area'
import { SYSTEMKEYWORD_DUMMY, SYSTEMKEYWORD_PAYFABRIC } from '@/stores/checkout-store'
import { CommerceBasePageProps } from '@/types/shared'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { AddressDisplay } from '../checkout/components/address-display'
import { CartLineItemReadOnly } from '../checkout/components/cart-line-item-read-only'
import { DEFAULT_CURRENCY_CODE, DEFAULT_MARKET, NO_SHIPPING } from '@/lib/constants'
import { checkForNoShipping } from '@/components/shared/shipping-options/shipping'
import withHydration from '@/lib/withHydration'

export type OrderConfirmationPageProps = {
  model: {
    pageUrl: string
    mainContentString?: string // html string
  }
} & CommerceBasePageProps

const OrderConfirmationPage = (props: Readonly<OrderConfirmationPageProps>) => {
  const { pageTitle, pageDescription, topContentArea, mainContentString, bottomContentArea } = props.model
  const { t } = useTranslation()
  const [searchParams, _] = useSearchParams()
  const trackingNumber = searchParams.get('id')
  const [shippingMethod, setShippingMethod] = useState<string>()
  const [shippingMethodLocalized, setShippingMethodLocalized] = useState<string>()
  const [order, setOrder] = useState<OrderModel>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    async function getOrder() {
      if (!trackingNumber) return
      setIsLoading(true)
      const order = await epiGetOrder(trackingNumber)
      let shippingMethodId = order?.shipments[0]?.shippingMethodId 
      let shippingMethodName: string | undefined = 'Shipping'
      try {
        const shippingMethodResult = await epiApiPost<{ name: string }>(props.model.pageUrl + 'GetShippingMethod', {
          shippingMethodId: shippingMethodId,
        })
        shippingMethodName = checkForNoShipping({
          name: shippingMethodResult?.name ?? "", 
          localizedValue: t("Common.NoShipping")
        })
        setShippingMethod(shippingMethodResult?.name ?? "")
      } catch {}
      setOrder(order)
      setIsLoading(false)
      setShippingMethodLocalized(shippingMethodName)
      purchaseGTM(trackingNumber, order, shippingMethodName)
    }
    getOrder()
  }, [trackingNumber])

  const renderCartItems = useCallback(() => {
    const lineItems = order?.shipments.flatMap((s) => s.lineItems)
    return (
      <div className="flex flex-col gap-4">
        {lineItems?.map((lineItem: CartLineItemModel) => (
          <CartLineItemReadOnly
            key={lineItem.id}
            cartLineModel={lineItem}
            currency={order?.currency}
            calculateCartLine={(lineId, qty) => {
              return lineItem.placedPrice * lineItem.quantity
            }}
          />
        ))}
      </div>
    )
  }, [order])

  const renderShippingAddress = useCallback(() => {
    if (!order) return <></>
    return (
      order.shipments &&
      order.shipments.length > 0 && (
        <div>
          <div className="font-bold text-grey-dark ">{t('OrderConfirmation.ShippingDetailsTitle')}</div>
          <AddressDisplay addressModel={order.shipments[0].shippingAddress} />
        </div>
      )
    )
  }, [order])

  const renderBillingAddress = useCallback(() => {
    if (!order) return <></>
    return (
      order.payments &&
      order.payments.length > 0 && (
        <div>
          <div className="font-bold text-grey-dark ">{t('OrderConfirmation.BillingDetailsTitle')}</div>
          <AddressDisplay addressModel={order.payments[0].billingAddress} />
        </div>
      )
    )
  }, [order])

  const renderShipmentAndPaymentMethod = useCallback(() => {
    if (!order) return <></>
    const paymentMethod = order.payments.find((p) => p.systemKeyword !== SYSTEMKEYWORD_DUMMY)
    const purchaseOrderNumber = paymentMethod?.extendedProperties?.find(x => x.name.includes("po_number"))?.value ?? "";
    let paymentMethodInfo = t('OrderConfirmation.PurchaseOrder')
    if (paymentMethod?.systemKeyword === SYSTEMKEYWORD_PAYFABRIC) {
      const cardType = paymentMethod?.extendedProperties?.find((kv) => kv.name === 'pf_cardtype')?.value ?? 'Credit'
      const lastFour = paymentMethod?.extendedProperties?.find((kv) => kv.name === 'pf_lastfour')?.value ?? '####'
      paymentMethodInfo = cardType + ' ****' + lastFour
    }
    const shippingCost = (
      <Price currency={order.currency} price={order.totals.shippingTotal.toString()} className="whitespace-nowrap" />
    )
    return (
      <div>
        <div>
          <div className="font-bold text-grey-dark ">{t('OrderConfirmation.ShippingMethodTitle')}</div>
          <div>
            {shippingMethodLocalized} {shippingMethod !== NO_SHIPPING && <span className="font-bold">{shippingCost}</span>}
          </div>
        </div>
        <div className="mt-3">
          <div className="font-bold text-grey-dark ">{t('OrderConfirmation.PaymentMethodTitle')}</div>
          <div>{paymentMethodInfo} {purchaseOrderNumber ? ` - ${purchaseOrderNumber}` : ''}</div>
        </div>
      </div>
    )
  }, [order])

  if (!order) return <></>

  return (
    <div className="container flex flex-col py-0 font-mulish">
      <div className="flex grid-cols-8 flex-col gap-7 lg:grid">
        <div className="flex-1 pt-8 lg:col-span-5">
          <div className="flex w-full flex-col gap-[12px] pb-[10px]">
            <h1 className="text-3xl font-bold text-grey-dark lg:text-5xl">{pageTitle}</h1>
            <span>{pageDescription}</span>
          </div>
          {RenderContentArea(topContentArea)}
          <div className="">
            <section className="mb-6">
              <header>
                <div className="text-xl text-black">
                  {t('OrderConfirmation.OrderNumber', {
                    0: order?.orderNumber,
                  })}
                </div>
                <div className="text-xl text-grey-dark">{t('OrderConfirmation.OrderConfirmation')}</div>
              </header>
              {mainContentString && (
                <p className="text-grey-600" dangerouslySetInnerHTML={{ __html: mainContentString }}></p>
              )}
            </section>
          </div>
          <div className="mb-6 flex flex-auto flex-col gap-[24px]">
            <div>{renderBillingAddress()}</div>
            <div>{renderShippingAddress()}</div>
            <div>{renderShipmentAndPaymentMethod()}</div>
          </div>
          <div className="my-3">{RenderContentArea(bottomContentArea)}</div>
        </div>
        <div className="rounded-b-md -mx-[1.125rem] h-auto flex-auto bg-grey-secondary-light px-[1.125rem] pt-0 text-grey-dark lg:col-span-3 lg:mx-0 lg:px-[36px] lg:pt-8">
          <div className="flex h-auto flex-col gap-3">
            <div className="flex pb-3 lg:pb-[6rem]">
              <div className="mt-2">{renderCartItems()}</div>
            </div>
            <OrderSummary
              isLoading={isLoading}
              hasShipping={true}
              onPage="checkout"
              cartInfo={{
                market: order?.market ?? DEFAULT_MARKET,
                currency: order?.currency ?? DEFAULT_CURRENCY_CODE,
              }}
              cartTotals={order?.totals}
              localizations={{
                cartSummaryTitle: t('OrderConfirmation.OrderSummaryTitle'),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default withHydration(OrderConfirmationPage)