import { AuthUserType } from '@/lib/ping-identity/types/auth-types'
import { EventType } from '../enums/event-type.enum'
import { pushFullStoryEvent } from '../functions/push-event.function'

export const fullStoryGTM = async (user: AuthUserType, cultureCode: string | undefined) => {
  const customerId = user?.customerInfo?.customerId

  if (!customerId) {
    console.error('Customer ID not found.')
    return
  }

  const ep = user.customerInfo?.extendedProperties || []
  
  const userRole = ep.find(p => p.name === 'UserRole')?.value
  const industry = ep.find(p => p.name === 'Industry')?.value
  const accountId = ep.find(p => p.name === 'AccountId')?.value
  const accountName = ep.find(p => p.name === 'AccountName')?.value

  pushFullStoryEvent({
    event: EventType.FullStory,
    uid: customerId,
    email: user.customerInfo?.email,
    displayName: `${user.customerInfo?.firstName} ${user.customerInfo?.lastName}`,
    userRole: userRole ?? 'Default Role',
    culture: cultureCode,
    industry: industry ?? 'Default Industry',
    accountId: accountId ?? 'Default Account ID',
    accountName: accountName ?? 'Default Account Name',
  })
}
