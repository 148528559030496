import { StatisticProps } from '@/components/blocks/statistics-link-card-block/types/statistics-link-card-types'
import SharedLink from '@/components/shared/buttons/shared-link'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { uid } from 'uid'

interface StatisticCardProps {
  cardUrl?: string
  cardLinkText?: string
  statistic?: StatisticProps[]
}

function StatisticsLinkCard(props: Readonly<StatisticCardProps>) {
  const { cardUrl, cardLinkText, statistic } = props

  return (
    <>
      {cardUrl && (
        <SharedLink
          href={cardUrl}
          className="relative hover:no-underline border-b-2 border-orange-primary flex flex-col space-y-2 justify-between items-center text-center rounded-lg  lg:rounded-b-none w-full lg:flex-1 bg-grey-secondary-light hover:bg-grey-secondary-dark px-4 lg:px-6 pt-8 pb-4 lg:pb-4"
        >
          <div>
            {statistic && statistic.length > 0 && (
              <>
                {statistic.map((item, index) => (
                  <React.Fragment key={uid()}>
                    {index === 0 && (
                      <>
                        {item.statNumber && (
                          <span className="block absolute top-0 left-0 right-0 -translate-y-1/2 font-mulish text-dark-blue-primary font-bold text-4xl lg:text-5xl">
                            {item.statNumber}
                          </span>
                        )}
                        {item.statDescription && (
                          <span className="flex-1 font-normal text-grey-light font-mulish lg:text-lg">
                            {item.statDescription}
                          </span>
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
          {cardLinkText && (
            <span className="flex text-dark-blue-primary font-semibold items-center space-x-3 py-2.5">
              <span>{cardLinkText}</span>
              <span>
                <ArrowRightIcon className="h-5 w-5" />
              </span>
            </span>
          )}
        </SharedLink>
      )}
    </>
  )
}

export default StatisticsLinkCard
