import { VariantModel } from '@/components/pages/product-detail/types/product-detail-types'
import { PartialNullable } from '@/lib/partial-nullable.type'
import { EventItemParameters } from '../types/event-item-parameters.type'
import { ProductId } from '../types/product-id.type'

const ITEM_BRAND = 'CPI'

export const createItem = (productId: ProductId, variant: VariantModel) => {
  const item: PartialNullable<EventItemParameters> = {
    item_id: productId,
    item_name: variant.displayName,
    item_brand: ITEM_BRAND,
    item_list_id: null,
    item_list_name: null,
  }

  return item
}
