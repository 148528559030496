import { CartInfoModel, ShippingMethodModel } from "@/api/types/content-delivery-types";
import { useEffect, useState } from "react";
import { useCartStore } from "@/stores/useCartStore";
import Price from "../price/price";
import { currencyToLocale } from "../price/types/price-types";
import { useUserStore } from "@/stores/useUserStore";
import { DEFAULT_ADDRESS_GUID, DEFAULT_CURRENCY_CODE, NO_SHIPPING } from "@/lib/constants";
import { useTranslation } from "react-i18next";

interface ShippingOptionsProps {
    title: string
    cartInfo: CartInfoModel
    defaultSelectedOption: string | null;
    onSelected: (selectedOption: ShippingMethodModel) => void
    setUpdateTotals: React.Dispatch<React.SetStateAction<boolean>>;
    availableShippingMethods: ShippingMethodModel[]
}

const ShippingOptions: React.FC<ShippingOptionsProps> = (props) => {
  const {
    title,
    cartInfo,
    defaultSelectedOption,
    availableShippingMethods,
    onSelected,
    setUpdateTotals,
  } = props
  const { user } = useUserStore()
  const { cartData, updateCartShippingMethod, setIsLoadingCartTotals } = useCartStore()
  const { t } = useTranslation()

  const filteredShippingOptions = availableShippingMethods.filter(x=> x.displayName !== NO_SHIPPING)
  const shipping = availableShippingMethods.find(x=> x.displayName === NO_SHIPPING) ?? null
  const cartShippingMethod = cartData?.fullCart?.shipments[0].shippingMethodId ?? ""
  const hasShipping = shipping?.id !== cartShippingMethod

  const validShippingMethodSelector = !(defaultSelectedOption ===  DEFAULT_ADDRESS_GUID || availableShippingMethods.some(x=> x.id !== defaultSelectedOption)) ? "" : availableShippingMethods?.find(x => x.id === defaultSelectedOption)
  const [selectedValue, setSelectedValue] = useState(validShippingMethodSelector !== undefined && validShippingMethodSelector !== ""  ? validShippingMethodSelector.id : DEFAULT_ADDRESS_GUID)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value)
    setSelected(event.target.value)
  }

  const setSelected = async(shippingMethodId: string) => {
    setIsLoadingCartTotals(true)
    const selectedOption = availableShippingMethods.find(x=> x.id === shippingMethodId);
    const isComplete = await updateCartShippingMethod({shipmentId: user?.cart?.shipments[0]?.id ?? null, shippingMethod: selectedOption ?? null})
    isComplete && setUpdateTotals(true)
  }

  const getSelectedOption = (selectedOption: ShippingMethodModel) => {
    onSelected(selectedOption)
  }

  useEffect(()=>{
      if (selectedValue === DEFAULT_ADDRESS_GUID) 
      {
        if (filteredShippingOptions.length > 0)
        {
          // This handles only the default address scenario so we select atleast 
          // one item in the list if we have it. (Prior to user explicitly saving a selection.)
          setSelectedValue(filteredShippingOptions[0].id)
        }
        return
      }

      if (shipping?.id === cartShippingMethod)
      {
        const selectedOption = availableShippingMethods.find(x=> x.id === shipping?.id)
        selectedOption && getSelectedOption(selectedOption)
        return;
      }
      
      const selectedOption = filteredShippingOptions.find(x=> x.id === selectedValue)
      selectedOption && getSelectedOption(selectedOption)
  }, [selectedValue, shipping?.id, cartShippingMethod]);


  return (
      <div className="flex flex-col w-full">
        <span className="font-bold text-black text-xl mb-[18px]">{title}</span>
        <div className="flex flex-col gap-4">
            {!hasShipping && 
              <span>{t("Common.NoShippingDescription")}</span>
            }
            {
              hasShipping && filteredShippingOptions.map((shippingMethod, idx) => {
                  return (
                    <label className={`text-black flex flex-row items-center gap-2`} key={shippingMethod.id}>
                      <input
                        type="radio"
                        value={shippingMethod.id}
                        checked={selectedValue === shippingMethod.id}
                        onChange={handleChange}
                        tabIndex={idx === 0 ? 0 : -1}
                      />
                      {
                        <span className="text-black">{shippingMethod.displayName}</span>
                      } {
                        shippingMethod.price <= 0 ? 
                          <span className="text-black">({t("Common.Free")})</span>
                          :
                          <span>(<Price currency={cartInfo?.currency} culture={currencyToLocale[cartInfo?.currency ?? DEFAULT_CURRENCY_CODE]} price={shippingMethod.price.toString()}/>)</span>
                      }
                    </label>
                  )
              })
            }
        </div>
      </div>
  )
}

ShippingOptions.defaultProps = {}

export default ShippingOptions
