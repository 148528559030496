import SharedImage from '@/components/shared/image/shared-image'
import { ContactUsHeroProps } from '../types/contact-types'

function ContactUsHero(props: Readonly<ContactUsHeroProps>) {
  const { heading, subheading, heroImage } = props

  return (
    <div className="container">
      <div className="flex flex-col md:justify-between md:items-center md:flex-row space-y-reverse space-y-4 md:space-y-0 md:space-x-8">
        <div className="order-1 md:order-none w-full md:w-5/12 flex items-center">
          {(heading || subheading) && (
            <div className="flex flex-col space-y-4 md:space-y-12">
              {heading && (
                <div
                  className="text-3xl lg:text-6xl text-grey-dark font-bold"
                  dangerouslySetInnerHTML={{ __html: heading }}
                ></div>
              )}
              {subheading && (
                <div className="flex">
                  <div
                    className="text-base md:text-xl text-grey-light prose md:prose-xl prose-headings:black prose-a:text-dark-blue-primary hover:prose-a:underline !max-w-none"
                    dangerouslySetInnerHTML={{ __html: subheading }}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        {heroImage && (
          <div className="order-0 md:order-none w-full md:w-1/2">
            <div className="w-full aspect-[3/2]">
              <SharedImage
                {...heroImage}
                className="w-full h-full object-cover rounded-lg"
                disableLazy={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default ContactUsHero
