import SharedImage from '@/components/shared/image/shared-image'
import { BlogItemFeaturedProp } from '@/types/shared'

const BlogItemFeatured = (props: Readonly<BlogItemFeaturedProp>) => {
  return (
    <a
      href={props.url}
      className="relative block text-grey-dark hover:text-grey-dark"
    >
      {props.image && (
        <SharedImage
          className="aspect-[16/11] object-cover rounded-lg w-full"
          {...props.image}
        />
      )}

      {props.featuredLabelText && (
        <span className="absolute top-[18px] left-[18px] bg-white rounded-lg text-xs uppercase px-2 py-1 tracking-[1.2px] font-bold shadow-sm">
          {props.featuredLabelText}
        </span>
      )}

      <div className="py-3 px-2 md:p-8 absolute bottom-0 rounded-lg w-full backdrop-blur-xl">
        <h4 className="mb-2 text-base lg:text-3xl lg:mt-4 font-bold">
          {props.title}
        </h4>
        <p className="mb-3 lg:text-xl">{props.description}</p>
      </div>
    </a>
  )
}

export default BlogItemFeatured
