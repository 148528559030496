import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import DownloadBtn from '@/components/shared/download-btn/download-btn'
import SharedRTF from '@/components/shared/rtf/shared-rtf'
import BlockComponentSelector from '@/epi/block-component-selector'
import LegislationHero from './components/legislation-hero'
import { LegislationDetailProps } from './types/legislation-detail-types'

const CaseStudyDetailPage = (props: Readonly<LegislationDetailProps>) => {
  const { model } = props

  return (
    <>
      <Breadcrumbs />
      {model.hero && (
        <LegislationHero {...model.hero} publishDate={model.publishDate} />
      )}
      <div className="flex flex-col space-y-[72px] lg:space-y-32">
        <div className="container flex justify-center">
          <div className="w-full lg:w-2/3 flex flex-col space-y-14 lg:space-y-[72px]">
            <div className="flex flex-col space-y-6 md:space-y-8">
              {model.mainBody && <SharedRTF html={model.mainBody} />}
              {model.fileDownload?.map((item, index) => (
                <DownloadBtn
                  key={index}
                  href={item.href}
                  text={item.text}
                  type={item.type}
                  extension={item.extension}
                />
              ))}
            </div>
            {model.pageContent?.map((blockModel, i) => (
              <BlockComponentSelector
                {...blockModel}
                hideContainerClass={true}
                key={`pageContent-${i}`}
              />
            ))}
          </div>
        </div>
        {model.fullWidthPageContent && (
          <div className="flex flex-col space-y-[72px] lg:space-y-32">
            {model.fullWidthPageContent.map((blockModel, i) => (
              <BlockComponentSelector
                {...blockModel}
                key={`fullWidthPageContent-${i}`}
              />
            ))}
          </div>
        )}
      </div>
    </>
  )
}
export default CaseStudyDetailPage
