import { RESULTCODES, epiApiPost, epiGetPaymentMethods } from '@/api/content-delivery'
import { CartModel, PaymentMethodModel } from '@/api/types/content-delivery-types'
import { addPaymentInfoGTM } from '@/components/shared/analytics/gtm-manager'
import { FormCheckbox } from '@/components/shared/form-input/form-checkbox'
import { FormInput } from '@/components/shared/form-input/form-input'
import { SYSTEMKEYWORD_DUMMY, SYSTEMKEYWORD_PAYFABRIC, SYSTEMKEYWORD_PAYONACC, useCheckoutStore } from '@/stores/checkout-store'
import { useGlobalSettings } from '@/stores/global-settings'
import { useCartStore } from '@/stores/useCartStore'
import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { creditCardMask } from '../../masks/credit-card-mask'
import { cvvMask } from '../../masks/cvv-mask'
import { expirationDateMask } from '../../masks/expiration-date-mask'
import { CheckoutStepThreeFormInputs } from '../../types/checkout-types'
import { StepThreeProps } from '../../types/step-three-props'
import { AddressDisplay } from '../address-display'
import { checkExpirationDate } from '@/api/validation'
import { useUserStore } from '@/stores/useUserStore'
import { DEFAULT_MARKET } from '@/lib/constants'
import { useShippingSameAsBilling } from '@/stores/useShippingSameAsBilling'
import { useLocationsStore } from '@/stores/locations-store'

export const ReviewStep: React.FC<Readonly<StepThreeProps>> = (props: Readonly<StepThreeProps>) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodModel | null>(null)
  const {
    selectedCountry
  } = useLocationsStore()
  const {
    setStepThreeFormSubmitting,
    setStep
  } = props

  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors },
  } = useForm<CheckoutStepThreeFormInputs>()
  const { setShippingSameAsBilling } = useShippingSameAsBilling()
  const [paymentMethods, setPaymentMethods] = useState<
    PaymentMethodModel[] | null
  >(null)

  const { t: checkout } = useTranslation('', { keyPrefix: 'CheckoutReviewStep' })
  const { t } = useTranslation()
  const {globalSettings} = useGlobalSettings()
  const checkoutStore = useCheckoutStore()
  const { getFullCart, cartData } = useCartStore()
  const { user } = useUserStore()
  const [fullCart, setFullCart] = useState<CartModel | null>(null)
  const purchaseOrderAttestation = watch('purchaseOrderAttestation', false)
  const purchaseOrderLater = watch('purchaseOrderLater', false)

  useEffect(() => {
    const fetchData = async () => {
      const cart = await getFullCart()
      setFullCart(cart)
    }
    fetchData()
  }, [])

  const renderShippingAddress = () =>
    fullCart?.shipments &&
    fullCart.shipments.length > 0 && (
      <div className='flex-[50%] lg:flex-1'>
        <div className="font-bold text-grey-dark mb-2">{checkout('ShippingDetailsTitle')}</div>
        <AddressDisplay addressModel={fullCart.shipments[0].shippingAddress} />
      </div>
    )

  const renderBillingAddress = () =>
    fullCart?.payments &&
    fullCart.payments.length > 0 && (
      <div className='flex-[50%] lg:flex-1'>
        <div className="font-bold text-grey-dark mb-2">{checkout('BillingDetailsTitle')}</div>
        <AddressDisplay addressModel={fullCart?.payments[0].billingAddress} />
      </div>
    )

    useEffect(() => {
      const fetchData = async () => {
        const methods = await epiGetPaymentMethods(selectedCountry?.location?.cultureCode ?? "en")
        const methodsExceptDummy = methods?.filter(m => m.systemKeyword !== SYSTEMKEYWORD_DUMMY)
        setPaymentMethods(methodsExceptDummy ?? null)
      }
      fetchData()
    }, [])

    
  const handleRadioChange = (paymentId: string) => {
    const paymentMethod = paymentMethods?.find(x => x.paymentMethodId === paymentId)
    paymentMethod && setSelectedPaymentMethod(paymentMethod)
  }

  const onSubmitStepThree: SubmitHandler<Partial<CheckoutStepThreeFormInputs>> = async (data, e) => {
    if (!selectedPaymentMethod || !data) return

    if(purchaseOrderLater && !purchaseOrderAttestation)
    {
     setError("purchaseOrderAttestation", {message: ""})
     return
    }
    setStepThreeFormSubmitting(true)
    const poNumber = purchaseOrderLater ? 'Not Provided' : data.purchaseOrderNumber
    const result = await checkoutStore.placeOrder(
      user?.authInfo?.market ?? DEFAULT_MARKET,
      {
      paymentType: selectedPaymentMethod.systemKeyword,
      purchaseOrderNumber: poNumber,
      cardInfo: {
        ccn: data.creditCardNumber ?? '',
        cvv: data.cvv ?? '',
        month: Number(data.expirationDate?.split('/')?.[0]),
        year: Number(data.expirationDate?.split('/')?.[1]),
        name: `${data.firstName} ${data?.lastName}`?.trim(),
      },
    })

    addPaymentInfoGTM(cartData, selectedPaymentMethod)

    if (result.code === RESULTCODES.SUCCESS) {
      const order = result.order
      if (!order) return
      await epiApiPost<{ name: string }>('SendEmail', { trackingNumber: order.orderNumber })
      const orderConfirmationUrl = globalSettings?.orderConfirmationPageUrl
      if (!orderConfirmationUrl) return
      setShippingSameAsBilling(false)
      setStepThreeFormSubmitting(false)
      window.location.href = orderConfirmationUrl + '?id=' + order.orderNumber
      return
    }

    let errorMessage = checkout('GenericError')

    if (result.code === RESULTCODES.CARTVALIDATION_ERROR) {
      errorMessage = checkout('CartError')
    } else if (result.code === RESULTCODES.CARTSTOCK_ERROR) {
      errorMessage = checkout('UnavailableError')
    } else if (result.code === RESULTCODES.PAYMENT_ERROR) {
      if (selectedPaymentMethod?.systemKeyword === SYSTEMKEYWORD_PAYFABRIC) {
        errorMessage = checkout('PaymentError')
      } else if (selectedPaymentMethod?.systemKeyword === SYSTEMKEYWORD_PAYONACC) {
        errorMessage = checkout('PayOnAccountError')
      }
    }

    toast(
      <div className="flex flex-col gap-2">
        <span className="text-red-600">{errorMessage}</span>
      </div>,
      { position: 'top-right' },
    )
    setShippingSameAsBilling(false)
    setStepThreeFormSubmitting(false)
  }

  return (
    <>
        <form
          id="step-three-form"
          className="CommerceForms EPiServerForms flex flex-col p-0"
          onSubmit={handleSubmit(onSubmitStepThree)}
        >
          <div className='border border-grey-400 border-opacity-30 mb-[55px]' >
            {paymentMethods?.map((paymentMethod, idx) => {
              let styles: any;
              const isLastItem = idx === paymentMethods.length - 1

              if (!isLastItem && paymentMethod.systemKeyword !== selectedPaymentMethod?.systemKeyword)
              {
                styles = {borderBottom: "1px solid rgba(157, 166, 172, .33)"}
              }
              
              if (isLastItem && selectedPaymentMethod?.systemKeyword && selectedPaymentMethod?.systemKeyword !== paymentMethod.systemKeyword)
              {
                styles = {borderTop: "1px solid rgba(157, 166, 172, .33)"}
              }
              
              return (
                <div key={paymentMethod.paymentMethodId} className='flex flex-row'>
                  <div className='flex flex-col flex-[100%]'>
                    <label 
                      className={`text-black flex flex-row items-center gap-2 p-[24px]`}
                      style={styles}
                    >
                      <input
                        type="radio"
                        key={paymentMethod.paymentMethodId}
                        value={paymentMethod.paymentMethodId}
                        checked={selectedPaymentMethod?.paymentMethodId === paymentMethod.paymentMethodId}
                        onChange={(e) => handleRadioChange(e.target.value)}
                      />
                        <span className="text-black">{paymentMethod.name}</span>
                    </label>

                    {selectedPaymentMethod?.systemKeyword === SYSTEMKEYWORD_PAYFABRIC && paymentMethod?.systemKeyword === SYSTEMKEYWORD_PAYFABRIC && (
                      <div className='flex-col flex p-[24px] gap-[22px]'>
                        <FormInput
                          label={checkout('FirstName.Label')}
                          labelHasAsterisk={true}
                          placeholder={""}
                          register={register('firstName', {
                            required: checkout('FirstName.Error.Required'),
                          })}
                          errors={errors}
                        />

                        <FormInput
                          label={checkout('LastName.Label')}
                          placeholder={""}
                          labelHasAsterisk={true}
                          register={register('lastName', {
                            required: checkout('LastName.Error.Required'),
                          })}
                          errors={errors}
                        />

                        <div className="flex flex-col">
                          <div className={`flex flex-col gap-3 ${errors.creditCardNumber ? 'ValidationFail' : ''}`}>
                            <label className={"font-bold text-black flex-1"} htmlFor="creditCardNumber">{checkout('CreditCardNumber.Label')} *</label>
                            <Controller
                                name="creditCardNumber"
                                control={control}
                                rules={{
                                    required: checkout('CreditCardNumber.Error.Required'),
                                    pattern: {
                                      value: /\b(?:\d{4}[ -]?){3}(?=\d{3,4}\b)(?:\d{3,4})/,
                                      message: checkout('CreditCardNumber.Error.Format'),
                                    },
                                }}
                                render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className={`flex-[50%]`}
                                    onChange={(e) => {
                                        const maskedValue = creditCardMask(e.target.value);
                                        field.onChange(maskedValue);
                                    }}
                                />
                                )}
                            />
                              <div className="flex flex-col gap-3">
                                  {errors?.creditCardNumber && <span className="text-red-600 flex-[50%]">{errors.creditCardNumber.message}</span>}
                              </div>   
                          </div>
                        </div>

                        <div className="flex flex-col">
                          <div className={`flex flex-col gap-3 ${errors.expirationDate ? 'ValidationFail' : ''}`}>
                            <label className={"font-bold text-black flex-1"} htmlFor="expirationDate">{checkout('ExpirationDate.Label')} *</label>
                            <span>{t("Common.DateFormat")}</span>
                            <Controller
                                name="expirationDate"
                                control={control}
                                
                                rules={{
                                  validate: (value) => checkExpirationDate(value, {
                                    invalidDateText: checkout('ExpirationDate.Error.InvalidDate'),
                                    invalidMonthText: checkout('ExpirationDate.Error.InvalidMonthRange')
                                  }),
                                  required: checkout('ExpirationDate.Error.Required'),
                                  pattern: {
                                    value: /(\d{2})\/(\d{2})/,
                                    message: checkout('ExpirationDate.Error.Format'),
                                  },
                                }}
                                render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className={`flex-[50%]`}
                                    onChange={(e) => {
                                        const maskedValue = expirationDateMask(e.target.value);
                                        field.onChange(maskedValue);
                                    }}
                                />
                                )}
                            />
                              <div className="flex flex-col gap-3">
                                  {errors?.expirationDate && <span className="text-red-600 flex-[50%]">{errors.expirationDate.message}</span>}
                              </div>   
                          </div>
                        </div>

                        <div className="flex flex-col">
                          <div className={`flex flex-col gap-3 ${errors.cvv ? 'ValidationFail' : ''}`}>
                            <label className={"font-bold text-black flex-1"} htmlFor="cvv">{checkout('Cvv.Label')} *</label>
                            <Controller
                                name="cvv"
                                control={control}
                                rules={{
                                    required: checkout('Cvv.Error.Required'),
                                    pattern: {
                                      value: /(\d{0,4})/,
                                      message: checkout('Cvv.Error.Format'),
                                    },
                                }}
                                render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    className={`flex-[50%]`}
                                    onChange={(e) => {
                                        const maskedValue = cvvMask(e.target.value);
                                        field.onChange(maskedValue);
                                    }}
                                />
                                )}
                            />
                              <div className="flex flex-col gap-3">
                                  {errors?.cvv && <span className="text-red-600 flex-[50%]">{errors.cvv.message}</span>}
                              </div>   
                          </div>
                        </div>

                      </div>
                    )}

                    {selectedPaymentMethod?.systemKeyword === SYSTEMKEYWORD_PAYONACC && paymentMethod?.systemKeyword === SYSTEMKEYWORD_PAYONACC && (
                      <div className='flex flex-col gap-[22px] p-[24px]'>
                        <FormInput
                          label={checkout('PurchaseOrder.Label')}
                          placeholder={""}
                          labelHasAsterisk={true}
                          disabled={purchaseOrderLater}
                          register={register('purchaseOrderNumber', {
                            required: purchaseOrderLater ? false : checkout('PurchaseOrder.Error.Required'),
                          })}
                          errors={errors}
                        />
                        <div className={`flex flex-col ${errors.purchaseOrderAttestation ? 'ValidationFail' : ''}`}>
                          <FormCheckbox label={checkout('PurchaseOrderAttestation.Label')}
                            labelHasAsterisk={true}
                            register={register('purchaseOrderAttestation', {
                              required: purchaseOrderLater ? false : checkout('PurchaseOrderError'),
                            })}
                          />
                          <div className="flex flex-col gap-3">
                            {errors?.purchaseOrderAttestation && <span className="mt-n1 text-red-600 flex-[50%] mb-2">{checkout('PurchaseOrderError')}</span>}
                          </div>
                          <FormCheckbox label={checkout('PurchaseOrder.Later')}
                            labelHasAsterisk={true}
                            register={register('purchaseOrderLater', {
                              required: false,
                            })}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>)
              })}
          </div>

          <div className="flex flex-col justify-start items-start mb-[8px]">
              <button className='text-left bg-transparent text-dark-blue-400 border-transparent
                hover:text-dark-blue-primary
                acitve:text-dark-blue-primary
                disabled:text-grey-400 disabled:cursor-not-allowed' onClick={(e) => {setStep(1)}}>
                  <span>{t("AddressBook.Edit")}</span>
              </button>
          </div>

          <div className="flex flex-col gap-8 lg:flex-row justify-start align-top">
            {renderShippingAddress()}
            {renderBillingAddress()}
          </div>

        </form>
    </>
  )
}
