import SectionHeader from '@/components/shared/section-header'
import BlockComponentSelector from '@/epi/block-component-selector'
import { LayoutBlockProps } from './types/layout-types'

function LayoutBlock(props: Readonly<LayoutBlockProps>) {
  const { model, hideContainerClass } = props

  return (
    <div className={!hideContainerClass ? 'container' : ''}>
    {model.heading || model.subheading || model.smallIntro? (
      <SectionHeader
        heading={model.heading}
        subheading={model.subheading}
        smallIntro={model.smallIntro}
          isLayoutBlock={true}
      />
      ) : (<div></div>)}
      <div className="grid grid-cols-12 gap-4 md:gap-8">
        {model.content?.map((item, i) => (
          <BlockComponentSelector
            {...item}
            hideContainerClass={true}
            key={`content-${i}`}
          />
        ))}
      </div>
    </div>
  )
}

export default LayoutBlock
