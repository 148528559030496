import { useFindTrainingStore } from '@/stores/find-training-store'
import { uid } from 'uid'
import {
  QuestionFourItemProps,
  QuestionOneAnswerItemProps,
} from '../types/find-training-types'
import AnswersList from './answers-list'
import QuestionElement from './question-element'
import QuizBtnLink from './quiz-btn-link'

const QuestionFour = (props: QuestionFourItemProps) => {
  const { question, answerOneLink, answerTwoLink } = props
  const { activeStep } = useFindTrainingStore()

  const answers: QuestionOneAnswerItemProps[] = [
    { answerLink: answerOneLink },
    { answerLink: answerTwoLink },
  ]
  return (
    <div
      className={`flex flex-col space-y-8 md:space-y-12 items-center shared-transition ${
        activeStep === 4 ? 'opacity-1' : 'invisible sr-only opacity-0'
      }`}
    >
      {question && <QuestionElement question={question} />}
      {answers && (
        <AnswersList>
          {answers.map((answer) => (
            <div key={uid()} className="w-full">
              {answer.answerLink && <QuizBtnLink link={answer.answerLink} />}
            </div>
          ))}
        </AnswersList>
      )}
    </div>
  )
}

export default QuestionFour
