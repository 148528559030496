import React from 'react';
import Popover from './popover';

interface PopoverTriggerProps {
    popoverChildren: React.ReactNode
    buttonChildren: React.ReactNode
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PopoverTrigger: React.FC<PopoverTriggerProps> = ({isOpen, setIsOpen, popoverChildren, buttonChildren }) => {

 const togglePopover = () => {
    setIsOpen(!isOpen);
 }

 return (
    <div>
      <button onClick={togglePopover} className="focus:outline-none text-dark-blue-400 border-transparent hover:text-dark-blue-primary active:text-dark-blue-primary disabled:text-grey-400 disabled:cursor-not-allowed">
        {buttonChildren}
      </button>
      <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="max-h-60 overflow-y-auto">
          {popoverChildren}
        </div>
      </Popover>
    </div>
 );
};

export default PopoverTrigger;