import {
  CardItemProps,
  IndexCategoryItemProp,
} from '@/components/shared/index/types/index-types'
import axios from 'axios'
import { create } from 'zustand'

interface IndexStoreProps {
  pageUrl: string
  page: number
  pageSize: number
  categories?: IndexCategoryItemProp[]
  selectedCategories: number[]
  selectedState: string
  isLoading: boolean
  moreItems: boolean
  activeTabIndex: number
  cards: CardItemProps[]
  setPageUrl: (url: string) => void
  setActiveTabIndex: (index: number) => void
  setCards: (data: []) => void
  setPage: (newPage: number) => void
  setIsLoading: (value: boolean) => void
  setMoreItems: (value: boolean) => void
  setPageSize: (value: number) => void
  setSelectedState: (name: string) => void
  resetCards: () => void
  resetSelectedCategories: () => void
  resetPage: () => void
  resetState: () => void
  updateSelectedCategories: (id: number) => void
  fetchResults: () => Promise<void>
}

export const useIndexPageStore = create<IndexStoreProps>((set, get) => ({
  pageUrl: '',
  page: 1,
  pageSize: 0,
  categories: [],
  selectedCategories: [],
  selectedState: '',
  isLoading: true,
  moreItems: false,
  activeTabIndex: 0,
  cards: [],
  setPageUrl: (url: string) => set(() => ({ pageUrl: url })),
  setCards: (data: []) =>
    set((state) => ({ cards: [...state.cards, ...data] })),
  setPage: (newPage: number) => set(() => ({ page: newPage })),
  setSelectedState: (name: string) => set(() => ({ selectedState: name })),
  setIsLoading: (value: boolean) => set(() => ({ isLoading: value })),
  setMoreItems: (value: boolean) => set(() => ({ moreItems: value })),
  setPageSize: (value: number) => set(() => ({ pageSize: value })),
  setActiveTabIndex: (index: number) => set(() => ({ activeTabIndex: index })),
  resetCards: () => set(() => ({ cards: [] })),
  resetSelectedCategories: () => set(() => ({ selectedCategories: [] })),
  resetPage: () => {
    get().setPage(1)
    get().resetCards()
  },
  updateSelectedCategories: (id: number) => {
    const index = get().selectedCategories?.findIndex(
      (categoryId) => categoryId === id,
    )
    if (index !== -1) {
      get().selectedCategories.splice(index, 1)
    } else {
      get().selectedCategories.push(id)
    }
  },
  resetState: () => {
    get().setActiveTabIndex(0)
    get().resetSelectedCategories()
    get().resetPage()
  },
  fetchResults: async () => {
    get().setIsLoading(true)

    await axios
      .post(
        get().pageUrl,
        {
          page: get().page,
          pageSize: get().pageSize,
          categories: get().selectedCategories,
        },
        {
          headers: { Accept: '*/*' },
        },
      )
      .then((response) => {
        if (response.data.cards) {
          get().setCards(response.data.cards)
          get().setMoreItems(response.data.hasMore)
        }

        get().setIsLoading(false)
      })
      .catch((error) => {
        console.log(error)
        get().setIsLoading(false)
      })
  },
}))
