import { usePrimaryMenuMobileStore } from '@/stores/primary-menu-mobile'
import { ArrowRightIcon, ArrowUturnLeftIcon } from '@heroicons/react/24/solid'
import SharedLink from '../../buttons/shared-link'
import { PrimaryMenuNavItem } from '../types/header'
import MobileNavSubItem from './mobile-nav-sub-item'

interface PrimaryMenuNavItemMobileProps extends PrimaryMenuNavItem {
  backToMenuText?: string
  parentIndex: number
}

const MobileNavItem = (props: Readonly<PrimaryMenuNavItemMobileProps>) => {
  const { model, backToMenuText, parentIndex } = props
  const { openMenuIndexItem, setOpenMenuIndexItem, resetOpenMenuIndexItem } =
    usePrimaryMenuMobileStore()

  return (
    <li className="relative">
      {model.columns && model.columns.length > 0 ? (
        <>
          <button
            className="flex items-center justify-between space-x-2 p-2 w-full text-black"
            onClick={() => setOpenMenuIndexItem(parentIndex)}
          >
            <span className="text-left">
              {model.primaryLink ? model.primaryLink.text : model.title}
            </span>
            <span>
              <ArrowRightIcon className="h-5 w-5 text-black" />
            </span>
          </button>
          <div
            className={`fixed z-10 top-[57px] left-0 right-0 bottom-0 bg-white shared-transition py-[14px] overflow-auto flex flex-col justify-between ${
              openMenuIndexItem === parentIndex
                ? 'translate-x-0 visible'
                : 'translate-x-full invisible'
            }`}
          >
            <div className="container flex flex-col space-y-2">
              <button
                className="flex items-center space-x-2 p-2 text-black"
                onClick={resetOpenMenuIndexItem}
              >
                <span>
                  <ArrowUturnLeftIcon className="h-5 w-5 text-grey-dark" />
                </span>
                <span>{backToMenuText}</span>
              </button>
              <ul className="flex flex-col space-y-2">
                {model.primaryLink && (
                  <li>
                    <SharedLink
                      {...model.primaryLink}
                      className="hover:no-underline flex items-center justify-between space-x-2 py-2 px-1 w-full text-black hover:text-black"
                    >
                      <span className="text-left">
                        {model.primaryLink.text}
                      </span>
                      <span>
                        <ArrowRightIcon className="h-5 w-5 text-black" />
                      </span>
                    </SharedLink>
                  </li>
                )}
                {model.columns.map((item, j) => (
                  <li key={j}>
                    {item.model.submenu && item.model.submenu.length > 0 ? (
                      <MobileNavSubItem
                        {...item}
                        columnIndex={j}
                        parentMenuIsOpen={openMenuIndexItem === parentIndex}
                      />
                    ) : (
                      <>
                        {item.model.link && (
                          <SharedLink
                            {...item.model.link}
                            className="hover:no-underline flex items-center space-x-3 pt-2.5 pb-2 px-1"
                          >
                            <span className="flex-1 flex flex-col">
                              {item.model.link?.text && (
                                <span className="text-grey-dark font-bold text-sm">
                                  {item.model.link.text}
                                </span>
                              )}
                              {item.model.description && (
                                <span className="text-grey-light text-sm">
                                  {item.model.description}
                                </span>
                              )}
                            </span>
                            <span className="w-5 h-5">
                              <ArrowRightIcon className="h-5 w-5 text-black" />
                            </span>
                          </SharedLink>
                        )}
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            {model.calloutLink && (
              <div>
                <SharedLink
                  {...model.calloutLink}
                  className="group w-full flex space-x-2 justify-between p-3 hover:no-underline bg-dark-blue-100 hover:bg-dark-blue-primary"
                >
                  <span className="text-black group-hover:text-white shared-transition">
                    {model.calloutLink.text}
                  </span>
                  <span className="flex">
                    <ArrowRightIcon className="text-[#0F172A] group-hover:text-white h-5 w-5 shared-transition" />
                  </span>
                </SharedLink>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {model.primaryLink && (
            <SharedLink
              {...model.primaryLink}
              className="flex items-center justify-between space-x-2 p-2 w-full text-black"
            >
              <span className="text-left">{model.primaryLink.text}</span>
            </SharedLink>
          )}
        </>
      )}
    </li>
  )
}

export default MobileNavItem
