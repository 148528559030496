import CountrySelector from '@/components/shared/header/components/country-selector'
import { PhoneIcon } from '@heroicons/react/24/solid'
import { HeaderProps } from '../types/header'
import { BookOpenIcon, UserCircleIcon } from '@heroicons/react/24/solid'

function Utility(props: Readonly<HeaderProps>) {
  return (
    <div className="bg-grey-secondary-light p-2 rounded-br-lg rounded-bl-lg">
      <div className="flex justify-between space-x-4">
        <div className="flex items-center">
          {props.model.utilityNavigationBar?.phoneLink?.href && (
            <a
              href={props.model.utilityNavigationBar.phoneLink?.href}
              className="flex items-center space-x-2 text-sm font-medium"
              target={props.model.utilityNavigationBar.phoneLink?.target}
            >
              <span>
                <PhoneIcon className="fill-dark-blue-primary w-5 h-5" />
              </span>
              <span className='uppercase'>{props.model.utilityNavigationBar.phoneLink?.text}</span>
            </a>
          )}

          {props.model.utilityNavigationBar?.whatsLink?.href && (
            <a
              href={props.model.utilityNavigationBar.whatsLink?.href}
              className="flex items-center space-x-2 text-sm font-medium ml-6"
              target={props.model.utilityNavigationBar.whatsLink?.target}
            >
              <span>
                <img src="https://www.crisisprevention.com/globalassets/global-assets/icons/whatsapp-logo-primary.svg" alt="whats-app-logo-uae" className='w-5 h-5' />
              </span>
              <span className='uppercase'>{props.model.utilityNavigationBar.whatsLink?.text}</span>
            </a>
          )}
        </div>
        <div className="flex items-center space-x-1">
          <CountrySelector />
          {props.model.utilityNavigationBar?.cpiLearningLink?.href && (
            <a
              href={props.model.utilityNavigationBar.cpiLearningLink?.href}
              className="text-sm flex items-center space-x-2 py-1 pl-0 2xl:pl-3 pr-3 text-dark-blue-primary"
              target={props.model.utilityNavigationBar.cpiLearningLink?.target}
            >
              <span className='w-5 h-5'>
                <BookOpenIcon className="w-5 h-5 text-dark-blue-primary" />
              </span>
              <span className='uppercase'>{props.model.utilityNavigationBar.cpiLearningLink?.text}</span>
            </a>
          )}
          {props.model.utilityNavigationBar?.myAccountLink?.href && (
            <a
              href={props.model.utilityNavigationBar.myAccountLink?.href}
              className="text-sm flex items-center space-x-2 py-1 pl-0 2xl:pl-3 pr-3 text-dark-blue-primary"
              target={props.model.utilityNavigationBar.myAccountLink?.target}
            >
              <span className='w-5 h-5'>
                <UserCircleIcon className="w-5 h-5 text-dark-blue-primary" />
              </span>
              <span className='uppercase'>{props.model.utilityNavigationBar.myAccountLink?.text}</span>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default Utility
