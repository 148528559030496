import { MaskFunction } from '../types/mask-type'

export const expirationDateMask: MaskFunction = (value: string = ''): string => {
  const expirationDatePattern = /(\d{0,2})(\d{0,2})/
  const dateValue = value?.replace(/\D/g, '')?.match(expirationDatePattern)

  if (dateValue) {
    const formattedDate = dateValue[1] ? dateValue[1] : ''
    const formattedMonth = dateValue[2] ? `/${dateValue[2]}` : ''

    value = `${formattedDate}${formattedMonth}`
  }

  return value
}

