import SharedLink from '@/components/shared/buttons/shared-link'
import SharedImage from '@/components/shared/image/shared-image'
import Modal from '@/components/shared/modal/shared-modal'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { Image16To9HeroBlockProps } from './types/image-16to9-hero-block-types'
import MediaContent from '@/components/blocks/featured-media-block/components/media-content'
import { useMediaStore } from '@/stores/media-store'
import { useState } from 'react'

const Image16To9HeroBlock: React.FC<Image16To9HeroBlockProps> = ({
  className,
  hideContainerClass,
  model,
}) => {
  const classNames = [
    !hideContainerClass ? 'container' : '',
    className,
    'grid grid-cols-1 md:grid-cols-2 gap-8',
  ]
    .filter((i) => i !== undefined && i !== '')
    .join(' ')

  const [showModal, setShowModal] = useState(false)
  const { isMobile } = useMediaStore()
  return (
    <section className={classNames}>
      <div className="md:order-1">
        {(model.desktopImage || model.video) && (
          <div className='md:order-1 w-full rounded-lg aspect-[16/9]'>
            <MediaContent
              type={model.video ? 'Video' : 'Image'}
              image={!isMobile ? model.desktopImage : model.mobileImage}
              preloadImage={true}
              video={model.video}
              setShowModal={setShowModal}
          />
          </div>
        )}
        {model.video && (
          <Modal
            transparentBg={true}
            closeButtonLabel="close"
            showModal={showModal}
            onClose={() => setShowModal(false)}
          >
            <iframe
              src={`https://www.youtube.com/embed/${model.video}`}
              title="YouTube video player"
              className="w-full lg:w-[832px] aspect-video lg:m-32"
              allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </Modal>
        )}
      </div>

      <div className="flex flex-col justify-center space-y-4 lg:space-y-8">
        {model.heading && (
          <div
            className="text-3xl lg:text-6xl font-bold text-grey-dark"
            dangerouslySetInnerHTML={{ __html: model.heading }}
          />
        )}
        {model.subheading && (
          <div
            className="lg:text-xl text-grey-light"
            dangerouslySetInnerHTML={{ __html: model.subheading }}
          />
        )}
        <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2">
          {model.primaryCtaButton && (
            <SharedLink
              style={model.primaryCtaButton.buttonStyle}
              {...model.primaryCtaButton}
              className="w-full md:w-auto"
            >
              <span>{model.primaryCtaButton.text}</span>
              <span>
                <ArrowRightIcon className="text-blue-primary ml-2 h-5 w-5" />
              </span>
            </SharedLink>
          )}

          {model.secondaryCtaButton && (
            <SharedLink
              style="outline"
              {...model.secondaryCtaButton}
              className="w-full md:w-auto"
            >
              <span>{model.secondaryCtaButton.text}</span>
              <span>
                <ArrowRightIcon className="text-blue-primary ml-2 h-5 w-5" />
              </span>
            </SharedLink>
          )}
        </div>
      </div>
    </section>
  )
}

export default Image16To9HeroBlock
