import SharedLink from '@/components/shared/buttons/shared-link'
import { uid } from 'uid'
import { ContactTollFreeProps } from '../types/contact-types'
import ContactTitle from './contact-title'

const ContactTollFree = (props: Readonly<ContactTollFreeProps>) => {
  const { header, list } = props

  return (
    <div className="col-span-1 flex flex-col space-y-4">
      {header && <ContactTitle header={header} />}
      {list && list.length > 0 && (
        <div className="flex flex-col space-y-2 pl-3">
          {list.map((item) => (
            <div key={uid()} className="flex space-x-3">
              {item.number && (
                <SharedLink {...item.number} className="min-w-[110px]">
                  {item.number.text}
                </SharedLink>
              )}
              {item.region && (
                <div className="text-grey-light">{item.region}</div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ContactTollFree
