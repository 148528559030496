import HomeHero from '@/components/pages/home/components/home-hero'
import BlockComponentSelector from '@/epi/block-component-selector'
import { HomePageProps } from './types/home'

const HomePage = (props: Readonly<HomePageProps>) => {
  const { model } = props
  return (
    <>
      {props.model.hero && <HomeHero {...props.model.hero} />}
      <div className="flex flex-col space-y-14 md:space-y-20">
        {model.pageContent?.map((blockModel, i) => (
          <BlockComponentSelector {...blockModel} key={`pageContent-${i}`} />
        ))}
      </div>
    </>
  )
}
export default HomePage
