import { IconProps } from './types/icon-types'

const FacebookIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      width="12"
      height="24"
      viewBox="0 0 12 24"
      fill="#859097"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M7.789 23.997V13.052H11.3269L11.8566 8.78652H7.789V6.06316C7.789 4.82824 8.1192 3.98658 9.8248 3.98658L12 3.98553V0.170579C11.6236 0.118786 10.3325 0.00268555 8.8304 0.00268555C5.6942 0.00268555 3.54712 1.9904 3.54712 5.64094V8.78664H0V13.0521H3.54701V23.9971L7.789 23.997Z" />
    </svg>
  )
}

export default FacebookIcon
