import { MultiColumnTextBlockProps } from '@/components/blocks/multi-column-text/types/multi-column-text-types'
import SharedLink from '@/components/shared/buttons/shared-link'
import SectionHeader from '@/components/shared/section-header'
import { ArrowRightIcon } from '@heroicons/react/24/solid'

const MultiColumnTextBlock = (props: Readonly<MultiColumnTextBlockProps>) => {
  const { headline, subheadline, smallIntro, columns } = props.model
  return (
    <section
      className={`
     ${!props.hideContainerClass ? 'container' : ''}
        ${props.className}`}
    >
      <div className="w-full">
        {(headline || smallIntro || subheadline) && (
          <SectionHeader
            heading={headline}
            smallIntro={smallIntro}
            subheading={subheadline}
          />
        )}

        <div className="flex flex-col md:flex-row gap-8 md:gap-16">
          {columns.map((column, i) => {
            return column.headline || column.subheadline || column.link ? (
              <div key={i} className="md:w-1/3">
                {column.headline && (
                  <div className="font-bold text-xl md:text-2xl text-grey-dark mb-4">
                    <span>{column.headline}</span>
                  </div>
                )}

                {column.subheadline && (
                  <div className="text-grey-light">
                    <span>{column.subheadline}</span>
                  </div>
                )}

                {column.link && (
                  <SharedLink
                    style={'text'}
                    {...column.link}
                    className="mt-[6px] !bg-transparent !pl-0"
                  >
                    {column.link.text}
                    <ArrowRightIcon className="h-4 w-4 ml-2" />
                  </SharedLink>
                )}
              </div>
            ) : null
          })}
        </div>
      </div>
    </section>
  )
}

export default MultiColumnTextBlock
