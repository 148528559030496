import { epiGetProduct } from '@/api/content-delivery'
import { PartialNullable } from '@/lib/partial-nullable.type'
import { EventItemParameters } from '../types/event-item-parameters.type'
import { ProductId } from '../types/product-id.type'

export const includeItemLanguage = async (item: PartialNullable<EventItemParameters>, productId: ProductId) => {
  if (!productId) return

  const product = await epiGetProduct(productId as string)

  if (!product) return

  item.item_language = product.language.name
}
