import SharedImage from '@/components/shared/image/shared-image'
import { useLocationsStore } from '@/stores/locations-store'
// @ts-ignore
import { DateTime } from 'luxon'
import { LegislationHeroProps } from '../types/legislation-detail-types'

function LegislationHero(props: Readonly<LegislationHeroProps>) {
  const { heading, subheading, publishDate, desktopImage, mobileImage } = props

  const { selectedCountry } = useLocationsStore()

  const formatDate = (date: string) => {
    const country = selectedCountry?.location?.cultureCode

    if (country) {
      return DateTime.fromISO(date)
        .setLocale(country)
        .toLocaleString(DateTime.DATE_FULL)
    } else {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL)
    }
  }

  return (
    <div className="container mb-14 md:mb-20">
      <div className="flex flex-col md:justify-between md:items-center md:flex-row space-y-reverse space-y-4 md:space-y-0 md:space-x-8">
        <div className="order-1 md:order-none w-full md:w-1/2 flex items-center">
          <div className="flex flex-col space-y-2 md:space-y-8">
            {heading && (
              <div
                className="text-4xl lg:text-5xl text-grey-dark font-bold"
                dangerouslySetInnerHTML={{ __html: heading }}
              ></div>
            )}
            {(subheading || publishDate) && (
              <div className="flex items-start space-x-1">
                {subheading && (
                  <div
                    className="text-base lg:text-lg text-grey-light"
                    dangerouslySetInnerHTML={{ __html: subheading }}
                  />
                )}
                {publishDate && (
                  <div
                    className={`flex items-center text-base lg:text-lg text-grey-light ${
                      subheading ? 'border-l border-text-grey-light pl-1' : ''
                    }`}
                  >
                    {formatDate(publishDate)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {desktopImage && (
          <div className="order-0 md:order-none w-full md:w-1/2">
            <div className="w-full aspect-[3/2]">
              <SharedImage
                {...desktopImage}
                mobileUrl={mobileImage?.url}
                className="w-full h-full object-cover rounded-lg"
                disableLazy={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default LegislationHero
