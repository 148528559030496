import { PrimaryMenuNavItem } from '@/components/shared/header/types/header'
import { create } from 'zustand'

interface HeaderPrimaryMenuProps {
  primaryMenu: PrimaryMenuNavItem[]
  activeMenuIndex: number
  activeSubMenuIndex: number
  remainingContainerSpace: number
  setRemainingContainerSpace: (value: number) => void
  updateActiveMenuIndex: (index: number) => void
  resetActiveMenuIndex: () => void
  updateActiveSubMenuIndex: (index: number) => void
  resetActiveSubMenuIndex: () => void
  incrementActiveSubMenuIndex: () => void
}

export const usePrimaryMenuStore = create<HeaderPrimaryMenuProps>(
  (set, get) => ({
    primaryMenu: [],
    activeMenuIndex: -1,
    activeSubMenuIndex: -1,
    remainingContainerSpace: 1248,
    setRemainingContainerSpace: (value: number) =>
      set(() => ({ remainingContainerSpace: value })),
    updateActiveMenuIndex: (index: number) =>
      set(() => ({ activeMenuIndex: index })),
    resetActiveMenuIndex: () => set(() => ({ activeMenuIndex: -1 })),
    updateActiveSubMenuIndex: (index: number) =>
      set(() => ({ activeSubMenuIndex: index })),
    resetActiveSubMenuIndex: () => set(() => ({ activeSubMenuIndex: -1 })),
    incrementActiveSubMenuIndex: () =>
      set(() => ({ activeSubMenuIndex: get().activeSubMenuIndex + 1 })),
  }),
)
