import SharedLink from '@/components/shared/buttons/shared-link'
import SharedRTF from '@/components/shared/rtf/shared-rtf'
import BlockComponentSelector from '@/epi/block-component-selector'
import { ErrorPageProps } from './types/error'

const ErrorPage = (props: Readonly<ErrorPageProps>) => {
  const { model } = props
  return (
    <div className="container mt-14 lg:mt-20">
      <div className="flex flex-col space-y-14 md:space-y-20">
        {(model.heading || model.subheading || model.mainBody) && (
          <div className="flex flex-col lg:flex-row lg:space-x-8 space-y-14 lg:space-y-0">
            {(model.heading || model.subheading) && (
              <div className="w-full lg:w-1/2 flex flex-col space-y-14 lg:space-y-20">
                <div className="flex flex-col space-y-4 md:space-y-6">
                  {model.heading && (
                    <div
                      className="font-mulish text-4xl lg:text-5xl text-grey-dark font-bold"
                      dangerouslySetInnerHTML={{ __html: model.heading }}
                    ></div>
                  )}
                  {model.subheading && (
                    <div
                      className="font-mulish text-xl md:text-2xl text-grey-light font-medium"
                      dangerouslySetInnerHTML={{ __html: model.subheading }}
                    ></div>
                  )}
                </div>
              </div>
            )}

            {model.mainBody && (
              <div className="w-full lg:w-1/2">
                <SharedRTF html={model.mainBody} />
              </div>
            )}
          </div>
        )}

        {model.pageContent && model.pageContent.length > 0 && (
          <div className="w-full">
            {model.pageContent.map((item, i) => (
              <BlockComponentSelector
                {...item}
                hideContainerClass={true}
                isChildBlock={true}
                key={`pageContent-${i}`}
              />
            ))}
          </div>
        )}

        {(model.ctaButtonOne || model.ctaButtonTwo) && (
          <div className="flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0">
            {model.ctaButtonOne && (
              <SharedLink
                {...model.ctaButtonOne}
                style="primary"
                size="small"
                className="w-full md:w-auto"
              >
                {model.ctaButtonOne.text}
              </SharedLink>
            )}
            {model.ctaButtonTwo && (
              <SharedLink
                {...model.ctaButtonTwo}
                style="outline"
                size="small"
                className="w-full md:w-auto"
              >
                {model.ctaButtonTwo.text}
              </SharedLink>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default ErrorPage
