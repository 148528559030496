import SharedLink from '@/components/shared/buttons/shared-link'
import Price from '@/components/shared/price/price'
import { currencyToLocale } from '@/components/shared/price/types/price-types'
import { RenderContentArea } from '@/epi/pageUtils/render-content-area'
import { DEFAULT_CURRENCY_CODE } from '@/lib/constants'
import { formatDateShort } from '@/lib/currency/formatting'
import { useGlobalSettings } from '@/stores/global-settings'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { ColumnDef, createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DesktopTable from './desktop-table'
import { useOrderHistoryTableAPI } from './hooks/useOrderHistoryAPI'
import { usePagination } from './hooks/usePagination'
import { useSorting } from './hooks/useSorting'
import MobileTable from './mobile-table'
import { OrderHistoryPageProps, TOrderHistory } from './types/order-history-types'
import withHydration from '@/lib/withHydration'

const columnHelper = createColumnHelper<TOrderHistory>()

const OrderHistoryListingPage = (props: Readonly<OrderHistoryPageProps>) => {
  const globalSettings = useGlobalSettings((s) => s.globalSettings);
  const initialPageSize = globalSettings?.orderHistoryPageSize
  const {t} = useTranslation()
  
  const {
    pageTitle,
    pageDescription,
    emptyContentArea,
    topContentArea,
    bottomContentArea,
  } = props.model

  const { limit, onPaginationChange, skip, pagination } = usePagination(initialPageSize);
  const { sorting, onSortingChange, field, order } = useSorting();
  const [customFilters, setCustomFilters] = useState<{ modifiedFrom?: string, modifiedTo?: string, statuses?: string[] | null } | null>(null)
  const { data, totalCount, loading: loadingNewRows } = useOrderHistoryTableAPI({
    pagination: { skip, limit },
    sort: { field, order },
    orderHistoryListingPageUrl: globalSettings?.orderHistoryListingPageUrl
  })

  const columns = useMemo<ColumnDef<TOrderHistory, any>[]>(
    () =>
      [
        columnHelper.accessor('orderId', {
          id: "orderId",
          header: () => <span className="text-black">{t('OrderHistory.TableColumn.OrderId')}</span>,
          cell: info => <a href={`${globalSettings?.orderDetailsPageUrl}?orderNumber=${info.getValue()}`}>{info.getValue()}</a>,
        }),
        columnHelper.accessor('date', {
          id: "date",
          header: () => <span className="text-black">{t('OrderHistory.TableColumn.Date')}</span>,
          cell: info => formatDateShort(info.getValue()),
        }),
        columnHelper.accessor('status', {
          header: () => <span className="text-black">{t('OrderHistory.TableColumn.Status')}</span>,
          cell: info => t(`OrderDetails.Status.${info.getValue()}`),
        }),
        columnHelper.accessor('organization', {
          header: () => <span className="text-black">{t('OrderHistory.TableColumn.Organization')}</span>,
          cell: info => info.getValue(),
        }),
        columnHelper.accessor('total', {
          header: () => <span className="text-black">{t('OrderHistory.TableColumn.Total')}</span>,
          cell: info => <Price culture={currencyToLocale[info.row.original.currency ?? DEFAULT_CURRENCY_CODE]} currency={info.row.original.currency ?? DEFAULT_CURRENCY_CODE} price={info.getValue()} />,
        }),
        columnHelper.accessor('viewOrder', {
          header: '',
          cell: info => {
            const orderId = info.row.original.orderId;
            return <a href={`${globalSettings?.orderDetailsPageUrl}?orderNumber=${orderId}`}>{info.getValue()}</a>;
          },
        })
      ], []
  )

  const columnsMobile = useMemo<ColumnDef<TOrderHistory, any>[]>(
    () =>
      [
        columnHelper.accessor('date', {
          id: "date",
          header: () => <span className="text-black">{t('OrderHistory.TableColumn.Date')}</span>,
          cell: info => formatDateShort(info.getValue()),
        }),
        columnHelper.accessor('total', {
          header: () => <span className="text-black">{t('OrderHistory.TableColumn.Total')}</span>,
          cell: info => <Price textClassName="text-black" culture={currencyToLocale[info.row.original.currency ?? DEFAULT_CURRENCY_CODE]} currency={info.row.original.currency ?? DEFAULT_CURRENCY_CODE} price={info.getValue()} />,
        }),
        columnHelper.accessor('orderId', {
          id: "orderId",
          header: () => <span className="text-black">{t('OrderHistory.TableColumn.OrderId')}</span>,
          cell: info => <a href={`/orderDetails?orderId=${info.getValue()}`}>{t('OrderHistory.OrderNumber')}{info.getValue()}</a>,
        }),
        columnHelper.accessor('organization', {
          header: () => <span className="text-black">{t('OrderHistory.TableColumn.Organization')}</span>,
          cell: info => info.getValue(),
        }),
        columnHelper.accessor('status', {
          header: () => <span className="text-black">{t('OrderHistory.TableColumn.Status')}</span>,
        }),
        columnHelper.accessor('viewOrder', {
          header: '',
          cell: info => {
            const orderId = info.row.original.orderId;
            return <SharedLink href={`${globalSettings?.orderDetailsPageUrl}?orderNumber=${orderId}`}><span className="text-black"><ArrowRightIcon className="h-[14px] w-[14px]" /></span></SharedLink>;
          },
        })
      ], []
  )

  const table = useReactTable({
    data: data ?? [],
    columns: typeof window !== "undefined"  ? (window.innerWidth <= 1023 ? columnsMobile : columns) : columns,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    manualPagination: true,
    onPaginationChange,
    onSortingChange,
    rowCount: totalCount,
    pageCount: Math.ceil(totalCount / limit),
    state: {
      pagination,
      sorting
    },
    initialState: {
      columnVisibility: {
        organization: false
      }
    }
  })

  const onSearchChange = async (value: string) => {
    if (!value) {
      table.resetGlobalFilter(true)
    }
    table.setGlobalFilter(value)
  }

  const onDateFromChange = async (value: string) => {
    setCustomFilters((prev) => {
      const updatedState = { ...prev }

      if (value === "") {
        delete updatedState.modifiedFrom
      } else {
        updatedState.modifiedFrom = value
      }

      return updatedState
    })
  }

  const onDateToChange = async (value: string) => {
    setCustomFilters((prev) => {
      const updatedState = { ...prev }

      if (value === "") {
        delete updatedState.modifiedTo
      } else {
        updatedState.modifiedTo = value
      }

      return updatedState
    })
  }

  const onStatusChange = async (value: string) => {
    setCustomFilters((prev) => ({
      ...prev,
      statuses: [value],
    }))
  }

  useEffect(() => {
    const getFilteredOrders = async () => {
    }
    getFilteredOrders()
  }, [customFilters?.modifiedFrom, customFilters?.modifiedTo, customFilters?.statuses])

  useEffect(() => {
    if (data?.some(x => x.organization !== null)) {
      table.setColumnVisibility({
        organization: true,
      })
    }
  }, [data]);

  return (
    <div className="container flex flex-col py-0 font-mulish ">
      <div className="flex flex-col">
        <div className="flex w-full flex-col gap-[12px] pb-[10px]">
          {pageTitle && <h1 className="text-2xl font-bold text-grey-dark lg:text-5xl pt-[32px]">
            {pageTitle}
          </h1>}
          {pageDescription && <span>{pageDescription}</span>}
        </div>
        {topContentArea && RenderContentArea(topContentArea)}
        <div className="flex grid-cols-12 flex-col gap-7 border-[1px solid red]">
          <div className="flex-1">
            <div className="flex flex-col gap-4">
              {DesktopTable({
                table,
                isLoadingRows: loadingNewRows,
                filters: {
                  onSearchChange,
                  onDateFromChange,
                  onDateToChange,
                  onStatusChange,
                },
              })}
              {MobileTable({
                table,
                isLoadingRows: loadingNewRows,
              })}
              {emptyContentArea && totalCount <= 0 && RenderContentArea(emptyContentArea)}
            </div>
          </div>
        </div>
        {bottomContentArea && <div className="mb-3 mt-3">{RenderContentArea(bottomContentArea)}</div>}
      </div>
    </div>
  )
}

export default withHydration(OrderHistoryListingPage)
