import { AuthInfo, AuthUserType } from '@/lib/ping-identity/types/auth-types';
import { create, StateCreator } from 'zustand';
import { useCustomerHardLockStore } from '@/stores/customer-hard-lock-store'
import config from '@/lib/ping-identity/config'
import { persist, createJSONStorage } from 'zustand/middleware';
import api from '@/api/intercept-axios-api';
import { epiApiGet, epiGetValidatedCart } from '@/api/content-delivery';
import { GET_ME, GET_ME_CART } from '@/api/routes';
import { CartModel, CustomerModel } from '@/api/types/content-delivery-types';
import { DEFAULT_CURRENCY_CODE, DEFAULT_MARKET } from '@/lib/constants';
import { useCartStore } from './useCartStore';

type UserStoreProps = {
  isLoading: boolean;
  user: AuthUserType | null
  setUserContextData: (authContext: AuthInfo) => Promise<void>
  signOut: () => Promise<void>
  handleSignIn: () => Promise<void>
  setUser: (user: Partial<AuthUserType | null>) => void;
  isExpired: () => boolean
  setupCustomerHardLock: (customer: CustomerModel | undefined) => void
  initializeAuthProvider: () => Promise<void>
  setIsLoading: (isLoading: boolean) => void
}

const baseUrl = typeof window !== 'undefined' && window.location.origin

const userStore: StateCreator<UserStoreProps, [["zustand/persist", unknown]]> = (set, get) => ({
    user: null,
    isLoading: false,
    setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
    setUser: (user: Partial<AuthUserType | null>) => set((prevUser) => ({
      user: user === null ? null : {
         ...prevUser.user,
         ...user,
      },
    })),
    handleSignIn: async (): Promise<void> => {
      // eslint-disable-next-line prefer-promise-reject-errors
      return new Promise((resolve, reject) => {
        try {
          get().setUser(null)
          window.location.href = `${baseUrl}${config.loginUrl}`
          useCustomerHardLockStore.getState().reset()
          resolve()
        } catch (e) {
          reject(e)
        }
      })
    },
    setupCustomerHardLock: (customer: CustomerModel | undefined) => {
      const hardLock = customer?.extendedProperties?.find(
        ({ name }) => name === 'HardLock',
      )?.value
      useCustomerHardLockStore.getState().setIsCustomerHardLocked(hardLock === 'True')
      useCustomerHardLockStore.getState().openModal()
    },
    setUserContextData: async (authContext: AuthInfo) => {
      const cartStore = useCartStore.getState()
      cartStore.setIsLoadingCartTotals(true)
      cartStore.setIsLoading(true)
      const customer = await epiApiGet<CustomerModel>(GET_ME)
      get().setupCustomerHardLock(customer)
      const cart = await epiApiGet<CartModel>(GET_ME_CART(authContext.market ?? DEFAULT_MARKET))
      const validatedCartWithTotals = await epiGetValidatedCart(cart!.id)
      if (validatedCartWithTotals) 
      {
        get().setUser({
          authInfo: authContext,
          cart: validatedCartWithTotals?.cart,
          customerInfo: customer,
          totals: validatedCartWithTotals?.totals,
          validationIssues: validatedCartWithTotals?.validationIssues,
          availableShippingMethods:
            validatedCartWithTotals?.availableShippingMethods,
        })
        cartStore.setValidatedCartData({
          cart: validatedCartWithTotals?.cart,
          totals: validatedCartWithTotals?.totals,
          validationIssues: validatedCartWithTotals?.validationIssues,
          availableShippingMethods:
            validatedCartWithTotals?.availableShippingMethods,
        })
        cartStore.setCartData({
          fullCart: validatedCartWithTotals?.cart,
          cartLines: cartStore.extractCartLines(validatedCartWithTotals.cart.shipments),
          cartInfo: {
            market: validatedCartWithTotals.cart.market ?? DEFAULT_MARKET,
            currency: validatedCartWithTotals.cart.currency ?? DEFAULT_CURRENCY_CODE,
          },
        })
        cartStore.setIsLoading(false)
        cartStore.setIsLoadingCartTotals(false)
      }
    },
    isExpired: () => {
      const storedTimestamp = get().user?.authInfo?.expirationTime

      if (!storedTimestamp) {
        return false
      }

      const storedDateTime = new Date(storedTimestamp)
      const now = new Date()

      return now.getTime() >= storedDateTime.getTime()
    },
    signOut: async (): Promise<void> => {
      // eslint-disable-next-line prefer-promise-reject-errors
      return new Promise((resolve, reject) => {
        try {
          window.location.href = `${baseUrl}${config.logoutUrl}`
          resolve()
          get().setUser(null)
          useCustomerHardLockStore.getState().reset()
        } catch (e) {
          reject(e)
        }
      })
    },
    initializeAuthProvider: async () => {
      if (!get().user) {
        get().setIsLoading(true)
        const authInfo: AuthInfo = (
          await api.get<AuthInfo>(`${baseUrl}${config.userInfoUrl}`)
        ).data
        if (authInfo.userId !== null) {
          await get().setUserContextData(authInfo)
        } else {
          get().setUser(null)
          useCustomerHardLockStore.getState().reset()
        }
      } else {
        const expired = get().isExpired()
        if (expired) {
          const authInfo: AuthInfo = (
            await api.get<AuthInfo>(`${baseUrl}${config.userInfoUrl}`)
          ).data

          if (authInfo.userId !== null) {
            await get().setUserContextData(authInfo)
          } else {
            get().setUser(null)
            useCustomerHardLockStore.getState().reset()
            await get().signOut()
          }
        }
      }

      get().setIsLoading(false)
    }
});

export const useUserStore = create(
    persist(userStore, {
        name: 'user',
        storage: createJSONStorage(() => localStorage),
    })
);