import { useIndexPageStore } from '@/stores/index-store'
import { CheckIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { IndexCategoryItemProp } from '../types/index-types'

function CategoryBtn(props: Readonly<IndexCategoryItemProp>) {
  const { id, name } = props

  const { resetPage, updateSelectedCategories, fetchResults } =
    useIndexPageStore()

  const [isChecked, setIsChecked] = useState(false)

  const handleCategoryChange = (id: number) => {
    setIsChecked(!isChecked)
    resetPage()
    updateSelectedCategories(id)
    fetchResults()
  }

  return (
    <button
      className="rounded inline-flex items-center space-x-2.5 hover:cursor-pointer"
      onClick={() => handleCategoryChange(id)}
    >
      <span className="h-4 w-4 border border-grey-secondary-dark rounded relative">
        {isChecked && (
          <span className="bg-light-blue-primary rounded h-4 w-4 flex items-center justify-center">
            <CheckIcon className="h-2.5 w-2.5 text-white" />
          </span>
        )}
      </span>
      <span className="text-sm font-medium text-grey-dark">{name}</span>
    </button>
  )
}

export default CategoryBtn
