import { GtmEcomEvent, GtmEvent, GtmFullStoryEvent } from '../interfaces/gtm-event.interface'
import { logEvent } from './log-event.function'

export const pushEvent = (eventData: GtmEcomEvent) => {
  if (!window) {
    console.error('window not defined')
    return
  }

  window.dataLayer ||= []
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push(eventData)
  logEvent(eventData)
}

export const pushFullStoryEvent = (eventData: GtmFullStoryEvent) => {
  if (!window) {
    console.error('window not defined')
    return
  }

  window.dataLayer ||= []
  window.dataLayer.push(eventData)
  logEvent(eventData, '🟢')
  resetDataLayer()
}

export const resetDataLayer = () => {
  window.dataLayer.push(function() {
    // @ts-ignore:next-line
    this.reset();
  })
}
