import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Select, { GroupBase, SelectInstance } from 'react-select';
import { useDebounceValue } from 'usehooks-ts';
import { AddressCompletionSelectProps, SelectOption } from './types/address-types';
import { Parameter } from '@/api/melissa-address/types/melissa-address-types';
import { AddressCompletionInputCodes } from '@/api/melissa-address/enums/address-completion-enums';
import DropdownErrorIndicator from './components/dropdown-error-indicator';
import { useAddressStore } from '@/stores/useAddressStore';
import withHydration from '@/lib/withHydration';

const AddressCompletionSelectForwardRef: React.ForwardRefRenderFunction<SelectInstance<SelectOption, false, GroupBase<SelectOption>>, AddressCompletionSelectProps> = 
    ({
        countryISO2,
        stateOrProvinceISO2,
        className,
        partialAddress,
        placeholder,
        hasError,
        selectValue,
        handleChange,
        setSelectValue,
        handleInputChange,
        onSelectedAddress,

    }, ref) => {
    const [ menuOpen, setMenuOpen ] = useState(false);
    const { addressCompletions, getCompletionAddresses, setAddressCompletions } = useAddressStore();
    const [ debouncedInputValue ]  = useDebounceValue(partialAddress, 400);

    const defaultInputStyles = classNames("select", className);

    const onChange = (selectedOption: SelectOption | null) =>{
        if (!selectedOption)
        {
          setSelectValue(null)
          setAddressCompletions(null)
      }
      const address = addressCompletions?.completions.find((detail) => detail.address.mak === selectedOption?.value)?.address ?? null;
      if(address)
      {
        onSelectedAddress(address)
        setSelectValue({value:address.address1, label: `${address.address1}, ${address.locality}`});
      }
      handleChange(selectedOption);
      setMenuOpen(false);
    }

    const addressOptions = 
      addressCompletions ? 
      addressCompletions.completions.map((detail)=> ({
            value: detail.address.mak,
            label: detail.address.address
        }))
    : undefined;
    
    useEffect(()=>{
        const handleListLoad = async() => {
          if (countryISO2 === "" || stateOrProvinceISO2 === "") return

          if (debouncedInputValue) {
              const addressCompletionParams: Parameter[] =
              [
                {
                  paramType: AddressCompletionInputCodes.Address1,
                  paramValue: debouncedInputValue,
                },
                {
                  paramType: AddressCompletionInputCodes.Country,
                  paramValue: countryISO2,
                },
                {
                  paramType: AddressCompletionInputCodes.StateOrProvince,
                  paramValue: stateOrProvinceISO2,
                }
            ]
            await getCompletionAddresses(addressCompletionParams);
          }
        }
        handleListLoad();
    }, [debouncedInputValue]);
    

    const boxShadowFocusedErrorHandler = hasError ? "0px 0px 0px 3px rgba(244, 63, 94, 0.25)" : "0px 0px 0px 3px rgba(44, 172, 226, 0.3)" 
    const boxShadowUnfocusedErrorHandler = hasError ? "0px 0px 0px 3px rgba(244, 63, 94, 0.25)" : ""
    const borderFocusedErrorHandler = hasError ? "1px solid #DC2626" : "1px solid #2cabe2"
    const borderUnfocusedErrorHandler = hasError ? "1px solid #DC2626" : "1px solid #EFEFF4"
    
    const customStyles = {
        menu: (styles: any, state: any) => ({
            ...styles,
            color: state.isSelected ? "#FFF" : styles.color,
            backgroundColor: "#FFF",
            border: "1px solid black",
            boxShadow: 'none',
        }),
        input: (styles: any, state: any)=>({
          ...styles,
          border: "none",
          outline: "none",
          boxShadow: "none",
        }),
        option: (styles: any, state: any) => ({
            ...styles,
            paddingLeft: "8px",
            paddingRight: "8px",
            borderBottom: "unset",
            color: state.isSelected ? '#FFF' : 'inherit',
            backgroundColor: state.isFocused || state.isSelected ? '#c8c8c8' : 'transparent',
            "&:hover": {
              color: "#FFF",
              backgroundColor: "#1967d2"
            }
        }),
        control: (styles: any, state: any) => ({
          ...styles,
          boxShadow: state.isFocused ? boxShadowFocusedErrorHandler : boxShadowUnfocusedErrorHandler,
          borderRadius: "8px",
          border: !state.isFocused ? borderUnfocusedErrorHandler : borderFocusedErrorHandler,
          padding: "12px",
        }),
    }
    return (
          <Select
              styles={customStyles}
              ref={ref}
              onMenuClose={() => setMenuOpen(false)}
              onMenuOpen={() => setMenuOpen(true)}
              unstyled={true}
              components={hasError ? {DropdownIndicator: DropdownErrorIndicator} : undefined}
              className={defaultInputStyles}
              options={addressOptions}
              value={selectValue}
              onChange={onChange}
              onInputChange={(value, meta) => handleInputChange(value)}
              isClearable
              placeholder={placeholder}
              menuIsOpen={menuOpen && addressOptions !== undefined }
          />
      )
}
const AddressCompletionSelect = React.forwardRef(AddressCompletionSelectForwardRef);

AddressCompletionSelect.defaultProps = {
    placeholder: "Start typing your address.."
}

export default withHydration(AddressCompletionSelect);