interface QuestionElementProps {
  question: string
}

const QuestionElement = (props: QuestionElementProps) => {
  const { question } = props
  return (
    <div className="w-full lg:w-1/2 flex flex-col text-grey-dark text-xl md:text-2xl font-bold text-center">
      {question}
    </div>
  )
}

export default QuestionElement
