import { Currency, Locale } from "@/components/shared/price/types/price-types";
import { DateTime } from "luxon";

const formatCurrency = (price: string | null, culture: Locale | string, currency: Currency | string) => {
    const formatter = new Intl.NumberFormat(culture, {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol'
    });
    let decimalAmount = parseFloat(price ?? "0");
    let parts = formatter.formatToParts(decimalAmount);
    let currencySymbol = parts.find(part => part.type === 'currency')?.value ?? '';
    let amount = formatter.format(decimalAmount).replace(currencySymbol, '').trim();
    let currencyCode = currency.toUpperCase();
    return `${currencySymbol}${amount} ${currencyCode}`;
}

const formatDateShort = (date: string) => {
      return DateTime.fromISO(date)
        .toLocaleString(DateTime.DATE_SHORT)
}

const replaceNonXmlWithSpace = (input: string): string  => {
    return input.replace(/[^a-zA-Z0-9_\-\.]/g, ' ');
}

const trimSpace = (input: string): string  => {
    return input.replace(/\s/g, "");
}

export {
    formatCurrency,
    formatDateShort,
    replaceNonXmlWithSpace,
    trimSpace
}

