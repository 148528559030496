import SharedLink from '@/components/shared/buttons/shared-link'
import { uid } from 'uid'
import { ContactLinkItemProp } from '../types/contact-types'
import ContactTitle from './contact-title'

const ContactLinkItem = (props: Readonly<ContactLinkItemProp>) => {
  const { header, list } = props

  return (
    <div className="col-span-1 flex flex-col space-y-4">
      {header && <ContactTitle header={header} />}
      {list && list.length > 0 && (
        <div className="flex flex-col pl-3">
          {list.map((item) => (
            <SharedLink key={uid()} {...item}>
              {item.text}
            </SharedLink>
          ))}
        </div>
      )}
    </div>
  )
}

export default ContactLinkItem
