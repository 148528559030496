import { ShippingAndBillingAddressModel } from '@/api/types/content-delivery-types'

export const AddressDisplay = ({
  addressModel,
}: {
  addressModel: ShippingAndBillingAddressModel | null
}) =>
  addressModel && (
    <div className='text-black flex flex-col gap-1'>
      <div>{addressModel.line1}</div>
      <div>{addressModel.line2}</div>
      <div>{addressModel.city} {addressModel.regionName} {addressModel.postalCode}</div>
      <div>{addressModel.countryName}</div>
      <div>{addressModel.phoneNumber}</div>
      <div>{addressModel.email}</div>
    </div>
  )
