import { MultiColumnBlockProps } from '@/components/blocks/multi-column/types/multi-column-types'
import SharedLink from '@/components/shared/buttons/shared-link'
import SectionHeader from '@/components/shared/section-header'
import { CheckCircleIcon } from '@heroicons/react/24/solid'

const MultiColumnBlock = (props: Readonly<MultiColumnBlockProps>) => {
  const { headline, subheadline, smallIntro, columns } = props.model

  return (
    <section
      className={`
     ${!props.hideContainerClass ? 'container' : ''}`}
    >
      {(headline || smallIntro || subheadline) && (
        <SectionHeader
          textCenter={true}
          heading={headline}
          smallIntro={smallIntro}
          subheading={subheadline}
        />
      )}

      <div className="flex md:justify-center gap-5 md:gap-6 flex-col md:flex-row">
        {columns?.map((card, i) =>
          card.headline || card.subheadline || card.link ? (
            <div
              key={i}
              className="flex-1 md:max-w-[33%] border border-solid border-grey-secondary-dark rounded-lg px-4 py-6 md:px-6 md:py-10"
            >
              <div className="flex flex-col space-y-3">
                <h3 className="text-grey-dark font-bold text-xl md:text-2xl">
                  {card.headline}
                </h3>
                <p className="text-grey-light text-base md:text-lg">
                  {card.subheadline}
                </p>
              </div>

              <SharedLink
                className="my-6 md:my-8 w-full"
                style={
                  card.buttonStyle === 'primary' ? card.buttonStyle : 'outline'
                }
                size="large"
                {...card.link}
              >
                {card.link?.text}
              </SharedLink>

              {card.benefits && (
                <div className="flex flex-col space-y-5">
                  {card.benefits?.map((benefit, i) => (
                    <div key={i} className="flex w-full">
                      <CheckCircleIcon className="text-dark-blue-primary w-6 h-6 min-w-[24px] mr-2" />
                      {benefit}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : null,
        )}
      </div>
    </section>
  )
}

export default MultiColumnBlock
