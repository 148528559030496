import SharedLink from '@/components/shared/buttons/shared-link'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { SearchPageItemProps } from '../types/search-types'

const SearchProgramItem = (props: Readonly<SearchPageItemProps>) => {
  const { title, linkText, url, isExternal, target } = props

  return (
      <article className="w-full flex flex-col md:flex-row md:items-center md:justify-between md:space-x-8 space-y-3 md:space-y-0 p-3 md:py-4 border border-grey-secondary-dark rounded-lg">
      {title && (
        <h3 className="flex-1 text-grey-dark text-base lg:text-lg font-semibold">
          {title}
        </h3>
      )}
      {linkText && (
        <SharedLink
          href={url}
          isExternal={isExternal}
          target={target}
          className="flex items-center space-x-3 font-semibold hover:no-underline"
        >
          <span>{linkText}</span>
          <span>
            <ArrowRightIcon className="h:4 md:h-5 w-4 md:w-5 text-dark-blue-primary py-1.5 md:py-0" />
          </span>
        </SharedLink>
      )}
    </article>
  )
}

export default SearchProgramItem
