import { usePrimaryMenuMobileStore } from '@/stores/primary-menu-mobile'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import { useHydrated } from 'react-hydration-provider'
import { useTranslation } from 'react-i18next'


function MobileNavButton() {
  const hydrated = useHydrated()
  const { t } = useTranslation()
  const { mobileMenuIsOpen, toggleMobileMenu } = usePrimaryMenuMobileStore()

  return (
    <button
      className="flex items-center justify-center h-10 w-10 rounded-lg bg-grey-secondary-light text-grey-dark"
      id="mobileNavButton"
      aria-haspopup="true"
      aria-controls="mobileMenu"
      onClick={toggleMobileMenu}
    >
      <span className="sr-only">{hydrated && t('ScreenReader.OpenSiteNavigation')}</span>
      <span>
        {!mobileMenuIsOpen ? (
          <Bars3Icon className="h-6 w-6 text-grey-dark" />
        ) : (
          <XMarkIcon className="h-6 w-6 text-grey-dark" />
        )}
      </span>
    </button>
  )
}

export default MobileNavButton
