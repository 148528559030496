import { useBreadcrumbs } from '@/stores/breadcrumbs'
import { useGlobalSettings } from '@/stores/global-settings'
import { useMediaStore } from '@/stores/media-store'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import SharedLink from '../buttons/shared-link'

export const BreadcrumbsEcomm = () => {
  const { breadcrumbItems, pageName, parentLinkUrl } = useBreadcrumbs()
  const { isMobile } = useMediaStore()
  const globalSettings = useGlobalSettings((state) => state.globalSettings)
  const links = breadcrumbItems.filter((item) => !item.isHome)
  
  const renderBackButton = () =>
    parentLinkUrl && (
      <Link to={parentLinkUrl} className="inline-flex items-center space-x-3 py-1.5 hover:no-underline">
        <span>
          <ChevronLeftIcon className="h-4 w-4 text-dark-blue-300" />
        </span>
        <span className="text-white">{globalSettings?.breadcrumbsBackText}</span>
      </Link>
    )

  const renderCurrentPageName = () =>
    pageName && (
      <li className="flex">
        <span className="cursor-default text-white">{pageName}</span>
      </li>
  )

  return (
    <div className="container py-4">
      {breadcrumbItems && (
        <>
          {!isMobile ? (
            <ul className="flex items-center">
              {links.map((link, idx) => (
                <li
                  key={link?.href + '-' + idx}
                  className="relative mr-2 flex pr-5 after:absolute after:right-0 after:top-1/2 after:w-[12px] after:-translate-y-1/2 after:text-dark-blue-300 after:content-['/']"
                >
                  <SharedLink
                    {...link}
                    className={
                      'cursor-default text-white underline' +
                      (!link.href ? '' : ' !cursor-pointer hover:!text-dark-blue-300')
                    }
                  >
                    {link?.text}
                  </SharedLink>
                </li>
              ))}

              {renderCurrentPageName()}
            </ul>
          ) : (
            renderBackButton()
          )}
        </>
      )}
    </div>
  )
}
