import { SearchPageItemProps } from '@/components/pages/search/types/search-types'
import ProductTile from '@/components/shared/product-tile/product-tile'
import { uid } from 'uid'

interface ProductTileGridProps {
  gridGap?: string
  top?: number
  smGridSize?: number
  mdGridSize?: number
  lgGridSize?: number
  products: SearchPageItemProps[]
  itemListId?: string
}

const ProductTileGrid: React.FC<ProductTileGridProps> = ({
  gridGap,
  smGridSize,
  mdGridSize,
  lgGridSize,
  products,
  itemListId,
}) => {
  return (
    <div className={`grid sm:grid-cols-${smGridSize} md:grid-cols-${mdGridSize} lg:grid-cols-${lgGridSize} ${gridGap}`}>
      {products.map((product) => (
        <ProductTile key={uid()} product={product} itemListId={itemListId} />
      ))}
    </div>
  )
}

ProductTileGrid.defaultProps = {
  gridGap: 'gap-5',
  smGridSize: 1,
  mdGridSize: 2,
  lgGridSize: 3,
}

export default ProductTileGrid
