import { useSearchStore } from '@/stores/search-store'
import { SearchTabProp } from '../types/search-types'

function SearchTab(props: Readonly<SearchTabProp>) {
  const { title, index, isProductTab, setUrlQueryParams } = props

  const {
    activeTabIndex,
    setActiveTabIndex,
    resetPage,
    setActiveCategory,
    fetchResults,
  } = useSearchStore() 

  const handleActiveIndex = () => {
    setActiveTabIndex(index)
    setActiveCategory(isProductTab ? 'products' : 'all')
    setUrlQueryParams({ category: isProductTab ? 'products' : 'all' })
    resetPage()
    fetchResults()
  }

  return (
    <button
      className={`flex-inline items-center justify-center font-medium text-base py-1 px-3 w-full sm:w-auto sm:min-w-[128px] rounded-lg shared-transition ${
        activeTabIndex === index
          ? 'bg-dark-blue-primary text-white'
          : 'bg-white text-grey-dark'
      }`}
      onClick={handleActiveIndex}
    >
      {title && <span className="whitespace-nowrap">{title}</span>}
    </button>
  )
}

export default SearchTab
