import { SubmitHandler, useForm } from "react-hook-form"
import classNames from "classnames"
import { CheckoutStepOneShippingFormProps, CheckoutStepOneShippingFormInputs } from "../types/checkout-types"
import { useEffect, useState } from 'react'
import { EMAIL_REGEX, getPostalCodeRegex } from "@/api/validation"
import { useCartStore } from "@/stores/useCartStore"
import { AddressFormFieldsWarning } from "@/components/shared/address/types/address-types"
import { AddressBookFormWarning, useAddressStore } from "@/stores/useAddressStore"
import { Parameter } from "@/api/melissa-address/types/melissa-address-types"
import { AddressVerificationInputCodes } from "@/api/melissa-address/enums/address-verification-enums"
import { renderErrors, renderWarnings } from "@/api/validation/input-validation"
import { useTranslation } from "react-i18next"
import { useUserStore } from "@/stores/useUserStore"

const StepOneShippingForm: React.FC<Readonly<CheckoutStepOneShippingFormProps>> = (props: Readonly<CheckoutStepOneShippingFormProps>) => {
    const { user } = useUserStore()
    const { t } = useTranslation()
    const { cartData, updateCartShippingMethod, loadUserCart } = useCartStore()
    const { hasWarning, setHasWarning, getValidatedAddress } = useAddressStore()
    const [ warnings, setWarnings ] = useState<{ [key in keyof AddressFormFieldsWarning]?: string[] } | null>(null)
    
    const existingShippingAddressCountryCode = cartData?.fullCart?.shipments[0]?.shippingAddress?.countryCode ?? ""
    const {
        className,
        defaultCartShippingAddress: shippingAddress,
        localizations,
        onSubmitForm,
    } = props;
    const {
        register,
        handleSubmit,
        setValue,
        setFocus,
        watch,
        trigger,
        formState: { 
            errors, 
        },
    } = useForm<CheckoutStepOneShippingFormInputs>({
        values: {
            firstName: shippingAddress?.firstName ?? "",
            lastName: shippingAddress?.lastName ?? "",
            address1: shippingAddress?.line1 ?? "",
            address2: shippingAddress?.line2 ?? "",
            city: shippingAddress?.city ?? "",
            country: shippingAddress?.countryName ?? "",
            countryCode: shippingAddress?.countryCode ?? "",
            postalCode: shippingAddress?.postalCode ?? "",
            provinceOrState: shippingAddress?.regionName ?? "",
            email: shippingAddress?.email ?? "",
            addressName: shippingAddress?.name ?? "",
        }
    })

    const defaultFormStyles = classNames("gap-3", className)
    const watchedFields = watch();

    useEffect(() => {
        if (shippingAddress !== null) 
        {
            setValue("firstName", shippingAddress.firstName ?? "", {shouldDirty: true})
            setValue("lastName", shippingAddress.lastName ?? "", {shouldDirty: true})
            setValue("addressName", shippingAddress.name ?? "", {shouldDirty: true})
            setValue("address1", shippingAddress.line1 ?? "", {shouldDirty: true})
            setValue("address2", shippingAddress.line2 ?? "", {shouldDirty: true})
            setValue("city", shippingAddress.city ?? "", {shouldDirty: true})
            setValue("countryCode", shippingAddress.countryCode ?? "", {shouldDirty: true})
            setValue("country", shippingAddress.countryName ?? "", {shouldDirty: true})
            setValue("provinceOrState", shippingAddress.regionName ?? "", {shouldDirty: true})
            setValue("postalCode", shippingAddress.postalCode ?? "", {shouldDirty: true})
            setValue("email", shippingAddress.email ?? "", { shouldDirty: true })
        }
    }, [shippingAddress])

    useEffect(() => {
        if (watchedFields.countryCode && watchedFields.countryCode !== existingShippingAddressCountryCode) {
            updateCartShippingMethod({shipmentId: user?.cart?.shipments[0]?.id ?? null, shippingMethod: null})
        }
    }, [watchedFields.countryCode, trigger]);

    const onSubmit: SubmitHandler<CheckoutStepOneShippingFormInputs> = async (data, e) => {

        const hasWarnings = await handleAddressValidation(data)
        if (hasWarnings)
        {
            if (hasWarning === false)
            {
                return
            }
        }
        
        setHasWarning(false)
        onSubmitForm(data, e)
        await loadUserCart()
    }

    const handleAddressValidation = async (data: CheckoutStepOneShippingFormInputs) => {
        const addressVerificationParams: Parameter[] =
        [
            {
              paramType: AddressVerificationInputCodes.Address1,
              paramValue: data.address1,
            },
            {
              paramType: AddressVerificationInputCodes.Country,
              paramValue: data.countryCode,
            },
            {
              paramType: AddressVerificationInputCodes.City,
              paramValue: data.city,
            },
            {
              paramType: AddressVerificationInputCodes.PostalCode,
              paramValue: data.postalCode,
            },
            {
              paramType: AddressVerificationInputCodes.StateOrProvince,
              paramValue: data.provinceOrState,
            }
        ]
  
        const addressVerificationResult = await getValidatedAddress(addressVerificationParams)
        if(addressVerificationResult)
        {
            const isWarnings = addressVerificationResult?.verificationWarnings && addressVerificationResult?.verificationWarnings.length > 0
            isWarnings && setHasWarning(true)
            isWarnings && addressVerificationResult?.verificationWarnings.forEach((warning) => {
                setWarnings(prevWarnings => {
                    const key = warning.warningId as keyof AddressBookFormWarning;
                    return {
                        ...prevWarnings,
                        [key]: warning?.warningMessages
                    };
                });
            });
            return isWarnings
        }
        return false;
    }
    
    // Focus handler for when we have warnings.
    useEffect(()=>{
        if (!hasWarning) return

        if(warnings?.address1)
        {
            setFocus("address1")
        }else if(warnings?.address2)
        {
            setFocus("address2")
        }else if(warnings?.city)
        {
            setFocus("city")
        }else if(warnings?.postalCode)
        {
            setFocus("postalCode")
        }else if(warnings?.provinceOrState)
        {
            setFocus("provinceOrState")
        }
    },[warnings?.address1, warnings?.address2, warnings?.city, warnings?.postalCode, warnings?.provinceOrState, hasWarning])

    return (
        <form id="step-one-form-shipping" className={defaultFormStyles} onSubmit={handleSubmit(onSubmit)}>

            {/* Address1 */}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 Form__Element FormTextbox ${errors.address1 ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="address1">{t("FormFields.AddressOne.Label")}</label>
                    <input
                        {...register("address1", {
                            required: t("FormFields.AddressOne.Required")
                        })}
                        type="text"
                        className={`input flex-[50%]`}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    {renderErrors(errors?.address1)}
                    {renderWarnings(warnings?.address1)}
                </div>
            </div>

            {/* Address2 */}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 Form__Element FormTextbox ${errors.address2 ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="address2">{t("FormFields.AddressTwo.Label")}</label>
                    <input
                        {...register("address2")}
                        type="text"
                        className={`input flex-[50%]`}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    {renderErrors(errors?.address2)}
                    {renderWarnings(warnings?.address2)}
                </div>
            </div>

            {/* City with required ex*/}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 ${errors.city ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="city">{t("FormFields.City.Label")}</label>
                    <input
                        {...register("city", {
                            required: t("FormFields.City.Required")
                        })}
                        type="text"
                        className={`input flex-[50%]`}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    {renderErrors(errors?.city)}
                    {renderWarnings(warnings?.address2)}
                </div>
            </div>

            {/* Postal Code */}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 ${errors.postalCode ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="postalCode">{t("FormFields.PostalCode.Label")}</label>
                    <input
                        {...register("postalCode",
                        {
                            required: t("FormFields.PostalCode.Required"),
                            pattern: {
                                value: getPostalCodeRegex(watchedFields.countryCode ?? "US"),
                                message: t("FormFields.PostalCode.Pattern")
                            }
                        })}
                        type="text" 
                        className={`input flex-[50%]`}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    {renderErrors(errors?.postalCode)}
                    {renderWarnings(warnings?.postalCode)}
                </div>
            </div>

            {/* Country */}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 ${errors.countryCode ? 'ValidationFail' : ''}`}>

                    <label className={"font-bold text-black flex-1"} htmlFor="countryCode">{t("FormFields.Country.Label")}</label>

                    <select {...register("countryCode",
                        {
                            required: t("FormFields.Country.Required")
                        })}
                    >
                        <option value="" disabled>{t("FormFields.Country.Placeholder")}</option>
                        {localizations?.countries.map((value) => {
                            return(
                                <option key={value.name} value={value.name}>{value.code}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="flex flex-col gap-3">
                    {renderErrors(errors?.countryCode)}
                </div>
            </div>

            {/* Province or State */}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 ${errors.provinceOrState ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="provinceOrState">{t("FormFields.StateOrProvince.Label")}</label>
                    <select {...register("provinceOrState",
                        {
                            required: t("FormFields.StateOrProvince.Required")
                        })}
                        defaultValue=""
                    >
                        <option value="" disabled>{t("FormFields.StateOrProvince.Placeholder")}</option>
                        {localizations?.statesAndProvinces.filter(x => x.country.name === watchedFields.countryCode)?.map((value, idx)=>{
                            return(
                                <option key={value.name} value={value.name}>{value.name}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="flex flex-col gap-3">
                    {renderErrors(errors?.provinceOrState)}
                    {renderWarnings(warnings?.provinceOrState)}
                </div>
            </div>

            {/* Email Address */}
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 ${errors.email ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="email">{t("FormFields.Email.Label")}</label>
                    <input 
                        {...register("email",
                        {
                            required: t("FormFields.Email.Required"),
                            pattern: {
                                value: EMAIL_REGEX,
                                message: t("FormFields.Email.Pattern")
                            }
                        })}
                        type="text" 
                        className={`input flex-[50%]`}
                        />
                </div>
                <div className="flex flex-col gap-3">
                    {renderErrors(errors?.email)}
                </div>
          </div>
    </form>
  )
}

// Set default values to some of the properties.
StepOneShippingForm.defaultProps = {
  className: "flex flex-col",
}

export default StepOneShippingForm
