import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import SharedButton from '@/components/shared/buttons/shared-button'
import LoadingSpinner from '@/components/shared/loading-spinner'
import ProductTile from '@/components/shared/product-tile/product-tile'
import SharedRTF from '@/components/shared/rtf/shared-rtf'
import { useHeaderSearchStore } from '@/stores/header-search-store'
import { useSearchStore } from '@/stores/search-store'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { uid } from 'uid'
import SearchPageItem from './components/page-item'
import SearchProgramItem from './components/program-item'
import SearchPageInput from './components/search-page-input'
import SearchTab from './components/search-tab'
import SearchSectionTitle from './components/section-title'
import { SearchPageProps, SearchTabProp } from './types/search-types'
import { viewItemListGTM } from '@/components/shared/analytics/gtm-manager'

const SearchPage = (props: Readonly<SearchPageProps>) => {
  const { model } = props

  const {
    isLoading,
    page,
    setPage,
    setPageUrl,
    setSearchTerm,
    setActiveCategory,
    setIsLoadMore,
    fetchResults,
    activeCategory,
    programs,
    products,
    pages,
    hasMoreProducts,
    hasMorePages,
    setActiveTabIndex,
    hasSearchQuery,
    noResultsMessage,
    resetPage,
    resetProducts
  } = useSearchStore()

  const { setHeaderSearchTerm } = useHeaderSearchStore()
  const [searchParams] = useSearchParams()

  const handleLoadMore = () => {
    setPage(page + 1)
    setIsLoadMore(true)
    fetchResults()
  }
  const setUrlQueryParams = (queryParams: object) => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    Object.entries(queryParams).forEach(([key, value]) => {
      currentSearchParams.set(key, String(value));
    });
    const newUrl = `${window.location.pathname}?${currentSearchParams.toString()}`;
    window.history.pushState(null, '', newUrl);
  }

  const searchTabs: SearchTabProp[] = [
    { title: model.allLabel, isProductTab: false, index: 0, setUrlQueryParams: setUrlQueryParams },
    { title: model.productsTabLabel, isProductTab: true, index: 1, setUrlQueryParams: setUrlQueryParams },
  ]

  const handleSearchParams = () => {
    const term = searchParams.get('q')
    const category = searchParams.get('category')

    if (term) {
      setSearchTerm(term) // set search input value to query value on page load
    }
    if (category === 'products') {
      setActiveCategory('products') // set active category to query value on page load
      setActiveTabIndex(1) // set active index to products if search category is products on page load
    }
    setHeaderSearchTerm('') // clear header search term on page load
  }

  const handleSeeProductResults = async () => {
    setActiveCategory('products')
    setActiveTabIndex(1)
    setUrlQueryParams({ category: 'products' });
    resetProducts()
    fetchResults()
  }

  useEffect(() => {
    handleSearchParams()
    setPageUrl(model.pageUrl)
    resetPage()
    fetchResults()
  }, [])

  useEffect(() => {
    const handlePopstate = (event: PopStateEvent) => {
      location.reload()
    }
    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])

  const itemListId = 'search_results'
  useEffect(() => {
    if (!products.length) return
    // Push the 'view_item_list' GTM event
    viewItemListGTM(products, itemListId)
  }, [products])

  return (
    <>
      <Breadcrumbs />
      {model.title && <h1 className="sr-only">{model.title}</h1>}
      <div className="flex flex-col space-y-[72px] md:space-y-32">
        <div className="container">
          <div className="flex flex-col space-y-6 md:space-y-10">
            <SearchPageInput
              placeholder={model.placeholder}
              clearSearchAria={model.clearSearchAria}
            />
            {searchTabs && (products.length + programs.length + pages.length > 0) && (
              <ul className="w-full flex space-x-0.5 md:space-x-1">
                {searchTabs.map((tab) => (
                  (!tab.isProductTab || products.length > 0) && (
                    <li key={uid()} className="w-1/2 sm:w-auto">
                      <SearchTab {...tab} />
                    </li>
                  )
                ))}
              </ul>
            )}

            <div
              className={`flex flex-col space-y-14 md:space-y-20 ${activeCategory === 'products' ? 'w-full' : 'w-full lg:w-2/3'
                }`}
            >
              {(products.length > 0 ||
                programs.length > 0 ||
                pages.length > 0) && (
                  <div className="flex flex-col space-y-8 md:space-y-14">
                    {activeCategory !== 'products' &&
                      programs &&
                      programs.length > 0 && (
                        <div className="flex flex-col space-y-4 md:space-y-6">
                          {model.programResultsText && (
                            <SearchSectionTitle
                              title={model.programResultsText}
                            />
                          )}
                          <div className="flex flex-col space-y-3">
                            {programs.map((program, index) => (
                              <React.Fragment key={uid()}>
                                {index < 3 && <SearchProgramItem {...program} />}
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      )}
                    {products && products.length > 0 && (
                      <div className="flex flex-col space-y-4 md:space-y-6">
                        {activeCategory !== 'products' && (model.productResultsText ||
                          model.productResultsLinkText) && (
                            <SearchSectionTitle title={model.productResultsText}>
                              {model.productResultsLinkText && activeCategory !== 'products' && (
                                <button className="flex items-center text-dark-blue-primary font-semibold space-x-3 hover:no-underline"
                                  onClick={async () => await handleSeeProductResults()} >
                                  <span>{model.productResultsLinkText}</span>
                                  <span>
                                    <ArrowRightIcon className="h-5 w-5 text-dark-blue-primary" />
                                  </span>
                                </button>
                              )}
                            </SearchSectionTitle>
                          )}
                        <div
                          className={`grid gap-8 grid-cols-1 ${activeCategory === 'products'
                            ? 'md:grid-cols-2 2xl:grid-cols-4'
                            : 'md:grid-cols-3'
                            }`}
                        >
                          {products.map((product, index) => (
                            <React.Fragment key={uid()}>
                              {activeCategory === 'all' && (
                                <div className="col-span-1 flex flex-col">
                                  <ProductTile product={product} itemListId={itemListId} />
                                </div>
                              )}
                              {activeCategory === 'products' && (
                                <div className="col-span-1 flex flex-col">
                                  <ProductTile product={product} itemListId={itemListId} />
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    )}
                    {activeCategory !== 'products' &&
                      pages &&
                      pages.length > 0 && (
                        <div className="flex flex-col space-y-4 md:space-y-6">
                          {model.pageResultsText && (
                            <SearchSectionTitle title={model.pageResultsText} />
                          )}
                          <div className="flex flex-col space-y-6 md:space-y-8">
                            {pages.map((page) => (
                              <SearchPageItem key={uid()} {...page} />
                            ))}
                          </div>
                        </div>
                      )}
                  </div>
                )}

              {((activeCategory === 'products' &&
                products.length > 0 &&
                hasMoreProducts) ||
                (activeCategory === 'all' &&
                  pages.length > 0 &&
                  hasMorePages)) && (
                  <div className="flex justify-center">
                    <SharedButton
                      ariaLabel={
                        activeCategory === 'products'
                          ? model.loadMoreProductAriaText
                          : model.loadMorePageAriaText
                      }
                      style="primary"
                      size="regular"
                      className="space-x-3"
                      onClick={handleLoadMore}
                    >
                      {isLoading && (
                        <span>
                          <LoadingSpinner className="h-5 w-5 text-white" />
                        </span>
                      )}
                      {activeCategory === 'products' ? (
                        <span>{model.loadMoreProductButtonText}</span>
                      ) : (
                        <span>{model.loadMorePageButtonText}</span>
                      )}
                    </SharedButton>
                  </div>
                )}

              {pages.length < 1 &&
                products.length < 1 &&
                programs.length < 1 &&
                isLoading && (
                  <div className="w-full flex items-center justify-center py-10 md:py-20">
                    <LoadingSpinner className="h-5 w-5 text-grey-light" />
                  </div>
                )}

              {pages.length < 1 &&
                products.length < 1 &&
                programs.length < 1 &&
                !isLoading && (
                  <div className="flex flex-col border-t border-grey-secondary-light py-10 md:py-20">
                    {hasSearchQuery && <SharedRTF html={noResultsMessage} />}
                    {!hasSearchQuery && model.emptySearchMessage && (
                      <div className="text-2xl font-medium text-grey-light">
                        {model.emptySearchMessage}
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default SearchPage
