import { EpiPageContextStateProps, LanguagePageProps } from '@/epi/types/epi'
import { create } from 'zustand'

interface MetaDataStoreProps extends EpiPageContextStateProps {
  setMetaData: (data: object) => void
  setMetaLanguage: (lang: LanguagePageProps) => void
  setMetaUrl: (value: string) => void
  setPageTitle: (value: string) => void
}
export const useMetaDataStore = create<MetaDataStoreProps>((set) => ({
  metadata: {},
  language: {},
  url: '',
  pagetitle: '',
  setMetaData: (data: object) => set(() => ({ metadata: data })),
  setMetaLanguage: (lang: LanguagePageProps) => set(() => ({ language: lang })),
  setMetaUrl: (value: string) => set(() => ({ url: value })),
  setPageTitle: (value: string) => set(() => ({ pagetitle: value }))
}))
