import SharedRTF from '@/components/shared/rtf/shared-rtf'
import { TableBlockProps } from './types/table-block-types'

const TableBlock: React.FC<TableBlockProps> = ({
  model,
  className,
  hideContainerClass,
}) => {
  const classNames = [
    'overflow-auto prose-th:font-normal prose-td:font-normal prose-table:border-none prose-td:border-none prose-table:border-spacing-1 prose-table:!border-separate prose-td:rounded prose-th:rounded prose:tr:rounded prose-td:py-3 prose-td:px-2 prose-th:py-3 prose-th:px-2',
    !hideContainerClass ? 'container' : '',
    className,
  ]
    .filter((i) => i !== undefined)
    .filter((i) => i !== '')
    .join(' ')

  return (
    <section id={model.anchorId} className={classNames}>
      <SharedRTF html={model.tableField}></SharedRTF>
    </section>
  )
}
export default TableBlock
