
import CartButton from '@/components/shared/header-ecomm/components/cart-btn'
import NavToggleButton from '@/components/shared/header-ecomm/components/nav-toggle-btn'
import SharedImage from '@/components/shared/image/shared-image'
import { useGlobalSettings } from '@/stores/global-settings'
import { usePageContext } from '@/stores/page-context-store'
import { useSsrInitSettings } from '@/stores/ssr-init-store'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { BreadcrumbsEcomm } from '../breadcrumbs/breadcrumbs-ecomm'
import { HeaderProps } from './types/header-ecomm'
import { useHydrated } from 'react-hydration-provider'

const HeaderEcomm = (props: Readonly<HeaderProps>) => {
  const hydrated = useHydrated()
  const { t } = useTranslation()
  const { localizations } = props
  const globalSettings = useGlobalSettings((state) => state.globalSettings)
  const location = useLocation()
  const { pathname } = location

  const CheckoutUrl = globalSettings?.checkoutPageUrl

  const isOnCheckoutPage: boolean = CheckoutUrl === pathname

  const { contentTypes } = usePageContext()

  const getPageType = () => {
    if (!contentTypes) return null
    return contentTypes[contentTypes.length - 1]
  }

  const isOnEventPage = getPageType() === 'EventRegistrationPage'

  const { wasInitForDesktop } = useSsrInitSettings()
  const [isDesktop, setIsDesktop] = useState(wasInitForDesktop())
  const handleResize = () => {
    if (window.matchMedia('(max-width: 767px)').matches) {
      setIsDesktop(false)
    } else {
      setIsDesktop(true)
    }
  }

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize, false)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <header className="z-40 bg-white">
      <div className="main-navigation">
        {hydrated && isDesktop && (
          <div>
            <a href="#mainContent" className="sr-only">{t('ScreenReader.SkipNavigation')}</a>
          </div>
        )}
        <div>
          <div className="header-container ml-6 flex min-h-[70px] justify-between">
            <a
              href={globalSettings?.logoLink?.href ?? '/'}
              title={globalSettings?.logoLink?.title ?? 'Logo Link'}
              className="my-4 max-w-[164px] align-top"
            >
              <span className="sr-only">{hydrated && t('ScreenReader.HeaderLogoDescription')}</span>
              {globalSettings?.logo && (
                <SharedImage
                  {...globalSettings?.logo}
                  svgFullWidth={true}
                  mobileBreakpoint={1280}
                  className="w-full align-top"
                />
              )}
            </a>
            <div className="flex">
              <CartButton />
              {!(isOnEventPage || isOnCheckoutPage) && (
                <NavToggleButton localizations={localizations} />
              )}
            </div>
          </div>
        </div>
        
      </div>

      <div className="min-h-[56px] bg-dark-blue-primary px-6 text-white">
        {!isOnCheckoutPage && isDesktop && <BreadcrumbsEcomm />}
      </div>

    </header>
  )
}

export default HeaderEcomm
