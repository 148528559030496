import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import BlockComponentSelector from '@/epi/block-component-selector'
import { LandingPageProps } from './types/landing-page-types'

const LandingPage: React.FC<LandingPageProps> = ({ model }) => {
  return (
    <>
      <Breadcrumbs />
      <div className="flex flex-col space-y-14 md:space-y-20">
        {model.hero && <BlockComponentSelector {...model.hero} />}
        <div className="flex flex-col space-y-14 md:space-y-20">
          {model.pageContent?.map((item, i) => (
            <BlockComponentSelector {...item} key={`pageContent-${i}`} />
          ))}
        </div>
      </div>
    </>
  )
}
export default LandingPage
