import SharedLink from '@/components/shared/buttons/shared-link'
import BlogItemFeatured from '@/components/shared/cards/blog-item-featured'
import BlogItemHighlighted from '@/components/shared/cards/blog-item-highlighted'
import SectionHeader from '@/components/shared/section-header'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { uid } from 'uid'
import { RecentBlogsBlockProps } from './types/recent-blogs'

const RecentBlogsBlock = (props: Readonly<RecentBlogsBlockProps>) => {
  const {
    heading,
    smallIntro,
    featuredLabelText,
    viewAllLink,
    featuredBlogPost,
    highlightedBlogPosts,
  } = props.model
  return (
    <div className="container">
      <SectionHeader heading={heading} smallIntro={smallIntro}>
        {viewAllLink && (
          <SharedLink {...viewAllLink} style={viewAllLink.buttonStyle}>
            {viewAllLink.text}
            <ArrowRightIcon className="h-4 w-4 ml-2" />
          </SharedLink>
        )}
      </SectionHeader>

      <div className="flex flex-col lg:flex-row gap-8">
        <div className="lg:w-2/3 xl:w-1/2">
          {featuredBlogPost && (
            <BlogItemFeatured
              {...featuredBlogPost}
              featuredLabelText={featuredLabelText}
            />
          )}
        </div>

        <div className="lg:w-1/3 xl:w-1/2 flex flex-col justify-between sm:gap-6 lg:gap-0">
          {highlightedBlogPosts.map((card) => (
            <BlogItemHighlighted {...card} key={uid()} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default RecentBlogsBlock
