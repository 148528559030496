import SharedLink from '@/components/shared/buttons/shared-link'
import DesktopNavItem from '@/components/shared/header/components/desktop-nav-item'
import MobileNav from '@/components/shared/header/components/mobile-nav'
import MobileNavButton from '@/components/shared/header/components/mobile-nav-btn'
import Search from '@/components/shared/header/components/search'
import UtilityNav from '@/components/shared/header/components/utility-nav'
import { HeaderProps } from '@/components/shared/header/types/header'
import SharedImage from '@/components/shared/image/shared-image'
import BlockComponentSelector from '@/epi/block-component-selector'
import { useMediaStore } from '@/stores/media-store'
import { usePrimaryMenuStore } from '@/stores/primary-menu'
import { usePrimaryMenuMobileStore } from '@/stores/primary-menu-mobile'
import {
  ArrowUpRightIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid'
import { useEffect, useRef, useState } from 'react'
import { useHydrated } from 'react-hydration-provider'
import { useTranslation } from 'react-i18next'
import { uid } from 'uid'


function Header(props: Readonly<HeaderProps>) {
  const [isLoaded, setIsLoaded] = useState(false);
  const hydrated = useHydrated()
  const { model } = props
  const { isDesktop } = useMediaStore()
  const { t } = useTranslation()

  const {
    resetActiveMenuIndex,
    resetActiveSubMenuIndex,
    setRemainingContainerSpace,
  } = usePrimaryMenuStore()

  const { mobileMenuIsOpen, handleMobileSearchMenuToggle } =
    usePrimaryMenuMobileStore()

  const container = useRef<HTMLDivElement>(null)

  const handleIndexReset = () => {
    resetActiveMenuIndex()
    resetActiveSubMenuIndex()
  }

  const handleRemainingContainerSpace = () => {
    if (container.current) {
      setRemainingContainerSpace(
        container.current?.getBoundingClientRect().right,
      )
    }
  }

  useEffect(() => {
    setIsLoaded(true);
    handleRemainingContainerSpace()
    window.addEventListener('resize', handleRemainingContainerSpace, false)
    return () => {
      window.removeEventListener('resize', handleRemainingContainerSpace)
    }
  }, [])

  return (

    <header className={`bg-white ${mobileMenuIsOpen ? 'h-screen' : ''}`}>
      {model.trackingScriptContent && (
        <BlockComponentSelector
          {...model.trackingScriptContent}
          key={uid()}
          hideContainerClass={true}
        />
      )}
      {isLoaded && (
      <div
        className={`container flex flex-col space-y-[18px] ${!isDesktop ? 'border-b border-grey-secondary-dark' : ''
          }`}
      >
        {isDesktop && (
          <div>
            <a href="#mainContent" className="sr-only">{hydrated && t('ScreenReader.SkipNavigation')}</a>
            <UtilityNav {...props} />
          </div>
        )}

        <div
          ref={container}
          className={`relative flex items-center py-2 lg:py-4 justify-between ${isDesktop ? 'border-b border-grey-secondary-light' : ''
            }`}
        >
          <div className="flex">
            <a
              href={model.mainNavigationBar?.homePageUrl ?? '/'}
              className="max-w-[75px] lg:max-w-[164px]"
            >
              <span className="sr-only">{hydrated && t('ScreenReader.HeaderLogoDescription')}</span>
              {model.mainNavigationBar?.desktopLogo && (
                <SharedImage
                  {...model.mainNavigationBar.desktopLogo}
                  mobileUrl={model.mainNavigationBar.mobileLogo?.url}
                  svgFullWidth={true}
                  mobileBreakpoint={1280}
                  className="w-full"
                />
              )}
            </a>
          </div>

          {isDesktop && model.mainNavigationBar?.primaryMenu && (
            <nav
              aria-label={hydrated ? t('ScreenReader.MainNavigation'): undefined}
              className="py-2"
              onMouseLeave={handleIndexReset}
            >
              <ul className="flex items-center space-x-4">
                {model.mainNavigationBar.primaryMenu.map((item, i) => (
                  <DesktopNavItem
                    key={item.model.contentLink}
                    {...item}
                    menuIndex={i}
                  />
                ))}
              </ul>
            </nav>
          )}

          {isDesktop && (
            <div className="flex space-x-4">
              <Search
                placeholder={model.mainNavigationBar?.searchBar?.placeholder}
                pageUrl={model.mainNavigationBar?.searchBar?.pageUrl}
                isDesktop={isDesktop}
              />

              {model.mainNavigationBar?.contactUsButton &&
                model.mainNavigationBar?.contactUsButton.href && (
                  <SharedLink
                    href={model.mainNavigationBar?.contactUsButton.href}
                    target={model.mainNavigationBar?.contactUsButton.target}
                    isExternal={
                      model.mainNavigationBar?.contactUsButton.isExternal
                    }
                    style="primary"
                    size="small"
                    className="space-x-3"
                  >
                    <span>
                      {model.mainNavigationBar?.contactUsButton.title}
                    </span>
                    <span>
                      <ArrowUpRightIcon className="text-white h-5 w-5" />
                    </span>
                  </SharedLink>
                )}
            </div>
          )}

          {!isDesktop && (
            <div className="flex items-center space-x-2">
              <div>
                <button
                  className="w-10 h-10 flex items-center justify-center"
                  onClick={handleMobileSearchMenuToggle}
                >
                  <span className="sr-only">{hydrated && t('ScreenReader.OpenSearch')}</span>
                  <span>
                    <MagnifyingGlassIcon className="w-6 h-6 text-dark-blue-primary" />
                  </span>
                </button>

                <Search
                  placeholder={model.mainNavigationBar?.searchBar?.placeholder}
                  pageUrl={model.mainNavigationBar?.searchBar?.pageUrl}
                  isDesktop={isDesktop}
                />
              </div>
              <MobileNavButton />
            </div>
          )}
        </div>
        </div>
      )}
      {!isDesktop && isLoaded && <MobileNav {...props} />}
    </header>
  )
}

export default Header
