import SharedImage from '@/components/shared/image/shared-image'
import { useState } from 'react'
import { CardProps } from '../types/training-approach-types'

function TraingingCard(props: Readonly<CardProps>) {
  const {
    heading,
    subheading,
    image,
    index,
    activeIndex,
    updateActiveIndex,
    pauseTimer,
    isDesktop,
  } = props

  const [pauseAnimation, setPauseAnimation] = useState(false)

  const handleFocusElement = () => {
    if (isDesktop) {
      updateActiveIndex(index)
      pauseTimer(true)
      setPauseAnimation(true)
    }
  }

  const handleUnFocusElement = () => {
    if (isDesktop) {
      pauseTimer(false)
      setPauseAnimation(false)
    }
  }

  return (
    <button
      className={`group hover:cursor-default flex-1 2xl:flex-none shared-transition min-h-[333px] 2xl:max-h-[333px] bg-grey-secondary-light rounded-lg flex flex-col 2xl:flex-row space-y-reverse space-y-4 2xl:space-y-0 pt-2 2xl:pt-0 px-2 2xl:px-0 pb-6 2xl:pb-0
      ${index === activeIndex ? '2xl:w-1/2' : '2xl:w-1/4'}
      `}
      onClick={() => updateActiveIndex(index)}
      onKeyUp={handleFocusElement}
      onKeyDown={handleUnFocusElement}
      onMouseEnter={handleFocusElement}
      onMouseLeave={handleUnFocusElement}
    >
      <div className="order-1 2xl:order-none 2xl:h-full text-left flex-1 flex flex-col 2xl:justify-between 2xl:pt-6 2xl:px-6 2xl:pb-10 space-y-4">
        <div className="flex items-start">
          <div className="h-10 2xl:h-14 w-10 2xl:w-14 rounded-[20px] 2xl:rounded-[28px] inline-flex justify-center items-center bg-orange-primary text-grey-dark font-bold text-[32px] 2xl:text-4xl">
            {index}
          </div>
        </div>
        <div className="flex flex-col flex-1 2xl:flex-none justify-between 2xl:justify-normal">
          <div className="flex flex-col px-2 2xl:px-0">
            {heading && (
              <h2 className="text-2xl text-grey-dark font-bold md:line-clmap-3 2xl:line-clamp-2">
                {heading}
              </h2>
            )}
            {subheading && (
              <div
                className={`overflow-hidden shared-transition ${
                  index === activeIndex ? 'md:max-h-48' : 'md:max-h-0'
                }`}
              >
                <div className="mt-2 2xl:mt-4 text-grey-light 2xl:text-grey-dark md:line-clamp-5 2xl:line-clamp-3">
                  {subheading}
                </div>
              </div>
            )}
          </div>
          <div
            className={`block w-full overflow-hidden shared-transition max-h-12 ${
              index === activeIndex ? 'md:max-h-12' : 'md:max-h-0'
            }`}
          >
            <div
              className={`mt-6 md:mt-0 2xl:mt-9 h-[3px] w-full flex rounded-sm relative ${
                index === activeIndex
                  ? 'animate-bg-progress-bar md:group-hover:pause'
                  : ''
              } ${pauseAnimation ? 'md:pause' : ''}`}
            >
              <span
                className={`h-[3px] rounded-sm absolute bg-dark-blue-primary top-0 left-0 ${
                  index === activeIndex
                    ? 'animate-progress-bar md:group-hover:pause'
                    : ''
                } ${pauseAnimation ? 'md:pause' : ''}`}
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`md:hidden 2xl:block order-0 2xl:order-none w-full 2xl:h-full shared-transition
          ${index === activeIndex ? '2xl:max-w-[292px]' : '2xl:max-w-[0]'}
        `}
      >
        <div className="2xl:ml-7 2xl:h-full 2xl:pt-2 2xl:pr-2 2xl:pb-2">
          <div className="aspect-[16/9] 2xl:aspect-none 2xl:w-full 2xl:h-full">
            {image && (
              <SharedImage
                {...image}
                className="h-full w-full object-cover rounded-md"
              />
            )}
          </div>
        </div>
      </div>
    </button>
  )
}

export default TraingingCard // TODO: Fix Spelling
