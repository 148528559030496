import './styles/shared-rtf.scss'

interface RTFHTMLProps {
  html: string
  className?: string
}

const RTF = (props: Readonly<RTFHTMLProps>) => {
  const { html, className } = props
  return (
    <div>
      <div
        className={`rich-text prose lg:prose-xl prose-headings:black prose-a:text-dark-blue-primary prose-a:no-underline hover:prose-a:underline !max-w-none ${className}`}
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <div className="clear-both"></div>
    </div>
  )
}
export default RTF
