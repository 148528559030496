import { isBrowser } from '@/lib/is-browser'
import { create } from 'zustand'

const enum LocalStorageKeys {
  isLocked = 'customer_hard_lock_is_locked',
  hasAcknowledged = 'customer_hard_lock_has_acknowledged',
}

interface CustomerHardLockStoreProps {
  isCustomerHardLocked: boolean
  isModalOpen: boolean
  hasAcknowledged: boolean
  openModal: () => void
  closeModal: () => void
  setIsCustomerHardLocked: (state: boolean) => void
  setHasAcknowledged: (state: boolean) => void
  reset: () => void
}

export const useCustomerHardLockStore = create<CustomerHardLockStoreProps>(
  (set) => {
    const hasAcknowledged =
      isBrowser() &&
      localStorage.getItem(LocalStorageKeys.hasAcknowledged) === 'true'
    const isCustomerHardLocked =
      isBrowser() && localStorage.getItem(LocalStorageKeys.isLocked) === 'true'

    return {
      hasAcknowledged,
      isCustomerHardLocked,
      isModalOpen: false,
      openModal: () =>
        set((state) => {
          return state.isCustomerHardLocked ? { isModalOpen: true } : state
        }),
      closeModal: () => set(() => ({ isModalOpen: false })),
      setIsCustomerHardLocked: (isLocked) =>
        set(() => ({ isCustomerHardLocked: isLocked })),
      setHasAcknowledged: (state) => set(() => ({ hasAcknowledged: state })),
      reset: () =>
        set(() => ({
          isCustomerHardLocked: false,
          isModalOpen: false,
          hasAcknowledged: false,
        })),
    }
  },
)

useCustomerHardLockStore.subscribe((state) => {
  isBrowser() &&
    localStorage.setItem(
      LocalStorageKeys.hasAcknowledged,
      state.hasAcknowledged ? 'true' : '',
    )
  isBrowser() &&
    localStorage.setItem(
      LocalStorageKeys.isLocked,
      state.isCustomerHardLocked ? 'true' : '',
    )
})
