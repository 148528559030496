import { PartialNullable } from '@/lib/partial-nullable.type'
import { DEFAULT_PRICE, DEFAULT_QUANTITY, EMPTY_VALUE, ITEM_BRAND } from '../constants'
import { EventType } from '../enums/event-type.enum'
import { includeItemCategories } from '../functions/include-item-categories.function'
import { includeItemLanguage } from '../functions/include-item-language.function'
import { pushEvent } from '../functions/push-event.function'
import { EventItemParameters } from '../types/event-item-parameters.type'

export const viewItemListGTM = async (products: any[], itemListId: string) => {
  const ecommerce: { items: any[]; item_list_id: string; item_list_name: string } = {
    items: [],
    item_list_id: itemListId,
    item_list_name: itemListId,
  }

  await Promise.all(
    products.map(async (product) => {
      const item: PartialNullable<EventItemParameters> = {
        item_id: product.contentlinkId,
        item_name: product.title,
        item_brand: ITEM_BRAND,
        item_list_id: itemListId,
        item_list_name: itemListId,
        item_variant_id: EMPTY_VALUE,
        price: DEFAULT_PRICE,
        quantity: DEFAULT_QUANTITY,
      }
      await includeItemCategories(item, product.contentlinkId)
      await includeItemLanguage(item, product.contentlinkId)

      ecommerce.items.push(item)
    }),
  )

  pushEvent({
    event: EventType.ViewItemList,
    ecommerce: ecommerce,
  })
}
