import SharedImage from '@/components/shared/image/shared-image'
import { BlogItemHighlightedProp } from '@/types/shared'

const BlogItemHighlighted = (props: Readonly<BlogItemHighlightedProp>) => {
  return (
    <a
      className="flex flex-col sm:flex-row gap-x-8 gap-y-2 text-grey-dark hover:text-grey-dark hover:no-underline"
      href={props.url}
    >
      <div className="sm:w-1/3 hidden xl:block sm:block md:block lg:hidden">
        {props.image && (
          <SharedImage
            className="aspect-[32/21] object-cover rounded-lg"
            {...props.image}
          />
        )}
      </div>

      <div className="sm:w-2/3 w-full flex flex-col">
        {props.title && (
          <h4 className="mb-2 text-base xl:text-2xl font-bold">
            {props.title}
          </h4>
        )}

        {props.description && (
          <p className="mb-3 text-base text-grey-light">{props.description}</p>
        )}

        {props.industry && (
          <span className="text-xs uppercase tracking-[1.2px] text-grey-light font-bold">
            {props.industry}
          </span>
        )}
      </div>
    </a>
  )
}

export default BlogItemHighlighted
