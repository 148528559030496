import { epiApiDelete, epiApiGet, epiApiPost, epiApiPut } from '@/api/content-delivery';
import { getAddressAutoComplete, getAddressVerification } from '@/api/melissa-address/melissa';
import { AddressCompletionViewModel, AddressVerificationViewModel, Parameter } from '@/api/melissa-address/types/melissa-address-types';
import { GET_ME, GET_ME_ADDRESSES, GET_ME_CART } from '@/api/routes';
import { CustomerAddressType } from '@/api/types/content-delivery-enums';
import { AddressModel, CartModel, CustomerModel, ShippingAndBillingAddressModel } from '@/api/types/content-delivery-types'
import { create } from 'zustand'
import { useUserStore } from './useUserStore';
import { DEFAULT_MARKET } from '@/lib/constants';

export interface AddressBookFormWarning {
    address1: string[]
    address2: string[]
    city: string[]
    provinceOrState: string[]
    postalCode: string[]
    partiallyValidated: string[];
}

interface AddressStoreProps {
    hasWarning: boolean
    addressFieldsHidden: boolean,
    showAddressBookModal: boolean
    addressCompletions: AddressCompletionViewModel | null
    customer: CustomerModel | null;
    addresses: AddressModel[] | null
    isLoading: boolean
    addressFormEditMode: boolean
    editableAddress: AddressModel | null
    setAddressFieldsHidden: (addressFieldsHidden: boolean) => void
    setHasWarning: (hasWarning: boolean) => void
    setEditableAddress: (editableAddress: AddressModel | null) => void
    setAddressFormEditMode: (addressBookFormEditable: boolean) => void
    setIsLoading: (isLoading: boolean) => void
    setCustomer: (customer: CustomerModel | null) => void
    setAddresses: (addresses: AddressModel[] | null) => void
    setAddressCompletions: (addressCompletions: AddressCompletionViewModel | null) => void
    setShowAddressBookModal: (isLoading: boolean) => void
    getAddressBookData: () => Promise<void>
    getValidatedAddress: (payload: Parameter[]) => Promise<AddressVerificationViewModel | null>
    getCompletionAddresses: (payload: Parameter[]) => Promise<void>
    putCustomer: (customerInfoModel: CustomerModel | null) => Promise<void>
    saveAddress: (addressModel: AddressModel, isEdit?: boolean) => Promise<void>
    deleteAddress: (addressModel: AddressModel) => Promise<void>
    createAddress: (address: AddressModel, targetType: CustomerAddressType) => AddressModel
    addressModelToShippingAndBilling: (address: AddressModel | null) => ShippingAndBillingAddressModel | null
    makeDefaultAddress: (address: AddressModel | null) =>Promise<void>
}

export const useAddressStore = create<AddressStoreProps>((set, get) => ({
    hasWarning: false,
    warnings: null,
    addressFieldsHidden: true,
    addressFormEditMode: false,
    showAddressBookModal: false,
    addressCompletions: null,
    customer: null,
    addresses: [],
    isLoading: false,
    editableAddress: null,
    setHasWarning: (hasWarning: boolean) => set(() => ({ hasWarning })),
    setAddressFieldsHidden: (addressFieldsHidden: boolean) => set(() => ({ addressFieldsHidden })),
    setEditableAddress: (editableAddress) => set(()=> ({editableAddress: editableAddress})),
    setAddressFormEditMode: (addressFormEditMode: boolean) => set(() => ({ addressFormEditMode })),
    setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
    setShowAddressBookModal: (showAddressBookModal: boolean) => set(() => ({ showAddressBookModal })),
    setAddresses: (addresses) => set(()=> ({addresses: addresses})),
    setCustomer: (customer) => set(()=> ({customer: customer})),
    setAddressCompletions: (addressCompletions) => set(()=> ({addressCompletions: addressCompletions})),
    getAddressBookData: async () => {
        const currentState = get()
        const customer = await epiApiGet<CustomerModel>(GET_ME)
        if (customer) 
        {
            currentState.setCustomer(customer)
            currentState.setAddresses(customer?.addresses)
        }
    },
    getCompletionAddresses: async (payload: Parameter[]) => {
        const currentState = get()
        const addressResult = await getAddressAutoComplete(payload)
        if (addressResult) {
            currentState.setAddressCompletions(addressResult)
        }
    },
    putCustomer: async (customerInfoModel: CustomerModel | null) => {
        if (!customerInfoModel) return;
        const currentState = get()
        const updatedCustomer = await epiApiPut<CustomerModel>(GET_ME, customerInfoModel)
        if (updatedCustomer) {
            currentState.setCustomer(updatedCustomer)
            currentState.setAddresses(updatedCustomer.addresses)
        }
    },
    getValidatedAddress: async (payload: Parameter[]) => {
        try {
            const addressVerificationResult = await getAddressVerification(payload);
            if (addressVerificationResult) {
                return addressVerificationResult
            }
        } catch(e) {
            console.error(`Error`, e)
        }
        return null
    },
    saveAddress: async (addressModel: AddressModel | null, isEdit: boolean = false) => {
        if (!addressModel) return
        const currentState = get()
        const updatedAddress = await epiApiPost<AddressModel>(GET_ME_ADDRESSES, addressModel)
        if (!updatedAddress || currentState.addresses === null) return

        if (isEdit)
        {
            // Update if we have address.
            const addresses = currentState.addresses.map(x => x.name === addressModel.name ? updatedAddress : x);
            currentState.setAddresses(addresses)
        } else {
            await currentState.getAddressBookData()
        }

    },
    deleteAddress: async (addressModel: AddressModel | null) => {
        if (!addressModel) return
        const userState = useUserStore.getState()
        const currentState = get()
        const customer: CustomerModel = {
            ...currentState.customer!
        }
        const cart = await epiApiGet<CartModel>(GET_ME_CART(userState.user?.authInfo?.market ?? DEFAULT_MARKET))

        if (!cart) return

        if (addressModel.addressType?.includes(CustomerAddressType.Billing) && addressModel.name.includes(customer.preferredBillingAddressName ?? ""))
        {
            customer.preferredBillingAddressName = null
        }
        if (addressModel.addressType?.includes(CustomerAddressType.Shipping) && addressModel.name.includes(customer.preferredShippingAddressName ?? ""))
        {
            customer.preferredShippingAddressName = null
        }
       
        await currentState.putCustomer(customer)
        
        const isDeleted = await epiApiDelete<AddressModel>(`${GET_ME_ADDRESSES}/${encodeURIComponent(addressModel.name)}`)
        if (isDeleted) {
            const updatedAddresses = currentState.addresses?.filter(address => address.name !== addressModel.name) ?? [];
            if (updatedAddresses)
            {
                currentState.setAddresses(updatedAddresses);
            }
        }
    },
    createAddress:(address: AddressModel, targetType: CustomerAddressType) => {
        return {
          ...address,
          addressType: [1, targetType]
        } as AddressModel
    },
    makeDefaultAddress: async (address: AddressModel | null) => {
        if (!address) return;
        const currentState = get()
        if (!currentState.customer) return
        const hasAddress = address.addressType?.includes(CustomerAddressType.Billing) || address.addressType?.includes(CustomerAddressType.Shipping)

        const customerInfoModel: CustomerModel = {
            ...currentState.customer
        }

        if (hasAddress) {
            customerInfoModel.preferredBillingAddressName = address.name
            customerInfoModel.preferredShippingAddressName = address.name
        }

        if (customerInfoModel.preferredBillingAddressName || customerInfoModel.preferredShippingAddressName) {
            await currentState.putCustomer(customerInfoModel)
        }
    },
    addressModelToShippingAndBilling:(address: AddressModel | null) => {
        if(!address) return null
        return {
            firstName: address.firstName ?? "",
            lastName: address.lastName ?? "",
            line1: address.line1,
            line2: address.line2 ?? "",
            city: address.city,
            countryName: address.countryName ?? "",
            countryCode: address.countryCode ?? "",
            postalCode: address.postalCode ?? "",
            regionName: address.region ?? "",
            email: address.email ?? "",
            phoneNumber: (address.daytimePhoneNumber ?? address.eveningPhoneNumber) ?? "",
            name: address.name
        }
    }
}))
