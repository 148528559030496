import { CareersProps } from '@/components/blocks/careers/types/careers-types'
import SharedLink from '@/components/shared/buttons/shared-link'
import SectionHeader from '@/components/shared/section-header'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { uid } from 'uid'

const CareersBlock = (props: Readonly<CareersProps>) => {
  const { smallIntro, sectionHeading, sectionSubheading, sectionButton, jobs } =
    props.model
  return (
    <section
      className={`
     ${!props.hideContainerClass ? 'container' : ''}`}
    >
      {(sectionHeading || smallIntro || sectionSubheading || sectionButton) && (
        <SectionHeader
          heading={sectionHeading}
          smallIntro={smallIntro}
          subheading={sectionSubheading}
        >
          <SharedLink style="outline" {...sectionButton}>
            {sectionButton?.text}
            <ArrowRightIcon className="h-4 w-4 ml-2" />
          </SharedLink>
        </SectionHeader>
      )}

      {jobs.map((job, i) => {
        const isLast = i === jobs.length - 1
        return (
          <div
            key={uid()}
            className={`relative py-2 px-4 md:py-5 md:px-10
            ${job.model.showGreyBackground ? 'bg-grey-secondary-light' : ''}
            ${i === 0 ? 'pt-4 lg:pt-8 rounded-t-lg' : ''}
            ${isLast ? 'pb-4 lg:pb-8 rounded-b-lg ' : ''}
            `}
          >
            <div className="flex flex-col md:flex-row justify-between md:items-center">
              <div className="flex-1">
                <div className="text-grey-dark font-bold text-lg md:text-2xl">
                  {job.model.title}
                </div>
                <div className="flex items-center text-grey-light text-sm md:text-lg">
                  <span>{job.model.detail}</span>
                  <span className="mx-2 inline-block text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="7"
                      viewBox="0 0 6 7"
                      fill="none"
                    >
                      <circle cx="3" cy="3.86719" r="3" fill="#E4E3E9" />
                    </svg>
                  </span>

                  <span>{job.model.location}</span>
                </div>
              </div>
              <div className="flex-1 text-left md:text-right">
                <SharedLink
                  style="text"
                  {...job.model.link}
                  className="!font-bold !bg-transparent !pl-0 mt-2 lg:mt-0"
                >
                  {job.model.link?.text}
                  <ArrowRightIcon className="lg:h-5 lg:w-5 w-4 h-4 lg:ml-3 ml-2 min-w-[20px]" />
                </SharedLink>
              </div>
            </div>
            {!isLast ? (
              <hr className="absolute bottom-0 bg-grey-secondary-dark h-0.5 border-none w-[calc(100%_-_32px)] lg:w-[calc(100%_-_80px)]" />
            ) : null}
          </div>
        )
      })}
    </section>
  )
}

export default CareersBlock
