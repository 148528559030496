import { AddressBookFormInputs, AddressBookFormProps, AddressFormFieldsWarning, SelectOption } from "./types/address-types"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import classNames from "classnames"
import AddressCompletionSelect from "./addresscompletion-select";
import { AddressCompletion, Parameter } from "@/api/melissa-address/types/melissa-address-types";
import { useEffect, useState } from "react";
import { AddressBookFormWarning, useAddressStore } from "@/stores/useAddressStore";
import { phoneNumberMask } from "@/components/pages/checkout/masks/phone-number-mask";
import { CustomerAddressType } from "@/api/types/content-delivery-enums";
import { AddressVerificationInputCodes } from "@/api/melissa-address/enums/address-verification-enums";
import { renderErrors, renderWarnings } from "@/api/validation/input-validation";
import { useTranslation } from "react-i18next";
import { EMAIL_REGEX } from "@/api/validation";
import Tooltip from './components/tooltip'


const AddressBookForm: React.FC<Readonly<AddressBookFormProps>> = (props: Readonly<AddressBookFormProps>) => {
    const { t } = useTranslation()
    const [ selectValue, setSelectValue ] = useState<SelectOption | null>(null)
    const [ addressInput, setAddressInput ] = useState<string>("")
    const [ invalidForm, setInvalidForm ] = useState<boolean>(false)
    const { hasWarning, editableAddress, addressFieldsHidden, getValidatedAddress, setAddressCompletions, setHasWarning, setAddressFieldsHidden } = useAddressStore()
    const [ warnings, setWarnings ] = useState<{ [key in keyof AddressFormFieldsWarning]?: string[] } | null>(null)
    const {
        className,
        isEditable,
        onSubmitForm,
        localizations,
        selectBilling,
        selectShipping
    } = props;
    const [ editableSelectValue, setEditableSelectValue ] = useState<SelectOption | null>(isEditable ? {value:editableAddress?.line1 ?? "", label: `${editableAddress?.line1}, ${editableAddress?.region}`} : null);

    const {
        register,
        handleSubmit,
        setValue,
        setFocus,
        control,
        watch,
        formState: { errors },
    } = useForm<AddressBookFormInputs>({
        values: isEditable ? {
            addressSelector: editableAddress?.line1 ?? "",
            addressName: editableAddress?.name ?? "",
            address1: editableAddress?.line1 ?? "",
            firstName: editableAddress?.firstName ?? "",
            lastName: editableAddress?.lastName ?? "",
            address2: editableAddress?.line2 ?? "",
            city: editableAddress?.city ?? "",
            country: editableAddress?.countryName ?? "",
            countryCode: editableAddress?.countryCode ?? "",
            provinceOrState: editableAddress?.region ?? "",
            postalCode: editableAddress?.postalCode ?? "",
            email: editableAddress?.email ?? "",
            isBilling: editableAddress?.addressType?.includes(CustomerAddressType.Billing) ?? false,
            isShipping: editableAddress?.addressType?.includes(CustomerAddressType.Shipping) ?? false,
            phoneNumber: (editableAddress?.daytimePhoneNumber ?? editableAddress?.eveningPhoneNumber) ?? ""
        } : undefined,
    });

    const defaultFormStyles = classNames("CommerceForms EPiServerForms flex flex-col gap-3", className);
    const watchedFields = watch();

    const handleAddressValidation = async (addressVerificationParams: Parameter[]) => {
        const addressVerificationResult = await getValidatedAddress(addressVerificationParams)
        if(addressVerificationResult)
        {
            const isWarnings = addressVerificationResult?.verificationWarnings && addressVerificationResult?.verificationWarnings.length > 0
            isWarnings && setHasWarning(true)
            isWarnings && addressVerificationResult?.verificationWarnings.forEach((warning) => {
                setWarnings(prevWarnings => {
                    const key = warning.warningId as keyof AddressBookFormWarning;
                    return {
                        ...prevWarnings,
                        [key]: warning?.warningMessages
                    };
                });
            });
            return isWarnings
        }
        return false;
    }
    
    const validateAddress = async (data: Parameter[]) => {
        const hasWarnings = await handleAddressValidation(data)
        setHasWarning(hasWarnings)
        return hasWarnings
    }

    const onSubmit: SubmitHandler<AddressBookFormInputs> = async (data, e) => {

        const hasWarnings = await validateAddress([
            {
              paramType: AddressVerificationInputCodes.Address1,
              paramValue: data.address1,
            },
            {
              paramType: AddressVerificationInputCodes.Country,
              paramValue: data.countryCode,
            },
            {
              paramType: AddressVerificationInputCodes.City,
              paramValue: data.city,
            },
            {
              paramType: AddressVerificationInputCodes.PostalCode,
              paramValue: data.postalCode,
            },
            {
              paramType: AddressVerificationInputCodes.StateOrProvince,
              paramValue: data.provinceOrState,
            }
        ])

        if (hasWarnings)
        {
            if (hasWarning === false)
            {
                return
            }
        }
        setHasWarning(false)
        const countryName = localizations.countries.find(country => country.name === data.countryCode)?.code ?? ""
        data.country = countryName
        if (watchedFields.isBilling === false && watchedFields.isShipping === false)
        {
            setInvalidForm(true)
            setFocus("isShipping")
            return
        }

        onSubmitForm(data, e)
    }

    const handleAddressSelectChange = (selectedOption: SelectOption | null) => {
        if (!selectedOption)
        {
            setValue("address1", "")
            setValue("address2", "")
            setValue("city", "")
            setValue("postalCode", "")
        }
    }

    const handleAddressSelectInputChange = (partialAddress: string) => {
        setAddressInput(partialAddress)
    }

    const selectedAddressCallBack = (completedAddress: AddressCompletion) => {
        if (completedAddress) {
            const options = {shouldDirty: true}
            setValue("address1", completedAddress.address1, options);
            setValue("city", completedAddress.locality, options);
            setValue("postalCode", completedAddress.postalCode, options);
        }
    }

    const checkBoxErrorStyle = invalidForm ? {border: "1px solid #dc2626" } : {}

    // When the country or province changes, we will clear out our selection.
    useEffect(()=>{
        if(isEditable) return
        setAddressCompletions(null)
        setSelectValue(null)
        setValue("address1", "")
        setValue("address2", "")
        setValue("city", "")
        setValue("postalCode", "")
    }, [watchedFields.countryCode, watchedFields.provinceOrState])
    
    // Show address fields when editable mode
    useEffect(()=>{
        if(isEditable)
        {
            const warningCheck = async () => {
                const haswWarnings = await validateAddress([
                    {
                      paramType: AddressVerificationInputCodes.Address1,
                      paramValue: editableAddress?.line1 ?? "",
                    },
                    {
                      paramType: AddressVerificationInputCodes.Country,
                      paramValue: editableAddress?.countryCode ?? "",
                    },
                    {
                      paramType: AddressVerificationInputCodes.City,
                      paramValue: editableAddress?.city ?? "",
                    },
                    {
                      paramType: AddressVerificationInputCodes.PostalCode,
                      paramValue: editableAddress?.postalCode ?? "",
                    },
                    {
                      paramType: AddressVerificationInputCodes.StateOrProvince,
                      paramValue: editableAddress?.regionCode ?? "",
                    }
                ])

                if (haswWarnings)
                {
                    setAddressFieldsHidden(false)
                    setWarnings(null)
                }
            }
            warningCheck()
        }

        return () => {
            setAddressFieldsHidden(true)
            setHasWarning(false)
        }
    }, [isEditable])

    // Focus handler for when we have warnings.
    useEffect(()=>{
        if (!hasWarning) return
        setAddressFieldsHidden(false)
        if(warnings?.address1)
        {
            setFocus("address1")
        }else if(warnings?.address2)
        {
            setFocus("address2")
        }else if(warnings?.city)
        {
            setFocus("city")
        }else if(warnings?.postalCode)
        {
            setFocus("postalCode")
        }else if(warnings?.provinceOrState)
        {
            setFocus("provinceOrState")
        }
    },[warnings?.address1, warnings?.address2, warnings?.city, warnings?.postalCode, warnings?.provinceOrState, hasWarning])

    return (
      <form id={props.id} className={defaultFormStyles} onSubmit={handleSubmit(onSubmit)}>

        <div className="flex flex-col">
          <div className={`flex flex-col gap-3 ${errors.addressName ? 'ValidationFail' : ''}`}>
            <label className={"font-bold text-black flex-1 flex flex-row items-center"} htmlFor="addressName">
              {t("FormFields.AddressName.Label")}
              <Tooltip text={t("FormFields.AddressName.Tooltip")} />
            </label>
                <input
                    {...register("addressName",
                    {
                        required: t("FormFields.AddressName.Required")
                    })}
                    type="text"
                    className={`flex-[50%]`}
                    disabled={isEditable}
                />
            </div>
            <div className="flex flex-col gap-3">
                {renderErrors(errors?.addressName)}
            </div>
        </div>

        <hr className="text-grey-400 opacity-30 m-2 mb-0" />

        <input type="hidden" {...register("countryCode")} defaultValue=""/>
        <div className="flex flex-col">
            <div className={`flex flex-col gap-3 ${errors.countryCode ? 'ValidationFail' : ''}`}>
            <label className={"font-bold text-black flex-1 flex flex-row items-center"} htmlFor="countryCode">
              {t("FormFields.Country.Label")}
              <Tooltip text={t("FormFields.Country.Tooltip")} />
            </label>

                <select {...register("countryCode",
                    {
                        required: t("FormFields.Country.Required")
                    })}
                >
                    <option value="" disabled>{t("FormFields.Country.Placeholder")}</option>
                    {localizations?.countries.map((value) => {
                        return(
                            <option key={value.name} value={value.name}>{value.code}</option>
                        )
                    })}
                </select>
            </div>
            <div className="flex flex-col gap-3">
                 {renderErrors(errors?.countryCode)}
            </div>
        </div>

        {/* Province or State */}
        <div className="flex flex-col">
            <div className={`flex flex-col gap-3 ${errors.provinceOrState ? 'ValidationFail' : ''}`}>
                <label className={"font-bold text-black flex-1"} htmlFor="provinceOrState">{t("FormFields.StateOrProvince.Label")} *</label>
                <select {...register("provinceOrState",
                    {
                        required: t("FormFields.StateOrProvince.Required")
                    })}
                    defaultValue=""
                >
                    <option value="" disabled>{t("FormFields.StateOrProvince.Placeholder")}</option>
                    {localizations?.statesAndProvinces.filter(x => x.country.name === watchedFields.countryCode)?.map((value, idx)=>{
                        return(
                            <option key={value.name} value={value.name}>{value.name}</option>
                        )
                    })}
                </select>
            </div>
            <div className="flex flex-col gap-3">
                {renderErrors(errors?.provinceOrState)}
                {renderWarnings(warnings?.provinceOrState)}
            </div>
        </div>
        <hr className="text-grey-400 opacity-30 m-2 mb-0" />

        {/* First Name */}
        <div className="flex flex-col">
          <div className={`flex flex-col gap-3 ${errors.firstName ? 'ValidationFail' : ''}`}>
            <label className={"font-bold text-black flex-1"} htmlFor="firstName">{t("FormFields.FirstName.Label")} *</label>
            <input
              {...register("firstName",
                {
                  required: t("FormFields.FirstName.Required")
                })}
              type="text"
              className={`flex-[50%]`}
            />
          </div>
          <div className="flex flex-col gap-3">
            {renderErrors(errors?.firstName)}
          </div>
        </div>

        {/* Last Name */}
        <div className="flex flex-col">
          <div className={`flex flex-col gap-3 ${errors.lastName ? 'ValidationFail' : ''}`}>
            <label className={"font-bold text-black flex-1"} htmlFor="lastName">{t("FormFields.LastName.Label")} *</label>
            <input
              {...register("lastName",
                {
                  required: t("FormFields.LastName.Required")
                })}
              type="text"
              className={`flex-[50%]`}
            />
          </div>
          <div className="flex flex-col gap-3">
            {renderErrors(errors?.lastName)}
          </div>
        </div>

        <hr className="text-grey-400 opacity-30 m-2 mb-0" />

        {/* Address Selector */}
        {!hasWarning  &&
            <div className="flex flex-col">
                <div className={`flex flex-col gap-3 ${errors.address1 ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="addressSelector">{t("FormFields.AddressSelector.Label")}</label>
                    <AddressCompletionSelect
                        {...register("addressSelector", {
                        })}
                        type="text"
                        className='flex-[50%]'
                        stateOrProvinceISO2={watchedFields.provinceOrState}
                        countryISO2={watchedFields.countryCode}
                        partialAddress={addressInput}
                        selectValue={selectValue ?? editableSelectValue}
                        setSelectValue={setSelectValue}
                        handleChange={handleAddressSelectChange}
                        onSelectedAddress={selectedAddressCallBack}
                        handleInputChange={handleAddressSelectInputChange}
                    />
                </div>
                <div className="flex flex-col gap-3">
                    {renderErrors(errors?.addressSelector)}
                </div>
                <div className="flex flex-col gap-3">
                    <span className="mt-1 flex items-center text-black gap-1">
                        <button type="button" className='bg-transparent text-dark-blue-400 border-transparent
                            hover:text-dark-blue-primary
                            acitve:text-dark-blue-primary
                            disabled:text-grey-400 disabled:cursor-not-allowed min-w-none' onClick={(e) => setAddressFieldsHidden(!addressFieldsHidden)}>{t("AddressBook.ClickHere")}</button>
                        <span>{t("AddressBook.ClickHereContinued")}</span>
                    </span>
                </div>
            </div>
        }

        {addressFieldsHidden &&
            // Ensures we can always collect the form values regardless of them being hidden.
            <>
                <input type="hidden" value={watchedFields.address1}/>
                <input type="hidden" value={watchedFields.address2} />
                <input type="hidden" value={watchedFields.city}/>
                <input type="hidden" value={watchedFields.postalCode}/>
            </>
        }

        {!addressFieldsHidden &&
            <>
                {/* Address Line 1 */}
                <div className="flex flex-col">
                    <div className={`flex flex-col gap-3 ${errors.address1 ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="address1">{t("FormFields.AddressOne.Label")} *</label>
                        <input
                            {...register("address1", {required: t("FormFields.AddressOne.Required")})}
                            type="text"
                            className={`flex-[50%]`}
                        />
                    </div>
                    <div className="flex flex-col gap-3">
                        {renderErrors(errors?.address1)}
                        {renderWarnings(warnings?.address1)}
                    </div>
                </div>

                {/* Address Line 2 */}
                <div className="flex flex-col">
                    <div className={`flex flex-col gap-3 ${errors.address2 ? 'ValidationFail' : ''}`}>
                    <label className={"font-bold text-black flex-1"} htmlFor="address2">{t("FormFields.AddressTwo.Label")}</label>
                        <input
                            {...register("address2")}
                            type="text"
                            className={`flex-[50%]`}
                        />
                    </div>
                    <div className="flex flex-col gap-3">
                        {renderErrors(errors?.address2)}
                        {renderWarnings(warnings?.address2)}
                    </div>
                </div>

                {/* City with required ex*/}
                <div className="flex flex-col">
                    <div className={`flex flex-col gap-3 ${errors.city ? 'ValidationFail' : ''}`}>
                        <label className={"font-bold text-black flex-1"} htmlFor="city">{t("FormFields.City.Label")} *</label>
                        <input
                            {...register("city", {
                                required: t("FormFields.City.Required")
                            })}
                            type="text"
                            className={`flex-[50%]`}
                        />
                    </div>
                    <div className="flex flex-col gap-3">
                        {renderErrors(errors?.city)}
                        {renderWarnings(warnings?.city)}
                    </div>
                </div>

                {/* Postal Code */}
                <div className="flex flex-col">
                    <div className={`flex flex-col gap-3 ${errors.postalCode ? 'ValidationFail' : ''}`}>
                        <label className={"font-bold text-black flex-1"} htmlFor="postalCode">{t("FormFields.PostalCode.Label")} *</label>
                        <input
                            {...register("postalCode",
                            {
                                required: t("FormFields.PostalCode.Required")
                            })}
                            type="text"
                            className={`flex-[50%]`}
                        />
                    </div>
                    <div className="flex flex-col gap-3">
                        {renderErrors(errors?.postalCode)}
                        {renderWarnings(warnings?.postalCode)}
                    </div>
                </div>
            </>
        }

        <hr className="text-grey-400 opacity-30 m-2 mb-0" />

        {/* Email */}
        <div className="flex flex-col">
            <div className={`flex flex-col gap-3 ${errors.email ? 'ValidationFail' : ''}`}>
            <label className={"font-bold text-black flex-1 flex flex-row items-center"} htmlFor="email">
              {t("FormFields.Email.Label")} *
              <Tooltip text={t("FormFields.Email.Tooltip")} />
            </label>
                <input
                    {...register("email",
                    {
                        required: t("FormFields.Email.Required"),
                        pattern: {
                            value: EMAIL_REGEX,
                            message: t("FormFields.Email.Pattern")
                        }
                    })}
                    type="text"
                    className={`flex-[50%]`}
                />
            </div>
            <div className="flex flex-col gap-3">
                {renderErrors(errors?.email)}
            </div>
        </div>

        <div className="flex flex-col">
            <div className={`flex flex-col gap-3 ${errors.phoneNumber ? 'ValidationFail' : ''}`}>
            <label className={"font-bold text-black flex-1 flex flex-row items-center"} htmlFor="phoneNumber">
              {t("FormFields.Phone.Label")} *
              <Tooltip text={t("FormFields.Phone.Tooltip")} />
            </label>
            <Controller
                name="phoneNumber"
                control={control}
                rules={{
                required: t("FormFields.Phone.Required"),
                pattern: {
                    value: /^\d{3}-\d{3}-\d{4}$/,
                    message: t("FormFields.Phone.Pattern")
                },
                }}
                render={({ field }) => (
                <input
                    {...field}
                    type="text"
                    className={`flex-[50%]`}
                    onChange={(e) => {
                        const maskedValue = phoneNumberMask(e.target.value);
                        field.onChange(maskedValue);
                    }}
                />
                )}
            />
            </div>
            <div className="flex flex-col gap-3">
                {renderErrors(errors?.phoneNumber)}
            </div>
        </div>

        <hr className="text-grey-400 opacity-30 m-2 mb-0" />

        {warnings?.partiallyValidated && <div className="flex flex-col gap-3 mb-2 mt-2">
            {renderWarnings(warnings?.partiallyValidated)}
        </div>}

        {invalidForm && <div className="flex flex-col gap-3"><span className="text-red-600">{t("Common.YouMustSelectOne")}</span></div>}

        <div className="flex flex-col CommerceForms EPiServerForms">
            <div className="flex flex-col gap-3">
                <label htmlFor="isShipping" className={"text-black font-bold flex-1"}>{t("FormFields.ShippingAddressCheckbox.Label")}</label>
                <div className={`flex flex-row gap-2`}>
                    <input style={checkBoxErrorStyle} {...register("isShipping")} defaultChecked={selectShipping ?? false} type="checkbox" className={`checkbox`}/>
                    <label className={"label flex-1"} htmlFor="isShipping">{t("FormFields.ShippingAddressCheckbox.Description")}</label>
                </div>
            </div>
        </div>

        <div className="flex flex-col CommerceForms EPiServerForms">
            <div className="flex flex-col gap-3">
                <label htmlFor="isBilling" className={"text-black font-bold flex-1"}>{t("FormFields.BillingAddressCheckbox.Label")}</label>
                <div className={`flex flex-row gap-2`}>
                    <input style={checkBoxErrorStyle} {...register("isBilling")} defaultChecked={selectBilling ?? false} type="checkbox" className={`checkbox`} />
                    <label className={"label flex-1"} htmlFor="isBilling">{t("FormFields.BillingAddressCheckbox.Description")}</label>
                </div>
            </div>
        </div>
    </form>
  )
}

// Set default values to some of the properties.
AddressBookForm.defaultProps = {
  className: "flex flex-col"
}

export default AddressBookForm
