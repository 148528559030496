import { usePrimaryMenuStore } from '@/stores/primary-menu'
import SharedLink from '../../buttons/shared-link'
import { PrimaryMenuSubNavItem } from '../types/header'

function SubNavItem(props: Readonly<PrimaryMenuSubNavItem>) {
  const { model, menuIndex, itemIndex, isLastItem, hasCTAlink } = props
  const {
    activeMenuIndex,
    activeSubMenuIndex,
    incrementActiveSubMenuIndex,
    resetActiveMenuIndex,
    resetActiveSubMenuIndex,
  } = usePrimaryMenuStore()

  const handleKeyDown = (e: any) => {
    if (hasCTAlink && isLastItem && e.code === 'Tab') {
      e.preventDefault()
      incrementActiveSubMenuIndex()
    }

    if (e.code === 'Enter') {
      handleCloseMenu()
    }
  }

  const handleCloseMenu = () => {
    resetActiveMenuIndex()
    resetActiveSubMenuIndex()
  }

  const handleTabIndex = () => {
    let tabIndex = -1

    if (activeMenuIndex === menuIndex) {
      tabIndex = 1 //sets all index values to 1 when menu is open
    }

    if (activeSubMenuIndex === itemIndex) {
      tabIndex = 0 // sets all index values to 0 when the sub nav item is active
    }

    return tabIndex
  }

  return (
    <>
      {model.link && (
        <SharedLink
          {...model.link}
          className="group hover:no-underline flex flex-col w-full"
          tabIndex={handleTabIndex()}
          onKeyDown={(e) => handleKeyDown(e)}
          onClick={() => handleCloseMenu()}
        >
          <span className="text-grey-dark group-hover:text-dark-blue-primary font-semibold text-sm">
            {model.link.text}
          </span>
          {model.description && (
            <span className="text-grey-light group-hover:text-dark-blue-primary text-sm">
              {model.description}
            </span>
          )}
        </SharedLink>
      )}
    </>
  )
}

export default SubNavItem
