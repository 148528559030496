import SharedButton from "@/components/shared/buttons/shared-button";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { Table } from "@tanstack/react-table";
import { uid } from 'uid'

interface PaginationComponentsProps<T> {
    table: Table<T>
}

export default function PaginationComponent<T> (props: Readonly<PaginationComponentsProps<T>>){
    const {
        table,
    } = props

    return (
        <div className="flex flex-row items-center gap-2 pt-[15px]">
            <SharedButton onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} style='primary' className="border rounded p-1">
            <div>
                <span><ArrowLeftIcon className='h-[16px] w-[16px]'/></span>
            </div>
            </SharedButton>
            {
                (() => {
                    const totalPages = table.getPageCount();
                    const currentPage = table.getState().pagination.pageIndex + 1;
                    let pageNumbers = [];
                
                    // Always show the first page
                    pageNumbers.push(1);
                
                    // Show ellipsis if there are more than 3 pages
                    if (totalPages >= 3) {
                        // Show ellipsis before the current page if it's not the first page
                        if (currentPage > 3) {
                            pageNumbers.push('...');
                        }
                
                        // Show the current page and the two pages before and after it
                        for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
                            pageNumbers.push(i);
                        }
                
                        // Show ellipsis after the current page if it's not the last page
                        if (currentPage < totalPages - 2) {
                            pageNumbers.push('...');
                        }
                    }
                
                    // Always show the last page
                    if(totalPages > 1) 
                        pageNumbers.push(totalPages) 
                    else
                        pageNumbers.push("...")

                    return pageNumbers.map((page, index) => (
                        <SharedButton
                            style='text'
                            key={uid()}
                            onClick={page !== "..." ? () => table.setPageIndex(parseInt(page.toString()) - 1) : undefined} 
                            disabled={table.getState().pagination.pageIndex + 1 === page || page === "..."}
                        >
                        <div>
                            <span>{page}</span>
                        </div>
                        </SharedButton>
                    ));
                })()
            }
            <SharedButton onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} style='primary' className="border rounded p-1">
                <div>
                    <span><ArrowRightIcon className='h-[16px] w-[16px]'/></span>
                </div>
            </SharedButton>
    </div>
    )
}
