import { create } from 'zustand'

interface HeaderPrimaryMenuMobileProps {
  mobileMenuIsOpen: boolean
  mobileSearchMenuIsOpen: boolean
  openMenuIndexItem: number
  openSubMenuItemIndex: number
  toggleMobileMenu: () => void
  toggleMobileSearchMenu: () => void
  closeMobileMenu: () => void
  handleMobileSearchMenuToggle: () => void
  setOpenMenuIndexItem: (value: number) => void
  setOpenSubMenuIndexItem: (value: number) => void
  resetOpenMenuIndexItem: () => void
  resetOpenSubMenuIndexItem: () => void
}

export const usePrimaryMenuMobileStore = create<HeaderPrimaryMenuMobileProps>(
  (set, get) => ({
    mobileMenuIsOpen: false,
    mobileSearchMenuIsOpen: false,
    openMenuIndexItem: -1,
    openSubMenuItemIndex: -1,
    toggleMobileMenu: () => {
      if (get().mobileMenuIsOpen) {
        get().resetOpenMenuIndexItem()
        get().resetOpenSubMenuIndexItem()
      }
      set(() => ({ mobileMenuIsOpen: !get().mobileMenuIsOpen }))
    },
    toggleMobileSearchMenu: () =>
      set(() => ({
        mobileSearchMenuIsOpen: !get().mobileSearchMenuIsOpen,
      })),
    closeMobileMenu: () => {
      set(() => ({ mobileMenuIsOpen: false }))
      get().resetOpenMenuIndexItem()
      get().resetOpenSubMenuIndexItem()
    },
    handleMobileSearchMenuToggle: () => {
      if (get().mobileMenuIsOpen) {
        get().closeMobileMenu()
      }
      get().toggleMobileSearchMenu()
    },
    setOpenMenuIndexItem: (value: number) =>
      set(() => ({ openMenuIndexItem: value })),
    setOpenSubMenuIndexItem: (value: number) =>
      set(() => ({ openSubMenuItemIndex: value })),
    resetOpenMenuIndexItem: () => set(() => ({ openMenuIndexItem: -1 })),
    resetOpenSubMenuIndexItem: () => set(() => ({ openSubMenuItemIndex: -1 })),
  }),
)
