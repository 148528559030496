import { IndexScoreBreakdownBlockProps } from './types/index-score-breakdown-block-types'
import BlockComponentSelector from '@/epi/block-component-selector'

const IndexScoreBreakdownBlock = (props: Readonly<IndexScoreBreakdownBlockProps>) => {
  const { indexScoreBreakdownSmallIntro, indexScoreBreakdownSubheading, scoreResultBlocks } = props.model
  return (
    <div className='container'>
      <div className='flex flex-col md:items-center'>
        {indexScoreBreakdownSmallIntro &&
          <p className='font-bold text-4xl lg:text-3xl font-mulish text-grey-dark'>{indexScoreBreakdownSmallIntro}</p>}
        {indexScoreBreakdownSubheading &&
          <div className='lg:w-3/4 text-lg lg:text-1xl md:text-center font-normal font-mulish text-base md:text-lg mt-4'>
            <p>{indexScoreBreakdownSubheading}</p></div>}
        <div className='flex flex-col md:flex-row items-start cols-3 gap-4 justify-center mt-8'>
          {scoreResultBlocks?.map((item, i) => (
            <BlockComponentSelector className={'score-results-block-' + i}
              {...item}
              key={`content-${i}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default IndexScoreBreakdownBlock
