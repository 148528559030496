import { ImageBlockProps } from '@/components/blocks/image/types/image-types'
import SharedImage from '@/components/shared/image/shared-image'
import RichText from '@/components/shared/rtf/shared-rtf'
import SectionHeader from '@/components/shared/section-header'

const ImageBlock = (props: Readonly<ImageBlockProps>) => {
  const { heading, subheading, optionalDescription, image, imageAspectRatio } =
    props.model
  return (
    <section
      className={`${!props.hideContainerClass ? 'container' : ''} ${
        props.className
      }`}
    >
      {heading && subheading && (
        <SectionHeader
          heading={heading}
          subheading={subheading}
          smallHeadingGap={true}
        />
      )}
      {image && (
        <div>
          <SharedImage
            className={`w-full rounded-lg object-cover aspect-[${imageAspectRatio?.replace(
              ':',
              '/',
            )}]`}
            {...image}
          />
          {optionalDescription && (
            <RichText
              className="mt-3 text-grey-light !text-base"
              html={optionalDescription}
            />
          )}
        </div>
      )}
    </section>
  )
}
export default ImageBlock
