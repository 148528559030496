import { YTAutoplayParams } from '@/components/blocks/video-block/video-block'
import SharedButton from '@/components/shared/buttons/shared-button'
import { ModalProps } from '@/components/shared/modal/types/modal'
import { XMarkIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLockedBody } from 'usehooks-ts'


const Modal = (props: Readonly<ModalProps>) => {
  useLockedBody(props.showModal, 'root')
  const { t } = useTranslation()
  const childrenRef = useRef<HTMLElement>(null)
  const firstFocusable = useRef<HTMLElement>(null)
  const lastFocusable = useRef<HTMLElement>(null)
  let iframe: HTMLIFrameElement | null | undefined = null

  const returnFocus = () => {
    firstFocusable.current?.focus()
  }

  const onClose = () => {
    if (props.showModal) {
    props.onClose()
    const iframe = childrenRef.current?.querySelector('iframe')
      const src = iframe?.getAttribute('src')
      var iFrameParent = iframe?.parentElement
      //This is to assist with auto-play and fix the disabled back button issue
      iframe?.remove()
      if (iframe) {
        iFrameParent?.appendChild(iframe);
      }
      if (src) iframe?.setAttribute('src', src.replace(YTAutoplayParams, ''))
    }
  }

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && props.showModal) {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [props.showModal])

  useEffect(() => {
    if (props.showModal) {
      firstFocusable.current?.focus()
      iframe = childrenRef.current?.querySelector('iframe')
      const src = iframe?.getAttribute('src')
      var iFrameParent = iframe?.parentElement
      const altSrc = src?.split('?')[0]
      //This is to assist with auto-play and fix the disabled back button issue
      iframe?.remove()
      iframe?.setAttribute('src', altSrc + YTAutoplayParams)

      if (iframe) {
        iFrameParent?.appendChild(iframe);
      }

    } 
  }, [props.showModal])
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-[1000]${props.maxWidth ? ' max-w-[' + props.maxWidth + ']' : ''}${props.showModal ? '' : ' hidden'}`}
    >
      <div className="fixed inset-0 backdrop-blur-md" onClick={onClose}></div>

      <div
        className={`relative z-10 mx-[18px] p-6 w-full lg:w-auto ${props.transparentBg
            ? ''
            : 'bg-white rounded-xl shadow-xl border border-grey-secondary-light'
          }`}
      >
        <div className="mb-8 flex justify-end">
          <SharedButton
            style="primary"
            size="regular"
            className="h-[40px] w-[40px] flex items-center justify-center"
            ref={firstFocusable as React.RefObject<HTMLButtonElement>}
            onClick={onClose}
          >
            <span className="sr-only">{t('ScreenReader.ClosedNamedModal', { 0: props.closeButtonLabel })}</span>
            <span>
              <XMarkIcon className="text-white h-5 w-5" />
            </span>
          </SharedButton>
        </div>

        <div ref={childrenRef as React.RefObject<HTMLDivElement>}>
          {props.children}
        </div>

        {/* Focus trap */}
        <input
          className="opacity-0 w-0 h-0"
          aria-hidden="true"
          type="text"
          ref={lastFocusable as React.RefObject<HTMLInputElement>}
          onFocus={returnFocus}
        />
      </div>
    </div>
  )
}

export default Modal
