import { AddressModel, CustomerModel } from '@/api/types/content-delivery-types'
import PopoverTrigger from '@/components/shared/popover-menu/popover-trigger';
import { useAddressStore } from '@/stores/useAddressStore';
import { PencilSquareIcon, StarIcon, TrashIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AddressCardProps {
    isDefault: boolean
    customerInfo: CustomerModel | null
    address: AddressModel | null
    index: number
}

const AddressCard = (props: Readonly<AddressCardProps>) => {
    const [isOpen, setIsOpen] = useState(false);
    const { setShowAddressBookModal, deleteAddress, setEditableAddress, setAddressFormEditMode, makeDefaultAddress } = useAddressStore();
    const { t } = useTranslation()
   
    const {
        index,
        isDefault,
        address,
    } = props;

    const makeDefaultedAddress = async (address: AddressModel | null) => {
        await makeDefaultAddress(address)
        setIsOpen(false)
    }

    const editAddress = async (address: AddressModel | null) => {
        if (!address) return;
        setIsOpen(false)
        setAddressFormEditMode(true)
        setEditableAddress(address)
        setShowAddressBookModal(true)
    }

    const removeAddress = async (address: AddressModel | null) => {
        if (!address) return;
        await deleteAddress(address)
        setIsOpen(false)
    }

    return (
        <div key={index} className="flex flex-col h-full bg-white rounded-lg shadow p-4 flex-1 gap-3 w-full">
            {isDefault && <div className='flex flex-col flex-wrap text-wrap'>
                <div className={'flex flex-row gap-2 items-center'}>
                    <span className='font-bold text-black text-sm'>{t('AddressBook.DefaultAddressText')}</span>
                </div>
            </div>}
            <div className='flex flex-col flex-wrap text-wrap'>
                <h2 className="text-xl text-black font-bold">{address?.name}</h2>
            </div>
            <div className='flex flex-col flex-wrap text-wrap'>
                <span className='text-black overflow-anywhere'>{address?.line1}</span>
                {address?.line2 && <span className='text-black overflow-anywhere'>{address?.line2}</span>}
                <span className='text-black overflow-anywhere'>{address?.city}</span>
                <span className='text-black overflow-anywhere'>{address?.region} {address?.postalCode}</span>
                <span className='text-black overflow-anywhere'>{address?.countryName}</span>
            </div>
            <div className='flex h-full flex-col justify-end'>
                <PopoverTrigger
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    buttonChildren={
                        <div className={'flex flex-row gap-2 items-center'}>
                            <EllipsisVerticalIcon className='w-[20px] h-[20px]'/>
                            <span>Options</span>
                        </div>
                    }
                    popoverChildren={
                        <div className="flex flex-col gap-3 py-[8px] px-[12px]">
                            <div className='w-full'>
                                <button onClick={async() => await makeDefaultedAddress(address)} className={'flex flex-row gap-2 items-center text-black border-transparent disabled:text-grey-400 disabled:cursor-not-allowed'}>
                                    <StarIcon className='w-[20px] h-[20px]'/>
                                    <span>{t('AddressBook.MakeDefault')}</span>
                                </button>
                            </div>
                            <div className='w-full'>
                                <button onClick={async() => await editAddress(address)} className={'flex flex-row gap-2 items-center text-black border-transparent disabled:text-grey-400 disabled:cursor-not-allowed'}>
                                    <PencilSquareIcon className='w-[20px] h-[20px]'/>
                                    <span>{t('AddressBook.Edit')}</span>
                                </button>
                            </div>
                            <div className='w-full'>
                                <button onClick={async() => await removeAddress(address)} className={'flex flex-row gap-2 items-center text-black border-transparent disabled:text-grey-400 disabled:cursor-not-allowed'}>
                                    <TrashIcon className='w-[20px] h-[20px]'/>
                                    <span>{t('AddressBook.Delete')}</span>
                                </button>
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
}

export default AddressCard
