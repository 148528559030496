import FormContainerBlock from '@/components/blocks/form-container/form-container'
import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import BlockComponentSelector from '@/epi/block-component-selector'
import ContactUsHero from './components/contact-hero'
import ContactLabelLinkItem from './components/contact-label-link-item'
import ContactLineItem from './components/contact-line-item'
import ContactLinkItem from './components/contact-link-item'
import ContactTollFree from './components/contact-toll-free'
import { ContactUsPageProps } from './types/contact-types'

const ContactUsPage = (props: Readonly<ContactUsPageProps>) => {
  const { model } = props

  return (
    <>
      <Breadcrumbs />
      <div className="flex flex-col space-y-14 md:space-y-20">
        {(model.heroHeading || model.heroSubheading || model.heroImage) && (
          <ContactUsHero
            heading={model.heroHeading}
            subheading={model.heroSubheading}
            heroImage={model.heroImage}
          />
        )}
        <div className="container grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
          {model.phoneFax && <ContactLabelLinkItem {...model.phoneFax} />}
          {model.email && <ContactLinkItem {...model.email} />}
          {model.officeHours && <ContactLineItem {...model.officeHours} />}
          {model.mailAddress && <ContactLineItem {...model.mailAddress} />}
          {model.dementiaCare && (
            <ContactLabelLinkItem {...model.dementiaCare} />
          )}
          {model.warehouseAddress && (
            <ContactLineItem {...model.warehouseAddress} />
          )}
          {model.warehouseHours && (
            <ContactLineItem {...model.warehouseHours} />
          )}
          {model.tollFree && <ContactTollFree {...model.tollFree} />}
        </div>
        <div className="container flex justify-center">
          <div className="flex flex-col w-full md:w-1/2 space-y-14 md:space-y-20">
            {model.form?.model && (
              <FormContainerBlock
                model={model.form.model}
                isContactPageForm={true}
              />
            )}
            {model.pageContent?.map((item, i) => (
              <BlockComponentSelector
                key={`pageContent-${i}`}
                {...item}
                hideContainerClass={true}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUsPage
