import SharedRTF from '../../shared/rtf/shared-rtf'
import { RichTextBlockProps } from './types/rich-text-types'

const RichTextBlock = (props: Readonly<RichTextBlockProps>) => {
  const { model, className, hideContainerClass } = props

  return (
    <section
      id={model.anchorId}
      className={`
        ${!hideContainerClass ? 'container' : ''}
        ${className}
      `}
    >
      <SharedRTF html={model.richTextField}></SharedRTF>
    </section>
  )
}
export default RichTextBlock
