import SharedLink from '@/components/shared/buttons/shared-link'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { uid } from 'uid'
import FormContainerBlock from '../form-container/form-container'
import { LeadGenFormProps } from './types/lead-gen-form-types'

declare global {
  interface Window {
    $$epiforms: any
  }
}

const LeadGenerationFormWrapperBlock = (props: Readonly<LeadGenFormProps>) => {
  const { model, hideContainerClass } = props
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const handlePageLoad = () => {
    setTimeout(() => {
      if (typeof window.$$epiforms !== 'undefined') {
        window.$$epiforms(document).ready(function myfunction() {
          window
            .$$epiforms('.EPiServerForms')
            .on('formsSubmitted', function (event: any) {
              setIsFormSubmitted(true)
            })
        })
      }
    }, 1000)
  }

  useEffect(() => {
    window.addEventListener('load', handlePageLoad, false)

    return () => {
      window.removeEventListener('load', handlePageLoad)
    }
  }, [])

  return (
    <section
      className={`bg-grey-secondary-light md:rounded-lg py-16 md:py-[75px] ${
        hideContainerClass ? 'mx-[-18px] lg:mx-0' : 'lg:mx-6'
      }`}
    >
      <div className={!hideContainerClass ? 'container' : 'px-[18px] lg:px-0'}>
        <div className="w-full md:px-6 flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0 md:space-x-8">
          <div className="w-full md:max-w-[507px] flex flex-col space-y-4 md:space-y-8">
            {model.headline && (
              <h2 className="text-3xl md:text-5xl text-grey-dark font-bold">
                {model.headline}
              </h2>
            )}
            {model.subheadline && (
              <div className="text-grey-dark text-lg md:text-2xl">
                {model.subheadline}
              </div>
            )}

            {!isFormSubmitted && (
              <>
                {model.mainLink && (
                  <div className="flex flex-col space-y-3">
                    {model.mainLink && (
                      <div className="flex">
                        <SharedLink
                          {...model.mainLink}
                          className="hover:no-underline inline-flex items-center space-x-3 py-2.5"
                        >
                          <span className="text-dark-blue-primary font-semibold">
                            {model.mainLink.text}
                          </span>
                          <span>
                            <ArrowRightIcon className="h-5 w-5 text-dark-blue-primary" />
                          </span>
                        </SharedLink>
                      </div>
                    )}
                  </div>
                )}
                {(model.description || model.additionalLinks) && (
                  <div className="flex flex-col space-y-3">
                    {model.description && (
                      <div className="text-grey-dark text-lg md:text-2xl">
                        {model.description}
                      </div>
                    )}
                    {model.additionalLinks && (
                      <>
                        {model.additionalLinks.map((link) => (
                          <div className="flex" key={uid()}>
                            <SharedLink
                              {...link}
                              className="hover:no-underline inline-flex items-center space-x-3"
                            >
                              <span className="text-dark-blue-primary font-semibold">
                                {link.text}
                              </span>
                              <span>
                                <ArrowRightIcon className="h-5 w-5 text-dark-blue-primary" />
                              </span>
                            </SharedLink>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          {model.form && (
            <div className="w-full md:max-w-[465px]">
              <FormContainerBlock
                model={model.form.model}
                isChildBlock={true}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
export default LeadGenerationFormWrapperBlock
