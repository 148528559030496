import { uid } from "uid";
import BlockComponentSelector from "@/epi/block-component-selector";
import { BlockComponentSelectorProps } from "@/epi/types/epi";

/**
 * Usage is {RenderTopContentArea<YourPageProps>(pageModel)}, in this example "YourPageProps" should contain a topContentArea
 * in order to inject the blocks via the block-component-selector.
 * @param PageModel Should contain a "pageContent" property in the page model.
 * @returns JSX.Element
*/
export function RenderContentArea<T>(m: T) {
    let model = m as unknown as any;
    if (!model) return null;
    const EPIBlocks = model.map((item: JSX.IntrinsicAttributes & Readonly<BlockComponentSelectorProps>) => (
        <BlockComponentSelector
            key={uid()}
            {...item}
            hideContainerClass={true}
        />
    ))
    return EPIBlocks;
}
