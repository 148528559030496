import RichText from '@/components/shared/rtf/shared-rtf'
import SharedLink from '@/components/shared/buttons/shared-link'
import SharedImage from '@/components/shared/image/shared-image'
import { FinalScoreSectionBlockProps } from './types/final-score-section-block-types'
import BlockComponentSelector from '@/epi/block-component-selector'
import { ArrowRightIcon } from '@heroicons/react/24/solid'


const FinalScoreSectionBlock = (props: Readonly<FinalScoreSectionBlockProps>) => {
  const { smallIntroHeaderText, gaugeImage, gaugeImageScoreNumber, gaugeImageScoreText, exampleScoreRangeValues, finalResultsHeaderText, finalResultsBodyText, finalResultsPdfDownloadLink, finalResultsHiddenRedirectLink } = props.model

  return (
    <div className="text-grey-dark container px-0 mt-5" id='finalScoreSection'>
      <div className='lg:flex-row md:flex-col flex-col flex rounded-lg mx-4 shadow-results'>
        <div className='w-full pt-8 pb-8 flex flex-col items-center lg:w-1/2 md:flex md:flex-col md:items-center'>
          <div className='max-w-[60%]'>
          {smallIntroHeaderText && (
              <p className='mb-6 tracking-[.095rem] leading-[1.1rem] text-[0.85rem] font-bold font-mulish uppercase text-center lg:text-[1rem] lg:leading-[1.35rem]'>{smallIntroHeaderText}</p>
            )}
          </div>
          <div className="mb-4 px-4 lg:w-[500px] md:w-[450px] w-[320px] flex flex-col items-center">
            {gaugeImage?.url && (
              <SharedImage {...gaugeImage} />
            )}
            <h2 className='wvprp-left-score-number lg:-mt-[170px] md:-mt-[160px] -mt-[110px] lg:text-[125px] md:text-[120px] text-[80px] text-center text-grey-dark font-extrabold'>{gaugeImageScoreNumber}</h2>
            <p className='wvprp-left-score-text-header lg:mb-1 lg:-mt-[30px] md:-mt-[25px] -mt-[15px] font-bold text-[1.1rem] font-mulish uppercase tracking-widest'>{gaugeImageScoreText} </p>
          </div>
          <div className="mb-4" style={{ backgroundColor: '#E7E9EA', height: '1px', width: '80%' }}></div>
          <div className="md:grid md:grid-cols-2 gap-2 md:self-auto">
            {exampleScoreRangeValues?.map((item, i) => (
              <BlockComponentSelector
                {...item}
                key={`content-${i}`}
              />
            ))}
          </div>
        </div>
        <div className='w-full lg:w-[685px] md:px-12 md:py-16 px-8 py-8 min-h-full flex flex-col items-start bg-grey-secondary-dark rounded-tr-lg rounded-br-lg'>
          <h2 className='wvprp-main-header-text md:mb-[1.5rem] md:text-[3rem] md:leading-[3rem] mb-4 font-bold leading-[3rem] text-[2.75rem]'>{finalResultsHeaderText}</h2>

          {finalResultsBodyText && (
            <div>
              <RichText
                className="wvprp-main-main-body-text lg:prose-lg lg:leading-7 md:leading-[1.65rem] leading-6 max-w-none rich-text prose prose-headings:black prose"
                html={finalResultsBodyText}
              />
            </div>
          )}

          {finalResultsPdfDownloadLink && (
            <SharedLink
              style="text"
              {...finalResultsPdfDownloadLink}
              className="wvprp-download-rec-btn lg:text-md mt-4 text-md pl-0"
            >
              {finalResultsPdfDownloadLink?.text}
              <ArrowRightIcon className="h-4 w-4 ml-2" />
            </SharedLink>
          )}
          {finalResultsHiddenRedirectLink && (
            <SharedLink
              style="text"
              {...finalResultsHiddenRedirectLink}
              className="wvprp-hidden-btn lg:text-md mt-4 text-md pl-0"
            >
              {finalResultsHiddenRedirectLink?.text}
              <ArrowRightIcon className="h-4 w-4 ml-2" />
            </SharedLink>
          )}
        </div>
      </div>
    </div>
  )
}

export default FinalScoreSectionBlock
