import { HomeHeroProps } from '@/components/pages/home/types/home'
import SharedLink from '@/components/shared/buttons/shared-link'
import SharedImage from '@/components/shared/image/shared-image'
import { useMediaStore } from '@/stores/media-store'
import { ArrowRightIcon } from '@heroicons/react/24/solid'

function HomeHero(props: Readonly<HomeHeroProps>) {
  const { isMobile } = useMediaStore()

  return (
    <div className="container flex flex-col md:pt-12 pb-[72px]">
      {props.topCtaButton && (
        <div className="flex justify-center order-1 md:mb-[30px]">
          <SharedLink
            {...props.topCtaButton}
            className="absolute md:relative -translate-y-1/2 md:translate-y-0 inline-flex md:items-center justify-center space-x-3 pl-3 pr-1.5 py-1 text-blue-primary lg:text-lg font-medium bg-grey-secondary-light hover:bg-dark-blue-100 hover:no-underline rounded-lg"
          >
            <span>{props.topCtaButton.text}</span>
            <span>
              <ArrowRightIcon className="text-blue-primary h-5 w-5" />
            </span>
          </SharedLink>
        </div>
      )}
      <div className="flex flex-col space-y-8 lg:space-y-10 order-1 mt-12 md:mt-0 md:mb-[72px]">
        {props.heading && (
          <div
            className="text-3xl lg:text-6xl text-center text-grey-dark font-bold"
            dangerouslySetInnerHTML={{ __html: props.heading }}
          ></div>
        )}
        {props.subheading && (
          <div className="flex justify-center">
            <div
              className="lg:w-3/4 text-lg lg:text-2xl text-center text-grey-light prose lg:prose-xl prose-headings:black prose-a:text-dark-blue-primary hover:prose-a:underline !max-w-none"
              dangerouslySetInnerHTML={{ __html: props.subheading }}
            />
          </div>
        )}
      </div>

      {props.mainCtaButton && (
        <div className="order-1 mt-8 md:mt-0 flex justify-center">
          <SharedLink
            {...props.mainCtaButton}
            style="secondary"
            size={!isMobile ? 'large' : 'regular'}
            className="md:absolute md:-translate-y-1/2 inline-flex items-center justify-center space-x-3"
          >
            <span>{props.mainCtaButton.text}</span>
            <span>
              <ArrowRightIcon className="text-grey-dark h-5 w-5" />
            </span>
          </SharedLink>
        </div>
      )}

      {props.desktopImage && (
        <div className="order-0 md:order-1 mx-[-18px] md:mx-0">
          <SharedImage
            {...props.desktopImage}
            mobileUrl={props.mobileImage?.url}
            className="w-full md:rounded-xl"
            disableLazy={true}
          />
        </div>
      )}
    </div>
  )
}
export default HomeHero
