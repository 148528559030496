import SharedLink from '@/components/shared/buttons/shared-link'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { CardProps } from '../types/simple-link-slider-block-types'

const SimpleLinkSliderItem: React.FC<CardProps> = ({
  industry,
  title,
  description,
  linkText,
  url,
  tag,
}) => {
  return (
    <SharedLink
      href={url}
      className="flex flex-col justify-between flex-1 space-y-4 p-6 hover:no-underline border border-grey-secondary-dark bg-white rounded-lg hover:shadow-lg"
    >
      <div className="space-y-4">
        {industry && (
          <div className="text-dark-blue-primary text-xs uppercase">
            {industry}
          </div>
        )}

        {title && <div className="text-grey-dark font-semibold">{title}</div>}

        {description && (
          <div className="text-grey-dark text-sm">{description}</div>
        )}
      </div>
      <div className="flex justify-between items-center space-x-4">
        {tag && <div className="text-sm text-grey-light">{tag}</div>}

        {linkText && (
          <div className="flex items-center text-dark-blue-primary">
            <span>{linkText}</span>

            <ArrowRightIcon className="h-4 w-4 ml-2 inline" />
          </div>
        )}
      </div>
    </SharedLink>
  )
}

export { SimpleLinkSliderItem }
