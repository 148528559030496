import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import SharedButton from '@/components/shared/buttons/shared-button'
import SharedLink from '@/components/shared/buttons/shared-link'
import HeroIndex from '@/components/shared/index/components/hero'
import IndexTab from '@/components/shared/index/components/tabs'
import LoadingSpinner from '@/components/shared/loading-spinner'
import BlockComponentSelector from '@/epi/block-component-selector'
import { useIndexPageStore } from '@/stores/index-store'
import { useMediaStore } from '@/stores/media-store'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState, useRef } from 'react'
import { uid } from 'uid'
import CaseStudyCard from './components/case-study-card'
import { CaseStudyIndexProps } from './types/index-types'
import { useTranslation } from 'react-i18next'
import { ChevronUpIcon } from '@heroicons/react/24/solid'

const CaseStudyIndexPage = (props: Readonly<CaseStudyIndexProps>) => {
  const { model } = props

  const {
    cards,
    isLoading,
    moreItems,
    page,
    setPage,
    setPageUrl,
    setPageSize,
    fetchResults,
    resetState,
  } = useIndexPageStore()

  const { t } = useTranslation()
  const categoryMenu = useRef<HTMLDivElement>(null)
  const [industryMenuIsOpen, setindustryMenuIsOpen] = useState(false)

  const handleLoadMore = () => {
    setPage(page + 1)
    fetchResults()
  }

  const toggleIndustryMenu = () => {
    setindustryMenuIsOpen(!industryMenuIsOpen)
  }

  const closeIndustryMenu = (e: MouseEvent) => {
    if (categoryMenu.current && !(e.target as HTMLElement).closest('#categoryButton')) {
      setindustryMenuIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', closeIndustryMenu)
    return () => {
      document.removeEventListener('click', closeIndustryMenu)
    }
  }, [])

  const [activeTabPlaceholderText, setActiveTabText] = useState('');

  useEffect(() => {
    setPageSize(6)
    setPageUrl(model.pageUrl)
    resetState()
    fetchResults()
  }, [])

  const handleTabClick = (text: string) => {
    setActiveTabText(text);
    const dropdownTitle = document.getElementById('industryDropdownTitle');
    if (dropdownTitle) {
      dropdownTitle.textContent = text;
    }
  };

  return (
    <>
      <Breadcrumbs />
      <div className="flex flex-col space-y-14 md:space-y-20">
        {model.hero && (
          <div className="container">
            <HeroIndex
              heading={model.hero.heading}
              subheading={model.hero.subheading}
            >
              {model.hero.primaryCtaButton && (
                <div className="flex items-center justify-center">
                  <SharedLink
                    {...model.hero.primaryCtaButton}
                    style={model.hero.primaryCtaButton.buttonStyle}
                    size={model.hero.primaryCtaButton.buttonSize}
                    className=" w-full md:w-auto space-x-3 shadow-xl"
                  >
                    <span>{model.hero.primaryCtaButton.text}</span>
                    <span>
                      <ArrowRightIcon className="h-5 w-5" />
                    </span>
                  </SharedLink>
                </div>
              )}
            </HeroIndex>
          </div>
        )}

        <div className="container flex flex-col space-y-4 md:space-y-10">
          <div className='flex flex-col md:items-center md:justify-center space-y-4'>
            {model.industriesDropdownPlaceholder && (
              <h2 className='text-2xl md:text-3xl font-bold text-grey-dark'>{model.industriesDropdownPlaceholder}</h2>
            )}
            <div className='flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-4'>
              {
                model.tabs && model.tabs.length > 0 && (
                  <>
                    <div className="relative min-w-[292px]">
                      <button
                        id="categoryButton"
                        className="w-full flex space-x-3 items-center justify-between py-2 px-3 text-grey-dark rounded-lg border border-grey-100 shadow-sm"
                        aria-haspopup="true"
                        aria-controls="categoryMenu"
                        onClick={toggleIndustryMenu}
                        aria-label={t('ScreenReader.ToggleIndustryMenu')}
                      >
                        <span id='industryDropdownTitle'>
                          {activeTabPlaceholderText !== '' ? activeTabPlaceholderText : model.industriesCategoriesDropdownLabel}
                        </span>
                        <span
                          className={`shared-transition ${industryMenuIsOpen ? 'rotate-0' : '-rotate-180'
                            }`}
                        >
                          <ChevronUpIcon className="h-5 w-5 text-dark-blue-primary" />
                        </span>
                      </button>
                      <div
                        className={`${industryMenuIsOpen
                          ? 'max-h-[4000px] lg:max-h-none'
                          : 'max-h-0 overflow-hidden lg:max-h-none'
                          }`}
                      >
                        <div
                          className={`w-full bg-white border border-grey-100 shadow-md rounded-lg flex flex-col mt-4 lg:mt-0 lg:absolute top-full right-0 left-0 z-10 shared-transition ${industryMenuIsOpen
                            ? 'visible opacity-1 lg:translate-y-1'
                            : 'invisible opacity-0 lg:translate-y-0'
                            }`}
                          role="menu"
                          aria-labelledby="industryButton"
                          id="industryMenu"
                          ref={categoryMenu}
                        >
                          {model.tabs && (
                            <div className="py-2">
                              {model.tabs.map((tab, index) => (
                                      <div
                                        key={tab.index}
                                        role="none"
                                        onClick={() => handleTabClick(tab?.link?.text?.toString() ?? '')}
                                  className="flex items-center w-full pt-2.5 pb-2 px-3"
                                      >
                                        {model.tabs && <IndexTab {...tab} index={index} />}
                                      </div>
                                    ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )
              }
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 min-h-[369px] w-full">
            {cards.length > 0 && !isLoading && (
              <>
                {cards.map((card) => (
                  <React.Fragment key={uid()}>
                    <CaseStudyCard
                      {...card}
                      isFeatured={card.isFeatured}
                      setFeaturedAspectRatio={cards.length < 3}
                    />
                  </React.Fragment>
                ))}
              </>
            )}

            {cards.length < 1 && isLoading && (
              <div className="col-span-1 sm:col-span-2 flex h-full w-full items-center justify-center">
                <LoadingSpinner className="h-5 w-5 text-grey-light" />
              </div>
            )}

            {cards.length < 1 && !model.featured && !isLoading && (
              <div className="col-span-1 sm:col-span-2 flex h-full w-full items-center">
                <div className="flex justify-center py-8 px-4 bg-grey-100 w-full rounded-lg">
                  <span className="text-black text-2xl sm:text-3xl font-bold">
                    {model.noResultsMessage}
                  </span>
                </div>
              </div>
            )}
          </div>
          {cards.length > 0 && moreItems && (
            <div className="flex justify-center">
              <SharedButton
                ariaLabel={model.loadMoreAriaText}
                style="primary"
                size="regular"
                className="space-x-3"
                onClick={handleLoadMore}
              >
                {isLoading && (
                  <span>
                    <LoadingSpinner className="h-5 w-5 text-white" />
                  </span>
                )}
                <span>{model.loadMoreButtonText}</span>
              </SharedButton>
            </div>
          )}
        </div>

        {model.pageContent && (
          <>
            {model.pageContent?.map((blockModel, i) => (
              <BlockComponentSelector
                {...blockModel}
                key={`pageContent-${i}`}
              />
            ))}
          </>
        )}
      </div>
    </>
  )
}
export default CaseStudyIndexPage
