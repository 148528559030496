import MediaContent from '@/components/blocks/featured-media-block/components/media-content'
import { ProgramHeroProps } from '@/components/pages/program/types/program-page-types'
import SharedLink from '@/components/shared/buttons/shared-link'
import Modal from '@/components/shared/modal/shared-modal'
import { useMediaStore } from '@/stores/media-store'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import { useState, useEffect, useRef } from 'react'

function ProgramHero(props: Readonly<ProgramHeroProps>) {
  const [showModal, setShowModal] = useState(false)
  const { isMobile } = useMediaStore()

  return (
    <section className="container flex flex-col mb-[72px] md:mb-32">
      <div
        className={`
        relative flex flex-col space-y-reverse space-y-6 lg:space-y-0 lg:space-x-14 
        lg:flex-row lg:items-center lg:justify-between lg:min-h-[443px] 
        before:lg:content-[''] before:lg:block before:lg:absolute before:lg:h-full before:lg:w-10/12 before:lg:bg-grey-secondary-light before:lg:rounded-lg `}
      >
        <div className="relative order-1 lg:order-none lg:w-1/2 flex lg:items-center lg:py-12">
          <div className="md:text-center lg:text-left flex flex-col lg:self-center lg:space-y-5 lg:pl-[72px] w-full">
            {props.heading && (
              <div
                className="lg:text-left mb-8 lg:mb-0 text-4xl lg:text-5xl text-grey-dark font-bold"
                dangerouslySetInnerHTML={{ __html: props.heading }}
              ></div>
            )}
            {props.subheading && (
              <div
                className="mb-4 lg:mb-0 lg:w-full text-lg text-grey-light lg:text-grey-dark prose lg:prose-xl prose-headings:black prose-a:text-dark-blue-primary hover:prose-a:underline prose-p:leading-7 prose-a:leading-7 prose-ol:leading-7 prose-ul:leading-7 !max-w-none"
                dangerouslySetInnerHTML={{ __html: props.subheading }}
              />
            )}
            {(props.primaryCtaButton || props.secondaryCtaButton) && (
              <div className="flex flex-col md:flex-row md:justify-center lg:justify-start">
                <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-2">
                  {props.primaryCtaButton && (
                    <SharedLink
                      {...props.primaryCtaButton}
                      style={'secondary'}
                      size={'regular'}
                      className="w-full md:w-auto space-x-3 hover:shadow-lg"
                    >
                      <span>{props.primaryCtaButton.text}</span>
                      <span>
                        <ArrowRightIcon className="h-5 w-5 text-grey-dark" />
                      </span>
                    </SharedLink>
                  )}
                  {props.secondaryCtaButton && (
                    <SharedLink
                      {...props.secondaryCtaButton}
                      style={'outline'}
                      size={'regular'}
                      className="w-full md:w-auto hover:shadow-lg"
                    >
                      <span>{props.secondaryCtaButton.text}</span>
                    </SharedLink>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {(props.desktopImage || props.video) && (
          <div className="relative order-0 lg:order-none w-full lg:w-1/2">
            <MediaContent
              type={props.video ? 'Video' : 'Image'}
              image={!isMobile ? props.desktopImage : props.mobileImage}
              preloadImage={true}
              video={props.video}
              setShowModal={setShowModal}
            />
          </div>
        )}
      </div>
      {props.video && (
        <Modal
          transparentBg={true}
          closeButtonLabel="close"
          showModal={showModal}
          onClose={() => setShowModal(false)}
        >
          <iframe
            id='ytplayer'
            src={`https://www.youtube.com/embed/${props.video}`}
            title="YouTube video player"
            className="w-full lg:w-[832px] aspect-video lg:m-32"
            allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </Modal>
      )}
    </section>
  )
}

export default ProgramHero
