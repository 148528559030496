import { FacetGroupProps } from '@/components/pages/search/types/search-types'
import SharedButton from '@/components/shared/buttons/shared-button'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { uid } from 'uid'
import { ProductListingPageLocalizations } from '../types/plp-types'
import { useTranslation } from 'react-i18next'
import { replaceNonXmlWithSpace, trimSpace } from '@/lib/currency/formatting'
import { EIGHTH, FIFTH, FIRST, FOURTH, NINTH, SECOND, SEVENTH, SIXTH, TENTH, THIRD } from '@/lib/constants'

interface ProductListingFiltersProps {
  facetGroups: FacetGroupProps[]
  applyFilters: (selectedFacets: string) => void
  clearSelections: () => void
  localizations: ProductListingPageLocalizations | undefined
}

const ProductListingFilters: React.FC<ProductListingFiltersProps> = ({
  facetGroups,
  applyFilters,
  clearSelections,
  localizations,
}) => {
  const [selectedFacets, setSelectedFacets] = useState<string[]>([])
  const { t } = useTranslation()

  useEffect(() => {
    const updatedSelectedFacets = facetGroups.reduce((acc: string[], group) => {
      // Check if the group has facets and displayMode is 1 (checkbox)
      if (group.facets && group.displayMode === 1) {
        group.facets.forEach((facet) => {
          facet.selected && acc.push(facet.key)
        })
      }
      // Check if the group has facets and displayMode is 10 (dropdown)
      else if (group.facets && group.displayMode === 10) {
        const selectedFacet = group.facets.find((facet) => facet.selected)
        selectedFacet && acc.push(selectedFacet.key)
      }
      return acc
    }, [])

    setSelectedFacets(updatedSelectedFacets)
  }, [facetGroups])

  const handleCheckboxChange = (facetKey: string) => {
    setSelectedFacets((prevSelectedFacets) => {
      const newSelectedFacets = prevSelectedFacets.includes(facetKey)
        ? prevSelectedFacets.filter((key) => key !== facetKey)
        : [...prevSelectedFacets, facetKey]
      return newSelectedFacets
    })
  }

  const handleSelectChange = (facetKey: string) => {
    setSelectedFacets((prevSelectedFacets) => {
      const currentKeyPrefix = facetKey.split(':')[0]

      const updatedFacets = prevSelectedFacets.filter((key) => !key.startsWith(`${currentKeyPrefix}:`))

      if (!facetKey.includes('default')) {
        // If the selected option does not contain "default", add the entire facet.key
        const newSelectedFacets = [...updatedFacets, facetKey]
        return newSelectedFacets
      }

      return updatedFacets
    })
  }

  const handleApplyFilters = () => {
    applyFilters(selectedFacets.join(','))
  }

  const getSelectValue = (facetKey: string, selectedFacets: string[]) => {
    const currentKeyPrefix = facetKey.split(':')[0]
    const matchingElement = selectedFacets.find((key) => key.startsWith(`${currentKeyPrefix}:`))

    return matchingElement ?? `${currentKeyPrefix}:default`
  }

  const handleClearSelections = () => {
    setSelectedFacets([])
    clearSelections()
  }
  
  const createLocalizationKey = (facetDisplayName: string) => {
    facetDisplayName = handleFacetEditionLocalization(facetDisplayName)
    return trimSpace(replaceNonXmlWithSpace(facetDisplayName))
  }

  const handleFacetEditionLocalization = (facetDisplayName: string) => {
    switch(facetDisplayName)
    {
      case FIRST:
        return "First"
      case SECOND:
        return "Second"
      case THIRD:
        return "Third"
      case FOURTH:
        return "Fourth"
      case FIFTH:
        return "Fifth"
      case SIXTH:
        return "Sixth"
      case SEVENTH:
        return "Seventh"
      case EIGHTH:
        return "Eighth"
      case NINTH:
        return "Ninth"
      case TENTH:
        return "Tenth"
      default:
        return facetDisplayName;
    }
  }

  return (
    <div className="flex flex-col space-y-3">
      {facetGroups.map((group, i) => (
        <div className="pb-2" key={uid()}>
          <legend className="pb-4 text-sm font-semibold">{group.displayName}</legend>

          {/*checkbox case*/}
          {group.displayMode == 1 &&
            group.facets.map((facet, j) => (
              <div key={uid()} className="flex flex-row space-x-2 py-1 items-start">
                <input
                  id={`${facet.facetName}-${j}`}
                  name={facet.facetName}
                  type="checkbox"
                  className="custom-checkbox mt-0.5"
                  onChange={() => handleCheckboxChange(facet.key)}
                  checked={selectedFacets.includes(facet.key)}
                />
                <label className="text-sm" htmlFor={`${facet.facetName}-${j}`}>
                {t(`ProductListing.Facets.${createLocalizationKey(facet.displayName)}`)}
                </label>
              </div>
            ))}

          {/*dropdown case*/}
          {group.displayMode == 10 && (
            <select
              className="text-md custom-select w-full"
              onChange={(e) => handleSelectChange(e.target.value)}
              value={getSelectValue(group.facets[0].key, selectedFacets)}
            >
              <option value={`${group.fieldName}:default`}></option>
              {group.facets.map((facet, j) => (
                <option key={uid()} value={facet.key}>
                  {t(`ProductListing.Facets.${createLocalizationKey(facet.displayName)}`)}
                </option>
              ))}
            </select>
          )}
        </div>
      ))}

      <SharedButton onClick={handleApplyFilters} style="primary">
        {localizations?.applyFilters}
      </SharedButton>

      {selectedFacets.length > 0 && (
        <div className="mx-1 mt-5">
          <SharedButton
            onClick={handleClearSelections}
            className="text-md inline-flex items-center space-x-3 font-semibold text-dark-blue-primary hover:underline"
          >
            {localizations?.clearSelections}
            <XMarkIcon className="ml-2 h-5 w-5" />
          </SharedButton>
        </div>
      )}
    </div>
  )
}
export default ProductListingFilters
