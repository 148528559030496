import { SearchPageItemProps } from '@/components/pages/search/types/search-types'
import { selectItemGTM } from '@/components/shared/analytics/gtm-manager'
import SharedLink from '@/components/shared/buttons/shared-link'
import SharedImage from '@/components/shared/image/shared-image'
import LazyLoad from 'react-lazyload'

interface ProductTileProps {
  product: SearchPageItemProps
  itemListId: string
}

const ProductTile: React.FC<ProductTileProps> = ({ product, itemListId }) => {
  if (!product) {
    return null
  }

  const pushSelectItemGTM = (e: any) => {
    e.preventDefault()
    selectItemGTM(product, itemListId)
    window.location.href = product.url
  }

  const handleKeyDown = (e: any) => {
    // Check if the Enter key is pressed
    if (e.key === 'Enter') {
      e.preventDefault();
      selectItemGTM(product, itemListId)
      window.location.href = product.url
    }
  }
  return (
    <article className="flex h-full w-full flex-col pb-5">
      <SharedLink
        onKeyDown={handleKeyDown}
        href={product?.url}
        title={product.title}
        onClick={(e) => pushSelectItemGTM(e)}
        className="h-full overflow-clip rounded-lg hover:no-underline hover:shadow-lg"
      >
        <div className="flex h-full flex-col rounded-lg border border-solid border-grey-secondary-dark">
          <div className="relative rounded-lg bg-white p-3 shadow-lg">
            {product.defaultImageUrl && (
              <LazyLoad className="relative overflow-hidden pb-[100%]" once height={200}>
                <SharedImage
                  imageAlt={product.title!}
                  imageType="information"
                  url={product.defaultImageUrl}
                  className="absolute h-full w-full rounded-lg object-cover"
                />
              </LazyLoad>
            )}
          </div>

          <div className="flex h-full flex-col justify-between p-3">
            {product.title && (
              <div className="line-clamp-5 overflow-hidden text-ellipsis break-words text-base font-bold text-grey-dark">
                {product.title}
              </div>
            )}
            {(product?.productType?.length ?? 0) > 0 && (
              <div className="text-sm text-grey-light">
                {product?.productType?.join(', ')}
              </div>
            )}
          </div>
        </div>
      </SharedLink>
    </article>
  )
}

export default ProductTile
