import SharedImage from '@/components/shared/image/shared-image'
import { LibraryHeroProps } from '../types/library-detail-page-types'

function LibraryHero(props: Readonly<LibraryHeroProps>) {
  const { heading, desktopImage } = props

  return (
    <div className="container mb-14 md:mb-20">
      <div className="flex flex-col md:justify-between md:items-center md:flex-row space-y-reverse space-y-4 md:space-y-0 md:space-x-8">
        <div className="order-1 md:order-none w-full md:w-5/12 flex items-center">
          {heading && (
            <div
              className="text-4xl lg:text-5xl text-grey-dark font-bold"
              dangerouslySetInnerHTML={{ __html: heading }}
            ></div>
          )}
        </div>

        {desktopImage && (
          <div className="order-0 md:order-none w-full md:w-1/2">
            <div className="w-full aspect-[3/2]">
              <SharedImage
                {...desktopImage}
                className="w-full h-full object-cover rounded-lg"
                disableLazy={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default LibraryHero
