import { ShippingMethodModel } from '@/api/types/content-delivery-types'
import { addShippingInfoGTM } from '@/components/shared/analytics/gtm-manager'
import ShippingOptions from '@/components/shared/shipping-options/shipping-options'
import { useCartStore } from '@/stores/useCartStore'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CheckoutStepTwoFormInputs } from '../../types/checkout-types'
import { DEFAULT_CURRENCY_CODE, DEFAULT_MARKET } from '@/lib/constants'

interface StepTwoProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
  setStepTwoFormSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  setUpdateTotals: React.Dispatch<React.SetStateAction<boolean>>
}

const ShippingMethodStep: React.FC<Readonly<StepTwoProps>> = (props: Readonly<StepTwoProps>) => {
  const { updateCartShippingMethod, cartData, validatedCartData } = useCartStore()
  const [shippingMethod, setShippingMethod] = useState<ShippingMethodModel | null>(null)
  const defaultSelectedOption = cartData?.fullCart?.shipments[0]?.shippingMethodId

  const { handleSubmit } = useForm<CheckoutStepTwoFormInputs>({
    defaultValues: {},
  })

  const { setStep, setUpdateTotals, setStepTwoFormSubmitting } = props

  const defaultFormStyles = 'CommerceForms EPiServerForms flex flex-col gap-3 p-0'
  const onSubmitStepTwo: SubmitHandler<CheckoutStepTwoFormInputs> = async (data, e) => {
    setStepTwoFormSubmitting(true)
    if (data) {
      const isComplete = await updateCartShippingMethod({
        shipmentId: cartData?.fullCart?.shipments[0]?.id ?? null,
        shippingMethod: shippingMethod,
      })
      isComplete && setStep(3)
    }
    setStepTwoFormSubmitting(false)
    addShippingInfoGTM(cartData, shippingMethod)
  }

  return (
      <form id="step-two-form" className={defaultFormStyles} onSubmit={handleSubmit(onSubmitStepTwo)}>
        <ShippingOptions
          title={'Shipping Methods'}
          defaultSelectedOption={defaultSelectedOption ?? null}
          onSelected={setShippingMethod}
          setUpdateTotals={setUpdateTotals}
          cartInfo={{ market: cartData?.fullCart?.market ?? DEFAULT_MARKET, currency: cartData?.fullCart?.currency ?? DEFAULT_CURRENCY_CODE}}
          availableShippingMethods={validatedCartData?.availableShippingMethods ?? []}
        />
      </form>
  )
}

// Set default values to some of the properties.
ShippingMethodStep.defaultProps = {}

export default ShippingMethodStep
