import SharedLink from '@/components/shared/buttons/shared-link'
import { LinkProp } from '@/types/shared'

interface QuizBtnLinkProps {
  link: LinkProp
}

const QuizBtnLink = (props: QuizBtnLinkProps) => {
  const { link } = props

  return (
    <SharedLink
      {...link}
      className="text-center w-full p-3 flex justify-center text-base md:text-lg font-medium text-grey-dark hover:text-grey-dark shared-transition shadow-sm hover:shadow-md border border-grey-secondary-dark bg-white hover:bg-grey-secondary-light rounded hover:no-underline"
    >
      {link.text}
    </SharedLink>
  )
}

export default QuizBtnLink
