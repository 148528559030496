import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import SharedRTF from '@/components/shared/rtf/shared-rtf'
import BlockComponentSelector from '@/epi/block-component-selector'
import BlogDetailHero from './components/blog-detail-hero'
import { BlogDetailProps } from './types/blog-detail-types'

const BlogDetailPage = (props: Readonly<BlogDetailProps>) => {
  const { model } = props

  return (
    <>
      <Breadcrumbs />
      <div className="flex flex-col space-y-14 lg:space-y-32">
        <div className="container flex justify-center">
          <div className="w-full lg:w-2/3 flex flex-col space-y-8 lg:space-y-14">
            {(model.heroHeading ||
              model.heroSubheading ||
              model.heroAName ||
              model.heroATitle) && (
              <BlogDetailHero
                heading={model.heroHeading}
                subheading={model.heroSubheading}
                desktopImage={model.heroDesktopImage}
                mobileImage={model.heroMobileImage}
                authorImage={model.heroAuthorImage}
                authorName={model.heroAName}
                authorTitle={model.heroATitle}
                publishDate={model.publishDate}
              />
            )}
            {model.mainBody && <SharedRTF html={model.mainBody} />}
            {model.pageContent?.map((blockModel, i) => (
              <BlockComponentSelector
                key={`pageContent-${i}`}
                {...blockModel}
                hideContainerClass={true}
              />
            ))}
          </div>
        </div>
        {model.fullWidthContentArea && (
          <div className="flex flex-col space-y-14 lg:space-y-20">
            {model.fullWidthContentArea.map((blockModel, i) => (
              <BlockComponentSelector
                key={`fullWidthContentArea-${i}`}
                {...blockModel}
              />
            ))}
          </div>
        )}
      </div>
    </>
  )
}
export default BlogDetailPage
